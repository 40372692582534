import { Component, Input } from '@angular/core';

@Component({
	selector: 'seira-text-with-label',
	templateUrl: './text-with-label.component.html',
	styleUrls: ['./text-with-label.component.scss'],
})
export class TextWithLabelComponent {
	@Input() label!: string;
}
