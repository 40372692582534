<div class="d-flex flex-column mt-2 align-items-center">
	<div
		*ngIf="carregando; else mapas"
		class="d-flex justify-content-center align-items-center w-100 h-50"
		style="min-height: 500px">
		<seira-loading [pageCentered]="false"></seira-loading>
	</div>
	<ng-template #mapas>
		<div
			id="desvio-interpolacao"
			style="position: relative"
			class="w-100"
			*ngIf="interpolacaoDesvio">
			<label class="text-center text-tertiary fw-semibold mb-2 w-100">
				MAPA DE INTERPOLAÇÃO DE DESVIO
			</label>
			<div class="col-12 mb-3 mb-lg-0 p-0">
				<div class="p-0 mx-1">
					<seira-mapa-interpolacao
						[mapaId]="'desvio'"
						[imageOpacity]="1"
						[colorJsonInterpolacao]="colorJsonInterpolacao"
						[interpolacao]="interpolacaoDesvio"
						[weight]="weight"
						[carregando]="carregando"
						[valores]="valoresDesvio"
						[zoomOnlyWithCtrl]="true">
					</seira-mapa-interpolacao>
				</div>
			</div>
		</div>
	</ng-template>
</div>
