import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'seira-alert',
	templateUrl: './alert.component.html',
	styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
	@Input() type!:
		| 'primary'
		| 'danger'
		| 'success'
		| 'warning'
		| 'info'
		| 'indigo';
	icon = '';
	ngOnInit() {
		switch (this.type) {
			case 'danger':
				this.icon = 'x-circle';
				break;
			case 'primary':
				this.icon = 'bell-ringing';
				break;
			case 'success':
				this.icon = 'check-circle';
				break;
			case 'warning':
				this.icon = 'warning-circle';
				break;
			case 'info':
				this.icon = 'info-circle';
				break;
			case 'indigo':
				this.icon = 'gear-circle';
				break;
		}
	}
}
