import {
	DadosGraficoVariaveis,
	DadosSerieVariavelMeteorologica,
	SerieVariavelMeteorologica,
} from './serie-variavel-meteorologica';
import mensagens from './mensagens';
import { getOptions } from 'highcharts';

export interface DadosTemperatura extends DadosGraficoVariaveis {
	temperatura: number[];
}

export class SerieTemperatura extends SerieVariavelMeteorologica {
	constructor(readonly nomeDaSerie?: string) {
		super();
	}

	obterDadosSerie(
		dados: DadosTemperatura,
		indexEixo: number
	): DadosSerieVariavelMeteorologica {
		return {
			serie: [
				{
					yAxis: indexEixo,
					name: this.nomeDaSerie
						? this.nomeDaSerie
						: mensagens['variavel.temperatura'],
					type: 'spline',
					data: this.prepararSerie(dados.datas, dados.temperatura),
					color: getOptions().colors?.at(3),
					tooltip: {
						valueDecimals: 2,
						valueSuffix: ` ${mensagens['variavel.temperatura.unidade']}`,
					},
				},
			],
			eixo: {
				title: {
					text: this.nomeDaSerie
						? this.adicionarUnidade(this.nomeDaSerie)
						: mensagens['variavel.temperatura.titulo-meteograma'],
				},
				min: 0,
				softMax: 30,
				offset: 0,
				lineWidth: 2,
				opposite: false,
				labels: { align: 'left', x: 3 },
			},
		};
	}

	private adicionarUnidade(nomeDaSerie: string): string {
		return nomeDaSerie + ` ( ${mensagens['variavel.temperatura.unidade']} )`;
	}
}
