import { Component, Input } from '@angular/core';
import { LegendaCor } from '@utils/interpolacao/interpolacao';

@Component({
	selector: 'seira-monitoramento-legenda',
	templateUrl: './monitoramento-legenda.component.html',
	styleUrls: ['./monitoramento-legenda.component.scss'],
})
export class MonitoramentoLegendaComponent {
	@Input()
	valores!: LegendaCor[];
	@Input()
	carregando!: boolean;
	@Input() maxWidth!: string;
	@Input() class = 'mb-1 me-2 w-100';
}
