export enum Formato {
	TABELA = 'Tabela',
	GRAFICO = 'Gráfico',
	MAPA = 'Mapa',
}

export enum FormatoVariaveisMeteorologicas {
	TABELA = 'Tabela',
	GRAFICO = 'Gráfico',
}

export enum TipoRelatorio {
	EVAPOTRANSPIRACAO = 'Evapotranspiração',
	VARIAVEL_METEOROLOGICA = 'Variável meteorológica',
}

export enum TipoGraficos {
	QUANTIS_MENSAL = 'Quantis mensal',
	QUANTIS_ANUAL = 'Quantis anual',
	QUANTIS_PLUVIOMETRICOS = 'Quantis por período chuvoso',
	SPI = 'SPI',
	DESVIO_MILIMETRO = 'Desvio (mm)',
	DESVIO_PORCENTAGEM = 'Desvio (%)',
	PLUVIOMETRIA_OBSERVADA = 'Pluviometria observada',
	CLIMATOLOGIA = 'Climatologia',
	NUMERO_DIAS_COM_CHUVA = 'NDC',
	PLUVIOMETRIA_OBSERVADA_DESVIO_PORCENTAGEM_CLIMATOLOGIA = 'Observado/Desvio (%)/Climatologia',
	PLUVIOMETRIA_OBSERVADA_DESVIO_MM_CLIMATOLOGIA = 'Observado/Desvio (mm)/Climatologia',
}

export enum TiposMapas {
	PLUVIOMETRIA_OBSERVADA = 'Pluviometria observada',
	CLIMATOLOGIA = 'Climatologia',
	QUANTIS_MENSAL = 'Quantis mensal',
	QUANTIS_ANUAL = 'Quantis anual',
	CHUVA_ESTADO = 'Chuva no estado',
	DESVIO_MILIMETRO = 'Desvio (mm)',
	DESVIO_PORCENTAGEM = 'Desvio (%)',
	NUMERO_DIAS_COM_CHUVA = 'NDC',
	IAPM = 'IAPM',
	MOSAICO_CHUVAS = 'Mosaico de chuvas',
}

export enum TipoVariaveisMeteorologicas {
	PRECIPITACAO = 'Precipitação',
	UMIDADE = 'Umidade',
	TEMPERATURA = 'Temperatura',
	NEBULOSIDADE = 'Nebulosidade',
	PRESSAO = 'Pressão',
	VENTO = 'Vento',
	PRESSAO_ATMOSFERICA = 'Pressão Atmosférica',
	FLUXO_CALOR_SOLO = 'Fluxo de calor do solo',
	TEMPERATURA_AR = 'Temperatura do ar',
	TEMPERATURA_SOLO = 'Temperatura do solo',
	TEMPERATURA_INSTANTANEA = 'Temperatura instantânea',
	TEMPERATURA_MAXIMA = 'Temperatura Máxima',
	TEMPERATURA_MINIMA = 'Temperatura Mínima',
	QUANTIS_MENSAL = 'Quantis mensal',
	QUANTIS_ANUAL = 'Quantis anual',
	UMIDADE_RELATIVA = 'Umidade relativa',
	UMIDADE_INSTANTANEA = 'Umidade instantânea',
	UMIDADE_MAXIMA = 'Umidade máxima',
	UMIDADE_MINIMA = 'Umidade mínima',
	PRESSAO_INSTANTANEA = 'Pressão instantânea',
	PRESSAO_MAXIMA = 'Pressão máxima',
	PRESSAO_MINIMA = 'Pressão mínima',
	RAJADA_VENTO = 'Rajada de vento',
	RADIACAO = 'Radiação',
	RADIACAO_GLOBAL = 'Radiação global',
}

export enum TipoTabelas {
	PLUVIOMETRIA_OBSERVADA = 'Pluviometria observada',
	PRECIPITACAO_DIARIA = 'Precipitação diária',
	PRECIPITACAO_ANUAL = 'Precipitação anual',
	PRECIPITACAO_ACUMULADA = 'Precipitação acumulada',
	MEDIA_HISTORICA = 'Média histórica',
	PLUVIOMETRICO_POR_POSTO = 'Pluviométrico por posto',
	PLUVIOMETRICO_DO_ESTADO = 'Pluviométrico do estado',
	QUANTIS_MENSAL = 'Quantis mensal',
	QUANTIS_ANUAL = 'Quantis anual',
	HISTORICO_DA_SUDENE = 'Histórico da SUDENE',
	NUMERO_DIAS_COM_CHUVA = 'NDC',
	DESVIO = 'Desvio',
	VALORES_EXTREMOS_PLUVIOMETRIA = 'Valores Extremos de Chuva',
	CLIMATOLOGIA = 'Climatologia',
	SPI = 'SPI',
}

