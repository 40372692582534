import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { OptionRadio } from '@shared/interfaces/public-radio-group';
import { MunicipioService } from '@modulos/meteorologia/submodulos/municipio/services/municipio.service';
import { MicrorregiaoService } from '@modulos/meteorologia/submodulos/microrregiao/services/microrregiao.service';
import { MesorregiaoService } from '@modulos/meteorologia/submodulos/mesorregiao/services/mesorregiao.service';
import { combineLatest } from 'rxjs';
import { EstacaoService } from '@modulos/meteorologia/submodulos/estacao/services/estacao.service';
import { PostosRelatorios } from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import { Municipio } from '@modulos/meteorologia/submodulos/municipio/interfaces/municipio';
import { Microrregiao } from '@modulos/meteorologia/submodulos/microrregiao/interfaces/microrregiao';
import { Mesorregiao } from '@modulos/meteorologia/submodulos/mesorregiao/interfaces/Mesorregiao';
import { ToastrService } from 'ngx-toastr';
import { Bacia } from '@modulos/recursos-hidricos/submodulos/bacia/interface/bacia';
import { Regional } from '@modulos/meteorologia/submodulos/regional/interfaces/Regional';
import { RegionalService } from '@modulos/meteorologia/submodulos/regional/services/regional.service';
import { BaciaService } from '@modulos/recursos-hidricos/submodulos/bacia/services/bacia.service';
import { EstacaoFiltrada } from '@modulos/meteorologia/submodulos/estacao/interfaces/estacao';

@Component({
	selector: 'seira-modal-relatorio-postos',
	templateUrl: './modal-relatorio-postos.component.html',
	styleUrls: ['./modal-relatorio-postos.component.scss'],
})
export class ModalRelatorioPostosComponent implements OnInit {
	@Input() close!: () => void;
	@Input() onPostosSelecionados!: (postos: PostosRelatorios[]) => void;
	@Input() dadosSelecionados: PostosRelatorios[] = [];

	loadingFiltragem = false;
	formSelecao: FormGroup = this.formBuilder.group({
		modoSelecao: new FormControl('lote'),
		opcoesSelecionadas: new FormControl([]),
	});
	filtroForm: FormGroup = this.formBuilder.group({
		palavraChave: new FormControl(null),
		mesorregiao: new FormControl(null),
		microrregiao: new FormControl(null),
		municipio: new FormControl(null),
		bacia: new FormControl(null),
		regional: new FormControl(null),
		apenasAtivos: new FormControl(true),
	});
	mesorregioes: Mesorregiao[] = [];
	microrregioes: Microrregiao[] = [];
	microrregioesCompleta: Microrregiao[] = [];
	municipios: Municipio[] = [];
	bacias: Bacia[] = [];
	regionais: Regional[] = [];
	municipiosCompleto: Municipio[] = [];
	postos: PostosRelatorios[] = [];
	postosSelecionados: PostosRelatorios[] = [];
	modosSelecao: OptionRadio<'lote' | 'individual'>[] = [
		{ label: 'Em lote', value: 'lote' },
		{ label: 'Individual', value: 'individual' },
	];
	constructor(
		private formBuilder: FormBuilder,
		private municipioService: MunicipioService,
		private baciaService: BaciaService,
		private regionalService: RegionalService,
		private microrregioesService: MicrorregiaoService,
		private mesorregiosService: MesorregiaoService,
		private estacaoService: EstacaoService,
		private toastr: ToastrService
	) {}
	ngOnInit() {
		this.carregarSelects();
		this.observeMesorregioes();
		this.observeMicrorregioes();
		this.carregarDadosSelecionados();
	}

	carregarDadosSelecionados() {
		if (this.dadosSelecionados.length > 0) {
			this.postos = this.dadosSelecionados;
		}
	}
	observeMesorregioes() {
		this.filtroForm.get('mesorregiao')?.valueChanges.subscribe({
			next: mesorregiaoId => {
				this.filtroForm.get('microrregiao')?.reset();
				if (mesorregiaoId) {
					this.microrregioes = this.microrregioesCompleta.filter(
						m => m.mesorregiao.id === mesorregiaoId
					);
					this.municipios = this.municipiosCompleto.filter(municipio =>
						this.microrregioes.some(
							microrregiao => microrregiao.id === municipio.microrregiao.id
						)
					);
				} else {
					this.microrregioes = this.microrregioesCompleta;
					this.municipios = this.municipiosCompleto;
				}
			},
		});
	}

	observeMicrorregioes() {
		this.filtroForm.get('microrregiao')?.valueChanges.subscribe({
			next: microrregiaoId => {
				this.filtroForm.get('municipio')?.reset();
				if (microrregiaoId) {
					this.municipios = this.municipiosCompleto.filter(
						m => m.microrregiao.id === microrregiaoId
					);
				} else {
					this.municipios = this.municipiosCompleto;
				}
			},
		});
	}

	carregarSelects() {
		combineLatest([
			this.municipioService.list(),
			this.microrregioesService.list(),
			this.mesorregiosService.listar(),
			this.baciaService.listSelect(),
			this.regionalService.list(),
		]).subscribe({
			next: ([municipios, microrregioes, mesorregioes, bacias, regionais]) => {
				this.municipios = municipios._embedded.municipios;
				this.municipiosCompleto = municipios._embedded.municipios;
				this.microrregioes = microrregioes._embedded.microrregioes;
				this.microrregioesCompleta = microrregioes._embedded.microrregioes;
				this.mesorregioes = mesorregioes._embedded.mesorregioes;
				this.bacias = bacias._embedded.bacias;
				this.regionais = regionais._embedded.regionais;
			},
		});
	}

	filtrar() {
		const filtro = this.filtroForm.getRawValue();

		const apenasAtivos = !!filtro.apenasAtivos;
		const tipoEstacao = ['PCD', 'PLUVIOMETRO_CONVENCIONAL'] as [
			'PCD',
			'PLUVIOMETRO_CONVENCIONAL',
		];
		const regionalId = filtro.regional ?? undefined;
		const mesorregiaoId = filtro.mesorregiao ?? undefined;
		const microrregiaoId = filtro.microrregiao ?? undefined;
		const municipioId = filtro.municipio ?? undefined;
		const baciaId = filtro.bacia ?? undefined;
		const palavraChave = filtro.palavraChave ?? undefined;

		this.loadingFiltragem = true;
		this.estacaoService
			.filteredList(
				apenasAtivos,

				{
					regional: regionalId,
					microrregiao: microrregiaoId,
					mesorregiao: mesorregiaoId,
					municipio: municipioId,
					bacia: baciaId,
				} as EstacaoFiltrada,
				tipoEstacao,
				palavraChave
			)
			.subscribe({
				next: estacoes => {
					const estacoesFiltrada = estacoes.map(estacao => ({
						id: estacao.id,
						municipio: estacao.nomeMunicipio,
						nomePosto: estacao.nomePosto,
						responsavel: estacao.responsavel ?? '-',
						tipo: estacao.tipoEstacao,
					}));
					this.postos = this.postos.filter(posto => posto.selected);
					estacoesFiltrada.forEach(estacao => {
						const postoExistente = this.postos.find(
							posto => posto.id === estacao.id
						);
						if (!postoExistente) {
							this.postos.push(estacao);
						}
					});
					this.loadingFiltragem = false;
				},
				error: () => {
					this.loadingFiltragem = false;
					this.toastr.error('Ocorreu um erro ao buscar os postos', 'Erro');
				},
			});
	}

	_onPostosSelecionados(postos: PostosRelatorios[]) {
		this.postosSelecionados = postos;
	}

	selecionar() {
		this.onPostosSelecionados(this.postosSelecionados);
	}
}
