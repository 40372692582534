<div id="select" seiraClickOutside (clickOutside)="open = false">
	<div
		(click)="click()"
		class="text-primary fs-6"
		[ngClass]="open ? 'text-aero' : 'text-primary'">
		{{ label }} <i [ngClass]="open ? 'ph-caret-up' : 'ph-caret-down'"></i>
	</div>
	<div id="opcoes" [style.display]="open ? 'block' : 'none'" class="p-2">
		<seira-public-checkbox
			*ngFor="let checkbox of checkboxesList"
			[label]="checkbox.label"
			[controlName]="checkbox.controlName" />
	</div>
</div>
