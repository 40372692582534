import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'seira-select-list-menu',
	templateUrl: './select-list-menu.component.html',
	styleUrls: ['./select-list-menu.component.scss'],
})
export class SelectListMenuComponent implements OnInit {
	@Input() opcoes: { name: string; isFixed: boolean }[] = [];
	@Input() disabled: boolean = false;
	@Input() tituloBotao: string = 'Personalizar';
	@Output() atualizarOpcoesSelecionadas = new EventEmitter<string[]>();

	itensSelecionados: string[] = [];

	constructor() {}

	ngOnInit(): void {
		this.itensSelecionados = this.opcoes.map(option => option.name);
	}

	toggleSelection(item: { name: string; isFixed: boolean }, event: Event) {
		event.stopPropagation();
		const index = this.itensSelecionados.indexOf(item.name);
		if (index === -1) {
			this.itensSelecionados.push(item.name);
		} else {
			this.itensSelecionados.splice(index, 1);
		}
	}

	isSelected(item: { name: string; isFixed: boolean }): boolean {
		return this.itensSelecionados.includes(item.name);
	}

	_atualizarOpcoesSelecionadas() {
		this.atualizarOpcoesSelecionadas.emit(this.itensSelecionados);
	}
}
