<div class="container">
	<form [formGroup]="form" class="form-group">
		<!--	<div class="row mt-3">
			<div class="col-12 col-md-3">
				&lt;!&ndash;				<seira-public-radio-group&ndash;&gt;
				&lt;!&ndash;					idRadio="modoVisualizacaoEstacoes"&ndash;&gt;
				&lt;!&ndash;					[options]="OpcoesTipoVisualizacao"&ndash;&gt;
				&lt;!&ndash;					label="Modo de visualização"&ndash;&gt;
				&lt;!&ndash;					controlName="tipoVisualizacao"&ndash;&gt;
				&lt;!&ndash;					(change)="interpolarValores()">&ndash;&gt;
				&lt;!&ndash;				</seira-public-radio-group>&ndash;&gt;
			</div>
		</div>-->
		<div
			*ngIf="carregando; else mapa"
			class="d-flex justify-content-center align-items-center w-100 h-50"
			style="min-height: 500px">
			<seira-loading [pageCentered]="false"></seira-loading>
		</div>
		<ng-template #mapa>
			<!--		<div
				*ngIf="getFormItemValue('tipoVisualizacao') === 'tabela'"
				class="mt-4">
				<div class="row" *ngIf="dados.length; else nenhumResultado">
					<label class="text-center text-tertiary fw-semibold mb-2 w-100">
						TABELA DE CHUVA NO ESTADO
					</label>
					<seira-card-info aditionalClasses="my-3">
						Placeholder informativo
					  </seira-card-info>
					<div class="col-12">
						<div class="mb-3" style="position: relative">
							<seira-loading *ngIf="carregando"></seira-loading>
							<seira-public-table
								id="tabelaDados"
								[searching]="false"
								[info]="true"
								[paging]="true"
								[hasFooter]="true"
								[hasHeader]="false"
								[data]="dados"
								[columns]="colunasTabelaPrecipitacoes">
							</seira-public-table>
						</div>
					</div>
				</div>
			</div>-->
			<div class="mt-4">
				<div class="row" *ngIf="interpolacao; else nenhumResultado">
					<label class="text-center text-tertiary fw-semibold mb-2 w-100">
						MAPA DE CHUVA NO ESTADO
					</label>
					<div class="col-12">
						<div class="mb-3" style="position: relative">
							<seira-mapa-interpolacao
								[mapaId]="'chuvaEstado'"
								[imageOpacity]="0.8"
								[interpolacao]="interpolacao"
								[carregando]="carregando"
								[valores]="valores"
								[zoomOnlyWithCtrl]="true">
							</seira-mapa-interpolacao>
						</div>
					</div>
				</div>
			</div>
		</ng-template>
		<ng-template #nenhumResultado>
			<div class="row">
				<div class="col-12">
					<seira-sem-dados></seira-sem-dados>
				</div>
			</div>
		</ng-template>
	</form>
</div>
