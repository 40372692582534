import { Component, Input, OnInit } from '@angular/core';
import {
	BaciaReservatoriosAcumulado,
	Reservatorio,
} from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/interfaces/reservatorios';
import { BsModalService } from 'ngx-bootstrap/modal';
import { corPorVolume } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/utils/utils';
import { numberToBrNumber } from '@utils';
import { ModalReservatoriosComponent } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/componentes/modal-reservatorios/modal-reservatorios.component';

@Component({
	selector: 'seira-popup-reservatorio',
	templateUrl: './popup-reservatorio.component.html',
	styleUrls: ['./popup-reservatorio.component.scss'],
})
export class PopupReservatorioComponent implements OnInit {
	@Input() conteudoPopup!: Reservatorio | BaciaReservatoriosAcumulado;
	isReservatorio = false;
	constructor(private modalService: BsModalService) {}
	ngOnInit() {
		if (this.conteudoPopup.hasOwnProperty('bacia')) {
			this.isReservatorio = true;
		}
	}

	abrirModalReservatorio() {
		if (!this.isReservatorio) {
			return;
		}
		const reservatorio = this.conteudoPopup as Reservatorio;
		const close = () => {
			this.modalService.hide();
		};
		this.modalService.show(ModalReservatoriosComponent, {
			class: 'modal-xl modal-dialog-centered bg-transparent',
			initialState: {
				reservatorio,
				close,
			},
		});
	}
	get dataRegistro(): Date {
		return new Date(this.conteudoPopup.volume.dataColeta);
	}

	protected readonly corPorVolume = corPorVolume;
	protected readonly numberToBrNumber = numberToBrNumber;
}
