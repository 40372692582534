import { Component, HostBinding, Input } from '@angular/core';

@Component({
	selector: 'seira-custom-error-page',
	templateUrl: './custom-error-page.component.html',
	styleUrls: ['./custom-error-page.component.scss'],
})
export class CustomErrorPageComponent {
	@HostBinding('class') clazz = 'expandir-flex';
	@Input() msg = 'O recurso solicitado não foi encontrado.';
	@Input() statusCode = 404;
	@Input() returnHome = true;
}
