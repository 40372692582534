/**
 * @description usado para o formulário.
 */
export enum EPeriodoChuvoso {
	ANO_CIVIL = 'Ano civil',
	QUADRA_CHUVOSA = 'Quadra chuvosa',
	QUINTETO_CHUVOSO = 'Quinteto chuvoso',
	SEMESTRE_CHUVOSO = 'Semestre chuvoso',
}

export const PeriodoChuvosoMap = new Map<string, string>([
	['ANO_CIVIL', 'Ano civil'],
	['QUADRA_CHUVOSA', 'Quadra chuvosa'],
	['QUINTETO_CHUVOSO', 'Quinteto chuvoso'],
	['SEMESTRE_CHUVOSO', 'Semestre chuvoso'],
]);
