import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Rotas } from '@core/enums/routes';
import { Projection } from '@core/enums/projections';
import { Municipio, MunicipioForm } from '../interfaces/municipio';
import { HttpClientProtectedService } from '@core/services/auth/http-client-protected.service';

@Injectable({
	providedIn: 'root',
})
export class MunicipioService {
	constructor(private http: HttpClientProtectedService) {}

	adicionar(newMunicipio: MunicipioForm): Observable<MunicipioForm> {
		return this.http.post<MunicipioForm, MunicipioForm>(
			Rotas.MUNICIPIO,
			newMunicipio
		);
	}

	editar(editMunicipio: MunicipioForm): Observable<MunicipioForm> {
		return this.http.patch<MunicipioForm, any>(
			Rotas.MUNICIPIO + '/' + editMunicipio.id,
			editMunicipio
		);
	}

	remover(municipioId: number): Observable<any> {
		return this.http.delete<Municipio>(Rotas.MUNICIPIO + '/' + municipioId);
	}

	buscarPorId(municipioId: number): Observable<MunicipioForm> {
		return this.http.get<MunicipioForm>(
			Rotas.MUNICIPIO +
				'/' +
				municipioId +
				'?projection=' +
				Projection.MUNICIPIO_All
		);
	}

	list(): Observable<any> {
		return this.http.get<Municipio[]>(Rotas.MUNICIPIO);
	}
}
