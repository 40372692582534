import * as Highcharts from 'highcharts/highstock';

const configResponsividade: Highcharts.Options['responsive'] = {
	rules: [
		{
			condition: {
				maxWidth: 500,
			},
			chartOptions: {
				subtitle: {
					y: 0,
				},
				legend: {
					align: 'center',
					verticalAlign: 'bottom',
					layout: 'horizontal',
				},
				yAxis: {
					labels: {
						align: 'left',
						x: 0,
						y: -5,
					},
					title: {
						text: null,
					},
				},
				credits: {
					enabled: false,
				},
			},
		},
		{
			condition: {
				minWidth: 0,
			},
			chartOptions: {
				rangeSelector: {
					y: -26,
				},
			},
		},
		{
			condition: {
				minWidth: 300,
			},
			chartOptions: {
				rangeSelector: {
					y: -14,
				},
			},
		},
		{
			condition: {
				minWidth: 560,
			},
			chartOptions: {
				rangeSelector: {
					y: 20,
				},
				subtitle: {
					y: 14,
				},
			},
		},
	],
};
export const options: Highcharts.Options = {
	navigator: {
		maskFill: '#9BC1D480',
	},
	tooltip: {
		headerFormat: undefined,
		dateTimeLabelFormats: {
			day: '%A, %e %b %Y',
			hour: '%A, %e %b, %H:%M',
			millisecond: '%A, %e %b, %H:%M:%S.%L',
			minute: '%A, %e %b, %H:%M',
			month: '%B %Y',
			second: '%A, %e %b, %H:%M:%S',
			week: 'Week from %A, %e %b %Y',
			year: '%Y',
		},
	},
	series: [{ type: 'column' }],
	noData: {
		useHTML: true,
	},
	exporting: {
		enabled: false,
	},
	credits: {
		enabled: false,
	},
	legend: {
		enabled: false,
	},
	xAxis: {
		type: 'datetime',
		offset: 40,
	},
	plotOptions: {
		column: {
			stickyTracking: true,
		},
		series: {
			getExtremesFromAll: true,
			dataGrouping: {
				enabled: false,
			},
		},
		bar: {
			dataLabels: {
				enabled: true,
			},
		},
	},
	rangeSelector: {
		buttons: [
			{
				type: 'day',
				count: 1,
				text: '24h',
			},
			{
				type: 'day',
				count: 2,
				text: '48h',
			},
			{
				type: 'day',
				count: 3,
				text: '72h',
			},
			{
				type: 'day',
				count: 4,
				text: '96h',
			},
			{
				type: 'day',
				count: 5,
				text: '120h',
			},
			{
				type: 'all',
				count: 1,
				text: 'Todo',
			},
		],
		selected: 5,
		inputEnabled: false,
		inputStyle: {
			color: 'rgb(102, 102, 102)',
		},
	},
	yAxis: [
		{
			labels: {
				align: 'left',
				x: 10,
			},
			title: {
				text: '',
			},
			opposite: false,
			lineWidth: 2,
			offset: 0,
			className: 'text-white',
		},
	],
	responsive: configResponsividade,
};
