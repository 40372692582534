import { MenuItens } from '@layout/interfaces/layoutLabels';

export interface Referencia {
	singular: string;
	plural: string;
	com_artigo: string;
	grupo: MenuItens;
}

//Segurança
export const UsuarioReferencia: Referencia = {
	grupo: MenuItens.SEGURANCA,
	singular: 'Usuário',
	plural: 'Usuários',
	com_artigo: 'o usuário',
};

export const OrgaoReferencia: Referencia = {
	grupo: MenuItens.SEGURANCA,
	singular: 'Órgão',
	plural: 'Órgãos',
	com_artigo: 'o órgão',
};

export const GrupoReferencia: Referencia = {
	grupo: MenuItens.SEGURANCA,
	singular: 'Grupo de permissões',
	plural: 'Grupos de permissões',
	com_artigo: 'o grupo de permissões',
};

//Monitoramento
export const EstacaoReferencia: Referencia = {
	grupo: MenuItens.MONITORAMENTO,
	singular: 'Estação',
	plural: 'Estações',
	com_artigo: 'a estação',
};

export const CartasCampoReferencia: Referencia = {
	grupo: MenuItens.MONITORAMENTO,
	singular: 'Medição pluviométrica',
	plural: 'Medições pluviométricas',
	com_artigo: 'a medição pluviométrica',
};

//Zoneamento
export const SoloReferencia: Referencia = {
	grupo: MenuItens.ZONEAMENTO,
	singular: 'Solo',
	plural: 'Solos',
	com_artigo: 'o solo',
};

export const CulturaReferencia: Referencia = {
	grupo: MenuItens.ZONEAMENTO,
	singular: 'Cultura',
	plural: 'Culturas',
	com_artigo: 'a cultura',
};

export const ZoneamentoEdafoclimaticoReferencia: Referencia = {
	grupo: MenuItens.ZONEAMENTO,
	singular: 'Zoneamento edafoclimático',
	plural: 'Zoneamentos edafoclimáticos',
	com_artigo: 'o zoneamento edafoclimático',
};

export const RiscoClimaticoReferencia: Referencia = {
	grupo: MenuItens.ZONEAMENTO,
	singular: 'Risco climático',
	plural: 'Riscos climáticos',
	com_artigo: 'o risco climático',
};
//Regiões
export const MesorregiaoReferencia: Referencia = {
	grupo: MenuItens.REGIAO,
	singular: 'Mesorregião',
	plural: 'Mesorregiões',
	com_artigo: 'a mesorregião',
};

export const MicrorregiaoReferencia: Referencia = {
	grupo: MenuItens.REGIAO,
	singular: 'Microrregião',
	plural: 'Microrregiões',
	com_artigo: 'a microrregião',
};

export const MunicipioReferencia: Referencia = {
	grupo: MenuItens.REGIAO,
	singular: 'Município',
	plural: 'Municípios',
	com_artigo: 'o município',
};
export const RegionalReferencia: Referencia = {
	grupo: MenuItens.REGIAO,
	singular: 'Regional',
	plural: 'Regionais',
	com_artigo: 'a regional',
};

// Configurações

export const ModeloNumericoReferencia: Referencia = {
	grupo: MenuItens.CONFIGURACAO,
	singular: 'Modelo numérico',
	plural: 'Modelos numéricos',
	com_artigo: 'o modelo numérico',
};

export const AvisoReferencia: Referencia = {
	grupo: MenuItens.CONFIGURACAO,
	singular: 'Configuração de aviso',
	plural: 'Configurações de avisos',
	com_artigo: 'a configuração de aviso',
};

export const VeranicoReferencia: Referencia = {
	grupo: MenuItens.CONFIGURACAO,
	singular: 'Configuração de veranico',
	plural: 'Configurações de veranicos',
	com_artigo: 'a configuração veranico',
};

export const CompararResultadosReferencia: Referencia = {
	grupo: MenuItens.CONFIGURACAO,
	singular: 'Resultado do modelo',
	plural: 'Modelos numéricos',
	com_artigo: 'o modelo numérico',
};

export const RangesQuantisReferencia: Referencia = {
	grupo: MenuItens.CONFIGURACAO,
	singular: 'Ranges de quantis',
	plural: 'Ranges de quantis',
	com_artigo: 'os ranges de quantis',
};

export const ClimatologiaReferencia: Referencia = {
	grupo: MenuItens.CONFIGURACAO,
	singular: 'Climatologia ',
	plural: 'Climatologias',
	com_artigo: 'a climatologia',
};

export const ImagensLandingPageReferencia: Referencia = {
	grupo: MenuItens.CONFIGURACAO,
	singular: 'Imagem do sistema',
	plural: 'Imagens do sistema',
	com_artigo: 'as imagens do sistema',
};

// Reservatórios

export const ReservatorioReferencia: Referencia = {
	grupo: MenuItens.RESERVATORIO,
	singular: 'Reservatório',
	plural: 'Reservatórios',
	com_artigo: 'o reservatório',
};

export const BaciaReferencia: Referencia = {
	grupo: MenuItens.RESERVATORIO,
	singular: 'Bacia/Sub-bacia',
	plural: 'Bacias/Sub-bacias',
	com_artigo: 'a bacia/sub-bacia',
};

export const ClassificacaoReservatorioReferencia: Referencia = {
	grupo: MenuItens.RESERVATORIO,
	singular: 'Configuração de classificação de reservatório',
	plural: 'Configurações de classificação de reservatórios',
	com_artigo: 'a configuração de classificação de reservatório',
};

export const HistoricoReservatorioReferencia: Referencia = {
	grupo: MenuItens.RESERVATORIO,
	singular: 'Histórico do reservatório',
	plural: 'Históricos do reservatório',
	com_artigo: 'o histórico do reservatório',
};
