import {
	Component,
	inject,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { FormGroup, Validators } from '@angular/forms';
import { PostosRelatorios } from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import { Estacao } from '@home/submodulos/dados-meteorologicos/interfaces/filtros-opcoes';
import { Select } from '@layout/interfaces/select';
import {
	criarCanvasInterpolacao,
	InterpolacaoCanvasBounds,
} from '@utils/interpolacao/interpolacao';
import { GeoJSON } from '@shared/interfaces/geometry';
import { HttpClient } from '@angular/common/http';
import { Polygon } from 'geojson';
import { Iapm } from '@home/submodulos/dados-meteorologicos/interfaces/iapm';
import { GraficosPrecipitacaoService } from '@home/submodulos/dados-meteorologicos/services/graficos-precipitacao.service';
import { format, formatISO } from 'date-fns';
import { VariavelMeteorologica } from '@utils/interpolacao/intepolacao-parametros';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import Position = GeoJSON.Position;
import FeatureCollection = GeoJSON.FeatureCollection;
import {
	capitalizeFirstLetter,
	getBase64ImageFromURL,
	numberToBrNumber,
	obter_erro_request,
} from '@utils';
import { ToastrService } from 'ngx-toastr';
import { DadosTabela } from '@home/submodulos/dados-meteorologicos/interfaces/chuva-estado';
import html2canvas from 'html2canvas';
import ptBrLocale from 'date-fns/locale/pt-BR';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfseira from '@utils/pdf-seira';

@Component({
	selector: 'seira-mapa-iapm',
	templateUrl: './mapa-iapm.component.html',
	styleUrls: ['./mapa-iapm.component.scss'],
})
export class MapaIapmComponent implements OnInit, OnDestroy {
	@ViewChild('legendaMapaMobile') legendaMapaMobile!: TemplateRef<any>;
	interpolacaoPrecipitacao?: InterpolacaoCanvasBounds;
	interpolacaoIAPM?: InterpolacaoCanvasBounds;
	carregando = false;
	valoresPrecipitacao: Iapm[] = [];
	valoresIAPM: Iapm[] = [];
	inputs = inject(INPUTS_RELATORIOS);
	form!: FormGroup;
	postos: PostosRelatorios[] = [];
	estacoes: Estacao[] = [];
	microrregioes: Select[] = [];
	medicoes: Iapm[] = [];
	loading = false;
	regiao?: Position[][];

	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => {
				return this.exportarPDF();
			},
		},
	];

	constructor(
		private httpClient: HttpClient,
		private graficosPrecipitacaoService: GraficosPrecipitacaoService,
		private toastr: ToastrService
	) {}

	ngOnInit() {
		this.form = this.inputs.form;
		setTimeout(() => {
			this.form.patchValue({
				dataInicio: this.calcularDataInicio(),
				dataFim: new Date(),
			});
			this.setValidators();
		}, 0);
		this.initialValues();
	}

	initialValues() {
		const pbGeoJsonObservable = this.httpClient.get<FeatureCollection>(
			'assets/geoJson/pb-geo.json'
		);
		pbGeoJsonObservable.subscribe(value => {
			this.regiao = (<Polygon>value.features[0].geometry)
				.coordinates as Position[][];
		});
	}

	setValidators() {
		this.form.get(FormularioRelatorio.TIPO)?.setValidators(Validators.required);
		this.form
			.get(FormularioRelatorio.PERIODO)
			?.setValidators(Validators.required);
	}

	calcularDataInicio(): Date {
		const dataInicio = new Date();
		dataInicio.setMonth(dataInicio.getMonth() - 6);
		return dataInicio;
	}

	gerarRelatorio() {
		if (this.form.invalid) {
			return;
		}

		const dataInicio = this.form.get('dataInicio')?.value;
		const dataFim = this.form.get('dataFim')?.value;

		if (!dataInicio || !dataFim || !this.postos.length) {
			return;
		}

		this.inputs.setLoading(true);
		this.carregando = true;

		this.graficosPrecipitacaoService
			.buscarIAPM(formatISO(new Date(dataInicio)), formatISO(new Date(dataFim)))
			.subscribe({
				next: (resp: Iapm[]) => {
					this.medicoes = resp;
					this.interpolarValores();
					this.carregando = false;
					this.inputs.setLoading(false);
				},
				error: erro => {
					this.carregando = false;
					this.inputs.setLoading(false);
					const msg_erro = obter_erro_request(erro);
					this.toastr.error(msg_erro, 'Erro ao obter informações de IAPM');
				},
			});
	}

	ngOnDestroy() {
		this.form.get(FormularioRelatorio.TIPO)?.clearValidators();
		this.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.form.get(FormularioRelatorio.PERIODO)?.clearValidators();
	}

	async interpolarValores() {
		try {
			const [interpolacaoPrecipitacao, interpolacaoIAPM] = await Promise.all([
				this.criarImagemInterpolacao('precipitacao', true),
				this.criarImagemInterpolacao('mrai', false),
			]);

			this.interpolacaoPrecipitacao =
				interpolacaoPrecipitacao as InterpolacaoCanvasBounds;
			this.interpolacaoIAPM = interpolacaoIAPM as InterpolacaoCanvasBounds;
		} catch (error) {
			console.error('Erro ao interpolar valores:', error);
		}
	}

	valoresParaInterpolacao(isPrecipitacao: boolean) {
		return this.medicoes.map(value => ({
			lat: value.latitude,
			lng: value.longitude,
			value: isPrecipitacao ? value.acumulado : value.mrai,
			nomePosto: value.nomePosto,
			isPrecipitacao: isPrecipitacao,
		}));
	}
	criarImagemInterpolacao(
		variavel: VariavelMeteorologica,
		isPrecipitacao: boolean
	): Promise<InterpolacaoCanvasBounds> {
		return new Promise((resolve, reject) => {
			const interpolacao = criarCanvasInterpolacao(
				this.valoresParaInterpolacao(isPrecipitacao),
				variavel,
				this.regiao as number[][][],
				0.01,
				true
			);
			if (interpolacao === undefined || interpolacao === null) {
				reject(`Erro ao criar imagem de interpolação de ${variavel}.`);
			}
			resolve(interpolacao);
		});
	}

	getFormItemValue(field: string) {
		return this.form.get(field)!.value;
	}

	getTituloElemento(elemento: string): string | null {
		switch (elemento) {
			case 'iapm':
				return 'Gráfico de Índice de Anomalia de Precipitação Modificado';
			case 'precipitacao':
				return 'Gráfico de Precipitação Acumulada';
			default:
				return null;
		}
	}

	getDadosTabelaChuvaEstado(dados: DadosTabela[]) {
		const tableData: any[][] = [];
		const colunas = [
			'Mesorregião',
			'Chuva Observada (mm)',
			'Chuva Esperada (mm)',
			'Desvio Observado (%)',
		];
		tableData.push(colunas);

		dados.forEach((item: DadosTabela) => {
			const rowData = [
				capitalizeFirstLetter(item.mesorregiao.toLowerCase()),
				numberToBrNumber(item.acumulado.toFixed(2)),
				numberToBrNumber(item.media.toFixed(2)),
				numberToBrNumber(item.desvio.toFixed(2)),
			];
			tableData.push(rowData);
		});

		return tableData;
	}

	getPeriodo() {
		const dataInicio = this.form.get('dataInicio')?.value;
		const dataFim = this.form.get('dataFim')?.value;

		if (!dataInicio || !dataFim) return '';

		return `${format(dataInicio, 'dd/MM/yyyy')} a ${format(
			dataFim,
			'dd/MM/yyyy'
		)}`;
	}

	async exportarPDF(medicoes?: DadosTabela[]) {
		const elementos = ['iapm', 'precipitacao'];
		const imagens = [];

		for (const elemento of elementos) {
			const elementoHtml = document.getElementById(elemento);
			if (elementoHtml) {
				const img = await html2canvas(elementoHtml).then(canvas =>
					canvas.toDataURL('image/png')
				);
				imagens.push({ imagem: img, titulo: this.getTituloElemento(elemento) });
			}
		}

		const dataFormatada = format(new Date(), "dd 'de' MMMM 'de' yyyy HH'h'mm", {
			locale: ptBrLocale,
		});
		const documentDefinition: any = await pdfseira.documentDefinitions(
			'paisagem'
		);

		documentDefinition.content.push([
			{
				text: `Relatório - IAPM - ${this.getPeriodo()}`,
				alignment: 'center',
				margin: [0, 15, 5, 15],
			},
			{
				text: `Relatório exportado em: ${dataFormatada}`,
				alignment: 'center',
				margin: [0, 10, 5, 10],
				fontSize: 12,
			},
		]);

		for (const imagem of imagens) {
			if (imagem.imagem && imagem.titulo) {
				documentDefinition.content.push(
					{
						text: imagem.titulo,
						alignment: 'center',
						margin: [0, 15, 0, 5],
						fontSize: 12,
					},
					{
						image: imagem.imagem,
						width: 515,
						heigth: 250,
						alignment: 'center',
					}
				);
			}
		}

		if (medicoes) {
			documentDefinition.content.push({
				text: `Tabela de precipitação`,
				alignment: 'center',
				margin: [0, 15, 0, 5],
				fontSize: 12,
			});
			documentDefinition.content.push({
				table: {
					widths: ['*', 'auto', 'auto', '*'],
					body: this.getDadosTabelaChuvaEstado(medicoes).map(row =>
						row.map(cell => ({
							text: cell,
							alignment: 'center',
						}))
					),
					alignment: 'center',
					layout: 'fullWidth',
					fontSize: 12,
				},
			});
		}

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}
}
