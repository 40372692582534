import {
	ChangeDetectorRef,
	Component,
	DoCheck,
	EventEmitter,
	inject,
	KeyValueDiffer,
	KeyValueDiffers,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
} from '../../../interfaces/tipos-relatorios';
import { Validators } from '@angular/forms';
import { RelatoriosService } from '../../../services/relatorios.service';
import { Moment } from 'moment';
import { optionsChartDesvio } from './chart-options';
import { Chart, SeriesOptionsType } from 'highcharts';
import { DadosDesvioRelatorio, RelatorioDesvio } from '../../../interfaces';
import { format } from 'date-fns';
import { ToastrService } from 'ngx-toastr';
import { obter_erro_request } from '@utils';

export enum GraficoDesvioPeriodos {
	DIARIO = 365,
	MENSAL = 12,
	ANUAL = 10,
}
@Component({
	selector: 'seira-grafico-desvio-milimetro',
	templateUrl: './grafico-desvio.component.html',
	styleUrls: ['./grafico-desvio.component.scss'],
})
export class GraficoDesvioComponent implements DoCheck, OnDestroy, OnInit {
	inputs = inject(INPUTS_RELATORIOS);
	opcoesGrafico: Highcharts.Options = optionsChartDesvio;
	grafico?: Highcharts.Chart;
	relatorios: RelatorioDesvio[] = [];
	@Output() instanciaGrafico = new EventEmitter<Chart>();
	private differ: KeyValueDiffer<any, any>;

	constructor(
		private readonly relatorioService: RelatoriosService,
		private readonly differs: KeyValueDiffers,
		private readonly cdr: ChangeDetectorRef,
		private toast: ToastrService
	) {
		this.setValidators();
		this.differ = this.differs.find({}).create();
	}

	ngOnInit(): void {
		this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)?.setValue('mensal');
	}

	ngOnDestroy(): void {
		this.clearValidators();
	}

	setGrafico(grafico: Chart): void {
		this.grafico = grafico;
	}

	percorrerDatas(
		dataInicial: Date,
		dataFinal: Date,
		periodo: 'diario' | 'mensal' | 'anual',
		callback: (dataAtual: Date) => any
	): void {
		const dataAtual = dataInicial;
		if (periodo === 'anual') {
			dataFinal.setMonth(dataFinal.getFullYear() + 1);
		} else {
			dataFinal.setMonth(dataFinal.getMonth() + 1);
		}
		while (dataInicial <= dataFinal) {
			callback(dataAtual);
			if (periodo === 'anual') {
				dataAtual.setUTCFullYear(dataAtual.getUTCFullYear() + 1);
			} else {
				dataAtual.setMonth(dataAtual.getMonth() + 1);
			}
		}
	}

	getAno(dataString: string | Date): number {
		return new Date(dataString).getUTCFullYear();
	}

	get isSeparadoPorEstacoes(): boolean {
		return ['MUNICIPIO', 'MUNICIPIO_POSTO', 'MICRORREGIAO'].includes(
			this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.value
		);
	}

	get _agrupamento(): string {
		return this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.value;
	}

	public calcularDesvioMilimetro(chuva: number, climatologia: number): number {
		const desvio = chuva - climatologia;
		return parseFloat(desvio.toFixed(2));
	}

	public calcularDesvioPorcentagem(
		chuva: number,
		climatologia: number
	): number {
		if (climatologia === 0) {
			return 0;
		}
		const desvioPercentual = ((chuva - climatologia) / climatologia) * 100;
		return parseFloat(desvioPercentual.toFixed(2));
	}

	getSeriesSeparadasPorAno(relatorios: RelatorioDesvio[]): SeriesOptionsType[] {
		return relatorios.map(r => {
			const anos = new Set(r.desvios.map(d => this.getAno(d.data)));
			const desvios: DadosDesvioRelatorio[] = [];
			anos.forEach(ano => {
				const desviosDoAno = r.desvios.filter(d => this.getAno(d.data) === ano);
				const objChuvaEClimaSomados = desviosDoAno.reduce<DadosDesvioRelatorio>(
					(a, b) => ({
						desvioMilimetro: 0,
						chuva: a.chuva + b.chuva,
						desvioPorcentagem: 0,
						climatologia: a.climatologia + b.climatologia,
						data: new Date(this.getAno(b.data), 0, 1),
					}),
					{
						desvioMilimetro: 0,
						chuva: 0,
						desvioPorcentagem: 0,
						climatologia: 0,
						data: new Date(),
					}
				);
				const { length } = desviosDoAno;
				const { chuva, climatologia, data } = objChuvaEClimaSomados;
				const chuvaMedia = chuva / length;
				const climatologiaMedia = climatologia / length;
				const desvioMedioAno = {
					chuva: chuva / length,
					climatologia: chuva / length,
					data: data,
					desvioMilimetro: this.calcularDesvioMilimetro(
						chuvaMedia,
						climatologiaMedia
					),
					desvioPorcentagem: this.calcularDesvioPorcentagem(
						chuvaMedia,
						climatologiaMedia
					),
				};
				desvios.push(desvioMedioAno);
			});
			return {
				type: 'column',
				name: this.getNomeSerie(r),
				groupPadding: 0.1,
				pointPadding: 0.1,
				data: desvios.map(d =>
					this.isDesvioPorcentagem ? d.desvioPorcentagem : d.desvioMilimetro
				),
			};
		});
	}

	getNomeSerie(r: RelatorioDesvio): string {
		const mapeamento: Record<string, string> = {
			REGIAO_PLUVIOMETRICA: r.regiaoPluviometrica,
			BACIA: r.bacia,
			MESORREGIAO: r.mesorregiao,
		};
		let name = '';
		if (this.isSeparadoPorEstacoes) {
			if (this._agrupamento === 'MICRORREGIAO') {
				name = `${r.municipio} / `;
			}
			name += r.estacao;
		} else {
			name = mapeamento[this._agrupamento];
		}
		return name;
	}

	configurarGrafico(): void {
		const tipoPeriodo = this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)
			?.value;
		let categories: string[] = [];
		let series: SeriesOptionsType[];

		if (tipoPeriodo === 'anual') {
			series = this.getSeriesSeparadasPorAno(this.relatorios);
		} else {
			series = this.relatorios.map(r => {
				return {
					type: 'column',
					name: this.getNomeSerie(r),
					groupPadding: 0.1,
					pointPadding: 0.1,
					data: r.desvios.map(d =>
						this.isDesvioPorcentagem ? d.desvioPorcentagem : d.desvioMilimetro
					),
				};
			});
		}
		this.percorrerDatas(this.dataInicio, this.dataFim, tipoPeriodo, data => {
			categories.push(
				format(data, `${tipoPeriodo !== 'anual' ? 'MM/' : ''}yyyy`)
			);
		});
		if (this.grafico) {
			this.grafico.update(
				{
					series,
					xAxis: {
						categories,
						title: {
							text: tipoPeriodo === 'anual' ? 'anos' : 'meses',
						},
					},
					yAxis: {
						title: {
							text: this.isDesvioPorcentagem ? 'Desvio (%)' : 'Desvio (mm)',
						},
					},
					tooltip: {
						valueSuffix: this.isDesvioPorcentagem ? ' %' : ' mm',
					},
				},
				true,
				true
			);
		}
		this.cdr.detectChanges();
		this.differ.diff(this);
	}

	ngDoCheck(): void {
		const changes = this.differ.diff(this);
		if (changes && this.grafico) {
			this.grafico.reflow();
		}
	}

	clearValidators(): void {
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MUNICIPIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.ESTACAO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MICRORREGIAO)?.clearValidators();
	}

	setValidators(): void {
		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.MUNICIPIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.ESTACAO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.MICRORREGIAO)
			?.setValidators(Validators.required);
	}

	get dataFim(): Date {
		const dataFim = this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.value;
		return (dataFim as Moment).toDate();
	}

	get dataInicio(): Date {
		const data = this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.value;
		return (data as Moment).toDate();
	}

	get isDesvioPorcentagem(): boolean {
		const tipo = this.inputs.form.get(FormularioRelatorio.TIPO)?.value;
		return tipo === 'DESVIO_PORCENTAGEM';
	}

	gerarRelatorio(): void {
		if (
			![
				'MUNICIPIO_POSTO',
				'MUNICIPIO',
				'MICRORREGIAO',
				'MESORREGIAO',
				'REGIAO_PLUVIOMETRICA',
				'BACIA',
			].includes(this._agrupamento)
		) {
			this.toast.error(
				`A geração de gráfico de desvio não é permitida para este agrupamento`,
				'Opção não permitida'
			);
			return;
		}
		let chave: keyof typeof FormularioRelatorio | undefined;
		if (
			!['MESORREGIAO', 'REGIAO_PLUVIOMETRICA', 'BACIA'].includes(
				this._agrupamento
			)
		) {
			chave =
				this._agrupamento === 'MUNICIPIO_POSTO'
					? 'ESTACAO'
					: (this._agrupamento as keyof typeof FormularioRelatorio);
		}
		this.relatorioService
			.buscarRelatorioDesvio({
				agrupamento: this._agrupamento,
				idEntidade: chave
					? this.inputs.form.get(FormularioRelatorio[chave])?.value
					: null,
				dataFim: this.dataFim,
				dataInicio: this.dataInicio,
				incluirDetalhes: false,
			})
			.subscribe({
				next: value => {
					if (value.length === 0) {
						this.toast.info(
							'Não há desvios para os parametros selecionados',
							'Sem dados'
						);
					}
					this.relatorios = value;
					this.configurarGrafico();
				},
				error: err => {
					const msgErro = obter_erro_request(err);
					this.toast.error(
						msgErro,
						'Erro ao obter informações de desvio no servidor'
					);
				},
			});
	}
}

