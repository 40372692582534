<form [formGroup]="form" *ngIf="form">
	<seira-public-modal [close]="close">
		<div title class="text-tertiary d-flex gap-3 align-items-center">
			<h2 class="fw-medium m-0 w-auto">
				{{ tipoEstacao }} {{ estacao.nomeMunicipio }}
			</h2>
			<div class="h6 m-0 fw-normal d-flex align-items-center gap-1">
				<i class="ph ph-map-pin-fill"></i>
				{{ estacao.latitude.toFixed(7) }} {{ estacao.longitude.toFixed(7) }}
			</div>
		</div>
		<div header class="row w-100 p-0 text-tertiary">
			<hr class="hr mt-2 opacity-25" />

			<div class="p-0 d-flex gap-1 align-items-center">
				<i class="ph ph-clock"></i>
				{{ estacao.ultimaColeta | date: "dd/MM/yyyy 'às' HH:mm" }}
				<i class="ph-circle-fill {{ status.color }}"></i>{{ status.label }}
			</div>
		</div>
		<div content>
			<div class="row align-items-end">
				<div class="col-lg-4 col-xl-3">
					<seira-public-select
						[clearable]="false"
						label="Variável"
						placeholder="Informe o tipo de variável"
						formControlName="variavel"
						optionValue="name"
						optionLabel="value"
						[options]="variaveis"></seira-public-select>
				</div>
				<div class="col-lg-4 col-xl-3 pt-2">
					<seira-public-date-range-picker
						label="Período"
						controlEndName="dataFim"
						controlStartName="dataInicio"
						[intervaloDias]="30"></seira-public-date-range-picker>
				</div>
				<div class="col-lg-4 col-xl-6 d-flex pt-2 justify-content-end">
					<!-- TEMPORARIAMENTE DESABILITADO
						ATÉ QUE A FUNCIONALDIADE DE RELATÓRIOS ESTAJA FUNCIONANDO
					<seira-public-button
						buttonStyle="outline"
						[disabledButton]="true"
						(click)="irParaRelatorios()"
						>
						<i class="ph ph-arrow-square-out me-2"></i>Ir para: Relatórios
					</seira-public-button> -->
					<seira-public-button buttonStyle="outline" [disabledButton]="true">
						<i class="ph ph-arrow-square-out me-2"></i>Ir para: Relatórios
					</seira-public-button>
				</div>
			</div>
			<seira-loading *ngIf="loadingChart" />
			<seira-high-stock
				(chartInstance)="chartInstance($event)"
				classChart="highcharts-light h-auto"
				[options]="chartOptions"
				styleChart="width: 100%; height: 400px; display: block"></seira-high-stock>
		</div>
	</seira-public-modal>
</form>
