import { Component } from '@angular/core';

@Component({
  selector: 'seira-dados-hidrologicos',
  templateUrl: './dados-hidrologicos.component.html',
  styleUrls: ['./dados-hidrologicos.component.scss']
})
export class DadosHidrologicosComponent {

}
