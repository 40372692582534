<seira-public-modal [close]="bsModalRef.hide">
	<label
		class="text-tertiary font-size-24 d-flex m-0"
		style="font-weight: 500"
		title>
		Filtro do monitoramento de rede de estações
	</label>

	<form
		[formGroup]="formEstacao"
		class="d-flex flex-column justify-content-center"
		content>
		<div class="row">
			<div class="col-lg-6 col-12 mt-2">
				<seira-public-select
					optionLabel="label"
					optionValue="value"
					placeholder="Selecione..."
					label="Tipo de estação"
					[options]="tiposEstacao"
					notFoundText="Nenhum tipo de estação encontrado"
					formControlName="tipoEstacao"
					#seiraSelectTipoEstacao
					(ngModelChange)="filtroAtivo(seiraSelectTipoEstacao)">
				</seira-public-select>
			</div>
			<div class="col-lg-6 col-12 mt-2">
				<seira-public-select
					placeholder="Selecione..."
					label="Município"
					[options]="municipios"
					optionLabel="nome"
					optionValue="id"
					notFoundText="Nenhum município encontrado"
					formControlName="municipio"
					#seiraSelectMunicipio
					(ngModelChange)="filtroAtivo(seiraSelectMunicipio)">
				</seira-public-select>
			</div>
			<div class="col-lg-6 col-12 mt-2">
				<seira-public-select
					placeholder="Selecione..."
					label="Microrregião"
					[options]="microrregioes"
					optionLabel="nome"
					optionValue="id"
					notFoundText="Nenhuma microrregião encontrada"
					formControlName="microrregiao"
					#seiraSelectMicrorregioes
					(ngModelChange)="filtroAtivo(seiraSelectMicrorregioes)">
				</seira-public-select>
			</div>
			<div class="col-lg-6 col-12 mt-2">
				<seira-public-select
					placeholder="Selecione..."
					label="Mesorregião"
					[options]="mesorregioes"
					optionLabel="nome"
					optionValue="id"
					notFoundText="Nenhuma mesorregião encontrada"
					formControlName="mesorregiao"
					#seiraSelectMesorregioes
					(ngModelChange)="filtroAtivo(seiraSelectMesorregioes)">
				</seira-public-select>
			</div>
			<div class="col-lg-6 col-12 mt-2">
				<seira-public-select
					placeholder="Selecione..."
					label="Região Pluviométrica"
					[options]="regioes"
					optionLabel="nome"
					optionValue="id"
					notFoundText="Nenhuma região encontrada"
					formControlName="regiao"
					#seiraSelectRegioes
					(ngModelChange)="filtroAtivo(seiraSelectRegioes)">
				</seira-public-select>
			</div>
			<div class="col-lg-6 col-12 mt-2">
				<seira-public-select
					placeholder="Selecione..."
					label="Bacia"
					[options]="bacias"
					optionLabel="nome"
					optionValue="id"
					notFoundText="Nenhuma bacia encontrada"
					formControlName="bacia"
					#seiraSelectBacias
					(ngModelChange)="filtroAtivo(seiraSelectBacias)">
				</seira-public-select>
			</div>
		</div>

		<div class="d-flex justify-content-center mt-3 align-items-center">
			<seira-public-button (clicked)="adicinarFiltros()">{{
				tituloBotao
			}}</seira-public-button>
			<seira-public-a *ngIf="filtroModalAtivo" (click)="limparFiltros()"
				>Limpar</seira-public-a
			>
		</div>
	</form>
</seira-public-modal>
