import { Component, Input } from '@angular/core';
import {
	animate,
	state,
	style,
	transition,
	trigger,
} from '@angular/animations';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalRelatorioHistoricoImagensComponent } from '@home/submodulos/dados-meteorologicos/componentes/modal-relatorio-historico-imagens/modal-relatorio-historico-imagens.component';
import { LegendaOptions } from '@home/submodulos/dados-meteorologicos/componentes/legenda-relatorio-historico-variaveis/legenda-relatorio-historico-variaveis.component';
import { VariaveisMeteorologicasPorPeriodoETipoVariavel } from '@home/submodulos/dados-meteorologicos/interfaces/variavel-meteorologica-mapa';

@Component({
	selector: 'seira-imagem-historico-relatorio',
	templateUrl: './imagem-historico-relatorio.component.html',
	styleUrls: ['./imagem-historico-relatorio.component.scss'],
	animations: [
		trigger('expand', [
			state(
				'collapsed',
				style({ height: '*', transform: 'scale(1)', 'z-index': '0' })
			),
			state(
				'expanded',
				style({
					height: '*',
					transform: 'scale(1.3)',
					'z-index': '5',
				})
			),
			transition('collapsed <=> expanded', animate('200ms ease-in-out')),
		]),
	],
})
export class ImagemHistoricoRelatorioComponent {
	currentState = 'collapsed';
	@Input() imagem!: VariaveisMeteorologicasPorPeriodoETipoVariavel;
	@Input() legenda!: LegendaOptions;
	@Input() funcaoCoresMapa!: (valor: number) => string;
	@Input() idMapa!: string;
	loading = true;
	constructor(private modalService: BsModalService) {}
	mapaFoiGerado() {
		this.loading = false;
	}
	abrirModal() {
		const close = () => this.modalService.hide();
		this.modalService.show(ModalRelatorioHistoricoImagensComponent, {
			class: 'modal-xl modal-dialog-centered',
			initialState: {
				funcaoCoresMapa: this.funcaoCoresMapa,
				imagem: this.imagem,
				legenda: this.legenda,
				close,
			},
		});
	}
	expand() {
		this.currentState =
			this.currentState === 'collapsed' ? 'expanded' : 'collapsed';
	}
}
