import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PostosRelatorios } from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';

@Component({
	selector: 'seira-select-postos-relatorio',
	templateUrl: './select-postos-relatorio.component.html',
	styleUrls: ['./select-postos-relatorio.component.scss'],
})
export class SelectPostosRelatorioComponent implements OnInit {
	@Input() modoSelecao: 'lote' | 'individual' = 'lote';
	@Input() postos: PostosRelatorios[] = [];
	@Output() postosSelecionados = new EventEmitter<PostosRelatorios[]>();

	selectedPostos: PostosRelatorios[] = [];

	ngOnInit() {
		this.setSelectedPostos();
	}

	setSelectedPostos() {
		this.selectedPostos = this.postos.filter(posto => posto.selected);
	}
	get textSelecteds() {
		const postos = this.postos.length;
		return `${this.selectedPostos.length} de ${postos} posto${
			postos !== 1 ? 's' : ''
		} selecionado${postos !== 1 ? 's' : ''}`;
	}

	select(index: number) {
		this.postos[index].selected = true;
		this.selectedPostos.push(this.postos[index]);
		this.postosSelecionados.emit(this.selectedPostos);
	}
	remove(id: number, index: number) {
		const indexSelected = this.selectedPostos.findIndex(el => el.id === id);
		this.postos[index].selected = false;
		this.setSelectedPostos();
		this.postosSelecionados.emit(this.selectedPostos);
	}
	selectAll() {
		const postosComSelecao = this.postos.map(posto => ({
			...posto,
			selected: true,
		}));

		this.postos = postosComSelecao;
		this.selectedPostos = postosComSelecao;
		this.postosSelecionados.emit(this.selectedPostos);
	}
	removeAll() {
		this.postos = this.postos.map(el => ({ ...el, selected: false }));
		this.selectedPostos = [];
		this.postosSelecionados.emit(this.selectedPostos);
	}
}
