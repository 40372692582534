<!-- Login form -->
<form [formGroup]="loginForm" class="login-form" (submit)="login()">
	<div class="card mb-0">
		<div class="card-body">
			<div class="text-center mb-3">
				<div
					class="d-inline-flex align-items-center justify-content-center mb-4 mt-2">
					<img src="./assets/images/logo_1_1.svg" class="h-64px" alt="" />
				</div>
				<h5 class="mb-0">Faça login na sua conta</h5>
				<span class="d-block text-muted">Insira suas credenciais abaixo </span>
			</div>

			<div class="mb-3">
				<label class="pb-1 fw-semibold"
					>Usuário <span class="text-danger">*</span></label
				>
				<div class="form-control-feedback form-control-feedback-start">
					<input
						type="text"
						formControlName="login"
						class="form-control invalid"
						placeholder="Informe o usuário"
						[ngClass]="
							loginForm.get('login')?.errors?.['required'] &&
							loginForm.get('login')?.touched
								? 'is-invalid'
								: ''
						" />
					<div
						class="invalid-feedback"
						*ngIf="
							loginForm.get('login')?.errors?.['required'] &&
							loginForm.get('login')?.touched
						">
						O usuário é obrigatório.
					</div>
					<div class="form-control-feedback-icon">
						<i class="ph-user-circle text-muted"></i>
					</div>
				</div>
			</div>

			<div class="mb-3">
				<div class="d-flex justify-content-between pb-1 fw-semibold">
					<label>Senha <span class="text-danger">*</span></label>
					<seira-a (clicked)="goToRecovery()"> Esqueci minha senha </seira-a>
				</div>
				<div class="form-control-feedback form-control-feedback-start">
					<input
						type="password"
						formControlName="senha"
						class="form-control"
						placeholder="•••••••••••"
						[ngClass]="
							loginForm.get('senha')?.errors?.['required'] &&
							loginForm.get('senha')?.touched
								? 'is-invalid'
								: ''
						" />
					<div
						class="invalid-feedback"
						*ngIf="
							loginForm.get('senha')?.errors?.['required'] &&
							loginForm.get('senha')?.touched
						">
						A senha é obrigatória.
					</div>
					<div class="form-control-feedback-icon">
						<i class="ph-lock text-muted"></i>
					</div>
				</div>
			</div>

			<div class="mb-3">
				<seira-public-button
					[disabledButton]="!loginForm.valid || carregando"
					(clicked)="login()"
					customClass="w-100">
					<span *ngIf="carregando; else entrar">
						<div class="text-center">
							<div class="spinner-border" role="status"></div>
						</div>
					</span>
					<ng-template #entrar> Entrar </ng-template>
				</seira-public-button>
			</div>

			<div class="mb-3">
				<seira-public-button
					(clicked)="goToPagePublic()"
					buttonStyle="outline"
					customClass="w-100">
					<i class="ph-globe me-2"></i>
					<span>Área pública</span>
				</seira-public-button>
			</div>
		</div>
	</div>
</form>
<!-- /login form -->
