<seira-public-modal [close]="close">
	<div title class="fw-semibold align-items-start text-tertiary">Legenda</div>
	<div content class="py-3 px-3 d-flex flex-column" id="legenda">
		<div class="d-flex flex-column align-items-center gap-3 mt-1">
			<div *ngFor="let item of legenda" class="text-center">
				<seira-icon-with-label>
					<i icon [style.color]="item.color" class="ph ph-circle-fill me-1"></i>
					<label label class="text-tertiary">{{ item.label }}</label>
				</seira-icon-with-label>
				<span class="text-center">{{ item.index }} município(s)</span>
			</div>
		</div>
	</div>
</seira-public-modal>
