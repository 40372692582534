import {
	Component,
	ContentChild,
	Input,
	OnInit,
	TemplateRef,
} from '@angular/core';

@Component({
	selector: 'seira-public-pagination',
	templateUrl: './public-pagination.component.html',
	styleUrls: ['./public-pagination.component.scss'],
})
export class PublicPaginationComponent<T> implements OnInit {
	@ContentChild(TemplateRef) templateRef!: TemplateRef<any>;

	@Input() items: T[] = [];
	@Input() itemsPerPage = 10;
	@Input() numberOfPagesShown = 5;

	currentPageIndex = 0;
	firstItemNumber = 0;
	lastItemNumber = 0;
	itemsTotal = 0;

	ngOnInit() {
		this.firstItemNumber = this.currentPageIndex * this.itemsPerPage + 1;
		this.lastItemNumber = this.firstItemNumber + this.itemsPerPage - 1;
		this.itemsTotal = this.items.length;
	}

	get pagesTotal(): number {
		return Math.ceil(this.itemsTotal / this.itemsPerPage);
	}

	get isFirstPage() {
		return this.currentPageIndex === 0;
	}

	get isLastPage() {
		return this.currentPageIndex === this.pagesTotal - 1;
	}

	firstPage(): void {
		if (this.currentPageIndex > 0) {
			this.updatePageIndex(0);
		}
	}

	lastPage(): void {
		if (this.currentPageIndex + 1 < this.pagesTotal) {
			this.updatePageIndex(this.pagesTotal - 1);
		}
	}

	nextPage(): void {
		if (this.currentPageIndex + 1 < this.pagesTotal) {
			this.updatePageIndex(this.currentPageIndex + 1);
		}
	}

	previousPage(): void {
		if (this.currentPageIndex > 0) {
			this.updatePageIndex(this.currentPageIndex - 1);
		}
	}

	goToPage(page: number): void {
		if (page >= 1 && page <= this.pagesTotal) {
			this.updatePageIndex(page - 1);
		} else {
			this.nextPage();
		}
	}

	getPageNumbers(): number[] {
		const pageTotal = this.pagesTotal;
		const currentPage = this.currentPageIndex + 1;
		const lastPage = currentPage - 1;
		const nextPage = currentPage + 1;

		if (lastPage < 1) {
			const pageNumbers = [currentPage];

			for (
				let pageNumber = 1;
				pageNumber < this.numberOfPagesShown;
				pageNumber++
			) {
				if (currentPage + pageNumber <= pageTotal) {
					pageNumbers.push(currentPage + pageNumber);
				}
			}

			return pageNumbers;
		}

		if (nextPage >= pageTotal) {
			const pageNumbers = [currentPage];

			for (
				let pageNumber = 1;
				pageNumber < this.numberOfPagesShown;
				pageNumber++
			) {
				if (
					currentPage - pageNumber > 0 &&
					currentPage - pageNumber <= pageTotal
				) {
					pageNumbers.unshift(currentPage - pageNumber);
				}
			}

			return pageNumbers;
		}

		const pageNumbers = [];
		const pagesOnEachSide = Math.floor(this.numberOfPagesShown / 2);

		for (
			let pageNumber = currentPage - pagesOnEachSide;
			pageNumber <= currentPage + pagesOnEachSide;
			pageNumber++
		) {
			if (pageNumber > 0 && pageNumber < pageTotal) {
				pageNumbers.push(pageNumber);
			}
		}

		return pageNumbers;
	}

	private updatePageIndex(index: number) {
		this.currentPageIndex = index;
		this.firstItemNumber = this.currentPageIndex * this.itemsPerPage + 1;
		this.lastItemNumber = this.firstItemNumber + this.itemsPerPage - 1;
	}

	trackByFn(index: number, _item: unknown) {
		return index;
	}
}