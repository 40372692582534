<div class="tooltip-borders mb-0 ms-0 me-0 border shadow-lg">
	<div
		class="tooltip-header bg-primary text-white fw-semibold font-size-16 px-3 py-2">
		{{ markerData.nomePosto }}
	</div>
	<div
		class="tooltip-footer px-3 py-1 text-tertiary font-size-15 bg-white"
		style="white-space: nowrap">
		<div class="d-flex col align-items-center">
			<i class="ph ph-map-pin fs-5"></i>
			<div
				class="d-flex col py-2"
				style="margin-left: 5px"
				*ngIf="markerData.nomePosto">
				<span class="fw-semibold" style="margin-right: 5px">Posto:</span>
				{{ markerData.nomePosto }}
			</div>
		</div>
		<div class="d-flex col align-items-center">
			<i class="ph ph-compass fs-5"></i>
			<div class="d-flex col py-2" style="margin-left: 5px">
				<span class="fw-semibold" style="margin-right: 5px">Latitude:</span>
				{{ markerData.lat.toFixed(7) }}
			</div>
		</div>
		<div class="d-flex col align-items-center">
			<i class="ph ph-compass fs-5"></i>
			<div class="d-flex col py-2" style="margin-left: 5px">
				<span class="fw-semibold" style="margin-right: 5px">Longitude:</span>
				{{ markerData.lng.toFixed(7) }}
			</div>
		</div>
		<div class="d-flex col align-items-center" *ngIf="markerData.precipitacao">
			<i class="ph ph-cloud"></i>
			<div class="d-flex col py-2" style="margin-left: 5px">
				<span class="fw-semibold" style="margin-right: 5px"
					>Precipitação:
				</span>
				{{ markerData.precipitacao }}
			</div>
		</div>
		<div class="d-flex col align-items-center" *ngIf="markerData.value">
			<i class="ph-drop"></i>
			<div class="d-flex col py-2" style="margin-left: 5px">
				<span class="fw-semibold" style="margin-right: 5px"
					>Dias de chuva:
				</span>
				{{ markerData.value }}
			</div>
		</div>
	</div>
</div>
