import {
	ChangeDetectorRef,
	Component,
	OnInit,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import {
	OpcaoPagina,
	PublicPageOptionsComponent,
} from '@componentes/public-page-options/public-page-options.component';
import { TextoInformativo } from '@home/submodulos/dados-meteorologicos/interfaces/texto-informativo';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
	selector: 'seira-rede-monitoramento',
	templateUrl: './rede-monitoramento.component.html',
	styleUrls: ['./rede-monitoramento.component.scss'],
})
export class RedeMonitoramentoComponent {
	@ViewChild('legendaMapaMobile') legendaMapaMobile!: TemplateRef<any>;

	opcoesDaPagina: OpcaoPagina<any>[] = [];
	informacoesGerais: TextoInformativo = {
		label: 'INFORMAÇÕES GERAIS',
		texts: [
			{
				title: 'O que é uma estação?',
				text: 'Estação é o local onde está instalado o equipamento manual ou automático para realizar o monitoramento das variáveis meteorológicas, agrometeorológicas ou hídricas.',
			},
			{
				title: 'O que você vai encontrar nesta página?',
				text: 'Aqui você pode visualizar no mapa os pluviômetros existentes na rede do Estado. É possível visualizar tanto as PCD (Plataforma de Coleta de Dados) quanto os pluviômetros convencionais (de envio manual por humano) acumulado em 24 horas.',
			},
		],
	};

	constructor(private readonly modalService: BsModalService) {}

	ngAfterViewInit() {
		this.adicionarOpcoesDaPagina();
	}
	adicionarOpcoesDaPagina() {
		this.opcoesDaPagina = [
			{
				label: 'Legenda',
				template: this.legendaMapaMobile,
			},
		];
	}

	abrirModalOpcoesDaPagina(event: Event) {
		event.preventDefault();
		this.modalService.show(PublicPageOptionsComponent, {
			class: 'modal-dialog-centered',
			initialState: {
				opcoes: this.opcoesDaPagina,
			},
		});
	}
}
