import { Component, HostBinding } from '@angular/core';
import { BreadcrumbElement } from '@layout/interfaces/breadcrumb-element';

@Component({
	selector: 'seira-trocar-senha',
	templateUrl: './alterar-senha.component.html',
	styleUrls: ['./alterar-senha.component.scss'],
})
export class AlterarSenhaComponent {
	@HostBinding('class') clazz = 'expandir-flex';
	listPathPage: string[] = ['Autenticação', 'Alterar senha'];
	breadcrumbElements: BreadcrumbElement[] = [];
}
