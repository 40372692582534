<div [class]="class">
	<div
		class="d-block d-sm-flex gap-2 align-items-center"
		style="margin-right: 5px">
		<div
			[style.position]="'relative'"
			class="h-auto"
			seiraClickOutside
			(clickOutside)="mesesAbertos = false"
			(click)="mesesAbertos = !mesesAbertos">
			<button
				class="btn btn-light bg-transparent border-0 p-0 border-bottom rounded-0">
				<h3 class="m-0" style="cursor: pointer">
					{{ mesSelecionado | capitalize }}
				</h3>
			</button>
			<div
				*ngIf="mesesAbertos"
				style="
					position: absolute;
					top: 100%;
					left: 50%;
					cursor: pointer;
					transform: translateX(-50%);
				"
				class="bg-white rounded-bottom flex-column d-flex border select-periodo"
				[style.z-index]="1">
				<div
					class="w-100 item-select justify-content-center align-items-center px-2 py-1 text-center"
					*ngFor="let mes of meses"
					(click)="setMonth(mes.value)">
					{{ mes.label }}
				</div>
			</div>
		</div>
		<div
			[style.position]="'relative'"
			class="h-auto"
			seiraClickOutside
			(clickOutside)="anosAbertos = false"
			(click)="anosAbertos = !anosAbertos">
			<button
				class="btn btn-light bg-transparent border-0 p-0 border-bottom rounded-0">
				<h3 class="m-0" style="cursor: pointer">
					{{ viewDate.getFullYear() }}
				</h3>
			</button>
			<div
				*ngIf="anosAbertos"
				style="
					position: absolute;
					top: 100%;
					left: 50%;
					cursor: pointer;
					transform: translateX(-50%);
				"
				class="bg-white rounded-bottom flex-column d-flex border select-periodo"
				[style.z-index]="1">
				<div
					class="w-100 item-select justify-content-center align-items-center px-2 py-1 text-center"
					*ngFor="let ano of anos"
					(click)="setYear(ano.value)">
					{{ ano.label }}
				</div>
			</div>
		</div>
	</div>
	<button
		type="button"
		class="btn btn-light ph-caret-left rounded-circle m-1 p-2"
		mwlCalendarPreviousView
		[view]="view"
		[(viewDate)]="viewDate"
		(viewDateChange)="changeDate.emit($event)"></button>
	<button
		[disabled]="bloquearFuturo"
		type="button"
		class="btn btn-light ph-caret-right rounded-circle m-1 p-2"
		mwlCalendarNextView
		[view]="view"
		[(viewDate)]="viewDate"
		(viewDateChange)="changeDate.emit($event)"></button>
	<button
		type="button"
		class="btn btn-outline-primary"
		style="margin-left: 5px"
		mwlCalendarToday
		[(viewDate)]="viewDate"
		(viewDateChange)="changeDate.emit($event)">
		Mês atual
	</button>
</div>
