<div
	[class]="class"
	[ngStyle]="(maxWidth && { 'max-width': maxWidth }) || null">
	<div
		class="d-flex flex-column bg-white py-2 px-3 shadow-lg rounded text-tertiary d-flex justify-content-center">
		<label class="fw-semibold mb-1 fs-6">Tipo da PCD</label>
		<div class="d-flex flex-column">
			<div
				class="d-flex align-items-center my-1 font-size-14"
				*ngFor="let tipo of tipos">
				<div
					class="cor-legenda"
					[ngStyle]="{ 'background-image': 'url(' + tipo.imagem + ')' }"></div>
				{{ tipo.nome }}
			</div>
		</div>
	</div>
</div>
