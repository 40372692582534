<div
	class="tooltip-header p-2 text- fw-semibold fs-6 radious"
	[ngClass]="headerTextColor"
	[style]="'background-color: ' + headerColor">
	<i class="ph-map-pin me-1"></i> <ng-content select="[header]"></ng-content>
</div>
<div class="d-flex flex-column p-2 text-tertiary">
	<div class="d-flex">
		<ng-content select="[data]"></ng-content>
	</div>
	<div class="d-flex align-items-center my-2">
		<ng-content select="[description]"></ng-content>
	</div>
	<div class="d-flex justify-content-between">
		<ng-content select="[links]"></ng-content>
	</div>
</div>
