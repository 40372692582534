import { TipoEstacao } from '@modulos/meteorologia/submodulos/estacao/enums/tipo-estacao';

export enum LabelsHistoricoVariaveisMeteorologicas {
	PRECIPITACAO = 'Precipitação acumulada (mm)',
	TEMPERATURA = 'Temperatura média (ºC)',
}
export enum TiposPeriodosVariaveisMeteorologicas {
	DIARIO = 'DIARIO',
	ANUAL = 'ANUAL',
	MENSAL = 'MENSAL',
}

export enum TiposMapasVariaveisMeteorologicas {
	HISTORICO = 'Histórico',
	ISOLINHA = 'Isolinha',
}
interface Historico {
	id: number;
	geocodigo: number;
	periodo: string;
	precipitacaoAcumulada: number;
	temperaturaMedia: number;
	nomeEstacao: string;
	tipoEstacao: keyof typeof TipoEstacao;
	nomeMunicipio: string;
}

export enum CorLegendaChuvaDiariaPastel {
	VALOR_BEM_MAIS_BAIXO = '#f7f2e4', // Branco
	VALOR_MAIS_BAIXO = '#FFB3B3', // Vermelho pastel
	MUITO_ABAIXO_DA_MEDIA = '#FFF7B3', // Amarelo pastel
	MEDIA = '#B3FFB3', // Verde pastel
	ACIMA_DA_MEDIA = '#B3E6FF', // Azul pastel
	MUITO_ACIMA_DA_MEDIA = '#D9B3D9', // Roxo pastel
}

export enum CorLegendaChuvaMensalPastel {
	VALOR_MUITO_BAIXO = '#f7f2e4',
	VALOR_MAIS_BAIXO = '#FFB3B3', // Vermelho pastel
	MUITO_ABAIXO_DA_MEDIA = '#FFF7B3', // Amarelo pastel
	MEDIA = '#B3FFB3', // Verde pastel
	ACIMA_DA_MEDIA = '#B3E6FF', // Azul claro pastel
	MUITO_ACIMA_DA_MEDIA = '#B3B3FF', // Azul pastel (ligeiramente mais escuro)
	VALOR_MAIS_ALTO = '#D9B3D9', // Roxo pastel
}

export enum CorLegendaChuvaAnualPastel {
	VALOR_MUITO_BAIXO = '#f7f2e4', // Branco permanece o mesmo
	VALOR_MAIS_BAIXO = '#FFB3B3', // Vermelho pastel
	MUITO_ABAIXO_DA_MEDIA = '#FFF7B3', // Amarelo pastel
	MEDIA = '#B3FFB3', // Verde pastel
	ACIMA_DA_MEDIA = '#B3E6FF', // Azul claro pastel
	MUITO_ACIMA_DA_MEDIA = '#B3B3FF', // Azul pastel (ligeiramente mais escuro)
	VALOR_MAIS_ALTO = '#D9B3D9', // Roxo pastel
}
