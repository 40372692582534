import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import {
	ControlContainer,
	FormControl,
	FormGroupDirective,
} from '@angular/forms';
import { NgOption, NgSelectComponent } from '@ng-select/ng-select';

@Component({
	selector: 'seira-cycle-select',
	templateUrl: './cycle-select.component.html',
	styleUrls: ['./cycle-select.component.scss'],
	viewProviders: [
		{ provide: ControlContainer, useExisting: FormGroupDirective },
	],
})
export class CycleSelectComponent<T extends Record<string, any>>
	implements OnInit
{
	@Input() icon?: string;
	@Input() options!: T[];
	@Input() controlName!: string;
	@Input() optionLabel = '';
	@Input() optionValue = '';

	@ViewChild(NgSelectComponent) select!: NgSelectComponent;
	control = new FormControl();

	constructor(private controlContainer: ControlContainer) {}

	ngOnInit(): void {
		this.control = this.controlContainer.control?.get(
			this.controlName
		) as FormControl;

		this.bindFormControlValueChanges();
	}

	selectForward() {
		const selectedHorarioIndex = this.options.findIndex(
			option => option[this.optionValue] === this.control.value
		);
		const firstIndex = 0;
		const lastIndex = this.options.length - 1;
		this.control.setValue(
			selectedHorarioIndex === lastIndex || selectedHorarioIndex < 0
				? this.options[firstIndex][this.optionValue]
				: this.options[selectedHorarioIndex + 1][this.optionValue]
		);
	}

	selectBackward() {
		const selectedHorarioIndex = this.options.findIndex(
			option => option[this.optionValue] === this.control.value
		);
		const firstIndex = 0;
		const lastIndex = this.options.length - 1;
		this.control.setValue(
			selectedHorarioIndex === firstIndex || selectedHorarioIndex < 0
				? this.options[lastIndex][this.optionValue]
				: this.options[selectedHorarioIndex - 1][this.optionValue]
		);
	}

	bindFormControlValueChanges() {
		this.control?.valueChanges.subscribe(value => {
			setTimeout(() => {
				const item = this.select.itemsList.filteredItems.find(
					(item: NgOption) => item.value[this.optionValue] == value
				);

				if (!item || !value) {
					return this.select.itemsList.clearSelected();
				}

				this.select.select(item);
			});
		});
	}
}
