<seira-loading [show]="loading" />
<seira-card-info aditionalClasses="mt-3" [texto]="descricaoRelatorio">
</seira-card-info>
<div *ngIf="gerou && !loading && resultado">
	<div class="mt-3">
		<seira-highchart
			classChart="highcharts-light h-auto"
			styleChart="width: 100%; height: 600px; display: block"
			(chartInstance)="onChartCreated($event)"
			[options]="chartOptions" />
	</div>
</div>
<ng-container *ngIf="!gerou && !loading">
	<seira-sem-dados />
</ng-container>
