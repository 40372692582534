import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CalendarView } from 'angular-calendar';
import { isSameMonth, isSameYear } from 'date-fns';
import { months } from '@utils/date/nomes.mock';

@Component({
	selector: 'seira-select-periodo-calendario',
	templateUrl: './select-periodo-calendario.component.html',
	styleUrls: ['./select-periodo-calendario.component.scss'],
})
export class SelectPeriodoCalendarioComponent implements OnInit {
	@Input() view!: CalendarView | 'month' | 'week' | 'day';
	@Input() viewDate!: Date;
	@Output() changeDate = new EventEmitter<Date>();
	@Input() apenasPassado = true;
	mesesAbertos = false;
	anosAbertos = false;
	meses: { value: number; label: string }[] = [];
	anos: { value: number; label: string }[] = [];
	@Input()
	class = 'd-flex justify-content-end w-auto align-items-center';
	ngOnInit() {
		const hoje = new Date();
		for (let i = hoje.getFullYear(); i >= 1960; i--) {
			this.anos.push({ label: i.toString(), value: i });
		}
		if (this.apenasPassado) {
			for (let i = 0; i <= hoje.getMonth(); i++) {
				this.meses.push(months[i]);
			}
		} else {
			this.meses = months;
		}
	}

	setMonth(value: number) {
		this.viewDate.setMonth(value);
		this.changeDate.emit(this.viewDate);
		this.mesesAbertos = !this.mesesAbertos;
	}
	setYear(value: number) {
		this.viewDate.setFullYear(value);
		this.changeDate.emit(this.viewDate);
		this.anosAbertos = !this.anosAbertos;
		const hoje = new Date();
		if (hoje.getFullYear() == value && this.apenasPassado) {
			for (let i = 0; i <= hoje.getMonth(); i++) {
				this.meses.push(months[i]);
			}
		} else {
			this.meses = months;
		}
	}

	get bloquearFuturo() {
		const hoje = new Date();
		return (
			isSameMonth(this.viewDate, hoje) &&
			isSameYear(this.viewDate, hoje) &&
			this.apenasPassado
		);
	}
	get mesSelecionado(): string {
		return this.viewDate.toLocaleDateString('pt-BR', {
			month: 'long',
		});
	}
}
