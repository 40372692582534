import { Component, Input } from '@angular/core';
import { menuItens } from '@home/pages/home-page/menu-itens';

@Component({
	selector: 'seira-mobile-header',
	templateUrl: './mobile-header.component.html',
	styleUrls: ['./mobile-header.component.scss'],
})
export class MobileHeaderComponent {
	@Input() mode: 'landing-page' | 'home' = 'home';

	menu = menuItens;
	acessoAreaRestrita() {
		window.open('/auth/login', '_blank');
	}
}
