import { PublicSelectComponent } from '@componentes/public-select/public-select.component';
import { Select } from '@layout/interfaces/select';

interface StringEnum {
	[chave: string]: string;
}

export abstract class PublicSelectEnumComponent extends PublicSelectComponent<
	Select,
	string
> {
	override options!: Select[];
	override optionValue: string;
	override optionLabel: string;

	protected constructor(
		enumType: StringEnum,
		getLabel: (v: string) => string = v => v
	) {
		super();

		this.options = Object.keys(enumType).map(k => ({
			value: enumType[k],
			name: getLabel(enumType[k]),
		}));
		this.optionValue = 'value';
		this.optionLabel = 'name';
	}
}
