import { Component, Input, OnInit } from '@angular/core';
import {
	ControlContainer,
	FormControl,
	FormGroupDirective,
	Validators,
} from '@angular/forms';

@Component({
	selector: 'seira-select',
	templateUrl: './select.component.html',
	styleUrls: ['./select.component.scss'],
	viewProviders: [
		{ provide: ControlContainer, useExisting: FormGroupDirective },
	],
})
export class SelectComponent implements OnInit {
	@Input() id!: string;
	@Input() label!: string;
	@Input() searchable = true;
	@Input() required = false;
	@Input() placeholder!: string;
	@Input() controlName!: string;
	@Input() notFound = '';
	@Input() items!: any[] | null;
	@Input() bindLabel = 'name';
	@Input() bindValue = '';
	@Input() clearable = true;
	@Input() removeLabel = false;
	@Input() multiple: boolean = false;
	@Input() showsTextRequired: boolean = false;
	@Input() textRequired = '';
	@Input() labelContentClass = '';
	@Input() loading = false;
	@Input() loadingText = 'Carregando';
	@Input() maxShownValues: number = 3;

	control = new FormControl();

	constructor(private controlContainer: ControlContainer) {}

	ngOnInit(): void {
		this.control = this.controlContainer.control?.get(
			this.controlName
		) as FormControl;
	}

	selectAll() {
		this.control.setValue(this.items);
	}

	get isRequired(): boolean {
		return !!this.control?.hasValidator(Validators.required);
	}

	get seiraError() {
		if (this.control.errors) {
			const erros = Object.keys(this.control.errors);
			if (erros.length) {
				if (erros[0] === 'required') {
					if (this.control.touched) return 'Campo obrigatório.';
				} else {
					return this.control?.errors[erros[0]]?.label;
				}
			}
		}
		return '';
	}
}
