<div>
	<form [formGroup]="form" class="form-group">
		<div class="col-12 p-0">
			<div class="flex-row d-flex justify-content-start">
				<div
					class="p-3 my-3 fw-normal bg-white flex-row align-items-center rounded shadow-lg card text-tertiary-light">
					Exibindo mapa de {{ decapitalizeFirstLetter(variavel!) }}
					<i class="ph-clock ms-2 me-1"></i>
					<div class="fw-light">{{ getPeriodoFormatado() }}</div>
				</div>
				<div class="p-3 my-3 fw-normal flex-row align-items-center">
					<seira-public-a
						class="size-option"
						*seiraIfViewportSize="'mobile'"
						href
						(click)="abrirModalOpcoesDaPagina($event)"
						><i class="ph-list"></i> Opções desta página</seira-public-a
					>
				</div>
			</div>

			<div class="col-12 mb-3 mb-lg-0 p-0">
				<div
					id="map-container"
					class="p-0 mx-1 w-100"
					style="position: relative">
					<seira-mapa-interpolacao
						[imageOpacity]="0.8"
						[zoomOnlyWithCtrl]="true"
						[interpolacao]="interpolacao!"
						[carregando]="carregando"
						[exportButtons]="botoesDeExportacao"
						[valores]="valores"
						[medicoes]="medicoes"
						[unidade]="unidade"
						(mapaEmitter)="setupMap($event)">
					</seira-mapa-interpolacao>
				</div>
			</div>
		</div>
	</form>
</div>
<ng-template #legenda>
	<seira-monitoramento-legenda
		maxWidth="10"
		class="d-flex align-items-center justify-content-center"
		[valores]="interpolacao!.legenda"
		[carregando]="carregando"></seira-monitoramento-legenda>
	<seira-monitoramento-legenda-pcd
		class="d-flex align-items-center justify-content-center mt-2"
		maxWidth="10"></seira-monitoramento-legenda-pcd>
</ng-template>
