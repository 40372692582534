import { Component, Input } from '@angular/core';
import { Chart } from 'highcharts';
import { DadosGraficoQuantisMensal } from '@home/submodulos/dados-meteorologicos/interfaces';

@Component({
	selector: 'seira-modal-quantis',
	templateUrl: './modal-quantis.component.html',
	styleUrls: ['./modal-quantis.component.scss'],
})
export class ModalQuantisComponent {
	loadingSubmit = false;
	chart!: Chart;

	@Input() afterSubmitOnModalFunc = (context: any) => {};
	@Input() resultado?: DadosGraficoQuantisMensal;
	@Input() closeModalFunc = () => {};
	@Input() isAnual: boolean = true;
	@Input() useMaximumLimit = 500;

	fecharModal() {
		this.closeModalFunc();
	}

	onChartCreated(chart: Chart) {
		this.chart = chart;
	}
}
