<div class="modal-content shadow-lg">
	<div class="modal-header border-0 d-flex flex-column px-3 pt-3 pb-0">
		<div class="d-flex justify-content-between align-items-center w-100">
			<div *ngIf="paginaAtual; else labelMenuPrincipal">
				<seira-public-a (click)="navegarParaInicio()"
					><i class="ph-caret-left" style="margin-left: -1rem"></i> Opções desta
					página</seira-public-a
				>
				<label class="text-tertiary d-flex fw-medium fs-4 m-0">{{
					paginaAtual.label
				}}</label>
			</div>
			<seira-button
				type="button"
				classButton="btn-close m-0"
				buttonStyle="custom"
				(clicked)="bsModalRef.hide()">
			</seira-button>
		</div>
	</div>
	<div class="modal-body">
		<ng-container *ngIf="paginaAtual; else menuPrincipal">
			<ng-container *ngTemplateOutlet="paginaAtual.template"></ng-container>
		</ng-container>
	</div>
</div>

<ng-template #labelMenuPrincipal>
	<label *ngIf="!paginaAtual" class="text-tertiary d-flex fw-medium fs-4 m-0">
		Opções desta página
	</label>
</ng-template>

<ng-template #menuPrincipal>
	<ul>
		<li class="my-2 fs-6" *ngFor="let opcao of opcoes">
			<seira-public-a (click)="navegarParaPagina(opcao)">{{
				opcao.label
			}}</seira-public-a>
		</li>
	</ul>
</ng-template>
