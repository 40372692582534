import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FileValidators } from 'ngx-file-drag-drop';
import { ToastrService } from 'ngx-toastr';
import { modalConfirmacao } from '@utils';

@Component({
	selector: 'seira-drag-n-drop-input-file',
	templateUrl: './drag-n-drop-input-file.component.html',
	styleUrls: ['./drag-n-drop-input-file.component.scss'],
})
export class DragNDropInputFileComponent implements OnInit {
	@Input() id!: string;
	@Input() disabled = false;
	@Input() submitted = false;
	@Input() required = false;
	@Input() isOnlyView = false;
	@Input() arquivoAtual!: string;

	/**
	 * Não pode ser maior do que isso, pois o nginx já está nesse limite
	 * acarretaria em erro 413 com status 0
	 */
	@Input() maxSizeMB = 30;

	@Output() handleSetNewFile = new EventEmitter();
	@Output() handleDownloadCurrentFile = new EventEmitter();
	@Output() handleRemoveCurrentFile = new EventEmitter();

	fileControl!: FormControl;

	constructor(private readonly toastr: ToastrService) {}

	ngOnInit(): void {
		this.fileControl = new FormControl(
			{ value: [], disabled: this.disabled },
			[]
		);

		if (this.required) {
			this.fileControl.addValidators(FileValidators.required);
		}
	}

	_handleSetFile(files: File[]) {
		const file = files[0];
		if (file) {
			const sizeMb = file.size / 1024 / 1024;
			if (sizeMb > this.maxSizeMB) {
				this.toastr.show(
					'Arquivo não anexado',
					'Tamanho máximo permitido para anexo é de ' + this.maxSizeMB + ' MB.'
				);
				this.fileControl.setValue([]);
			} else {
				this.handleSetNewFile.emit(files);
			}
		}
	}

	async _handleRemoveCurrentFile() {
		const excluir = await modalConfirmacao('');

		if (excluir) {
			this.handleRemoveCurrentFile.emit();
		}
	}

	_handleDownloadCurrentFile() {
		this.handleDownloadCurrentFile.emit();
	}
}
