<div class="row mt-3 mb-5">
	<div class="mb-2">
		<div *ngFor="let relatorioPosto of dadosSudeneSeparadosPorPosto">
			<div class="justify-between" *ngIf="!carregandoRelatorio">
				<div>
					<label
						[for]="
							'tabela-relatorio-sudene-' + relatorioPosto.posto.toLowerCase()
						"
						class="text-tertiary mb-2 fw-semibold">
						{{ relatorioPosto.posto }}
					</label>
					<label
						[for]="
							'tabela-relatorio-sudene-' + relatorioPosto.posto.toLowerCase()
						"
						class="fw-normal legenda-total">
						&nbsp;(Lat: {{ relatorioPosto.localizacao.latitude }}, Lon:
						{{ relatorioPosto.localizacao.longitude }}, Alt:
						{{ relatorioPosto.localizacao.altitude }})
					</label>
				</div>
				<div>
					<label
						class="mb-2 fw-normal legenda-total"
						[for]="
							'tabela-relatorio-sudene-' + relatorioPosto.posto.toLowerCase()
						">
						Total de chuvas: {{ relatorioPosto.total().toFixed(2) }} mm
					</label>
				</div>
			</div>
			<div class="mb-4">
				<seira-public-table
					[id]="'tabela-relatorio-sudene-' + relatorioPosto.posto.toLowerCase()"
					[hasFooter]="true"
					[hasHeader]="false"
					[loading]="carregandoRelatorio"
					[info]="true"
					[paging]="true"
					[searching]="false"
					[scrollX]="true"
					[spaceReduceTh]="true"
					[columns]="colunas"
					[order]="[0, 'asc']"
					#tabelaRelatorioSudene
					[data]="relatorioPosto.dados || []"></seira-public-table>
			</div>
		</div>
	</div>
</div>
