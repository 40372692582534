<div
	class="d-flex flex-row justify-content-between align-items-center px-2 pb-1">
	<div class="text-tertiary-light">{{ textSelecteds }}</div>
	<div class="fw-medium">
		<seira-public-a (click)="selectAll()">Selecionar todos</seira-public-a>
		<seira-public-a color="red" (click)="removeAll()"
			>Remover todos</seira-public-a
		>
	</div>
</div>
<div class="overflow-scroll px-2" style="max-height: 386px">
	<div
		class="card shadow-none {{ posto.selected ? 'bg-secondary' : '' }}"
		*ngFor="let posto of postos; let i = index">
		<div class="card-body d-flex justify-content-between align-items-center">
			<div class="d-flex flex-column text-tertiary">
				<span
					>{{ posto.municipio }}:
					{{ (posto.nomePosto ? posto.nomePosto : '') | uppercase }}
				</span>
				<span>
					Tipo:
					{{
						posto.tipo === 'PLUVIOMETRO_CONVENCIONAL'
							? 'Pluviômetro convencional'
							: 'PCD'
					}}</span
				>
				<span class="fs-7">Responsável: {{ posto.responsavel }}</span>
			</div>
			<div class="fw-light">
				<seira-public-a (click)="select(i)" *ngIf="!posto.selected" color="gray"
					>Selecionar <i class="ph-plus-circle-fill text-tertiary"></i
				></seira-public-a>
				<seira-public-a (click)="remove(posto.id, i)" *ngIf="posto.selected"
					>Remover <i class="ph-minus-circle-fill text-danger"></i
				></seira-public-a>
			</div>
		</div>
	</div>
</div>
