import { Component, Input } from '@angular/core';
import { isNotNuloOuUndefined } from '@utils';
import { BreadcrumbElement } from '../../interfaces/breadcrumb-element';

@Component({
	selector: 'seira-page-header',
	templateUrl: './page-header.component.html',
	styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent {
	@Input() listPathPage: string[] = [];
	@Input() activePathPage: string | undefined = undefined;
	@Input() breadcrumbElements: BreadcrumbElement[] = [];
	protected readonly isNotNuloOuUndefined = isNotNuloOuUndefined;
}
