export interface Limites {
	vertendo: number;
	normalidade: number;
	observacao: number;
	favoravel: number;
	atencao: number;
}

let limites: Limites = {
	vertendo: 0,
	normalidade: 0,
	observacao: 0,
	favoravel: 0,
	atencao: 0,
};

export const setLimites = (novosLimites: Partial<Limites>) => {
	limites = { ...limites, ...novosLimites };
};

export const getLimites = (): Limites => {
	return limites;
};
