<div
	*ngIf="show"
	id="loading"
	[class.loading]="pageCentered"
	[style]="customStyle">
	<div
		class="spinner-border text-primary"
		style="width: 4rem; height: 4rem"
		role="status"></div>
</div>
