import {
	Component,
	EventEmitter,
	forwardRef,
	OnInit,
	Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { PublicSelectComponent } from '@componentes/public-select/public-select.component';
import { MunicipioService } from '@services/municipio/municipio.service';
import { Municipio } from '@modulos/meteorologia/submodulos/municipio/interfaces/municipio';

@Component({
	selector: 'seira-public-select-municipio',
	templateUrl: './public-select.component.html',
	styleUrls: ['./public-select.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => PublicSelectMunicipioComponent),
		},
	],
})
export class PublicSelectMunicipioComponent
	extends PublicSelectComponent<Municipio, Municipio>
	implements OnInit
{
	@Output() municipiosChange = new EventEmitter<Municipio[]>();

	override optionLabel: string = 'nome';
	override carregando: boolean = true;
	override carregandoLabel: string = 'Carregando municípios';
	override label: string = 'Município';
	override placeholder: string = 'Informe o município';
	override maxShownValues: number = 1;

	constructor(private municipioService: MunicipioService) {
		super();
	}

	ngOnInit() {
		this.carregando = true;
		this.municipioService.list().subscribe(responseMunicipios => {
			this.options = responseMunicipios._embedded.municipios;
			this.municipiosChange.emit(this.options);
			this.carregando = false;
			this.carregandoChange.emit(this.carregando);
		});
	}
}
