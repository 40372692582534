import {
	Component,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
} from '@angular/core';
import {
	DadosMediaHistorica,
	Relatorios,
} from '../../interfaces/tabela-relatorio';
import { ADTColumns } from 'angular-datatables/src/models/settings';
import { isNotNuloOuUndefined, numberToBrNumber } from '@utils';

@Component({
	selector: 'seira-tabela-relatorio-media-historica',
	templateUrl: './tabela-relatorio-media-historica.component.html',
	styleUrls: ['./tabela-relatorio-media-historica.component.scss'],
})
export class TabelaRelatorioMediaHistoricaComponent {
	@Input() relatorios: Relatorios<DadosMediaHistorica>[] = [];
	colunas: ADTColumns[] = [
		{
			data: 'mesorregiao',
			title: 'Mesorregião',
			type: 'string',
			className: 'text-start',
			orderable: false,
		},
		{
			data: 'posto',
			title: 'Posto',
			type: 'string',
			className: 'text-start',
			orderable: false,
		},
		{
			data: 'latitude',
			title: 'Latitude',
			type: 'number',
			className: 'text-start',
			orderable: false,
		},
		{
			data: 'longitude',
			title: 'Longitude',
			type: 'number',
			className: 'text-start',
			orderable: false,
		},
		{
			data: 'jan',
			title: 'Jan',
			type: 'number',
			orderable: false,
			render: function (jan) {
				return jan ? numberToBrNumber(jan) : '-';
			},
			className: 'text-start',
		},
		{
			data: 'fev',
			title: 'Fev',
			type: 'number',
			orderable: false,
			render: function (data) {
				return data ? numberToBrNumber(data) : '-';
			},
			className: 'text-start',
		},
		{
			data: 'mar',
			title: 'Mar',
			type: 'number',
			orderable: false,
			render: function (data) {
				return data ? numberToBrNumber(data) : '-';
			},
			className: 'text-start',
		},
		{
			data: 'abr',
			title: 'Abr',
			type: 'number',
			orderable: false,
			render: function (data) {
				return data ? numberToBrNumber(data) : '-';
			},
			className: 'text-start',
		},
		{
			data: 'mai',
			title: 'Mai',
			type: 'number',
			orderable: false,
			render: function (data) {
				return data ? numberToBrNumber(data) : '-';
			},
			className: 'text-start',
		},
		{
			data: 'jun',
			title: 'Jun',
			type: 'number',
			orderable: false,
			render: function (data) {
				return data ? numberToBrNumber(data) : '-';
			},
			className: 'text-end',
		},
		{
			data: 'jul',
			title: 'Jul',
			type: 'number',
			orderable: false,
			render: function (data) {
				return data ? numberToBrNumber(data) : '-';
			},
			className: 'text-end',
		},
		{
			data: 'ago',
			title: 'Ago',
			type: 'number',
			orderable: false,
			render: function (data) {
				return data ? numberToBrNumber(data) : '-';
			},
			className: 'text-end',
		},
		{
			data: 'set',
			title: 'Set',
			type: 'number',
			orderable: false,
			render: function (data) {
				return data ? numberToBrNumber(data) : '-';
			},
			className: 'text-end',
		},
		{
			data: 'out',
			title: 'Out',
			type: 'number',
			orderable: false,
			render: function (data) {
				return data ? numberToBrNumber(data) : '-';
			},
			className: 'text-end',
		},
		{
			data: 'nov',
			title: 'Nov',
			type: 'number',
			orderable: false,
			render: function (data) {
				return data ? numberToBrNumber(data) : '-';
			},
			className: 'text-end',
		},
		{
			data: 'dez',
			title: 'Dez',
			type: 'number',
			orderable: false,
			render: function (data) {
				return data ? numberToBrNumber(data) : '-';
			},
			className: 'text-end',
		},
		{
			data: 'max',
			title: 'Prec. Máx(mm)',
			type: 'number',
			orderable: false,
			render: function (data) {
				return data ? numberToBrNumber(data) : '-';
			},
			className: 'text-end',
		},
	];
}
