import { Injectable } from '@angular/core';
import { HttpClientProtectedService } from '@core/services/auth/http-client-protected.service';
import { Rotas } from '@core/enums/routes';
import { ChuvaEstado } from '@home/submodulos/dados-meteorologicos/interfaces/chuva-estado';

@Injectable({
	providedIn: 'root',
})
export class MapaChuvaEstadoService {
	constructor(private http: HttpClientProtectedService) {}
	buscarMapaChuvaEstado(dataInicial: any, dataFinal: any) {
		return this.http.get<ChuvaEstado[]>(
			Rotas.GRAFICOS +
				`/mapa-chuva-estado?dataInicial=${dataInicial}&dataFinal=${dataFinal}`
		);
	}
}
