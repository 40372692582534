import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { FormGroup, Validators } from '@angular/forms';
import {
	CorLegendaVariaveisMeteorologicasPrecipitacao,
	LabelsHistoricoVariaveisMeteorologicas,
	TiposPeriodosVariaveisMeteorologicas,
	VariaveisMeteorologicasPorPeriodo,
	VariaveisMeteorologicasPorPeriodoETipoVariavel,
} from '@home/submodulos/dados-meteorologicos/interfaces/variavel-meteorologica-mapa';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';
import { ValuesVariaveis } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/interfaces/estacao-monitorada';
import { LegendaOptions } from '@home/submodulos/dados-meteorologicos/componentes/legenda-relatorio-historico-variaveis/legenda-relatorio-historico-variaveis.component';
import {
	capitalizeFirstLetter,
	getBase64ImageFromURL,
	isNotNuloOuUndefined,
} from '@utils';
import * as pdfseira from '@utils/pdf-seira';
import html2canvas from 'html2canvas';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment/moment';
import { Moment } from 'moment';
import {
	CorLegendaChuvaAnualPastel,
	CorLegendaChuvaDiariaPastel,
	CorLegendaChuvaMensalPastel,
} from '@home/submodulos/dados-meteorologicos/enum/Variaveis-metereologica';

@Component({
	selector: 'seira-variaveis-metorologicas-mapa',
	templateUrl: './variaveis-metorologicas-mapa.component.html',
	styleUrls: ['./variaveis-metorologicas-mapa.component.scss'],
})
export class VariaveisMetorologicasMapaComponent
	implements OnInit, OnDestroy, InstanciaRelatorio
{
	inputs = inject(INPUTS_RELATORIOS);
	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => {
				this.exportPdf().then();
			},
		},
	];
	form!: FormGroup;

	resultados: VariaveisMeteorologicasPorPeriodoETipoVariavel[] = [];
	legenda: LegendaOptions = {
		label: LabelsHistoricoVariaveisMeteorologicas.PRECIPITACAO,
		items: [],
	};

	legendaTitle: LegendaOptions = {
		label: LabelsHistoricoVariaveisMeteorologicas.PRECIPITACAO,
		items: [],
	};

	funcaoCoresMapa?: (valor: number) => string;
	constructor(
		private relatorioService: RelatoriosService,
		private toastr: ToastrService
	) {}

	ngOnInit() {
		this.form = this.inputs.form;
		setTimeout(() => {
			this.setValidators();
		}, 0);
	}
	gerarRelatorio() {
		const dataInicio: Moment | null = this.form.get(
			FormularioRelatorio.DATA_INICIO
		)?.value;
		const dataFim: Moment | null = this.form.get(FormularioRelatorio.DATA_FIM)
			?.value;
		const periodo = this.form.get(FormularioRelatorio.TIPO_PERIODO)?.value;

		if (dataInicio && dataFim && periodo.toUpperCase() == 'MENSAL') {
			this.inputs.setLoading(true);
			const primeiroDiaMes = dataInicio.clone().startOf('month');
			const ultimoDiaMes = dataFim.clone().date(dataFim.daysInMonth());

			this.relatorioService
				.listarVariaveisMeteorologicas(
					periodo.toUpperCase(),
					primeiroDiaMes.toISOString(),
					ultimoDiaMes.toISOString()
				)
				.subscribe({
					next: res => {
						this._resultados = res;
						this.inputs.setLoading(false);
					},
					error: err => {
						this.toastr.error('Ocorreu um erro ao gerar o relatório', err);
						this.inputs.setLoading(false);
					},
				});
		} else if (periodo.toUpperCase() == 'ANUAL') {
			this.inputs.setLoading(true);
			this.relatorioService
				.listarVariaveisMeteorologicas(
					periodo.toUpperCase(),
					dataInicio?.toISOString()
				)
				.subscribe({
					next: res => {
						this._resultados = res;
						this.inputs.setLoading(false);
					},
					error: err => {
						this.toastr.error('Ocorreu um erro ao gerar o relatório', err);
						this.inputs.setLoading(false);
					},
				});
		} else if (dataInicio && dataFim && periodo.toUpperCase() == 'DIARIO') {
			this.inputs.setLoading(true);
			this.relatorioService
				.listarVariaveisMeteorologicas(
					periodo.toUpperCase(),
					dataInicio.toISOString(),
					dataFim.toISOString()
				)
				.subscribe({
					next: res => {
						this._resultados = res;
						this.inputs.setLoading(false);
					},
					error: err => {
						this.toastr.error('Ocorreu um erro ao gerar o relatório', err);
						this.inputs.setLoading(false);
					},
				});
		}
	}
	set _resultados(value: VariaveisMeteorologicasPorPeriodo[]) {
		const tipoVariavel = this.form.get(FormularioRelatorio.VARIAVEL_MAPA)
			?.value;
		let campoValor: 'precipitacaoAcumulada' | 'temperaturaMedia';
		if (tipoVariavel == ValuesVariaveis.PRECIPITACAO) {
			this.legenda.label = LabelsHistoricoVariaveisMeteorologicas.PRECIPITACAO;
			campoValor = 'precipitacaoAcumulada';
		} else {
			this.legenda.label = capitalizeFirstLetter(
				LabelsHistoricoVariaveisMeteorologicas.TEMPERATURA
			);
			campoValor = 'temperaturaMedia';
		}

		this.resultados = value
			.map(el => this.resultadoPorTipoPeriodo(el, campoValor))
			.filter(el => el.historico.length);

		this.setCoresLegenda();
	}

	setCoresLegenda() {
		const periodo = this.form.get(FormularioRelatorio.TIPO_PERIODO)?.value;
		this.legenda.items = [];

		switch (periodo) {
			case 'DIARIO':
				const valoresDiarios = [0, 0.1, 5.1, 15.1, 25.1, 50.1];
				const valoresDiariosString = valoresDiarios.map(valor =>
					new Intl.NumberFormat('pt-BR').format(valor)
				);
				const coresDiarias = Object.values(CorLegendaChuvaDiariaPastel);
				this.configurarLegenda(
					valoresDiarios,
					coresDiarias,
					valoresDiariosString
				);
				break;

			case 'MENSAL':
				const valoresMensais = [0, 0.1, 50.1, 100.1, 200.1, 300.1, 400.0];
				const valoresDiariosStrings = valoresMensais.map(valor =>
					new Intl.NumberFormat('pt-BR').format(valor)
				);
				const coresMensais = Object.values(CorLegendaChuvaMensalPastel);
				this.configurarLegenda(
					valoresMensais,
					coresMensais,
					valoresDiariosStrings
				);
				break;

			case 'ANUAL':
				const valoresAnuais = [0, 200.1, 400.1, 700.1, 1000.1, 1300.1, 1600.0];
				const valoresDiariosStringss = valoresAnuais.map(valor =>
					new Intl.NumberFormat('pt-BR').format(valor)
				);
				const coresAnuais = Object.values(CorLegendaChuvaAnualPastel);
				this.configurarLegenda(
					valoresAnuais,
					coresAnuais,
					valoresDiariosStringss
				);
				break;

			default:
				console.warn(`Período desconhecido: ${periodo}`);
				break;
		}

		this.funcaoCoresMapa = (valor: number) => {
			for (let i = 0; i < this.legenda.items.length; i++) {
				const itemDaLegenda = this.legenda.items[i];
				const nextItem = this.legenda.items[i + 1];

				if (nextItem) {
					if (
						valor >= itemDaLegenda.numberCurrentAndNext &&
						valor < nextItem.numberCurrentAndNext
					) {
						return itemDaLegenda.color;
					}
				} else {
					// Caso especial para o último item da legenda
					if (valor >= itemDaLegenda.numberCurrentAndNext) {
						return itemDaLegenda.color;
					}
				}
			}

			return ''; // Retorna vazio se o valor não estiver em nenhum intervalo
		};
	}

	private configurarLegenda(
		valores: number[],
		cores: string[],
		valoresString: string[]
	): void {
		this.legenda.items = valores.map((valor, index) => ({
			numberCurrentAndNext: valor,
			title: valoresString[index],
			color: cores[index],
		}));

		this.legendaTitle.items = valores.map((valor, index) => ({
			numberCurrentAndNext: valoresString[index],
			color: cores[index],
		}));
	}

	resultadoPorTipoPeriodo(
		resultado: VariaveisMeteorologicasPorPeriodo,
		field: 'precipitacaoAcumulada' | 'temperaturaMedia'
	): VariaveisMeteorologicasPorPeriodoETipoVariavel {
		const periodo: keyof typeof TiposPeriodosVariaveisMeteorologicas =
			this.form.get(FormularioRelatorio.TIPO_PERIODO)?.value;
		const tipoVariavel: ValuesVariaveis = this.form.get(
			FormularioRelatorio.VARIAVEL_MAPA
		)?.value;
		let historico: VariaveisMeteorologicasPorPeriodoETipoVariavel['historico'] =
			[];
		resultado.historico.forEach(el => {
			if (isNotNuloOuUndefined(el[field])) {
				const indexHistoricoMesmoPosto = historico.findIndex(
					medicao => medicao.geocodigo == el.geocodigo
				);
				if (
					historico[indexHistoricoMesmoPosto] &&
					historico[indexHistoricoMesmoPosto].valor < el[field]
				) {
					historico[indexHistoricoMesmoPosto] = {
						geocodigo: el.geocodigo,
						valor: el[field],
						nomeEstacao: el.nomeEstacao,
						tipoEstacao: el.tipoEstacao,
						nomeMunicipio: el.nomeMunicipio,
					};
				} else {
					historico.push({
						geocodigo: el.geocodigo,
						valor: el[field],
						nomeEstacao: el.nomeEstacao,
						tipoEstacao: el.tipoEstacao,
						nomeMunicipio: el.nomeMunicipio,
					});
				}
			}
		});

		return { data: resultado.data, historico, periodo, tipoVariavel };
	}
	setValidators() {
		this.form
			.get(FormularioRelatorio.VARIAVEL_MAPA)
			?.setValidators(Validators.required);
		this.form
			.get(FormularioRelatorio.TIPO_PERIODO)
			?.setValidators(Validators.required);
		this.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
	}

	getPeriodo() {
		const periodo = this.form.get(FormularioRelatorio.TIPO_PERIODO)?.value;
		const dataInicio = this.form.get(FormularioRelatorio.DATA_INICIO)?.value;
		const dataFim = this.form.get(FormularioRelatorio.DATA_FIM)?.value;

		if (periodo.toUpperCase() == 'DIARIO') {
			return `${dataInicio.format('dd/MM/yyyy')} a ${dataFim.format(
				'dd/MM/yyyy'
			)}`;
		}
		return `${dataInicio.format('MM/yyyy')} a ${dataFim.format('MM/yyyy')}`;
	}

	async exportPdf() {
		const pdfDefinitions: any = await pdfseira.documentDefinitions();
		const tipoPeriodo: 'MENSAL' | 'ANUAL' | 'DIARIO' = this.form.get(
			FormularioRelatorio.TIPO_PERIODO
		)?.value;
		const tipoVariavel = this.form.get(FormularioRelatorio.VARIAVEL_MAPA)
			?.value;

		let tipoVariavelLabel: string = capitalizeFirstLetter(
			LabelsHistoricoVariaveisMeteorologicas.TEMPERATURA
		);

		if (tipoVariavel == ValuesVariaveis.PRECIPITACAO) {
			tipoVariavelLabel = LabelsHistoricoVariaveisMeteorologicas.PRECIPITACAO;
		}

		const promiseImagens = this.resultados.map(async (historico, index) => {
			const imagem: any = document.getElementById('mapa-' + index + '-imagem');

			if (imagem) {
				const canvas = document.createElement('canvas');
				canvas.width = imagem.width * 1.5;
				canvas.height = imagem.height * 1.4;
				canvas.style.background = 'color: black';
				canvas?.getContext('2d')?.drawImage(imagem, 0, 0);

				const url = canvas.toDataURL('image/png');
				const imageData = await getBase64ImageFromURL(url);
				return { imagem: imageData, historico };
			}

			return null;
		});
		const legendaHtml = document.getElementById('legenda');
		if (legendaHtml) {
			const img = await html2canvas(legendaHtml).then(canvas =>
				canvas.toDataURL('image/png')
			);
			pdfDefinitions.content.push({
				text: `Relatório - Mosaico de chuvas ${
					tipoPeriodo.toUpperCase() === 'MENSAL' ? `- ${this.getPeriodo()}` : ''
				}`,
				alignment: 'center',
				margin: [15, 15, 5, 15],
			});
			pdfDefinitions.content.push({
				text: [{ text: 'Formato: ', bold: true }, 'Mapa'],
				fontSize: 10,
				alignment: 'start',
			});
			pdfDefinitions.content.push({
				text: [
					{ text: 'Tipo de variável: ', bold: true },
					`${tipoVariavelLabel}`,
				],
				fontSize: 10,
				alignment: 'start',
			});
			pdfDefinitions.content.push({
				text: [
					{ text: 'Agrupado por: ', bold: true },
					tipoPeriodo.toUpperCase() === 'ANUAL'
						? 'Anual'
						: tipoPeriodo.toUpperCase() === 'MENSAL'
						? 'Mensal'
						: tipoPeriodo.toUpperCase() === 'DIARIO'
						? 'Diário'
						: '',
				],
				fontSize: 10,
				alignment: 'start',
			});

			pdfDefinitions.content.push({
				image: img,
				margin: [0, 10, 0, 10],
				alignment: 'center',
				width: 500,
			});
		}

		Promise.all(promiseImagens).then(imagens => {
			if (imagens.every(imagem => imagem === null)) {
				this.toastr.error(
					'Não é possível gerar um pdf pois nenhum mapa foi gerado.'
				);
				return;
			}

			const columns: any = [[], [], []]; // Dividindo em 3 colunas

			const formatData = (data: Date, periodo: string): string => {
				switch (periodo) {
					case 'ANUAL':
						return moment(data).format('yyyy').toString();
					case 'MENSAL':
						return moment(data).format('MM/yyyy').toString();
					case 'DIARIO':
						return moment(data).format('DD/MM/yyyy').toString();
					default:
						return 'MM/yyyy';
				}
			};

			imagens.forEach((resultadoProcessamentoDaImagem, index) => {
				if (resultadoProcessamentoDaImagem) {
					let dataFormatada = formatData(
						resultadoProcessamentoDaImagem.historico.data,
						resultadoProcessamentoDaImagem.historico.periodo
					);

					const dateText = {
						text: dataFormatada,
						fontSize: 12,
						alignment: 'center',
						margin: [0, 10],
					};
					const image = {
						image: resultadoProcessamentoDaImagem.imagem,
						alignment: 'center',
						width: 160,
					};

					columns[index % 3].push(dateText);
					columns[index % 3].push(image);
				}
				if ((index < 15 && index + 1 === 15) || (index - 14) % 18 === 0) {
					columns[0].push({ text: '', pageBreak: 'after' });
					columns[1].push({ text: '', pageBreak: 'after' });
					columns[2].push({ text: '', pageBreak: 'after' });
				}
			});

			pdfDefinitions.content.push({
				columns: columns.map((column: any) => ({
					width: '33.3%',
					stack: column,
				})),
			});

			pdfseira.generatePdf(pdfDefinitions);
		});
	}

	ngOnDestroy() {
		this.form.get(FormularioRelatorio.VARIAVEL_MAPA)?.clearValidators();
		this.form.get(FormularioRelatorio.TIPO_PERIODO)?.clearValidators();
		this.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
	}
}
