import {
	DadosGraficoVariaveis,
	DadosSerieVariavelMeteorologica,
	SerieVariavelMeteorologica,
} from './serie-variavel-meteorologica';
import mensagens from './mensagens';
import { Color, getOptions } from 'highcharts';

export interface DadosUmidade extends DadosGraficoVariaveis {
	umidade: number[];
}

export class SerieUmidade extends SerieVariavelMeteorologica {
	private corUmidade = getOptions().colors?.at(2);

	constructor(readonly nomeDaSerie?: string) {
		super();
	}

	obterDadosSerie(
		dados: DadosUmidade,
		indexEixo: number
	): DadosSerieVariavelMeteorologica {
		if (this.corUmidade) {
			return {
				serie: [
					{
						yAxis: indexEixo,
						name: this.nomeDaSerie
							? this.nomeDaSerie
							: mensagens['variavel.umidade'],
						type: 'area',
						data: this.prepararSerie(dados.datas, dados.umidade),
						color: this.corUmidade,
						tooltip: {
							valueDecimals: 2,
							valueSuffix: ` ${mensagens['variavel.umidade.unidade']}`,
						},
						fillColor: {
							linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
							stops: [
								[0, this.corUmidade],
								[1, new Color(this.corUmidade).setOpacity(0).get('rgba')],
							],
						},
					},
				],
				eixo: {
					title: {
						text: this.nomeDaSerie
							? this.adicionarUnidade(this.nomeDaSerie)
							: mensagens['variavel.umidade.titulo-meteograma'],
					},
					lineWidth: 2,
					opposite: false,
					labels: { align: 'left', x: 3 },
				},
			};
		}
		return { eixo: {}, serie: [] };
	}

	private adicionarUnidade(nomeDaSerie: string): string {
		return nomeDaSerie + ` ( ${mensagens['variavel.umidade.unidade']} )`;
	}
}
