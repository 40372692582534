const mensagens = {
	variaveis: 'Variáveis',
	'variaveis-meteorologicas': 'Variáveis Meteorológicas',
	'variavel.precipitacao': 'Precipitação',
	'variavel.precipitacao-ac': 'Precipitação acumulada',
	'variavel.precipitacao-acumulada': 'Precipitação acumulada 24h',
	'variavel.precipitacao.unidade': 'mm',
	'variavel.precipitacao.titulo-meteograma': 'Precipitação (mm)',
	'variavel.temperatura': 'Temperatura',
	'variavel.temperatura.unidade': 'ºC',
	'variavel.temperatura.titulo-meteograma': 'Temperatura (ºC)',
	'variavel.pressao': 'Pressão',
	'variavel.pressao.unidade': 'hPa',
	'variavel.pressao.titulo-meteograma': 'Pressão (hPa)',
	'variavel.umidade': 'Umidade',
	'variavel.umidade.unidade': '%',
	'variavel.umidade.titulo-meteograma': 'Umidade (%)',
	'variavel.vento': 'Vento',
	'variavel.velocidade': 'Velocidade',
	'variavel.velocidade.unidade': 'm/s',
	'variavel.vento.velocidade': 'Velocidade do vento',
	'variavel.vento.titulo-meteograma': 'Vento (m/s)',
	'variavel.direcao': 'Direção',
	'variavel.direcao.unidade': 'º',
	'variavel.vento.direcao': 'Direção do vento',
	'variavel.nebulosidade': 'Nebulosidade',
	'variavel.nebulosidade.unidade': '%',
	'variavel.nebulosidade.titulo-meteograma': 'Nebulosidade (%)',
	'variavel.baixa': 'Baixa',
	'variavel.nebulosidade.baixa': 'Nebulosidade baixa',
	'variavel.media': 'Média',
	'variavel.nebulosidade.media': 'Nebulosidade média',
	'variavel.alta': 'Alta',
	'variavel.nebulosidade.alta': 'Nebulosidade alta',
	'variavel.pressaoAtmosferica': 'Pressão atmosférica',
	'variavel.fluxoCalor': 'Fluxo de calor',
	'variavel.fluxoCalor.unidade': 'mV',
	'variavel.temperaturaAr': 'Temperatura do ar',
	'variavel.temperaturaSolo': 'Temperatura do solo',
	'variavel.temperaturaInstantanea': 'Temperatura instantânea',
	'variavel.temperaturaMaxima': 'Temperatura Máxima',
	'variavel.temperaturaMinima': 'Temperatura Mínima',
	'variavel.umidadeRelativa': 'Umidade relativa',
	'variavel.umidadeInstantanea': 'Umidade instantânea',
	'variavel.umidadeMaxima': 'Umidade máxima',
	'variavel.umidadeMinima': 'Umidade mínima',
	'variavel.pontoOrvalhoInstantaneo': 'Ponto de orvalho instantâneo',
	'variavel.pontoOrvalhoMaximo': 'Ponto de orvalho máximo',
	'variavel.pontoOrvalhoMinimo': 'Ponto de orvalho mínimo',
	'variavel.pressaoInstantanea': 'Pressão instantânea',
	'variavel.pressaoMaxima': 'Pressão máxima',
	'variavel.pressaoMinima': 'Pressão mínima',
	'variavel.rajadaVento': 'Rajada de vento',
	'variavel.radiacaoGlobal': 'Radiação global',
	'variavel.radiacaoGlobal.unidade': 'W/m²',
	'variavel.radiacaoGlobal.titulo-meteograma': 'Radiação global ( W/m² )',
};

export default mensagens;
