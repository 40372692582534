export enum Rotas {
	MUNICIPIO = 'municipios',
	MICRORREGIAO = 'microrregioes',
	MESORREGIAO = 'mesorregioes',
	ORGAO = 'orgaos',
	GRUPO = 'grupos',
	ESTACAO = 'estacoes',
	USUARIO = 'usuarios',
	FONTE = 'fontes',
	CULTURA = 'culturas',
	CARTA_CAMPO = 'carta-campo',
	SOLO = 'solo',
	ZONEAMENTO = 'zoneamentos',
	MODELONUMERICO = 'modelo-numerico',
	ACOMPANHAMENTO_COLETA = 'acompanhamento-coleta',
	PLUVIOMETRIA = 'pluviometria',
	RELATORIOS = 'relatorios',
	RISCO_CLIMATICO = 'riscos-agricolas',
	BOLETIM = 'boletins_diarios',
	BOLETIM_PUBLICO = 'boletim',
	GRAFICOS = 'graficos',
	REGIONAIS = 'regionais',
	REGIES = 'regioes',
	METEOGRAMA = 'meteograma',
	VERANICO = 'avisos-veranico',
	AVISOS = 'avisos-limite',
	SELOS = 'selo',
	TIPO_MEDICAO = 'tipo-medicao',
	EXECUCAO = 'execucao',
	EVENTO = 'eventos',
	BACIA = 'bacias',
	CONFIGURACAO_LIMITE = 'configuracao-limite',
	CONFIGURACAO_VERANICO = 'configuracao-veranico',
	SUBBACIA = 'subbacia',
	RESERVATORIO = 'reservatorio',
	PERIODOS_RESERVATORIO = 'periodos-reservatorio',
	HISTORICO_RESERVATORIO = 'historico-reservatorio',
	DADOS_PERIODO_RESERVATORIO = 'dados_periodo_reservatorio',
	CONFIGURACAO_CLASSIFICACAO_RESERVATORIO = 'configuracaoClassificacaoReservatorio',
	PERMISSOES = 'permissoes',
	PERMISSOES_USUARIO = 'permissoes_usuario',
	RELATORIO_VARIAVEIS_METEOROLOGICAS = 'variaveis-meteorologicas/historico',
	DADOS_HISTORICO_CHUVAS = 'relatorios/precipitacao/mapa-ndc',
	QUANTIS = 'quantis-regiao',
	CLIMA = 'clima',
	CLIMATOLOGIA = 'climatologia',
	IMAGENS_DO_SISTEMA = 'imagens-sistema',
	REGIAO_PLUVIOMETRICA = 'regiao-pluviometrica',
	RELATORIO_DESVIO = 'relatorio-desvio',
}
