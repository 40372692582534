import { Component, Input, OnInit } from '@angular/core';
import {
	DadosMicrorregiao,
	Microrregiao,
} from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import { ADTColumns } from 'angular-datatables/src/models/settings';
import { isNotNuloOuUndefined, numberToBrNumber } from '@utils';

@Component({
	selector: 'seira-tabela-relatorios-pluviometricos-do-estado',
	templateUrl: './tabela-relatorios-pluviometricos-do-estado.component.html',
	styleUrls: ['./tabela-relatorios-pluviometricos-do-estado.component.scss'],
})
export class TabelaRelatoriosPluviometricosDoEstadoComponent {
	@Input() relatorios!: Microrregiao[];

	colunas: ADTColumns[] = [
		{ data: 'detalhesPosto.municipio', title: 'Municipio', type: 'string' },
		{ data: 'detalhesPosto.posto', title: 'Posto', type: 'string' },
		{
			data: 'detalhesPosto.latitude',
			title: 'Latitude',
			type: 'number',
			render: this.renderNumber,
		},
		{
			data: 'detalhesPosto.longitude',
			title: 'Longitude',
			type: 'number',
			render: this.renderNumber,
		},
		{
			data: 'mediaMes',
			title: 'Norm. Mensal (mm)',
			type: 'number',
			render: this.renderNumber,
		},
		{
			data: 'valorObservadoMes',
			title: 'Obs. Mensal (mm)',
			type: 'number',
			render: this.renderNumber,
		},
		{
			data: 'desvioMes',
			title: 'Desv. Mensal (mm)',
			type: 'number',
			render: this.renderNumber,
		},
		{
			data: 'desvioPercentualMes',
			title: 'Desv. Mensal (%)',
			type: 'number',
			render: this.renderNumber,
		},
		{
			data: 'mediaAno',
			title: 'Norm. Anual (mm)',
			type: 'number',
			render: this.renderNumber,
		},
		{
			data: 'valorObservadoAno',
			title: 'Obs. Anual (mm)',
			type: 'number',
			render: this.renderNumber,
		},
		{
			data: 'desvioAno',
			title: 'Desv. Anual (mm)',
			type: 'number',
			render: this.renderNumber,
		},
		{
			data: 'desvioPercentualAno',
			title: 'Desv. Anual (%)',
			type: 'number',
			render: this.renderNumber,
		},
	];

	renderNumber(data: number) {
		return isNotNuloOuUndefined(data) ? numberToBrNumber(data.toFixed(2)) : '-';
	}
}
