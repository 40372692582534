import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
	PeriodosLabel,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';

import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';
import { AbstractControl, Validators } from '@angular/forms';
import { HighStockComponent } from '@componentes/high-stock/high-stock.component';
import * as Highcharts from 'highcharts/highstock';
import { options } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/grafico-pluviometria-observada/chartOptions';
import { format } from 'date-fns-tz';
import { Subject, takeUntil } from 'rxjs';
import {
	AgrupamentoResponse,
	DadosAgrupamento,
} from '@home/submodulos/dados-meteorologicos/interfaces/agrupamento';
import { Moment } from 'moment/moment';
import moment from 'moment';

export enum GraficoPluviometriaObservadaPeriodos {
	DIARIO = 30,
	MENSAL = 12,
	ANUAL = 10,
}
@Component({
	selector: 'seira-grafico-pluviometria-observada',
	templateUrl: './grafico-pluviometria-observada.component.html',
	styleUrls: ['./grafico-pluviometria-observada.component.scss'],
})
export class GraficoPluviometriaObservadaComponent
	implements OnInit, OnDestroy, InstanciaRelatorio
{
	@ViewChild(HighStockComponent, { static: false })
	highStockComponent!: HighStockComponent;
	inputs = inject(INPUTS_RELATORIOS);
	chart!: Highcharts.Chart;
	chartOptions: Highcharts.Options = options;
	_destroyed = new Subject();
	constructor(private relatoriosService: RelatoriosService) {
		this.setValidators();
	}

	ngOnInit() {
		this.gerarRelatorio();
		this.observarMudancasPeriodoBusca();
	}

	ngOnDestroy() {
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.ESTACAO)?.clearValidators();
		this._destroyed.next(undefined);
	}

	observarMudancasPeriodoBusca() {
		const periodoBusca = this.inputs.form.get(
			FormularioRelatorio.PERIODO_BUSCA
		);
		this.lidarComPeriodo(periodoBusca?.value);
		periodoBusca?.valueChanges.pipe(takeUntil(this._destroyed)).subscribe({
			next: (periodo: PeriodosLabel | null) => {
				this.lidarComPeriodo(periodo);
			},
		});
	}

	lidarComPeriodo(periodo: PeriodosLabel | null) {
		const dataInicio: AbstractControl<Moment | null> | null =
			this.inputs.form.get(FormularioRelatorio.DATA_INICIO);
		const dataFim: AbstractControl<Moment | null> | null = this.inputs.form.get(
			FormularioRelatorio.DATA_FIM
		);
		switch (periodo) {
			case 'anual':
				if (dataFim?.value) {
					const hoje = new Date();
					if (dataFim.value.year() == hoje.getFullYear()) {
						dataFim?.setValue(moment(hoje));
					} else {
						dataFim?.setValue(dataFim.value.endOf('year'));
					}
				}
				if (dataInicio?.value && dataFim?.value) {
					const fim = moment(dataFim.value).subtract(
						GraficoPluviometriaObservadaPeriodos.ANUAL,
						'year'
					);
					dataInicio?.setValue(fim);
				}
				break;
			case 'mensal':
				if (dataFim?.value) {
					const hoje = new Date();
					if (
						dataFim.value.year() === hoje.getFullYear() &&
						dataFim.value.month() >= hoje.getMonth()
					) {
						dataFim.setValue(moment(hoje));
					} else {
						dataFim.setValue(dataFim.value.endOf('month'));
					}
				}
				if (dataInicio?.value && dataFim?.value) {
					const fim = moment(dataFim.value).subtract(
						GraficoPluviometriaObservadaPeriodos.MENSAL,
						'month'
					);
					dataInicio?.setValue(fim);
				}
				break;
			case 'diario':
				if (dataFim?.value) {
					const hoje = new Date();
					if (
						dataFim.value.year() === hoje.getFullYear() &&
						dataFim.value.month() >= hoje.getMonth()
					) {
						dataFim?.setValue(moment(hoje));
					} else {
						dataFim?.setValue(dataFim.value.endOf('month'));
					}
				}
				if (dataInicio?.value && dataFim?.value) {
					const fim = moment(dataFim.value).subtract(
						GraficoPluviometriaObservadaPeriodos.DIARIO,
						'day'
					);
					dataInicio?.setValue(fim);
				}
				break;
		}
	}

	lidarComAgrupamento() {
		const agrupamento = this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO);
		const municipio = this.inputs.form.get(FormularioRelatorio.MUNICIPIO);
		const microrregiao = this.inputs.form.get(FormularioRelatorio.MICRORREGIAO);
		const municipioPosto = this.inputs.form.get(FormularioRelatorio.ESTACAO);
		switch (agrupamento?.value) {
			case 'MUNICIPIO_POSTO':
				municipioPosto?.setValidators(Validators.required);
				break;
			case 'MICRORREGIAO':
				microrregiao?.setValidators(Validators.required);
				break;
			case 'MUNICIPIO':
				municipio?.setValidators(Validators.required);
				break;
		}
	}

	setValidators() {
		this.lidarComAgrupamento();
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.valueChanges.pipe(takeUntil(this._destroyed))
			.subscribe({
				next: () => {
					this.lidarComAgrupamento();
				},
			});
		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.PERIODO_BUSCA)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValidators(Validators.required);
	}

	setChart(chart: Highcharts.Chart) {
		this.chart = chart;
	}
	checaAgrupamento(dados: DadosAgrupamento) {
		const agrupamento = this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)
			?.value;

		let obj: any;

		switch (agrupamento) {
			case 'MUNICIPIO_POSTO':
				dados.posto = this.inputs.form.get(FormularioRelatorio.ESTACAO)?.value;
				obj = this.inputs.estacoes.filter(m => m.id === dados.posto);
				return {
					serieUnica: true,
					obj: obj,
					tipoRetorno: 'MUNICIPIO_POSTO',
				};
				break;
			case 'MICRORREGIAO':
				dados.microrregiao = this.inputs.form.get(
					FormularioRelatorio.MICRORREGIAO
				)?.value;
				obj = this.inputs.microrregioes.filter(
					m => m.value === dados.microrregiao + ''
				);

				return {
					serieUnica: true,
					obj: obj,
					tipoRetorno: 'MICRORREGIAO',
				};
				break;

			case 'MUNICIPIO':
				dados.municipio = this.inputs.form.get(FormularioRelatorio.MUNICIPIO)
					?.value;

				obj = this.inputs.municipios.filter(m => m.id === dados.municipio);
				return { serieUnica: true, obj: obj, tipoRetorno: 'MUNICIPIO' };
				break;
			default:
				return { serieUnica: false, obj: obj, tipoRetorno: null };
		}
	}

	gerarRelatorio() {
		const diaInicio = this.inputs.form.get(FormularioRelatorio.DATA_INICIO)
			?.value;
		const diaFim = this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.value;
		const agrupamento = this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)
			?.value;
		const periodoAgrupamento = this.inputs.form.get(
			FormularioRelatorio.PERIODO_BUSCA
		)?.value as PeriodosLabel;

		if (
			!diaInicio ||
			!diaFim ||
			!agrupamento ||
			!periodoAgrupamento ||
			this.inputs.form.invalid
		) {
			return;
		}

		const dados: DadosAgrupamento = {
			diaInicio,
			diaFim,
			agrupamento,
			periodoAgrupamento: periodoAgrupamento.toUpperCase(),
		};
		const agrupamentoUnico = this.checaAgrupamento(dados);
		this.relatoriosService.buscarRelatorioPorAgrupamento(dados).subscribe({
			next: preciptacoesAgrupadas => {
				this.inputs.setLoading(true);
				let dadosTratados: AgrupamentoResponse[] = [];
				if (agrupamentoUnico?.serieUnica) {
					dadosTratados = this.verificaTipoRetorno(
						agrupamentoUnico,
						preciptacoesAgrupadas
					);
				} else {
					dadosTratados = preciptacoesAgrupadas;
				}

				this.configurarChart(dadosTratados, periodoAgrupamento);
				this.highStockComponent.update();
				this.inputs.setLoading(false);
			},
		});
	}
	verificaTipoRetorno(
		agrupamento: any,
		preciptacoesAgrupadas: AgrupamentoResponse[]
	) {
		if (agrupamento.tipoRetorno === 'MUNICIPIO_POSTO') {
			return preciptacoesAgrupadas.filter(p => {
				return p.nome.split('/')[1] === agrupamento?.obj[0]?.nomePosto;
			});
		} else if (agrupamento.tipoRetorno === 'MUNICIPIO') {
			return preciptacoesAgrupadas.filter(p => {
				return p.nome === agrupamento?.obj[0]?.nome;
			});
		} else if (agrupamento.tipoRetorno === 'MICRORREGIAO') {
			return preciptacoesAgrupadas.filter(p => {
				return p.nome === agrupamento?.obj[0]?.name;
			});
		} else {
			return [];
		}
	}

	extrairDatasPrecipitacao(
		preciptacoesAgrupadas: AgrupamentoResponse[],
		periodoAgrupamento: PeriodosLabel
	) {
		return [
			...new Set(
				preciptacoesAgrupadas
					.filter(el => el.data)
					.map(el => {
						const [ano, mes, dia] = el.data!.split('-');
						return new Date(+ano, (+mes || 1) - 1, +dia || 1);
					})
					.sort((a, b) => +a - +b)
					.map(data => {
						let formato = 'dd/MM/yyyy';
						if (periodoAgrupamento === 'mensal') {
							formato = 'MM/yyyy';
						} else if (periodoAgrupamento === 'anual') {
							formato = 'yyyy';
						}
						return format(data, formato, {
							timeZone: 'America/Sao_Paulo',
						});
					})
			),
		];
	}

	extrairNomes(preciptacoesAgrupadas: AgrupamentoResponse[]) {
		return [...new Set(preciptacoesAgrupadas.map(el => el.nome))];
	}

	configurarChart(
		preciptacoesAgrupadas: AgrupamentoResponse[],
		periodoAgrupamento: PeriodosLabel
	) {
		const categorias = this.extrairNomes(preciptacoesAgrupadas);

		const dataSeries = this.extrairDatasPrecipitacao(
			preciptacoesAgrupadas,
			periodoAgrupamento
		);

		this.chartOptions.series = dataSeries.map(serie => {
			return {
				type: 'column',
				name: serie,
				groupPadding: 0,
				pointPadding: 0.1,
				data: categorias.map(categoria => {
					const formattedDate = serie.split('/').reverse().join('-');
					const entry = preciptacoesAgrupadas.find(
						el => el.nome === categoria && el.data === formattedDate
					);
					return entry ? +entry.precipitacaoAcumulada.toFixed(2) : null;
				}),
			};
		});

		this.chartOptions.xAxis = {
			categories: categorias,
			minRange: 1,
		};
	}
}
