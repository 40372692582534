import { Injectable } from '@angular/core';
import { HttpClientProtectedService } from '@core/services/auth/http-client-protected.service';
import { Bacia, RespostaListaBacias } from '../interface/bacia';
import { Observable } from 'rxjs';
import { Rotas } from '@core/enums/routes';

@Injectable({
	providedIn: 'root',
})
export class BaciaService {
	constructor(private http: HttpClientProtectedService) {}

	adicionar(newBacia: Bacia): Observable<Bacia> {
		return this.http.post<Bacia, Bacia>(Rotas.BACIA, newBacia);
	}

	editar(editBacia: Bacia): Observable<Bacia> {
		return this.http.patch<Bacia, any>(
			Rotas.BACIA + '/' + editBacia.id,
			editBacia
		);
	}

	remover(baciaId: number): Observable<any> {
		return this.http.delete<Bacia>(Rotas.BACIA + '/' + baciaId);
	}

	buscarPorId(baciaId: number): Observable<Bacia> {
		return this.http.get<Bacia>(Rotas.BACIA + '/' + baciaId);
	}
	listSelect() {
		return this.http.get<RespostaListaBacias>(Rotas.BACIA);
	}
	list() {
		return this.http.get<Bacia[]>(Rotas.BACIA + '/listar');
	}
}
