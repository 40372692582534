import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Microrregiao } from '../interfaces/microrregiao';
import { Rotas } from '@core/enums/routes';
import { Projection } from '@core/enums/projections';
import { Mesorregiao } from '../../mesorregiao/interfaces/Mesorregiao';
import { HttpClientProtectedService } from '@core/services/auth/http-client-protected.service';

@Injectable({
	providedIn: 'root',
})
export class MicrorregiaoService {
	constructor(private http: HttpClientProtectedService) {}

	adicionar(newMicrorregiao: Microrregiao): Observable<Microrregiao> {
		return this.http.post<Microrregiao, Microrregiao>(
			Rotas.MICRORREGIAO,
			newMicrorregiao
		);
	}

	editar(editMicrorregiao: Microrregiao): Observable<Microrregiao> {
		return this.http.patch<Microrregiao, any>(
			Rotas.MICRORREGIAO + '/' + editMicrorregiao.id,
			editMicrorregiao
		);
	}

	remover(microrregiaoId: number): Observable<any> {
		return this.http.delete<Microrregiao>(
			Rotas.MICRORREGIAO + '/' + microrregiaoId
		);
	}

	buscarPorId(microrregiaoId: number): Observable<Microrregiao> {
		return this.http.get<Microrregiao>(
			Rotas.MICRORREGIAO +
				'/' +
				microrregiaoId +
				'?projection=' +
				Projection.MICRORREGIAO_All
		);
	}

	list(): Observable<any> {
		return this.http.get<Microrregiao>(Rotas.MICRORREGIAO);
	}
	buscarMesorregioes(): Observable<Mesorregiao> {
		return this.http.get<any>(
			Rotas.MESORREGIAO + '?projection=' + Projection.MESORREGIAO_All
		);
	}
}
