import { Component, Input, OnChanges } from '@angular/core';
import { ImportError } from '@utils';

@Component({
	selector: 'seira-error-list',
	templateUrl: './error-list.component.html',
	styleUrls: ['./error-list.component.scss'],
})
export class ErrorListComponent implements OnChanges {
	@Input() errors: ImportError[] = [];

	errosPorLinha: Record<number, ImportError[]> = {};

	ngOnChanges() {
		this.errosPorLinha = this.errors.reduce(
			(acc, error) => {
				acc[error.linha] = [...(acc[error.linha] || []), error];
				return acc;
			},
			{} as typeof this.errosPorLinha
		);
	}

	protected readonly Object = Object;
}
