import {
	Component,
	EventEmitter,
	inject,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import * as Highcharts from 'highcharts/highstock';

import { formatISO } from 'date-fns';
import { Chart } from 'highcharts';
import { FormGroup, Validators } from '@angular/forms';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { ClimatologiaResponseDTO } from '@home/submodulos/dados-meteorologicos/interfaces/climatologia';
import { Agrupamento } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/interfaces/estacao-monitorada';
import { ClimatologiaService } from '@administracao/submodulos/climatologia/services/climatologia.service';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';
import {
	optionsChartClimatologia,
	relatorioClimatologia,
} from '@home/submodulos/dados-meteorologicos/componentes/relatorios/grafico-climatologia/chart-options';
import { capitalizeFirstLetter } from '@utils';

@Component({
	selector: 'seira-climatologia-grafico',
	templateUrl: './grafico-climatologia.component.html',
	styleUrls: ['./grafico-climatologia.component.scss'],
})
export class GraficoClimatologiaComponent
	implements OnInit, OnDestroy, InstanciaRelatorio
{
	carregando = false;
	inputs = inject(INPUTS_RELATORIOS);
	chart?: Highcharts.Chart;
	resultado: ClimatologiaResponseDTO[] = [];
	form!: FormGroup;
	loading: boolean = false;
	gerou = false;
	private subscription = new Subscription();
	_destroyed = new Subject();
	tipoAgrupamento: Agrupamento = Agrupamento.MUNICIPIO_POSTO;
	descricaoRelatorio =
		'Define-se como climatologia a média histórica de chuva superior a 30 anos de\n' +
		'um determinado local ou região do estado da Paraíba';
	height = '190px';
	chartInstance = new EventEmitter<Chart>();
	chartOptions = optionsChartClimatologia();

	series: Array<Partial<Highcharts.SeriesOptionsType> & Record<string, any>> =
		relatorioClimatologia;

	constructor(private climatologiaService: RelatoriosService) {
		this.setValidators();
	}

	gerarRelatorio(): void {
		if (this.form.invalid) {
			return;
		}

		this.loading = true;
		this.inputs.setLoading(true);
		const diaInicio = this.inputs.form.get(FormularioRelatorio.DATA_INICIO)
			?.value;
		const diaFim = this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.value;
		const idMunicipio = this.inputs.form.get('municipio')?.value;
		const idMicrorregiao = this.inputs.form.get('microrregiao')?.value;
		const idPosto = this.inputs.form.get('estacao')?.value;

		this.tipoAgrupamento = this.inputs.form.get('agrupamento')?.value;

		this.inputs.setLoading(true);

		this.climatologiaService
			.buscarClimatologiaPorMunicipio(
				this.tipoAgrupamento,
				formatISO(new Date(diaInicio)),
				formatISO(new Date(diaFim)),
				idMunicipio,
				idPosto,
				idMicrorregiao
			)
			.subscribe({
				next: climatologias => {
					this.inputs.setLoading(false);
					this.resultado = climatologias;

					this.loading = false;
					this.gerou = true;

					this.resultado = this.ordenarPorMes(this.resultado);
				},
				error: () => {
					this.inputs.setLoading(false);
					this.loading = false;
					this.gerou = false;
				},
			});
	}

	ngOnInit() {
		this.form = this.inputs.form;
	}

	onChartCreated(chart: Chart) {
		if (this.resultado) {
			this.loading = false;
			this.chart = chart;
			this.chartInstance.emit(chart);

			this.setSeries();
		}
	}

	ngOnDestroy() {
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.TIPO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MUNICIPIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.ESTACAO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MICRORREGIAO)?.clearValidators();
		this.subscription.unsubscribe();
	}

	lidarComAgrupamento() {
		const agrupamento = this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO);
		const municipio = this.inputs.form.get(FormularioRelatorio.MUNICIPIO);
		const microrregiao = this.inputs.form.get(FormularioRelatorio.MICRORREGIAO);
		const municipioPosto = this.inputs.form.get(FormularioRelatorio.ESTACAO);
		municipio?.clearValidators();
		microrregiao?.clearValidators();
		municipioPosto?.clearValidators();
		switch (agrupamento?.value) {
			case 'MUNICIPIO_POSTO':
				municipioPosto?.setValidators(Validators.required);
				break;
			case 'MICRORREGIAO':
				microrregiao?.setValidators(Validators.required);
				break;
			case 'MUNICIPIO':
				municipio?.setValidators(Validators.required);
				break;
		}
	}

	setValidators() {
		this.lidarComAgrupamento();
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.valueChanges.pipe(takeUntil(this._destroyed))
			.subscribe({
				next: () => {
					this.lidarComAgrupamento();
				},
			});
		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValidators(Validators.required);
	}

	setSeries() {
		this.chart?.series.forEach(el => el.remove());
		this.series?.map(serie => {
			serie.data = [];
			return serie;
		});

		this.resultado = this.ordenarPorMes(this.resultado);
		const meses: string[] = this.extrairMeses();
		const agrupamentoUnicoGrafico = [
			'MUNICIPIO',
			'MUNICIPIO_POSTO',
			'MICRORREGIAO',
		];
		if (agrupamentoUnicoGrafico.includes(<Agrupamento>this.tipoAgrupamento)) {
			const nome = this.resultado[0]?.nome ? this.resultado[0]?.nome : '-';
			const municipio =
				this.tipoAgrupamento === agrupamentoUnicoGrafico[1] && this.resultado[0]
					? `${this.resultado[0]?.dadosPosto?.municipio}/`
					: '';
			this.chart?.setTitle({
				text: `Gráfico de Climatologia de ${municipio}${nome}`,
				align: 'center',
			});
			this.resultado.forEach(value => {
				this.series[0].data.push({
					y: Number.parseFloat(value?.climatologia.toFixed(1)),
					name: capitalizeFirstLetter(this.verificaMes(value.mes)),
				});
			});

			this.chart!.xAxis[0].setCategories(meses);
			this.series.forEach(el => {
				this.chart?.addSeries(el as any);
			});
		} else {
			this.configurarChart(this.resultado);
		}
	}

	configurarChart(preciptacoesAgrupadas: ClimatologiaResponseDTO[]) {
		const categorias = this.extrairNomes(preciptacoesAgrupadas);

		const meses: string[] = this.extrairMeses();

		this.chart?.xAxis[0].setCategories(meses);
		this.chart?.setTitle({
			text: `Gráfico de Climatologia`,
			align: 'center',
		});
		this.series = categorias.map(res => {
			return {
				name: res,
				data: meses.map(mes => {
					const entry = this.resultado.find(
						item =>
							item.nome === res &&
							capitalizeFirstLetter(this.verificaMes(item.mes)) === mes
					);

					return entry
						? {
								name: this.verificaMes(entry.mes),
								y: Number.parseFloat(entry?.climatologia.toFixed(1)),
						  }
						: null;
				}),
			};
		});

		this.series.forEach(el => {
			this.chart?.addSeries(el as any);
		});
	}
	verificaMes(mes: string) {
		return mes === 'marco' ? 'Março' : mes;
	}
	extrairNomes(preciptacoesAgrupadas: ClimatologiaResponseDTO[]) {
		return [...new Set(preciptacoesAgrupadas.map(el => el.nome))];
	}

	extrairMeses() {
		return [
			...new Set(
				this.resultado.map((item: { mes: string }) => {
					if (item.mes === 'marco') {
						return capitalizeFirstLetter('março');
					} else {
						return capitalizeFirstLetter(item.mes);
					}
				})
			),
		];
	}

	ordenarPorMes(
		resultados: ClimatologiaResponseDTO[]
	): ClimatologiaResponseDTO[] {
		return resultados.sort((a, b) => {
			const indiceA = mesesDoAno[a.mes.toLowerCase()];
			const indiceB = mesesDoAno[b.mes.toLowerCase()];
			return indiceA - indiceB;
		});
	}
}
const mesesDoAno: Record<string, number> = {
	janeiro: 1,
	fevereiro: 2,
	marco: 3,
	abril: 4,
	maio: 5,
	junho: 6,
	julho: 7,
	agosto: 8,
	setembro: 9,
	outubro: 10,
	novembro: 11,
	dezembro: 12,
};
