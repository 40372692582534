import { PublicSelectComponent } from '@componentes/public-select/public-select.component';
import { Component, forwardRef, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MeteogramaService } from '@home/submodulos/previsao-tempo/services/meteograma.service';
import { Select } from '@layout/interfaces/select';
import { format } from 'date-fns';
import ptBrLocale from 'date-fns/locale/pt-BR';

const FORMATACAO_TEMPO = "dd 'de' MMMM 'de' yyyy HH:mm OOOO";

@Component({
	selector: 'seira-public-select-execucao-meteograma',
	templateUrl: './public-select.component.html',
	styleUrls: ['./public-select.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => PublicSelectExecucaoMeteogramaComponent),
		},
	],
})
export class PublicSelectExecucaoMeteogramaComponent
	extends PublicSelectComponent<Select<number>, number>
	implements OnInit
{
	override optionValue: string = 'value';
	override optionLabel: string = 'name';
	override carregando: boolean = true;
	override carregandoLabel: string = 'Carregando as execuções';
	override label: string = 'Execução';
	override placeholder: string = 'Informe o tempo de execução';

	constructor(private meteogramaService: MeteogramaService) {
		super();
	}

	ngOnInit() {
		this.meteogramaService.listExecucoes().subscribe(execucoes => {
			this.options = execucoes.map(({ dataExecucao, idResultadoModelo }) => ({
				value: idResultadoModelo,
				name: `WRF - ${format(new Date(dataExecucao), FORMATACAO_TEMPO, {
					locale: ptBrLocale,
				})}`,
			}));
			this.value = this.options[0].value;
			this.onValueChange(this.value);
			this.carregando = false;
			this.carregandoChange.emit(this.carregando);
		});
	}
}
