<div class="row mt-3 mb-5">
	<div class="mb-2" *ngFor="let relatorio of relatorios">
		<label
			for="tabela-precipitacao-do-estado"
			class="text-tertiary mb-2 fw-semibold">
			{{ 'Microrregião ' + relatorio.microrregiao }}
		</label>
		<seira-public-table
			id="tabela-precipitacao-do-estado"
			[hasFooter]="false"
			[hasHeader]="false"
			[info]="false"
			[paging]="false"
			[searching]="false"
			[columns]="colunas"
			[scrollX]="true"
			[data]="relatorio.dadosPorMunicipio" />
	</div>
</div>
