import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'seira-modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
	@Output() handleClose = new EventEmitter<Event>();
	@Input() customBodySize = false;
}
