<div class="d-flex flex-column mt-2 align-items-center">
	<label class="text-tertiary fw-semibold mt-3">
		{{ fraseAnaliseChuvas }}
	</label>

	<div
		*ngIf="loading; else mapa"
		class="d-flex justify-content-center align-items-center w-100 h-50"
		style="min-height: 500px">
		<seira-loading [pageCentered]="false"></seira-loading>
	</div>

	<ng-template #mapa>
		<div
			class="d-flex flex-column align-items-center"
			style="max-width: 1300px"
			*ngIf="chuvaPorMunicipio.length; else nenhumResultado">
			<seira-card-info aditionalClasses="my-3">
				Placeholder informativo
			</seira-card-info>
			<div id="map-container" style="position: relative" class="w-100">
				<seira-mapa-paraiba-svg
					tipoMapa="precipitacao"
					id="mapa_quantis_anual"
					[loading]="loading"
					[atributosMunicipio]="chuvaPorMunicipio"
					[microrregiao]="microrregiaoNome"
					(legendaItems)="handleLegendaItems($event)"
					[popoverTemplate]="popupPrecipitacao"></seira-mapa-paraiba-svg>
			</div>
			<seira-legenda-relatorio
				[isDesktop]="isDesktop"
				[legenda]="legendaItems"
				[tipo]="getTipo()"
				[periodo]="getPeriodo()"></seira-legenda-relatorio>
		</div>
	</ng-template>

	<ng-template #nenhumResultado>
		<seira-sem-dados></seira-sem-dados>
	</ng-template>
</div>

<ng-template
	#popupPrecipitacao
	let-color="color"
	let-geocodigo="geocodigo"
	let-nome="nome">
	<seira-popup-boletim-diario-mapa
		[precipitacaoAcumulada]="precipitacaoAcumuladaPorGeocodigo(geocodigo)"
		[headerColor]="color"
		[nome]="nome">
	</seira-popup-boletim-diario-mapa>
</ng-template>
