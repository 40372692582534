import { Component, Input } from '@angular/core';
import { TextoInformativo } from '@home/submodulos/dados-meteorologicos/interfaces/texto-informativo';

@Component({
	selector: 'seira-info-gerais',
	templateUrl: './info-gerais.component.html',
	styleUrls: ['./info-gerais.component.scss'],
})
export class InfoGeraisComponent {
	@Input() textosInformativos!: TextoInformativo;
}
