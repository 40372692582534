import { Observable } from 'rxjs';

import { HttpClientProtectedService } from '@core/services/auth/http-client-protected.service';
import { Injectable } from '@angular/core';
import { Municipio } from '@modulos/meteorologia/submodulos/municipio/interfaces/municipio';

interface ResponseMunicipios {
	_embedded: {
		municipios: Municipio[];
	};
}

@Injectable({
	providedIn: 'root',
})
export class MunicipioService {
	constructor(private http: HttpClientProtectedService) {}

	adicionar(newMunicipio: Municipio): Observable<Municipio> {
		return this.http.post<Municipio, Municipio>('municipios', newMunicipio);
	}

	editar(editMunicipio: Municipio): Observable<Municipio> {
		return this.http.put<Municipio, any>(
			'municipios/' + editMunicipio.id,
			editMunicipio
		);
	}

	remover(municipioId: number): Observable<any> {
		return this.http.delete<Municipio>('municipios/' + municipioId);
	}

	buscarPorId(municipioId: number): Observable<Municipio> {
		return this.http.get<Municipio>('municipios/' + municipioId);
	}

	list(): Observable<ResponseMunicipios> {
		return this.http.get<ResponseMunicipios>('municipios');
	}
}
