import { Component, Input } from '@angular/core';
import { LegendaItem } from '../../interfaces/legenda-de-cores';

@Component({
	selector: 'seira-modal-legenda-mapa-relatorios',
	templateUrl: './modal-legenda-mapa-relatorios.component.html',
	styleUrls: ['./modal-legenda-mapa-relatorios.component.scss'],
})
export class ModalLegendaMapaRelatoriosComponent {
	@Input() close!: () => void;
	@Input() legenda: LegendaItem[] = [];
}
