<div class="card border shadow-sm">
	<div
		class="card-header"
		[ngClass]="cardHeaderBackgroud !== '' ? cardHeaderBackgroud : 'bg-light'">
		<div class="d-flex justify-content-between">
			<h6 [ngClass]="iconHeader !== '' ? 'hstack gap-2 mb-0' : 'mb-0'">
				<i [ngClass]="iconHeader !== '' ? iconHeader : 'd-none'"></i>
				{{ textHeader }}
			</h6>
			<ng-content select="[header]"></ng-content>
		</div>
	</div>

	<div class="card-body" [ngClass]="bodyClass !== '' ? bodyClass : ''">
		<ng-content></ng-content>
	</div>
	<ng-container
		*ngTemplateOutlet="footerContent ? footerContent : null"></ng-container>
</div>
