import { Component } from '@angular/core';
import { map, Observable } from 'rxjs';
import { InfoService } from '@core/services/info/info.service';

@Component({
	selector: 'seira-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
	ano: number = new Date().getFullYear();
	nome = 'AESA - Agência Executiva de Gestão de Águas';
	url = 'https://logap.com.br';
	empresaImg = '../../../assets/images/empresa.png';
	versao$: Observable<string>;

	constructor(private infoService: InfoService) {
		this.versao$ = infoService.versao.pipe(
			map(versao => {
				if (versao === null || versao === undefined) {
					return 'Em desenvolvimento';
				}

				return versao;
			})
		);
	}
}
