<ul class="list-group">
	<li
		style="list-style: none"
		*ngFor="
			let item of items | slice: firstItemNumber - 1 : lastItemNumber;
			let i = index;
			trackBy: trackByFn
		">
		<ng-template
			[ngTemplateOutlet]="templateRef"
			[ngTemplateOutletContext]="{
				item: item,
				index: i
			}"></ng-template>
	</li>
</ul>

<nav class="d-flex justify-content-between align-items-center mt-2">
	<span>
		{{ firstItemNumber }} - {{ lastItemNumber }} de {{ itemsTotal }}
	</span>
	<ul class="pagination justify-content-between">
		<li class="page-item cursor-pointer w-100" [class.disabled]="isFirstPage">
			<span
				class="page-link custom-button"
				(click)="firstPage()"
				[class.disabled-button]="isFirstPage">
				<i class="ph ph-caret-double-left" style="font-size: 20px"></i
			></span>
		</li>
		<li class="page-item cursor-pointer w-100" [class.disabled]="isFirstPage">
			<span
				class="page-link custom-button"
				(click)="previousPage()"
				[class.disabled-button]="isFirstPage">
				<i class="ph ph-caret-left" style="font-size: 20px"></i
			></span>
		</li>
		<li
			class="page-item cursor-pointer"
			*ngFor="let page of getPageNumbers()"
			[class.active]="currentPageIndex + 1 === page">
			<a class="page-link page-number" (click)="goToPage(page)">{{ page }}</a>
		</li>
		<li class="page-item cursor-pointer w-100" [class.disabled]="isLastPage">
			<span
				class="page-link custom-button"
				(click)="nextPage()"
				[class.disabled-button]="isLastPage">
				<i class="ph ph-caret-right" style="font-size: 20px"></i>
			</span>
		</li>
		<li class="page-item cursor-pointer w-100" [class.disabled]="isLastPage">
			<span
				class="page-link custom-button"
				(click)="lastPage()"
				[class.disabled-button]="isLastPage">
				<i class="ph ph-caret-double-right" style="font-size: 20px"></i
			></span>
		</li>
	</ul>
</nav>