import {
	Component,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
} from '@angular/core';
import {
	ControlContainer,
	FormControl,
	FormGroupDirective,
} from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import * as moment from 'moment';
import { Moment } from 'moment';
import { TiposDatePicker } from '@shared/interfaces/date-picker';
import { Data } from '@angular/router';
import { sharedData } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/mapa-ndc/shared-data';

@Component({
	selector: 'seira-public-date-picker',
	templateUrl: './public-date-picker.component.html',
	styleUrls: ['./public-date-picker.component.scss'],
	viewProviders: [
		{ provide: ControlContainer, useExisting: FormGroupDirective },
	],
})
export class PublicDatePickerComponent implements OnInit, OnChanges {
	@Input() id!: string;
	@Input() controlName!: string;
	@Input() required = false;
	@Input() placeholder = '';
	@Input() label?: string;
	@Input() datePickerType: TiposDatePicker = 'day';
	@Input() maxDate: Date | null = new Date();
	@Input() minDate: Data | null = null;
	startView: 'month' | 'year' | 'multi-year' = 'month';
	parseDate: string = 'DD/MM/YYYY';
	control = new FormControl();
	constructor(private controlContainer: ControlContainer) {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['datePickerType']) {
			switch (this.datePickerType) {
				case 'year':
					this.startView = 'multi-year';
					this.parseDate = 'YYYY';
					break;
				case 'month':
					this.startView = 'multi-year';
					this.parseDate = 'MM/YYYY';
					break;
				default:
					this.startView = 'month';
					this.parseDate = 'DD/MM/YYYY';
					break;
			}
		}
	}

	ngOnInit(): void {
		this.control = this.controlContainer.control?.get(
			this.controlName
		) as FormControl;
		switch (this.datePickerType) {
			case 'year':
				this.startView = 'multi-year';
				break;
			case 'month':
				this.startView = 'multi-year';
				break;
		}
	}

	setYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
		if (this.datePickerType === 'year') {
			const ctrlValue = this.control.value
				? moment(this.control.value)
				: moment();
			ctrlValue.year(normalizedMonthAndYear.year());
			this.control.setValue(ctrlValue);
			datepicker.close();
			sharedData.anoSelecionado = this.control.value.format('YYYY');
			sharedData.isSelect = true;
		}
	}

	setMonthAndYear(
		normalizedMonthAndYear: Moment,
		datepicker: MatDatepicker<Moment>
	) {
		if (this.datePickerType === 'month') {
			const ctrlValue = this.control.value || moment();
			ctrlValue.month(normalizedMonthAndYear.month());
			ctrlValue.year(normalizedMonthAndYear.year());
			ctrlValue.startOf('month');
			this.control.setValue(ctrlValue);
			datepicker.close();
		}
	}
}
