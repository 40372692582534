import * as moment from 'moment';
import { format } from 'date-fns-tz';
import { DateTime, DateTimeFormatOptions, DateTimeOptions } from 'luxon';
import ZonedDateTime from '@utils/date/zoned-datetime';
const MASCARA_SISTEMA_24H = 'dd/MM/yyyy HH:mm';
export function formatar(date: any, formato: any): any {
	return moment(date, formato);
}
export interface Periodo {
	diaInicio: ZonedDateTime;
	diaFim: ZonedDateTime;
}

export const DEFAULT_OPTIONS = <DateTimeOptions>{
	locale: 'pt',
	zone: 'America/Sao_Paulo',
	setZone: true,
};

export class DateTimeUtils {
	static formatToZonedDateTime(dateString: string): string {
		const inputDate = new Date(dateString);
		inputDate.setDate(inputDate.getDate() + 1);
		const formattedDate = format(inputDate, "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX", {
			timeZone: 'America/Sao_Paulo',
		});
		return formattedDate;
	}

	static formatarData(dataOriginal: string, formatoSaida: string): string {
		if (dataOriginal) {
			const dataMoment = moment(dataOriginal);
			const dataFormatada = format(dataMoment.toDate(), formatoSaida, {
				timeZone: 'America/Sao_Paulo',
			});
			return dataFormatada;
		}
		return '';
	}

	static formatarISOParaFormato24hrs(value: string): string {
		const date = DateTime.fromISO(value, DEFAULT_OPTIONS);

		return date.toFormat(MASCARA_SISTEMA_24H);
	}

	static getPeriodoPorHoras(horas: number): Periodo {
		const diaFim = new ZonedDateTime();
		const diaInicio = new ZonedDateTime();
		diaInicio.dateInstance.setHours(diaFim.dateInstance.getHours() - horas);
		return { diaInicio, diaFim };
	}

	static formatarMilli(
		milli: number,
		formato: DateTimeFormatOptions = DateTime.DATETIME_SHORT
	): string {
		return DateTime.fromMillis(milli, DEFAULT_OPTIONS)
			.toLocaleString(formato)
			.trim();
	}

	static ehAntes(date1: Date, date2: Date): boolean {
		return date1.getTime() < date2.getTime();
	}

	static ehDepois(date1: Date, date2: Date): boolean {
		return date1.getTime() > date2.getTime();
	}

	static subtrairEmMilisegundos(date1: Date, date2: Date): number {
		return Math.abs(date1.getTime() - date2.getTime());
	}

	static getAno(dataString: string | Date): number {
		return new Date(dataString).getFullYear();
	}

	/**
	 * Obtem a diferença de horas de uma localidade especifica pro UTC
	 * @param dataLocal Data atual da localidade
	 * @returns A diferença de horas da hora recebida pro UTC
	 */
	static getDiferencaHorasUTC(dataLocal: Date) {
		const diferencaMinutos = new Date(dataLocal).getTimezoneOffset();
		const diferencaHoras = diferencaMinutos / 60;
		return diferencaHoras;
	}

	static getDataComHorasAdicionais(data: Date, horas: number) {
		const result = new Date(data);
		result.setHours(result.getHours() + horas);
		return result;
	}
}
