<seira-public-modal [close]="closeModalFunc">
	<label class="text-tertiary fw-medium d-flex h1 m-0" title>
		Quantis {{ isAnual ? 'Anual' : 'Mensal' }}
	</label>
	<div content>
		<div *ngIf="resultado">
			<seira-grafico-quantis
				(chartInstance)="onChartCreated($event)"
				[dado]="resultado"
				[useMaximumLimit]="useMaximumLimit"
				[isAnual]="isAnual"></seira-grafico-quantis>
		</div>
		<div class="container" *ngIf="loadingSubmit">
			<ngx-loading [show]="loadingSubmit"></ngx-loading>
		</div>
	</div>
	<ng-template #semDados><seira-sem-dados /> </ng-template>
</seira-public-modal>
