import { Component, Input } from '@angular/core';

@Component({
	selector: 'seira-public-a',
	templateUrl: './public-a.component.html',
	styleUrls: ['./public-a.component.scss'],
})
export class PublicAComponent {
	@Input() redirectLink: any[] | string | null | undefined = null;
	@Input() fragment?: string;
	@Input() disabled = false;
	@Input() state: { [p: string]: any } = {};
	@Input() color: 'blue' | 'red' | 'gray' | 'white' = 'blue';
	@Input() customClass = 'mx-2 fw-normal';
	get classTextColor() {
		switch (this.color) {
			case 'blue':
				return 'text-primary';
			case 'red':
				return 'text-danger';
			case 'gray':
				return 'text-tertiary';
			case 'white':
				return 'text-white';
			default:
				return 'text-primary';
		}
	}

	scrollToAnchor(wait = 100): void {
		if (!this.fragment) return;
		const element = document.querySelector('#' + this.fragment);
		if (element) {
			setTimeout(() => {
				element.scrollIntoView({
					behavior: 'smooth',
				});
			}, wait);
		}
	}
}
