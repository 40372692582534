import deepmerge from 'deepmerge';
import { DadosSerieVariavelMeteorologica } from './series/serie-variavel-meteorologica';
import { rangeSelector5Dias } from './range-selector';
import { configResponsividade } from './regras-responsividade';
import { ConfiguracaoGrafico } from '@home/submodulos/dados-meteorologicos/interfaces/variaveis-meteologicas';

/**
 * Cria um gráfico dinâmicamente a partir dos dados de variáveis meteorológicas
 */
export class FabricaGraficoVariavel {
	private ESPACO_ENTRE_GRAFICOS = [100, 235, 370, 505, 640, 775];

	obterConfiguracao(
		configuracao: ConfiguracaoGrafico,
		serie: DadosSerieVariavelMeteorologica
	): any {
		const configuracaoDefault = {
			chart: {
				renderTo: configuracao.render,
				height: 500,
				alignTicks: false,
				backgroundColor: 'transparent',
			},

			rangeSelector: rangeSelector5Dias,

			credits: {
				enabled: false,
			},

			legend: {
				enabled: false,
			},

			xAxis: {
				type: 'datetime',
			},
			exporting: {
				allowHTML: true,
			},
			plotOptions: {
				series: {
					getExtremesFromAll: true,
					dataGrouping: {
						enable: false,
					},
				},

				bar: {
					dataLabels: {
						enabled: true,
					},
				},
			},
			series: serie.serie,

			responsive: configResponsividade,

			yAxis: [
				{
					top: this.ESPACO_ENTRE_GRAFICOS[0],
					height: 250,
					...serie.eixo,
				},
			],
		};

		return deepmerge(configuracaoDefault, configuracao.configuracoesExtras);
	}
}
