import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import {
	DadosMediaHistorica,
	PostosRelatorios,
	Relatorios,
} from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import { Estacao } from '@home/submodulos/dados-meteorologicos/interfaces/filtros-opcoes';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';
import { TipoTabelas } from '../../../interfaces/relatorioEnum';
import moment, { Moment } from 'moment';
import { formatISO } from 'date-fns';
import { Select } from '@layout/interfaces/select';
import * as pdfseira from '@utils/pdf-seira';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { DocumentExporter } from '@utils/document-exporter';

@Component({
	selector: 'seira-pluviometrico-tabela-media-historica',
	templateUrl: './pluviometrico-tabela-media-historica.component.html',
	styleUrls: ['./pluviometrico-tabela-media-historica.component.scss'],
})
export class PluviometricoTabelaMediaHistoricaComponent
	implements OnInit, OnDestroy, InstanciaRelatorio
{
	inputs = inject(INPUTS_RELATORIOS);
	form!: FormGroup;
	postos: PostosRelatorios[] = [];
	estacoes: Estacao[] = [];
	periodos = [];
	carregandoRelatorio = false;
	dadosTabelaRelatorio: Relatorios<DadosMediaHistorica>[] = [];
	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => {
				return this.exportarPDF(this.dadosTabelaRelatorio);
			},
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => {
				return this.exportarCSV(this.dadosTabelaRelatorio);
			},
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => {
				return this.exportarTXT(this.dadosTabelaRelatorio);
			},
		},
	];

	private subscription = new Subscription();
	constructor(
		private toastr: ToastrService,
		private relatoriosService: RelatoriosService
	) {}
	microrregioes: Select<string>[] = [];

	ngOnInit() {
		this.form = this.inputs.form;
		this.postos = this.inputs.postos;
		this.estacoes = this.inputs.estacoes;

		setTimeout(() => {
			this.submitFirstSearch();
			this.setValidators();
		}, 0);
	}

	get tamanhoTabela() {
		return this.dadosTabelaRelatorio.length;
	}

	submitFirstSearch() {
		const hoje = new Date();
		const anoPeriodo = new Date();
		anoPeriodo.setFullYear(hoje.getFullYear());
		this.form.patchValue({
			periodo: moment(anoPeriodo),
		});
	}

	getFormItemValue(field: string) {
		return this.form.get(field)!.value;
	}

	capturaTipoTabelas(field: keyof typeof TipoTabelas) {
		return TipoTabelas[field].toUpperCase();
	}

	formataData(data: Date) {
		const formatoData = new Date(data);
		return formatoData.toLocaleDateString('pt-BR', {
			year: 'numeric',
		});
	}

	gerarRelatorio() {
		if (this.form.invalid) {
			return;
		}
		const ano = this.form.get('periodo')?.value! as moment.Moment;
		if (!ano || !this.postos.length) {
			return;
		}
		this.inputs.setLoading(true);
		return this.RelatorioMediaHistorica(ano);
	}

	RelatorioMediaHistorica(ano: Moment) {
		const postoIds = this.postos.map(posto => posto.id);

		this.carregandoRelatorio = true;
		this.relatoriosService
			.buscarRelatorioMediaHistorica(postoIds, ano)
			.subscribe({
				next: pluviometrico => {
					this.dadosTabelaRelatorio =
						this.formatoRelatorioMediaHistorica(pluviometrico);
					this.inputs.setLoading(false);
					this.carregandoRelatorio = false;
				},
				error: err => {
					this.carregandoRelatorio = false;
					this.toastr.error('Ocorreu um erro ao gerar o relatório');
				},
			});
	}

	formatoRelatorioMediaHistorica(dadosOriginais: any) {
		const dadosTransformados: Relatorios<DadosMediaHistorica>[] = [];
		if (dadosOriginais.listaMediasHistoricas) {
			dadosOriginais.listaMediasHistoricas.forEach((relatorio: any) => {
				const detalhesPosto = relatorio.detalhesPosto;
				const mediasPrecipitacoesMensais = relatorio.mediasPrecipitacoesMensais;

				if (mediasPrecipitacoesMensais) {
					const municipio = detalhesPosto.municipio;
					const posto = detalhesPosto.posto;
					const mesorregiao = detalhesPosto.mesorregiao;
					const microrregiao = detalhesPosto.microrregiao;
					const latitude = detalhesPosto.latitude;
					const longitude = detalhesPosto.longitude;

					const max = Math.max(...mediasPrecipitacoesMensais);

					const municipioExistente = dadosTransformados.find(
						dados => dados.municipio === municipio
					);

					let data: Relatorios<DadosMediaHistorica> = {
						municipio: municipio,
						data: [],
					};

					if (municipioExistente) {
						data = municipioExistente;
					}

					const dadosPosto: DadosMediaHistorica = {
						mesorregiao: mesorregiao,
						microrregiao: microrregiao,
						latitude: latitude,
						longitude: longitude,
						municipio: municipio,
						posto: posto,
						jan: 0,
						fev: mediasPrecipitacoesMensais[1],
						mar: mediasPrecipitacoesMensais[2],
						abr: mediasPrecipitacoesMensais[3],
						mai: mediasPrecipitacoesMensais[4],
						jun: mediasPrecipitacoesMensais[5],
						jul: mediasPrecipitacoesMensais[6],
						ago: mediasPrecipitacoesMensais[7],
						set: mediasPrecipitacoesMensais[8],
						out: mediasPrecipitacoesMensais[9],
						nov: mediasPrecipitacoesMensais[10],
						dez: mediasPrecipitacoesMensais[11],
						max: max,
					};
					data.data.push(dadosPosto);
					if (!municipioExistente) {
						dadosTransformados.push(data);
					}
				}
			});
		}

		return dadosTransformados;
	}

	setValidators() {
		this.form.get(FormularioRelatorio.TIPO)?.setValidators(Validators.required);
		this.form
			.get(FormularioRelatorio.PERIODO)
			?.setValidators(Validators.required);
	}

	getPeriodo() {
		return this.form.get('periodo')?.value.year() || '';
	}

	async exportarPDF(
		dadosTabelaMediaHistorica: Relatorios<DadosMediaHistorica>[]
	) {
		const documentDefinition: any = await pdfseira.documentDefinitions(
			'paisagem'
		);

		documentDefinition.pageOrientation = 'landscape';

		documentDefinition.content.push({
			text: `Relatório - Média histórica (mm) - ${this.getPeriodo()}`,
			fontSize: 12,
			alignment: 'center',
			margin: [0, 10],
		});

		if (!dadosTabelaMediaHistorica.length) {
			documentDefinition.content.push({
				text: 'Nenhum dado encontrado na tabela',
				alignment: 'center',
				fontSize: 10,
				margin: [0, 10],
			});
		}

		dadosTabelaMediaHistorica.forEach(dadoRelatorio => {
			let i = 0;
			while (i < dadoRelatorio.data.length) {
				const tableData: (string | number)[][] = [];

				tableData.push([
					'Mesorregião',
					'Microrregião',
					'Latitude',
					'Longitude',
					'Jan',
					'Fev',
					'Mar',
					'Abr',
					'Mai',
					'Jun',
					'Jul',
					'Ago',
					'Set',
					'Out',
					'Nov',
					'Dez',
					'Prec. Máx(mm)',
				]);

				let j = i;
				while (dadoRelatorio.data[i]?.posto === dadoRelatorio.data[j]?.posto) {
					tableData.push([
						dadoRelatorio.data[j].mesorregiao,
						dadoRelatorio.data[j].microrregiao,
						dadoRelatorio.data[j].latitude,
						dadoRelatorio.data[j].longitude,
						dadoRelatorio.data[j].jan || '-',
						dadoRelatorio.data[j].fev || '-',
						dadoRelatorio.data[j].mar || '-',
						dadoRelatorio.data[j].abr || '-',
						dadoRelatorio.data[j].mai || '-',
						dadoRelatorio.data[j].jun || '-',
						dadoRelatorio.data[j].jul || '-',
						dadoRelatorio.data[j].ago || '-',
						dadoRelatorio.data[j].set || '-',
						dadoRelatorio.data[j].out || '-',
						dadoRelatorio.data[j].nov || '-',
						dadoRelatorio.data[j].dez || '-',
						dadoRelatorio.data[j].max || '-',
					]);
					j++;
				}

				documentDefinition.content.push(
					{
						text: `${dadoRelatorio.municipio} - ${dadoRelatorio.data[i].posto}`,
						fontSize: 10,
						margin: [0, 10],
					},
					{
						table: {
							body: tableData.map(row =>
								row.map(cell => ({ text: cell, fontSize: 10 }))
							),
						},
					}
				);

				if (i < j) {
					i = j;
				} else {
					i++;
				}
			}
		});

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	exportarCSV(dadosTabelaMediaHistorica: Relatorios<DadosMediaHistorica>[]) {
		const tableData: (string | number)[][] = [];

		tableData.push([
			'Mesorregião',
			'Microrregião',
			'Município',
			'Posto',
			'Latitude',
			'Longitude',
			'Jan',
			'Fev',
			'Mar',
			'Abr',
			'Mai',
			'Jun',
			'Jul',
			'Ago',
			'Set',
			'Out',
			'Nov',
			'Dez',
			'Prec. Máx(mm)',
		]);

		dadosTabelaMediaHistorica.forEach(dadoRelatorio => {
			dadoRelatorio.data.forEach(dado => {
				tableData.push([
					dado.mesorregiao,
					dado.microrregiao,
					dado.municipio,
					dado.posto,
					dado.latitude,
					dado.longitude,
					dado.jan,
					dado.fev,
					dado.mar,
					dado.abr,
					dado.mai,
					dado.jun,
					dado.jul,
					dado.ago,
					dado.set,
					dado.out,
					dado.nov,
					dado.dez,
					dado.max,
				]);
			});
		});

		DocumentExporter.gerarCSV(
			tableData,
			`relatorio-pluviometrico-media-historica-${this.getPeriodo()}`
		);
	}

	exportarTXT(dadosTabelaMediaHistorica: Relatorios<DadosMediaHistorica>[]) {
		let txtData = '';

		dadosTabelaMediaHistorica.forEach(dadoRelatorio => {
			dadoRelatorio.data.forEach(dado => {
				txtData +=
					`\nMesorregião: ${dado.mesorregiao}\n` +
					`Microrregião: ${dado.microrregiao}\n` +
					`Município/Posto: ${dadoRelatorio.municipio}/${dado.posto}\n` +
					`Latitude: ${dado.latitude}\n` +
					`Longitude: ${dado.longitude}\n` +
					`Janeiro: ${dado.jan}\n` +
					`Fevereiro: ${dado.fev}\n` +
					`Março: ${dado.mar}\n` +
					`Abril: ${dado.abr}\n` +
					`Maio: ${dado.mai}\n` +
					`Junho: ${dado.jun}\n` +
					`Julho: ${dado.jul}\n` +
					`Agosto: ${dado.ago}\n` +
					`Setembro: ${dado.set}\n` +
					`Outubro: ${dado.out}\n` +
					`Novembro: ${dado.nov}\n` +
					`Dezembro: ${dado.dez}\n` +
					`Prec. Máx(mm): ${dado.max}\n`;
			});
		});

		DocumentExporter.gerarTXT(
			txtData,
			`relatorio-pluviometrico-media-historica-${this.getPeriodo()}`
		);
	}

	ngOnDestroy() {
		this.form.get(FormularioRelatorio.TIPO)?.clearValidators();
		this.form.get(FormularioRelatorio.PERIODO)?.clearValidators();
		this.form.get(FormularioRelatorio.ESTACAO)?.clearValidators();
		this.form.get(FormularioRelatorio.POSTO)?.clearValidators();
		this.subscription.unsubscribe();
	}
}
