import { Component } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { DatePickerComponent } from '@componentes/date-picker/date-picker.component';

@Component({
	selector: 'seira-day-month-picker',
	templateUrl: '../date-picker.component.html',
	styleUrls: ['../date-picker.component.scss'],
	viewProviders: [
		{ provide: ControlContainer, useExisting: FormGroupDirective },
		{
			provide: MAT_DATE_FORMATS,
			useValue: {
				parse: { dateInput: 'DD/MM' },
				display: {
					dateInput: 'DD/MM',
					monthYearLabel: 'MMM',
					dateA11yLabel: 'LL',
					monthYearA11yLabel: 'MMMM YYYY',
				},
			},
		},
	],
})
export class DayMonthPickerComponent extends DatePickerComponent {}
