import { Injectable } from '@angular/core';
import { Mesorregiao } from '../interfaces/Mesorregiao';
import { Observable } from 'rxjs';
import { Rotas } from '@core/enums/routes';
import { Projection } from '@core/enums/projections';
import { HttpClientProtectedService } from '@core/services/auth/http-client-protected.service';

@Injectable({
	providedIn: 'root',
})
export class MesorregiaoService {
	constructor(private http: HttpClientProtectedService) {}

	adicionar(mesorregiao: Mesorregiao): Observable<Mesorregiao> {
		return this.http.post<Mesorregiao, Mesorregiao>(
			Rotas.MESORREGIAO,
			mesorregiao
		);
	}

	listar(): Observable<any> {
		return this.http.get<Mesorregiao>(
			Rotas.MESORREGIAO + '?projection=' + Projection.MESORREGIAO_All
		);
	}
	remover(mesorregiaoId: number): Observable<any> {
		return this.http.delete<Mesorregiao>(
			Rotas.MESORREGIAO + '/' + mesorregiaoId
		);
	}

	buscarPorId(mesorregiaoId: number): Observable<any> {
		return this.http.get<Mesorregiao>(
			Rotas.MESORREGIAO +
				'/' +
				mesorregiaoId +
				'?projection=' +
				Projection.MESORREGIAO_All
		);
	}
	editar(mesorregiao: Mesorregiao): Observable<Mesorregiao> {
		return this.http.patch<Mesorregiao, any>(
			Rotas.MESORREGIAO + '/' + mesorregiao.id,
			mesorregiao
		);
	}
}
