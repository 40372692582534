import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [];

@NgModule({
	exports: [RouterModule],
	imports: [
		RouterModule.forChild([
			{
				path: 'estacao',
				loadChildren: () =>
					import('./submodulos/estacao/estacao.module').then(
						m => m.EstacaoModule
					),
			},
			{
				path: 'medicoes-pluviometricas',
				loadChildren: () =>
					import('./submodulos/cartas-campo/cartas-campo.module').then(
						m => m.CartasCampoModule
					),
			},
			{
				path: 'regional',
				loadChildren: () =>
					import('./submodulos/regional/regional.module').then(
						m => m.RegionalModule
					),
			},
			{
				path: 'mesorregiao',
				loadChildren: () =>
					import('./submodulos/mesorregiao/mesorregiao.module').then(
						m => m.MesorregiaoModule
					),
			},
			{
				path: 'microrregiao',
				loadChildren: () =>
					import('./submodulos/microrregiao/microrregiao.module').then(
						m => m.MicrorregiaoModule
					),
			},
			{
				path: 'municipio',
				loadChildren: () =>
					import('./submodulos/municipio/municipio.module').then(
						m => m.MunicipioModule
					),
			},
		]),
	],
})
export class MeteorologiaRoutingModule {}
