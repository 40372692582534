<seira-modal (handleClose)="close(false)">
	<div header>
		<h5 class="modal-title">Adicionar tabela agrupada por período no PDF</h5>
	</div>
	<div body>
		<form [formGroup]="form" class="form-group">
			<div class="row">
				<div class="col-lg-6 col-md-6">
					<seira-public-radio-group
						idRadio="modoBoletimTabela"
						[options]="OpcoesTipoTabela"
						label="Selecione opção de tabela:"
						controlName="periodo">
					</seira-public-radio-group>
				</div>
				<div class="col-lg-6 col-md-6">
					<div *ngIf="form.get('periodo')?.value" class="d-flex align-items-end">
						<seira-public-input
							type="number"
							mask="000"
							class="w-100"
							label="Definir limiares inferiores"
							placeholder="Digite um valor de limite"
							controlName="limiteInferior">
						</seira-public-input>
					</div>
				</div>
			</div>
			
			<div class="row">
				<div class="col-lg-6 col-md-6">
					<div *ngIf="form.get('periodo')?.value" class=" align-items-end">
						<seira-public-select
							optionLabel="label"
							optionValue="value"
							placeholder="Selecione..."
							label="Tipo de posto"
							[options]="tiposPosto"
							notFoundText="Nenhum tipo de posto encontrado"
							formControlName="tipoPosto">
						</seira-public-select>
					</div>
				</div>
				<div class="col-lg-6 col-md-6">
					<div *ngIf="
						form.get('periodo')?.value === PeriodoBoletim.ANUAL ||
						form.get('periodo')?.value === PeriodoBoletim.AMBOS
					" class="d-flex align-items-end">
						<seira-public-date-picker
							class="w-100"
							label="Ano"
							[required]="true"
							placeholder="Selecione o ano desejado"
							controlName="ano"
							datePickerType="year" />
					</div>
				</div>
			</div>
			
			
			<div
				*ngIf="
					form.get('periodo')?.value === PeriodoBoletim.MENSAL ||
					form.get('periodo')?.value === PeriodoBoletim.AMBOS
				">
				<div class="row">
					<div
						class="col-lg-6 col-md-4 col-sm-6 d-flex flex-row align-items-end gap-1">
						<seira-public-date-picker
							class="w-100"
							label="Mês inicial"
							[required]="true"
							placeholder="Selecione o mês inicial"
							controlName="dataInicio"
							datePickerType="month" />
					</div>
					<div
						class="col-lg-6 col-md-4 col-sm-6 d-flex flex-row align-items-end gap-1">
						<seira-public-date-picker
							class="w-100"
							label="Mês final"
							[required]="true"
							placeholder="Selecione o mês final"
							controlName="dataFim"
							datePickerType="month" />
					</div>
				</div>
			</div>
			<div class="container" *ngIf="loadingSubmit">
				<ngx-loading [show]="loadingSubmit"></ngx-loading>
			</div>
		</form>
	</div>
	<p footer><strong>OBS:</strong> Ao confirmar sem selecionar nenhuma opção, ele irá gerar o pdf somente com a tabela diária.</p>
	<seira-public-button footer (clicked)="limparFiltros()"
		>Limpar</seira-public-button
	>
	<seira-public-button footer (clicked)="onSubmit()" [disabledButton]="isConfirmDisabled()">
		Confirmar
	</seira-public-button>

</seira-modal>
