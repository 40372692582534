<div [class]="componentClass" style="position: relative">
	<label
		(click)="changeCheck()"
		*ngIf="labelPlacement === 'start'"
		class="mr-3 form-check-label"
		style="margin-right: 10px"
		[for]="id">
		{{ label }}<span class="text-danger" *ngIf="isRequired">*</span>
	</label>

	<div class="row" *ngIf="labelPlacement === 'top'">
		<label
			(click)="changeCheck()"
			class="form-label fw-semibold"
			style="margin-bottom: 10px"
			[for]="id">
			{{ label }}<span class="text-danger" *ngIf="isRequired">*</span>
		</label>
	</div>

	<input
		class="form-check-input"
		[ngClass]="{ ios: iosStyle }"
		type="checkbox"
		[formControlName]="controlName"
		[id]="id"
		[disabled]="disabled" />

	<label
		(click)="changeCheck()"
		*ngIf="labelPlacement === 'end'"
		class="form-check-label"
		style="margin-left: 10px"
		[for]="id">
		{{ label }}<span class="text-danger" *ngIf="isRequired">*</span>
	</label>

	<div class="invalid-feedback mt-0" *ngIf="showsTextRequired || seiraError">
		{{ textRequired || seiraError }}
	</div>
</div>
