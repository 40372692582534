import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Moment } from 'moment/moment';
import { ToastrService } from 'ngx-toastr';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';
import * as moment from 'moment/moment';
import {
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { PostosRelatorios } from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import { Estacao } from '@home/submodulos/dados-meteorologicos/interfaces/filtros-opcoes';
import { getBase64ImageFromURL, isNuloOuUndefined } from '@utils';
import { Subscription } from 'rxjs';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { Select } from '@layout/interfaces/select';
import * as pdfseira from '@utils/pdf-seira';
import * as pdfMake from 'pdfmake/build/pdfmake';

export const PERIODO_MINIMO_SPI_EM_ANOS = 10;

@Component({
	selector: 'seira-pluviometro-grafico-spi',
	templateUrl: './pluviometro-grafico-spi.component.html',
	styleUrls: ['./pluviometro-grafico-spi.component.scss'],
})
export class PluviometroGraficoSpiComponent
	implements InstanciaRelatorio, OnInit, OnDestroy
{
	form!: FormGroup;
	postos: PostosRelatorios[] = [];
	estacoes: Estacao[] = [];
	microrregioes: Select[] = [];

	private subscription = new Subscription();
	private inputs = inject(INPUTS_RELATORIOS);

	carregandoRelatorio: boolean = false;
	graficoSPIUrl?: string;
	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: async () => {
				await this.exportarPDF(this.graficoSPIUrl);
			},
		},
	];

	constructor(
		private readonly relatoriosService: RelatoriosService,
		private readonly toastr: ToastrService
	) {
		this.form = this.inputs.form;
	}

	ngOnInit() {
		setTimeout(() => {
			this.observarDatas();
			this.definirPeriodoPadrao();
		});
	}

	private definirPeriodoPadrao() {
		const anoAtual = moment().startOf('year');
		const anoInicial = moment(anoAtual).subtract(
			PERIODO_MINIMO_SPI_EM_ANOS,
			'year'
		);

		this.form.get('dataInicio')?.setValue(anoInicial);
		this.form.get('dataFim')?.setValue(anoAtual);
	}

	private observarDatas() {
		this.subscription.add(
			this.form.get('dataInicio')?.valueChanges.subscribe(dataInicio => {
				this.definirDataMinimaSPI(dataInicio, this.getFormItemValue('dataFim'));
			})
		);
		this.subscription.add(
			this.form.get('dataFim')?.valueChanges.subscribe(dataFim => {
				this.definirDataMinimaSPI(this.getFormItemValue('dataInicio'), dataFim);
			})
		);
	}

	definirDataMinimaSPI(dataInicio: Moment | null, dataFim: Moment | null) {
		const formato = this.getFormItemValue('modoVisualizacao') as string;
		const tipo = this.getFormItemValue('tipo');

		if (
			formato.toUpperCase() !== 'GRAFICO' ||
			tipo !== 'SPI' ||
			isNuloOuUndefined(dataInicio) ||
			isNuloOuUndefined(dataFim) ||
			!(dataInicio instanceof moment) ||
			!(dataFim instanceof moment)
		)
			return;

		const dataInicioDentroDoPeriodoMinimo =
			dataInicio.get('year') <=
			dataFim?.get('year') - PERIODO_MINIMO_SPI_EM_ANOS;

		if (!dataInicioDentroDoPeriodoMinimo) {
			const dataInicioMinima = moment(dataFim).subtract(
				PERIODO_MINIMO_SPI_EM_ANOS,
				'years'
			);
			this.form.get('dataInicio')?.setValue(dataInicioMinima);
		}
	}

	gerarRelatorio() {
		const dataInicio = this.getFormItemValue('dataInicio');
		const dataFim = this.getFormItemValue('dataFim');

		this.carregandoRelatorio = true;
		this.relatoriosService.buscarGraficoSPI(dataInicio, dataFim).subscribe({
			next: grafico => {
				this.graficoSPIUrl = URL.createObjectURL(grafico);
				this.carregandoRelatorio = false;
			},
			error: () => {
				this.carregandoRelatorio = false;
				this.toastr.error('Ocorreu um erro ao gerar o relatório');
			},
		});
	}

	getPeriodo() {
		const anoInicio = this.getFormItemValue('dataInicio').year();
		const anoFim = this.getFormItemValue('dataFim').year();
		return `${anoInicio} a ${anoFim}`;
	}

	async exportarPDF(graficoSPIUrl?: string) {
		const documentDefinition: any = await pdfseira.documentDefinitions();

		documentDefinition.content.push({
			text: `Relatório - Índice Padronizado de Precipitação (SPI) - ${this.getPeriodo()}`,
			fontSize: 12,
			alignment: 'center',
			margin: [0, 10],
		});

		if (!graficoSPIUrl) {
			documentDefinition.content.push({
				text: 'Nenhum dado encontrado',
				alignment: 'center',
				fontSize: 10,
				margin: [0, 10],
			});
		} else {
			documentDefinition.content.push({
				image: await getBase64ImageFromURL(graficoSPIUrl),
				alignment: 'center',
				width: 500,
				height: 250,
			});
		}

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	private getFormItemValue(name: string) {
		return this.form.get(name)!.value;
	}
}
