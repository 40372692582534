import { Component, OnInit } from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from '@angular/forms';
import { HttpClientProtectedService } from '@core/services/auth/http-client-protected.service';
import { lastValueFrom, tap } from 'rxjs';
import { TextoInformativo } from '@home/submodulos/dados-meteorologicos/interfaces/texto-informativo';

interface SateliteOpt {
	tipoSatelite: string;
	nomeSatelite: string;
}

@Component({
	selector: 'seira-imagens-satelite',
	templateUrl: './imagens-satelite.component.html',
	styleUrls: ['./imagens-satelite.component.scss'],
})
export class ImagensSateliteComponent implements OnInit {
	// [INTEGRAÇÃO PENDENTE] Mock para teste da interface
	opcoesSateliteOpts: SateliteOpt[] = [
		{ tipoSatelite: 'Meteosat', nomeSatelite: 'MSG' },
	];
	canaisSateliteOpts: string[] = [];
	imagensSateliteForm: FormGroup;
	sateliteGifUrls: string | null = null;
	loading = false;
	informacoesGerais: TextoInformativo = {
		label: 'INFORMAÇÕES GERAIS',
		texts: [
			{
				title: 'O que é imagem de satélite?',
				text: 'São fotografias tiradas por satélites de órbita polar ou geoestacionários, determinando a condição meteorológica através do campo de nebulosidade, da temperatura, etc.',
			},
		],
	};

	constructor(
		private formBuilder: FormBuilder,
		private http: HttpClientProtectedService
	) {
		this.imagensSateliteForm = this.formBuilder.group({
			opcoesSatelite: new FormControl({} as SateliteOpt, Validators.required),

			canaisSatelite: new FormControl<string | null>(null, Validators.required),
		});
	}

	get opcoesSateliteValue() {
		return this.imagensSateliteForm.get('opcoesSatelite')?.value;
	}

	get canaisSateliteValue() {
		return this.imagensSateliteForm.get('canaisSatelite')?.value;
	}

	ngOnInit(): void {
		this.imagensSateliteForm
			.get('opcoesSatelite')
			?.valueChanges.subscribe(async val => {
				if (!val) {
					this.canaisSateliteOpts = [];
					this.imagensSateliteForm.get('canaisSatelite')?.reset();
					return;
				}

				// const res = await lastValueFrom(
				// 	this.http.get<string[]>(
				// 		`satelite/retorna-canais?satelite=${val}
				// 		`
				// 	)
				// );
				// [INTEGRAÇÃO PENDENTE] Mock para teste da interface
				this.canaisSateliteOpts = [
					'IR108',
					'VIS006',
					'WV062',
					'RGBNatColour',
					'H03B',
				];
			});

		this.imagensSateliteForm
			.get('canaisSatelite')
			?.valueChanges.pipe(tap(() => (this.loading = true)))
			.subscribe(async val => {
				// const res = await lastValueFrom(
				// 	this.http.get<string>(
				// 		`satelite/retorna-imagens?satelite=${this.opcoesSateliteValue}&canal=${val}`
				// 	)
				// );
				// [INTEGRAÇÃO PENDENTE] Mock para teste da interface
				this.sateliteGifUrls =
					new Date().getSeconds() % 2 === 0
						? 'https://meteorologia.emparn.rn.gov.br/arquivos/MSG/IR108/2023-10-20_14:00.gif'
						: 'https://meteorologia.emparn.rn.gov.br/arquivos/MSG/H03B/2023-10-20_14:00.gif';
				setTimeout(() => {
					this.loading = false;
				}, 500);
			});
	}
}
