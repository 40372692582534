import { Component, Input, OnInit } from '@angular/core';

import {
	ControlContainer,
	FormControl,
	FormGroupDirective,
} from '@angular/forms';
import { identifierName } from '@angular/compiler';

@Component({
	selector: 'seira-custom-select',
	templateUrl: './custom-select.component.html',
	styleUrls: ['./custom-select.component.scss'],
	viewProviders: [
		{ provide: ControlContainer, useExisting: FormGroupDirective },
	],
})
export class CustomSelectComponent<T> implements OnInit {
	@Input() options!: T[];
	@Input() customClass = 'form-select';
	@Input() optionsClass = 'text-white';
	@Input() notFoundText = 'Nenhuma opção foi encontrada!';
	@Input() optionLabel?: string;
	@Input() optionValue?: string;
	@Input() controlName!: string;
	@Input() placeholder: null | string = null;
	@Input() label?: string;
	@Input() id?: string;
	@Input() labelClass = 'form-label';
	@Input() required = false;

	control = new FormControl();

	constructor(private controlContainer: ControlContainer) {}

	ngOnInit(): void {
		this.control = this.controlContainer.control?.get(
			this.controlName
		) as FormControl;
	}

	handleOption(option: T, field?: string) {
		if (!field) return option;
		if (
			option &&
			field &&
			typeof option === 'object' &&
			option.hasOwnProperty(field) &&
			option[field as keyof T]
		)
			return option[field as keyof T];
		return option;
	}

	protected readonly identifierName = identifierName;
}
