<button
	(click)="clicked.emit($event)"
	[disabled]="disabledButton"
	[routerLink]="link"
	[type]="type"
	[class]="classButton">
	<span
		*ngIf="icon && buttonStyle !== 'custom'"
		class="btn-labeled-icon bg-black bg-opacity-20">
		<i [ngClass]="!loading ? icon : 'spinner-border'"></i>
	</span>
	<i *ngIf="icon && buttonStyle === 'custom'" [ngClass]="icon"></i>
	<ng-content></ng-content>
</button>
