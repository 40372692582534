import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { TipoEstacao } from '@modulos/meteorologia/submodulos/estacao/enums/tipo-estacao';
import { PrecipitacaoMunicipio } from '@componentes/mapa-paraiba-svg/mapa.interfaces';

export interface Boletim {
	horarioExecucao: Date;
	inicioColeta: Date;
	fimColeta: Date;
	estatisticasPostosBoletim: EstatisticaPostoBoletim;
	estatisticasPrecipitacaoBoletim: EstatiticaPrecipitacaoBoletim;
	chuvasPostosPorMesorregiao: ChuvasPostosPorMesorregiao[];
	pathImagem: string;
	precipitacaoMunicipio?: PrecipitacaoMunicipio[];
}
export interface EstatisticaPostoBoletim {
	numeroPCDs: number;
	numeroPluviometrosAutomaticos: number;
	numeroPluviometrosManuais: number;
}
export interface EstatiticaPrecipitacaoBoletim {
	numeroEstacoesAtivas: number;
	numeroEstacoesSemChuva: number;
	numeroEstacoesComChuva: number;
	numeroEstacoesSemContato: number;
}
export interface ChuvasPostosPorMesorregiao {
	mesorregiao: string;
	precipitacaoPostos: PrecipitacaoPosto[];
}
export interface PrecipitacaoPosto {
	precipitacaoAcumulada: number;
	tipoEstacao: keyof typeof TipoEstacao;
	horasContabilizadas: number;
	diasContabilizados: number;
	detalhesPosto: Posto;
}
export interface Posto {
	idEstacao: number;
	codigoAesa: string;
	posto: string;
	municipio: string;
	microrregiao: string;
	mesorregiao: string;
	codigoMicrorregiao: string;
	regiao: string;
	codigoMunicipio: number;
	latitude: number;
	longitude: number;
	nomeRegiao: string;
}
export interface DashboardItems {
	pcd: { label: string; value: number | string }[];
	numeroEstacoes: { label: string; value: number | string }[];
	pluviometros: { label: string; value: number | string }[];
	botoesDeExportacao: GroupButton[];
}
export enum TiposAgrupamentoBoletimDiario {
	REGIAO = 'agrupadoPorRegiao',
	MESORREGIAO = 'agrupadoPorMesorregiao',
}

export interface DadosTabelaBoletimDiario {
	agrupadoPorRegiao: {
		[k in string]: Array<PrecipitacaoPosto>;
	};
	agrupadoPorMesorregiao: {
		[k in string]: Array<PrecipitacaoPosto>;
	};
}

export type RespostaBoletimPeriodo = {
	[k in string]: string;
};
