<div class="modal-content shadow-lg">
	<div class="modal-header bg-primary-light-2 border-0 d-flex flex-column">
		<div class="d-flex justify-content-between align-items-center w-100">
			<label class="text-tertiary fw-normal h1 m-0" [ngClass]="titleClass">
				<ng-content select="[title]"></ng-content>
			</label>
			<seira-button
				type="button"
				classButton="btn-close m-0"
				buttonStyle="custom"
				(clicked)="close()">
			</seira-button>
		</div>
		<ng-content select="[header]"></ng-content>
	</div>
	<div class="modal-body">
		<ng-content select="[content]"></ng-content>
	</div>
	<div class="modal-footer border-0 justify-content-center">
		<ng-content select="[footer]"></ng-content>
	</div>
</div>
