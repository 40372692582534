import { Component, Input, OnInit } from '@angular/core';
import {
	ControlContainer,
	FormControl,
	FormGroupDirective,
	Validators,
} from '@angular/forms';

@Component({
	selector: 'seira-text-area',
	templateUrl: './text-area.component.html',
	styleUrls: ['./text-area.component.scss'],
	viewProviders: [
		{ provide: ControlContainer, useExisting: FormGroupDirective },
	],
})
export class TextAreaComponent implements OnInit {
	@Input() id!: string;
	@Input() label!: string;
	@Input() required = false;
	@Input() controlName!: string;
	@Input() placeholder = '';
	@Input() showsTextRequired = false;
	@Input() textRequired = '';

	control = new FormControl();

	constructor(private controlContainer: ControlContainer) {}

	ngOnInit(): void {
		this.control = this.controlContainer.control?.get(
			this.controlName
		) as FormControl;
	}

	get isRequired(): boolean {
		return !!this.control?.hasValidator(Validators.required);
	}
	get seiraError() {
		if (this.control.errors) {
			const erros = Object.keys(this.control.errors);
			if (erros.length) {
				if (erros[0] === 'required') {
					if (this.control.touched) return 'Campo obrigatório.';
				} else {
					return this.control?.errors[erros[0]]?.label;
				}
			}
		}
		return '';
	}
}
