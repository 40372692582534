<div class="d-flex align-items-center">
	<a
		class="link-blue fw-normal fs-6"
		[class.disabled]="disabled"
		[ngClass]="customClass"
		[ngStyle]="{
			pointerEvents: 'all',
			cursor: disabled ? 'default' : 'pointer'
		}"
		(click)="!disabled && clicked.emit($event)">
		<ng-content></ng-content>
	</a>
</div>
