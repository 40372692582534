import { SeriePrecipitacao } from './series/serie-precipitacao';
import { SerieUmidade } from './series/serie-umidade';
import { SerieVariavelMeteorologica } from './series/serie-variavel-meteorologica';
import { SerieTemperatura } from './series/serie-temperatura';
import { SerieNebulosidade } from './series/serie-nebulosidade';
import { SeriePressao } from './series/serie-pressao';
import { SerieVento } from './series/serie-vento';
import { SerieRadiacaoGlobal } from './series/radiacao-global';
import mensagens from './series/mensagens';

const mapeamentoSeries = new Map<string, SerieVariavelMeteorologica>([
	['PRECIPITACAO', new SeriePrecipitacao()],

	['PRESSAO', new SeriePressao()],
	[
		'PRESSAO_ATMOSFERICA',
		new SeriePressao(mensagens['variavel.pressaoAtmosferica']),
	],
	[
		'PRESSAO_INSTANTANEA',
		new SeriePressao(mensagens['variavel.pressaoInstantanea']),
	],
	['PRESSAO_MAXIMA', new SeriePressao(mensagens['variavel.pressaoMaxima'])],
	['PRESSAO_MINIMA', new SeriePressao(mensagens['variavel.pressaoMinima'])],

	['TEMPERATURA', new SerieTemperatura()],
	['TEMPERATURA_AR', new SerieTemperatura(mensagens['variavel.temperaturaAr'])],
	[
		'TEMPERATURA_SOLO',
		new SerieTemperatura(mensagens['variavel.temperaturaSolo']),
	],
	[
		'TEMPERATURA_INSTANTANEA',
		new SerieTemperatura(mensagens['variavel.temperaturaInstantanea']),
	],
	[
		'TEMPERATURA_MAXIMA',
		new SerieTemperatura(mensagens['variavel.temperaturaMaxima']),
	],
	[
		'TEMPERATURA_MINIMA',
		new SerieTemperatura(mensagens['variavel.temperaturaMinima']),
	],

	['UMIDADE', new SerieUmidade()],
	['UMIDADE_RELATIVA', new SerieUmidade(mensagens['variavel.umidadeRelativa'])],
	[
		'UMIDADE_INSTANTANEA',
		new SerieUmidade(mensagens['variavel.umidadeInstantanea']),
	],
	['UMIDADE_MAXIMA', new SerieUmidade(mensagens['variavel.umidadeMaxima'])],
	['UMIDADE_MINIMA', new SerieUmidade(mensagens['variavel.umidadeMinima'])],

	['NEBULOSIDADE', new SerieNebulosidade()],

	['VENTO', new SerieVento()],

	['RADIACAO_GLOBAL', new SerieRadiacaoGlobal()],
]);

/**
 * Obtem a série a ser adicionada em um gráfico de variáveis meteorológicas
 * @param {Variavel} variavel a variavel desejada
 * @returns {SerieVariavelMeteorologica} a seríe (dados e configuração do eixo) a ser utilizada no gráfico
 */
export function obterFabricaSerie(
	variavel: string
): SerieVariavelMeteorologica | undefined {
	return mapeamentoSeries.get(variavel);
}
