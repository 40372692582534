<div
	[class]="class"
	[ngStyle]="(maxWidth && { 'max-width': maxWidth }) || null">
	<div
		class="d-flex flex-column bg-white py-2 px-3 shadow-lg rounded text-tertiary d-flex justify-content-center">
		<label class="fw-semibold mb-1 fs-6">Legenda</label>
		<div style="height: 275px" *ngIf="carregando">
			<seira-loading customStyle="padding-bottom: 2.5rem"></seira-loading>
		</div>
		<div
			class="d-flex flex-column"
			*ngIf="!carregando && valores && valores.length > 0">
			<div
				class="d-flex font-size-14"
				[ngStyle]="{ margin: '0.2em 0em' }"
				*ngFor="let item of valores; let i = index">
				<div
					class="cor-legenda"
					[ngStyle]="{
						'background-color': item.cor,
						'border-style': item.cor === '#ffffff' ? 'solid' : undefined
					}"></div>
				<div *ngIf="i === 0 && item.unidade === '%'">
					< {{ item.valorMin }} {{ item.unidade }}
				</div>
				<div *ngIf="i === 0 && item.unidade !== '%'">
					< {{ item.valorMax }} {{ item.unidade }}
				</div>
				<div *ngIf="i > 0 && i < valores.length - 1">
					{{ item.valorMin }} — {{ item.valorMax }} {{ item.unidade }}
				</div>
				<div *ngIf="i === valores.length - 1">
					> {{ item.valorMin }} {{ item.unidade }}
				</div>
			</div>
		</div>
	</div>
</div>
