import { NgModule } from '@angular/core';
import { CapitalizePipe } from '@utils/pipes/capitalize.pipe';
import { SafePipe } from '@utils/pipes/safe.pipe';

@NgModule({
	declarations: [CapitalizePipe, SafePipe],
	imports: [],
	exports: [CapitalizePipe, SafePipe],
})
export class PipesModule {}
