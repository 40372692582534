<div class="d-flex flex-column">
	<label class="text-tertiary fw-semibold mt-3">
		CLIMATOLOGIA(mm)
		{{ periodo }}
	</label>

	<div style="max-width: 1300px">
		<div class="d-flex flex-row justify-content-between align-items-end gap-2">
			<seira-card-info aditionalClasses="mt-3" [texto]="descricaoRelatorio">
			</seira-card-info>

			<seira-select-list-menu
				[opcoes]="opcoesColunas"
				[disabled]="disabledSelectList || loading"
				[tituloBotao]="'Personalizar colunas'"
				(atualizarOpcoesSelecionadas)="
					setColunasSelecionadas($event)
				"></seira-select-list-menu>
		</div>

		<div
			*ngIf="loading; else tabela"
			class="d-flex justify-content-center align-items-center w-100 h-50"
			style="min-height: 500px">
			<seira-loading [pageCentered]="false"></seira-loading>
		</div>

		<ng-template #tabela>
			<div
				style="max-width: 1300px"
				*ngIf="dadosTabelaClimatologia; else semDados">
				<div class="row mt-3 mb-5">
					<seira-public-table
						#tabelaClimatologia
						id="tabela-climatologia"
						[hasFooter]="true"
						[hasHeader]="false"
						[info]="true"
						[paging]="true"
						[scrollX]="true"
						[searching]="false"
						[columns]="colunas"
						[data]="dadosTabelaClimatologia"></seira-public-table>
				</div>
			</div>
		</ng-template>
	</div>
	<ng-template #semDados>
		<seira-sem-dados />
	</ng-template>
</div>
