<div class="d-flex align-items-center">
	<seira-page-title class="w-100 align-items-center">
		<div class="d-flex justify-content-between align-items-center">
			<div style="color: #728187; font-weight: 400; font-size: 24px !important">
				Dados meteorológicos
				<i class="dot bg-primary dot-space"></i>
				Quantis
			</div>
			<div class="d-flex justify-content-end">
				<seira-public-page-about
					class="size-about"
					[textosInformativos]="informacoesGerais"></seira-public-page-about>
			</div>
		</div>
	</seira-page-title>
</div>

<form [formGroup]="formQuantis" class="form-group" id="form">
	<div class="col-lg-4 col-xl-4 d-flex align-items-center gap-2">
		<seira-public-date-picker
			style="width: 100%"
			label="Período"
			placeholder="Selecione um período"
			controlName="periodo"
			datePickerType="year" />
		<div class="col-lg-6 col-md-4 col-sm-6 mt-auto">
			<seira-public-select
				placeholder="Selecione uma região pluviométrica"
				label="Região pluviométrica"
				[searchable]="true"
				[options]="regioes"
				optionValue="id"
				optionLabel="nome"
				notFoundText="Nenhuma região pluviométrica encontrada"
				formControlName="regiao">
			</seira-public-select>
		</div>
		<div class="col-lg-2 col-md-4 col-sm-6 mt-auto">
			<seira-public-button
				buttonStyle="fill"
				[disabledButton]="formQuantis.invalid"
				(clicked)="gerarQuantis()"
				[loading]="loading"
				>Buscar</seira-public-button
			>
		</div>
	</div>
</form>

<div class="col-lg-12">
	<seira-loading [show]="loading" />
	<div *ngIf="!loading && resultado">
		<seira-grafico-quantis
			(chartInstance)="onChartCreated($event)"
			[dado]="resultado"></seira-grafico-quantis>
	</div>
	<ng-container *ngIf="!resultado && !loading">
		<seira-sem-dados />
	</ng-container>
</div>
<div class="row" *seiraIfViewportSize="['xl', 'lg', 'md']">
	<div class="col-lg-6">
		<seira-info-gerais
			id="informacoes-gerais"
			[textosInformativos]="informacoesGerais"></seira-info-gerais>
	</div>
</div>
