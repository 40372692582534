import { Referencia } from '@shared/enum/menu-itens';

export enum MenuItens {
	SEGURANCA = 'Segurança',
	MONITORAMENTO = 'Monitoramento',
	ZONEAMENTO = 'Zoneamento',
	REGIAO = 'Região',
	RESERVATORIO = 'Reservatório',
	CONFIGURACAO = 'Configuração',
}
export enum Visualizacao {
	ADMINISTRACAO = 'Administração',
	METEOROLOGIA = 'Meteorologia',
	RECURSOS_HIDRICOS = 'Recursos hídricos',
}
export enum OpcoesAcoes {
	LISTAGEM = 'Listagem de ',
	ADICIONAR = 'Adicionar ',
	EDITAR = 'Editar ',
	DETALHAR = 'Detalhes d',
	COMPARAR = 'Comparar ',
	IMPORTAR = 'Importar ',
}

export function infoAddPath(
	escopo: Referencia,
	acao: keyof typeof OpcoesAcoes,
	menu: Visualizacao = Visualizacao.ADMINISTRACAO
): {
	listPathPage: string[];
	activePathPage: string;
} {
	let textoFinal = '';
	switch (acao) {
		case 'ADICIONAR':
			textoFinal = escopo.singular.toLowerCase();
			break;
		case 'LISTAGEM':
			textoFinal = escopo.plural.toLowerCase();
			break;
		case 'DETALHAR':
			textoFinal = escopo.com_artigo.toLowerCase();
			break;
		case 'EDITAR':
			textoFinal = escopo.singular.toLowerCase();
			break;
		case 'COMPARAR':
			textoFinal = escopo.plural.toLowerCase();
			break;
		case 'IMPORTAR':
			textoFinal = escopo.singular.toLowerCase();
			break;
	}
	return {
		listPathPage: [menu, escopo.grupo, escopo.plural],
		activePathPage: OpcoesAcoes[acao] + textoFinal,
	};
}
