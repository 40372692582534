export class DocumentExporter {
	static gerarCSV(dados: unknown[][] | string, nomeArquivo?: string) {
		let csv = '';

		if (Array.isArray(dados)) {
			csv = dados.map(row => row.join(';')).join('\n') || '';
		} else {
			csv = dados;
		}

		return DocumentExporter.gerardocumento(
			csv,
			{ type: 'text/csv;charset=utf-8' },
			'csv',
			nomeArquivo
		);
	}

	static gerarTXT(dados: string, nomeArquivo?: string) {
		return DocumentExporter.gerardocumento(
			dados,
			{ type: 'text/plain;charset=utf-8' },
			'txt',
			nomeArquivo
		);
	}

	static gerardocumento(
		dados: string,
		properties: object,
		type: string,
		fileName: string = 'relatorioAESA'
	) {
		const utf8Bom = new Uint8Array([0xef, 0xbb, 0xbf]);
		const blob = new Blob([utf8Bom, dados], properties);
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;

		a.download = fileName + '.' + type;
		a.click();
		window.URL.revokeObjectURL(url);
	}
}
