import { AdminSelectOptions } from '@layout/componentes/header/admin-select-options';
import { RoutesFront } from '@core/enums/routes-front';
import { MenuItens, OpcoesAcoes } from '@layout/interfaces/layoutLabels';
import {
	AvisoReferencia,
	BaciaReferencia,
	CartasCampoReferencia,
	ClassificacaoReservatorioReferencia,
	ClimatologiaReferencia,
	CompararResultadosReferencia,
	EstacaoReferencia,
	GrupoReferencia,
	HistoricoReservatorioReferencia,
	ImagensLandingPageReferencia,
	MesorregiaoReferencia,
	MicrorregiaoReferencia,
	ModeloNumericoReferencia,
	MunicipioReferencia,
	OrgaoReferencia,
	RangesQuantisReferencia,
	RegionalReferencia,
	ReservatorioReferencia,
	UsuarioReferencia,
	VeranicoReferencia,
} from '@shared/enum/menu-itens';
import {
	KEY_LOCAL_STORAGE_PERMISSIONS,
	PermissoesEnum,
} from '@administracao/submodulos/grupo/enums/permissoesEnum';

export const menuItemsAdministracao: AdminSelectOptions[] = [
	{
		href: 'menu-item-seguranca',
		label: MenuItens.SEGURANCA,
		icon: 'ph ph-fingerprint',
		submenu: [
			{
				title: UsuarioReferencia.plural,
				options: [
					{
						permission: PermissoesEnum.VISUALIZAR_USUARIO,
						label:
							OpcoesAcoes.LISTAGEM + UsuarioReferencia.plural.toLowerCase(),
						path: RoutesFront.ADMINISTRACAO + '/usuario',
					},
					{
						permission: PermissoesEnum.GERENCIAR_USUARIO,
						label:
							OpcoesAcoes.ADICIONAR + UsuarioReferencia.singular.toLowerCase(),
						path: RoutesFront.ADMINISTRACAO + '/usuario/add',
					},
				],
			},
			{
				title: OrgaoReferencia.plural,
				options: [
					{
						permission: PermissoesEnum.VISUALIZAR_ORGAO,
						label: OpcoesAcoes.LISTAGEM + OrgaoReferencia.plural.toLowerCase(),
						path: RoutesFront.ADMINISTRACAO + '/orgao',
					},
					{
						permission: PermissoesEnum.GERENCIAR_ORGAO,
						label:
							OpcoesAcoes.ADICIONAR + OrgaoReferencia.singular.toLowerCase(),
						path: RoutesFront.ADMINISTRACAO + '/orgao/add',
					},
				],
			},
			{
				title: GrupoReferencia.plural,
				options: [
					{
						permission: PermissoesEnum.VISUALIZAR_GRUPO,
						label: OpcoesAcoes.LISTAGEM + GrupoReferencia.plural.toLowerCase(),
						path: RoutesFront.ADMINISTRACAO + '/grupo',
					},
					{
						permission: PermissoesEnum.GERENCIAR_GRUPO,
						label:
							OpcoesAcoes.ADICIONAR + GrupoReferencia.singular.toLowerCase(),
						path: RoutesFront.ADMINISTRACAO + '/grupo/add',
					},
				],
			},
		],
	},
	// {
	// 	href: 'menu-item-zoneamento',
	// 	label: MenuItens.ZONEAMENTO,
	// 	icon: 'ph ph-signpost',
	// 	submenu: [
	// 		{
	// 			title: SoloReferencia.plural,
	// 			options: [
	// 				{
	// 					permission: PermissoesEnum.VISUALIZAR_SOLO,
	// 					label: OpcoesAcoes.LISTAGEM + SoloReferencia.plural.toLowerCase(),
	// 					path: RoutesFront.ADMINISTRACAO + '/solo',
	// 				},
	// 				{
	// 					permission: PermissoesEnum.GERENCIAR_SOLO,
	// 					label:
	// 						OpcoesAcoes.ADICIONAR + SoloReferencia.singular.toLowerCase(),
	// 					path: RoutesFront.ADMINISTRACAO + '/solo/add',
	// 				},
	// 			],
	// 		},
	// 		{
	// 			title: CulturaReferencia.plural,
	// 			options: [
	// 				{
	// 					permission: PermissoesEnum.VISUALIZAR_CULTURA,
	// 					label:
	// 						OpcoesAcoes.LISTAGEM + CulturaReferencia.plural.toLowerCase(),
	// 					path: RoutesFront.ADMINISTRACAO + '/cultura',
	// 				},
	// 				{
	// 					permission: PermissoesEnum.GERENCIAR_CULTURA,
	// 					label:
	// 						OpcoesAcoes.ADICIONAR + CulturaReferencia.singular.toLowerCase(),
	// 					path: RoutesFront.ADMINISTRACAO + '/cultura/add',
	// 				},
	// 			],
	// 		},
	// 		{
	// 			title: ZoneamentoEdafoclimaticoReferencia.plural,
	// 			options: [
	// 				{
	// 					permission: PermissoesEnum.VISUALIZAR_EDAFO,
	// 					label:
	// 						OpcoesAcoes.LISTAGEM +
	// 						ZoneamentoEdafoclimaticoReferencia.plural.toLowerCase(),
	// 					path: RoutesFront.ADMINISTRACAO + '/zoneamento',
	// 				},
	// 				{
	// 					permission: PermissoesEnum.GERENCIAR_EDAFO,
	// 					label:
	// 						OpcoesAcoes.ADICIONAR +
	// 						ZoneamentoEdafoclimaticoReferencia.singular.toLowerCase(),
	// 					path: RoutesFront.ADMINISTRACAO + '/zoneamento/add',
	// 				},
	// 			],
	// 		},
	// 		{
	// 			title: RiscoClimaticoReferencia.plural,
	// 			options: [
	// 				{
	// 					permission: PermissoesEnum.VISUALIZAR_RISCO_CLIMATICO,
	// 					label:
	// 						OpcoesAcoes.LISTAGEM +
	// 						RiscoClimaticoReferencia.plural.toLowerCase(),
	// 					path: RoutesFront.ADMINISTRACAO + '/risco_climatico',
	// 				},
	// 				{
	// 					permission: PermissoesEnum.GERENCIAR_RISCO_CLIMATICO,
	// 					label:
	// 						OpcoesAcoes.ADICIONAR +
	// 						RiscoClimaticoReferencia.singular.toLowerCase(),
	// 					path: RoutesFront.ADMINISTRACAO + '/risco_climatico/add',
	// 				},
	// 			],
	// 		},
	// 	],
	// },
	{
		href: 'menu-item-configuracao',
		label: MenuItens.CONFIGURACAO,
		icon: 'ph ph-gear',
		submenu: [
			{
				title: AvisoReferencia.plural,
				options: [
					{
						permission:
							PermissoesEnum.VISUALIZAR_CONFIGURACAO_DE_AVISO_METEOROLOGICO,
						label: OpcoesAcoes.LISTAGEM + AvisoReferencia.plural.toLowerCase(),
						path:
							RoutesFront.ADMINISTRACAO + '/configuracao-aviso-meteorologico',
					},
					{
						permission:
							PermissoesEnum.GERENCIAR_CONFIGURACAO_DE_AVISO_METEOROLOGICO,
						label:
							OpcoesAcoes.ADICIONAR + AvisoReferencia.singular.toLowerCase(),
						path:
							RoutesFront.ADMINISTRACAO +
							'/configuracao-aviso-meteorologico/add',
					},
					{
						permission:
							PermissoesEnum.GERENCIAR_CONFIGURACAO_DE_AVISO_METEOROLOGICO,
						label: OpcoesAcoes.IMPORTAR + AvisoReferencia.plural.toLowerCase(),
						path:
							RoutesFront.ADMINISTRACAO +
							'/configuracao-aviso-meteorologico/import',
					},
				],
			},
			{
				title: VeranicoReferencia.plural,
				options: [
					{
						permission: PermissoesEnum.VISUALIZAR_VERANICO,
						label:
							OpcoesAcoes.LISTAGEM + VeranicoReferencia.plural.toLowerCase(),
						path: RoutesFront.ADMINISTRACAO + '/configuracao-veranico',
					},
					{
						permission: PermissoesEnum.GERENCIAR_VERANICO,
						label:
							OpcoesAcoes.ADICIONAR + VeranicoReferencia.singular.toLowerCase(),
						path: RoutesFront.ADMINISTRACAO + '/configuracao-veranico/add',
					},
					{
						permission: PermissoesEnum.GERENCIAR_VERANICO,
						label:
							OpcoesAcoes.IMPORTAR + VeranicoReferencia.plural.toLowerCase(),
						path: RoutesFront.ADMINISTRACAO + '/configuracao-veranico/import',
					},
				],
			},
			{
				title: ModeloNumericoReferencia.plural,
				options: [
					{
						permission: PermissoesEnum.VISUALIZAR_MODELO_DE_EXECUCAO,
						label:
							OpcoesAcoes.LISTAGEM +
							ModeloNumericoReferencia.plural.toLowerCase(),
						path: RoutesFront.ADMINISTRACAO + '/modelo_numerico',
					},
					{
						permission: PermissoesEnum.VISUALIZAR_MODELO_DE_EXECUCAO,
						label:
							OpcoesAcoes.COMPARAR +
							CompararResultadosReferencia.plural.toLowerCase(),
						path: RoutesFront.ADMINISTRACAO + '/comparar_modelo',
					},
				],
			},
			{
				title: RangesQuantisReferencia.plural,
				options: [
					{
						permission:
							PermissoesEnum.VISUALIZAR_CONFIGURACAO_DE_RANGES_QUANTIS,
						label:
							OpcoesAcoes.LISTAGEM +
							RangesQuantisReferencia.plural.toLowerCase(),
						path: RoutesFront.ADMINISTRACAO + '/ranges-quantis',
					},
					{
						permission: PermissoesEnum.GERENCIAR_CONFIGURACAO_DE_RANGES_QUANTIS,
						label:
							OpcoesAcoes.ADICIONAR +
							RangesQuantisReferencia.plural.toLowerCase(),
						path: RoutesFront.ADMINISTRACAO + '/ranges-quantis/add',
					},
				],
			},
			{
				title: ClimatologiaReferencia.plural,
				options: [
					{
						permission: PermissoesEnum.VISUALIZAR_CONFIGURACAO_DE_CLIMATOLOGIA,
						label:
							OpcoesAcoes.LISTAGEM +
							ClimatologiaReferencia.plural.toLowerCase(),
						path: RoutesFront.ADMINISTRACAO + '/climatologia',
					},
					{
						permission: PermissoesEnum.GERENCIAR_CONFIGURACAO_DE_CLIMATOLOGIA,
						label:
							OpcoesAcoes.IMPORTAR +
							ClimatologiaReferencia.plural.toLowerCase(),
						path: RoutesFront.ADMINISTRACAO + '/climatologia/import',
					},
				],
			},
			{
				title: ImagensLandingPageReferencia.plural,
				options: [
					{
						permission: PermissoesEnum.GERENCIAR_IMAGENS_DO_SISTEMA,
						label:
							OpcoesAcoes.LISTAGEM +
							ImagensLandingPageReferencia.plural.toLowerCase(),
						path: RoutesFront.ADMINISTRACAO + '/imagens-sistema',
					},
					{
						permission: PermissoesEnum.GERENCIAR_IMAGENS_DO_SISTEMA,
						label:
							OpcoesAcoes.ADICIONAR +
							ImagensLandingPageReferencia.plural.toLowerCase(),
						path: RoutesFront.ADMINISTRACAO + '/imagens-sistema/add',
					},
				],
			},
		],
	},
];

export const menuItemsMeteorologia: AdminSelectOptions[] = [
	{
		href: 'menu-item-monitoramento',
		label: MenuItens.MONITORAMENTO,
		icon: 'ph ph-binoculars',
		submenu: [
			{
				title: EstacaoReferencia.plural,
				options: [
					{
						permission: PermissoesEnum.VISUALIZAR_ESTACAO,
						label:
							OpcoesAcoes.LISTAGEM + EstacaoReferencia.plural.toLowerCase(),
						path: RoutesFront.METEOROLOGIA + '/estacao',
					},
					{
						permission: PermissoesEnum.GERENCIAR_ESTACAO,
						label:
							OpcoesAcoes.ADICIONAR + EstacaoReferencia.singular.toLowerCase(),
						path: RoutesFront.METEOROLOGIA + '/estacao/add',
					},
				],
			},
			{
				title: CartasCampoReferencia.plural,
				options: [
					{
						permission: PermissoesEnum.GERENCIAR_CARTA_CAMPO,
						label:
							OpcoesAcoes.LISTAGEM + CartasCampoReferencia.plural.toLowerCase(),
						path: RoutesFront.METEOROLOGIA + '/medicoes-pluviometricas',
					},
				],
			},
		],
	},
	{
		href: 'menu-item-regioes',
		label: MenuItens.REGIAO,
		icon: 'ph ph-compass',
		submenu: [
			{
				title: MesorregiaoReferencia.plural,
				options: [
					{
						permission: PermissoesEnum.VISUALIZAR_MESORREGIAO,
						label:
							OpcoesAcoes.LISTAGEM + MesorregiaoReferencia.plural.toLowerCase(),
						path: RoutesFront.METEOROLOGIA + '/mesorregiao',
					},
					{
						permission: PermissoesEnum.GERENCIAR_MESORREGIAO,
						label:
							OpcoesAcoes.ADICIONAR +
							MesorregiaoReferencia.singular.toLowerCase(),
						path: RoutesFront.METEOROLOGIA + '/mesorregiao/add',
					},
				],
			},
			{
				title: MicrorregiaoReferencia.plural,
				options: [
					{
						permission: PermissoesEnum.VISUALIZAR_MICRORREGIAO,
						label:
							OpcoesAcoes.LISTAGEM +
							MicrorregiaoReferencia.plural.toLowerCase(),
						path: RoutesFront.METEOROLOGIA + '/microrregiao',
					},
					{
						permission: PermissoesEnum.GERENCIAR_MICRORREGIAO,
						label:
							OpcoesAcoes.ADICIONAR +
							MicrorregiaoReferencia.singular.toLowerCase(),
						path: RoutesFront.METEOROLOGIA + '/microrregiao/add',
					},
				],
			},
			{
				title: MunicipioReferencia.plural,
				options: [
					{
						permission: PermissoesEnum.VISUALIZAR_MUNICIPIO,
						label:
							OpcoesAcoes.LISTAGEM + MunicipioReferencia.plural.toLowerCase(),
						path: RoutesFront.METEOROLOGIA + '/municipio',
					},
					{
						permission: PermissoesEnum.GERENCIAR_MUNICIPIO,
						label:
							OpcoesAcoes.ADICIONAR +
							MunicipioReferencia.singular.toLowerCase(),
						path: RoutesFront.METEOROLOGIA + '/municipio/add',
					},
				],
			},
			{
				title: RegionalReferencia.plural,
				options: [
					{
						permission: PermissoesEnum.VISUALIZAR_REGIONAL,
						label:
							OpcoesAcoes.LISTAGEM + RegionalReferencia.plural.toLowerCase(),
						path: RoutesFront.METEOROLOGIA + '/regional',
					},
					{
						permission: PermissoesEnum.GERENCIAR_REGIONAL,
						label:
							OpcoesAcoes.ADICIONAR + RegionalReferencia.singular.toLowerCase(),
						path: RoutesFront.METEOROLOGIA + '/regional/add',
					},
				],
			},
		],
	},
];

export const menuItemsRecursosHidricos: AdminSelectOptions[] = [
	{
		href: 'menu-item-reservatorio',
		label: MenuItens.RESERVATORIO,
		icon: 'ph ph-drop',
		submenu: [
			{
				title: 'Reservatórios',
				options: [
					{
						permission: PermissoesEnum.VISUALIZAR_RESERVATORIO,
						label:
							OpcoesAcoes.LISTAGEM +
							ReservatorioReferencia.plural.toLowerCase(),
						path: RoutesFront.RECURSOS_HIDRICOS + '/reservatorio',
					},
					{
						permission: PermissoesEnum.GERENCIAR_RESERVATORIO,
						label:
							OpcoesAcoes.ADICIONAR +
							ReservatorioReferencia.singular.toLowerCase(),
						path: RoutesFront.RECURSOS_HIDRICOS + '/reservatorio/add',
					},
					{
						permission: PermissoesEnum.GERENCIAR_HISTORICO_DE_RESERVATORIO,
						label:
							OpcoesAcoes.LISTAGEM +
							HistoricoReservatorioReferencia.singular.toLowerCase(),
						path: RoutesFront.RECURSOS_HIDRICOS + '/reservatorio/historico',
					},
				],
			},
			{
				title: BaciaReferencia.plural,
				options: [
					{
						permission: PermissoesEnum.VISUALIZAR_BACIA,
						label: OpcoesAcoes.LISTAGEM + BaciaReferencia.plural.toLowerCase(),
						path: RoutesFront.RECURSOS_HIDRICOS + '/bacia',
					},
					{
						permission: PermissoesEnum.GERENCIAR_BACIA,
						label:
							OpcoesAcoes.ADICIONAR + BaciaReferencia.singular.toLowerCase(),
						path: RoutesFront.RECURSOS_HIDRICOS + '/bacia/add',
					},
				],
			},
			{
				title: ClassificacaoReservatorioReferencia.plural,
				options: [
					{
						permission:
							PermissoesEnum.VISUALIZAR_CONFIGURACAO_CLASSIFICACAO_RESERVATORIO,
						label:
							OpcoesAcoes.LISTAGEM +
							ClassificacaoReservatorioReferencia.plural.toLowerCase(),
						path:
							RoutesFront.RECURSOS_HIDRICOS +
							'/configuracao-classificacao-reservatorio',
					},
					{
						permission:
							PermissoesEnum.GERENCIAR_CONFIGURACAO_CLASSIFICACAO_RESERVATORIO,
						label:
							OpcoesAcoes.ADICIONAR +
							ClassificacaoReservatorioReferencia.singular.toLowerCase(),
						path:
							RoutesFront.RECURSOS_HIDRICOS +
							'/configuracao-classificacao-reservatorio/add',
					},
				],
			},
		],
	},
];
export const getMenuOptions = (
	menuItems: AdminSelectOptions[]
): AdminSelectOptions[] => {
	const permissions =
		localStorage
			.getItem(KEY_LOCAL_STORAGE_PERMISSIONS.PERMISSIONS)
			?.split(';') || [];
	return menuItems
		.flatMap(menu => ({
			...menu,
			submenu: menu.submenu.filter(grupo => {
				grupo.options = grupo.options.filter(
					option => option.permission && permissions.includes(option.permission)
				);
				return grupo.options.length;
			}),
		}))
		.filter(menu => menu.submenu.length);
};
