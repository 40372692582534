export const RANGES_QUANTIS_MENSAL: Record<string, RangeDataMonth[]> = {
	'25015': [
		{ nome: 'Brejo', mes: 1, ranges: [3.833333, 14.9, 48.4, 144.66666, 1000] },
		{ nome: 'Brejo', mes: 2, ranges: [15.66666, 50.4, 119.4, 194.46666, 1000] },
		{
			nome: 'Brejo',
			mes: 3,
			ranges: [66.93333, 137.7, 210.5, 308.33333, 1000],
		},
		{
			nome: 'Brejo',
			mes: 4,
			ranges: [57.73333, 116.5, 193.6, 313.73333, 1000],
		},
		{ nome: 'Brejo', mes: 5, ranges: [11.63333, 59.8, 127.4, 198.36666, 1000] },
		{ nome: 'Brejo', mes: 6, ranges: [5.733333, 20.4, 57.0, 110.2, 1000] },
		{ nome: 'Brejo', mes: 7, ranges: [0.0, 11.0, 32.9, 63.866667, 1000] },
		{ nome: 'Brejo', mes: 8, ranges: [0.0, 0.0, 4.3, 15.833333, 1000] },
		{ nome: 'Brejo', mes: 9, ranges: [0.0, 0.0, 0.0, 7.7, 1000] },
		{ nome: 'Brejo', mes: 10, ranges: [0.0, 0.0, 0.0, 1.666667, 1000] },
		{ nome: 'Brejo', mes: 11, ranges: [0.0, 0.0, 0.0, 3.933333, 1000] },
		{ nome: 'Brejo', mes: 12, ranges: [0.0, 0.0, 11.0, 36.666667, 1000] },
	],
	'25005': [
		{ nome: 'Piancó', mes: 1, ranges: [8.116667, 29.05, 69.0, 117.175, 1000] },
		{ nome: 'Piancó', mes: 2, ranges: [26.9, 54.12, 103.9, 181.275, 1000] },
		{
			nome: 'Piancó',
			mes: 3,
			ranges: [87.141667, 129.65, 209.7, 292.0583, 1000],
		},
		{
			nome: 'Piancó',
			mes: 4,
			ranges: [53.291667, 107.72, 206.425, 299.3666, 1000],
		},
		{
			nome: 'Piancó',
			mes: 5,
			ranges: [16.958333, 62.775, 117.475, 174.9916, 1000],
		},
		{ nome: 'Piancó', mes: 6, ranges: [1.75, 22.35, 52.5, 104.1833, 1000] },
		{ nome: 'Piancó', mes: 7, ranges: [0.0, 7.8, 33.75, 68.11666, 1000] },
		{ nome: 'Piancó', mes: 8, ranges: [0.0, 0.0, 1.7, 17.76666, 1000] },
		{ nome: 'Piancó', mes: 9, ranges: [0.0, 0.0, 0.0, 6.341667, 1000] },
		{ nome: 'Piancó', mes: 10, ranges: [0.0, 0.0, 0.0, 4.708333, 1000] },
		{ nome: 'Piancó', mes: 11, ranges: [0.0, 0.0, 0.0, 10.20833, 1000] },
		{ nome: 'Piancó', mes: 12, ranges: [0.0, 0.0, 19.875, 54.74166, 1000] },
	],
	'25022': [
		{
			nome: 'João Pessoa',
			mes: 1,
			ranges: [6.883333, 24.85, 72.2, 115.36666, 1000],
		},
		{
			nome: 'João Pessoa',
			mes: 2,
			ranges: [21.683333, 55.65, 123.1, 185.63333, 1000],
		},
		{
			nome: 'João Pessoa',
			mes: 3,
			ranges: [70.733333, 140.15, 231.75, 292.76666, 1000],
		},
		{
			nome: 'João Pessoa',
			mes: 4,
			ranges: [55.85, 111.75, 239.0, 327.33333, 1000],
		},
		{
			nome: 'João Pessoa',
			mes: 5,
			ranges: [20.916667, 60.1, 122.7, 179.05, 1000],
		},
		{
			nome: 'João Pessoa',
			mes: 6,
			ranges: [4.966667, 17.05, 41.85, 92.45, 1000],
		},
		{
			nome: 'João Pessoa',
			mes: 7,
			ranges: [0.0, 12.3, 29.05, 67.666667, 1000],
		},
		{ nome: 'João Pessoa', mes: 8, ranges: [0.0, 0.0, 5.15, 16.883333, 1000] },
		{ nome: 'João Pessoa', mes: 9, ranges: [0.0, 0.0, 0.0, 6.233333, 1000] },
		{ nome: 'João Pessoa', mes: 10, ranges: [0.0, 0.0, 0.0, 3.133333, 1000] },
		{ nome: 'João Pessoa', mes: 11, ranges: [0.0, 0.0, 0.0, 12.7, 1000] },
		{ nome: 'João Pessoa', mes: 12, ranges: [0.0, 0.0, 17.2, 46.916667, 1000] },
	],
	'25021': [
		{ nome: 'Sapé', mes: 1, ranges: [2.7, 20.4, 55.74, 119.94667, 1000] },
		{ nome: 'Sapé', mes: 2, ranges: [20.02667, 50.325, 106.11, 171.26, 1000] },
		{ nome: 'Sapé', mes: 3, ranges: [83.69667, 131.27, 209.115, 291.7, 1000] },
		{ nome: 'Sapé', mes: 4, ranges: [47.585, 100.88, 194.21, 288.80667, 1000] },
		{ nome: 'Sapé', mes: 5, ranges: [19.0, 44.93, 111.235, 161.315, 1000] },
		{ nome: 'Sapé', mes: 6, ranges: [3.8, 15.555, 44.735, 81.88333, 1000] },
		{ nome: 'Sapé', mes: 7, ranges: [0.0, 8.355, 30.8, 61.08667, 1000] },
		{ nome: 'Sapé', mes: 8, ranges: [0.0, 0.0, 6.38, 17.44333, 1000] },
		{ nome: 'Sapé', mes: 9, ranges: [0.0, 0.0, 0.0, 12.0, 1000] },
		{ nome: 'Sapé', mes: 10, ranges: [0.0, 0.0, 0.0, 1.83, 1000] },
		{ nome: 'Sapé', mes: 11, ranges: [0.0, 0.0, 0.59, 12.14333, 1000] },
		{ nome: 'Sapé', mes: 12, ranges: [0.0, 0.0, 16.325, 43.77333, 1000] },
	],
	'25020': [
		{
			nome: 'Litoral Norte',
			mes: 1,
			ranges: [21.61, 46.055, 98.99, 186.97833, 1000],
		},
		{
			nome: 'Litoral Norte',
			mes: 2,
			ranges: [29.883333, 61.335, 155.01, 250.8, 1000],
		},
		{
			nome: 'Litoral Norte',
			mes: 3,
			ranges: [107.22, 179.95, 260.22, 348.16167, 1000],
		},
		{
			nome: 'Litoral Norte',
			mes: 4,
			ranges: [77.076667, 123.4, 221.715, 295.435, 1000],
		},
		{
			nome: 'Litoral Norte',
			mes: 5,
			ranges: [25.383333, 67.82, 157.255, 203.91167, 1000],
		},
		{
			nome: 'Litoral Norte',
			mes: 6,
			ranges: [8.261667, 32.425, 61.895, 110.73833, 1000],
		},
		{
			nome: 'Litoral Norte',
			mes: 7,
			ranges: [0.0, 12.58, 36.795, 74.585, 1000],
		},
		{ nome: 'Litoral Norte', mes: 8, ranges: [0.0, 0.0, 6.66, 14.43333, 1000] },
		{ nome: 'Litoral Norte', mes: 9, ranges: [0.0, 0.0, 2.31, 19.18167, 1000] },
		{
			nome: 'Litoral Norte',
			mes: 10,
			ranges: [0.0, 0.0, 0.85, 18.53333, 1000],
		},
		{ nome: 'Litoral Norte', mes: 11, ranges: [0.0, 0.0, 0.0, 16.11667, 1000] },
		{
			nome: 'Litoral Norte',
			mes: 12,
			ranges: [0.0, 0.77, 22.415, 64.53167, 1000],
		},
	],
	'25019': [
		{
			nome: 'Umbuzeiro',
			mes: 1,
			ranges: [13.91667, 39.925, 80.9, 133.26666, 1000],
		},
		{
			nome: 'Umbuzeiro',
			mes: 2,
			ranges: [23.95833, 61.7, 135.8, 224.74166, 1000],
		},
		{
			nome: 'Umbuzeiro',
			mes: 3,
			ranges: [110.35, 156.32, 223.225, 293.51666, 1000],
		},
		{
			nome: 'Umbuzeiro',
			mes: 4,
			ranges: [58.35, 111.45, 218.3, 318.325, 1000],
		},
		{
			nome: 'Umbuzeiro',
			mes: 5,
			ranges: [15.85833, 63.1, 120.225, 181.91666, 1000],
		},
		{ nome: 'Umbuzeiro', mes: 6, ranges: [1.75, 16.625, 47.95, 90.125, 1000] },
		{ nome: 'Umbuzeiro', mes: 7, ranges: [0.0, 3.3, 28.25, 56.508333, 1000] },
		{ nome: 'Umbuzeiro', mes: 8, ranges: [0.0, 0.0, 2.0, 15.0, 1000] },
		{ nome: 'Umbuzeiro', mes: 9, ranges: [0.0, 0.0, 0.0, 7.125, 1000] },
		{ nome: 'Umbuzeiro', mes: 10, ranges: [0.0, 0.0, 0.0, 6.583333, 1000] },
		{ nome: 'Umbuzeiro', mes: 11, ranges: [0.0, 0.0, 0.0, 18.675, 1000] },
		{ nome: 'Umbuzeiro', mes: 12, ranges: [0.0, 0.0, 18.375, 60.208333, 1000] },
	],
	'25018': [
		{
			nome: 'Itabaiana',
			mes: 1,
			ranges: [13.833333, 42.0, 104.075, 156.31667, 1000],
		},
		{
			nome: 'Itabaiana',
			mes: 2,
			ranges: [34.016667, 75.3, 143.9, 215.75, 1000],
		},
		{
			nome: 'Itabaiana',
			mes: 3,
			ranges: [122.01666, 177.52, 257.225, 341.00833, 1000],
		},
		{
			nome: 'Itabaiana',
			mes: 4,
			ranges: [69.858333, 127.57, 253.8, 367.18333, 1000],
		},
		{
			nome: 'Itabaiana',
			mes: 5,
			ranges: [29.141667, 61.375, 120.6, 195.375, 1000],
		},
		{
			nome: 'Itabaiana',
			mes: 6,
			ranges: [6.508333, 23.125, 60.15, 108.74167, 1000],
		},
		{ nome: 'Itabaiana', mes: 7, ranges: [0.0, 8.45, 36.2, 69.38333, 1000] },
		{ nome: 'Itabaiana', mes: 8, ranges: [0.0, 0.0, 3.5, 16.58333, 1000] },
		{ nome: 'Itabaiana', mes: 9, ranges: [0.0, 0.0, 0.0, 10.75, 1000] },
		{ nome: 'Itabaiana', mes: 10, ranges: [0.0, 0.0, 0.0, 11.29167, 1000] },
		{ nome: 'Itabaiana', mes: 11, ranges: [0.0, 0.0, 2.925, 16.94167, 1000] },
		{ nome: 'Itabaiana', mes: 12, ranges: [0.0, 4.0, 25.05, 69.05833, 1000] },
	],
	'25017': [
		{
			nome: 'Campina Grande',
			mes: 1,
			ranges: [0.0, 3.7, 35.04, 96.813333, 1000],
		},
		{
			nome: 'Campina Grande',
			mes: 2,
			ranges: [10.726667, 25.36, 63.7, 152.26, 1000],
		},
		{
			nome: 'Campina Grande',
			mes: 3,
			ranges: [53.313333, 98.24, 167.4, 239.48666, 1000],
		},
		{
			nome: 'Campina Grande',
			mes: 4,
			ranges: [41.566667, 75.66, 168.34, 256.5, 1000],
		},
		{
			nome: 'Campina Grande',
			mes: 5,
			ranges: [8.566667, 61.08, 110.56, 171.68666, 1000],
		},
		{
			nome: 'Campina Grande',
			mes: 6,
			ranges: [8.44, 22.36, 53.74, 96.42, 1000],
		},
		{
			nome: 'Campina Grande',
			mes: 7,
			ranges: [1.553333, 12.2, 47.04, 86.573333, 1000],
		},
		{ nome: 'Campina Grande', mes: 8, ranges: [0.0, 1.12, 8.64, 22.8, 1000] },
		{
			nome: 'Campina Grande',
			mes: 9,
			ranges: [0.0, 0.0, 1.0, 12.466667, 1000],
		},
		{
			nome: 'Campina Grande',
			mes: 10,
			ranges: [0.0, 0.0, 0.0, 2.086667, 1000],
		},
		{ nome: 'Campina Grande', mes: 11, ranges: [0.0, 0.0, 0.0, 3.96, 1000] },
		{
			nome: 'Campina Grande',
			mes: 12,
			ranges: [0.0, 0.0, 6.2, 28.653333, 1000],
		},
	],
	'25016': [
		{ nome: 'Guarabira', mes: 1, ranges: [0.0, 11.0, 42.515, 94.94, 1000] },
		{
			nome: 'Guarabira',
			mes: 2,
			ranges: [10.57667, 30.79, 87.475, 141.881667, 1000],
		},
		{
			nome: 'Guarabira',
			mes: 3,
			ranges: [31.66, 70.375, 151.01, 235.128333, 1000],
		},
		{
			nome: 'Guarabira',
			mes: 4,
			ranges: [25.90667, 68.4, 151.05, 233.343333, 1000],
		},
		{
			nome: 'Guarabira',
			mes: 5,
			ranges: [15.6, 38.0, 76.81, 113.628333, 1000],
		},
		{ nome: 'Guarabira', mes: 6, ranges: [5.0, 15.18, 39.005, 71.735, 1000] },
		{ nome: 'Guarabira', mes: 7, ranges: [0.0, 11.095, 33.0, 69.823333, 1000] },
		{ nome: 'Guarabira', mes: 8, ranges: [0.0, 0.39, 9.65, 23.47, 1000] },
		{ nome: 'Guarabira', mes: 9, ranges: [0.0, 0.0, 1.4, 11.411667, 1000] },
		{ nome: 'Guarabira', mes: 10, ranges: [0.0, 0.0, 0.0, 2.07, 1000] },
		{ nome: 'Guarabira', mes: 11, ranges: [0.0, 0.0, 0.0, 6.623333, 1000] },
		{ nome: 'Guarabira', mes: 12, ranges: [0.0, 0.0, 6.42, 28.581667, 1000] },
	],
	'25014': [
		{ nome: 'Esperança', mes: 1, ranges: [2.066667, 18.9, 70.5, 119.25, 1000] },
		{
			nome: 'Esperança',
			mes: 2,
			ranges: [13.68333, 48.75, 103.7, 161.46666, 1000],
		},
		{ nome: 'Esperança', mes: 3, ranges: [49.5, 106.6, 185.65, 273.45, 1000] },
		{
			nome: 'Esperança',
			mes: 4,
			ranges: [38.81666, 93.85, 190.0, 313.01666, 1000],
		},
		{
			nome: 'Esperança',
			mes: 5,
			ranges: [10.0, 36.0, 100.65, 152.53333, 1000],
		},
		{
			nome: 'Esperança',
			mes: 6,
			ranges: [3.816667, 14.75, 38.75, 69.233333, 1000],
		},
		{ nome: 'Esperança', mes: 7, ranges: [0.0, 7.4, 26.1, 58.883333, 1000] },
		{ nome: 'Esperança', mes: 8, ranges: [0.0, 0.0, 6.6, 22.433333, 1000] },
		{ nome: 'Esperança', mes: 9, ranges: [0.0, 0.0, 0.1, 8.416667, 1000] },
		{ nome: 'Esperança', mes: 10, ranges: [0.0, 0.0, 0.0, 3.583333, 1000] },
		{ nome: 'Esperança', mes: 11, ranges: [0.0, 0.0, 0.4, 10.75, 1000] },
		{ nome: 'Esperança', mes: 12, ranges: [0.0, 0.0, 16.15, 42.383333, 1000] },
	],
	'25013': [
		{
			nome: 'Curimataú Oriental',
			mes: 1,
			ranges: [9.613333, 29.82, 75.0, 135.94333, 1000],
		},
		{
			nome: 'Curimataú Oriental',
			mes: 2,
			ranges: [16.31, 65.65, 132.46, 194.19333, 1000],
		},
		{
			nome: 'Curimataú Oriental',
			mes: 3,
			ranges: [77.66333, 143.48, 220.61, 295.32, 1000],
		},
		{
			nome: 'Curimataú Oriental',
			mes: 4,
			ranges: [36.72, 84.63, 196.29, 295.67333, 1000],
		},
		{
			nome: 'Curimataú Oriental',
			mes: 5,
			ranges: [8.226667, 33.3, 86.5, 144.94333, 1000],
		},
		{
			nome: 'Curimataú Oriental',
			mes: 6,
			ranges: [0.0, 10.03, 30.04, 61.07, 1000],
		},
		{
			nome: 'Curimataú Oriental',
			mes: 7,
			ranges: [0.0, 1.03, 14.42, 37.946667, 1000],
		},
		{
			nome: 'Curimataú Oriental',
			mes: 8,
			ranges: [0.0, 0.0, 0.74, 9.983333, 1000],
		},
		{
			nome: 'Curimataú Oriental',
			mes: 9,
			ranges: [0.0, 0.0, 0.0, 2.496667, 1000],
		},
		{ nome: 'Curimataú Oriental', mes: 10, ranges: [0.0, 0.0, 0.0, 5.2, 1000] },
		{
			nome: 'Curimataú Oriental',
			mes: 11,
			ranges: [0.0, 0.0, 0.0, 8.096667, 1000],
		},
		{
			nome: 'Curimataú Oriental',
			mes: 12,
			ranges: [0.0, 3.43, 28.42, 58.36, 1000],
		},
	],
	'25012': [
		{
			nome: 'Curimataú Ocidental',
			mes: 1,
			ranges: [2.0, 20.0, 66.55, 115.55, 1000],
		},
		{
			nome: 'Curimataú Ocidental',
			mes: 2,
			ranges: [14.641667, 51.85, 109.925, 168.45833, 1000],
		},
		{
			nome: 'Curimataú Ocidental',
			mes: 3,
			ranges: [40.575, 95.075, 171.15, 226.88333, 1000],
		},
		{
			nome: 'Curimataú Ocidental',
			mes: 4,
			ranges: [34.0, 78.85, 177.8, 281.675, 1000],
		},
		{
			nome: 'Curimataú Ocidental',
			mes: 5,
			ranges: [2.583333, 20.625, 68.925, 114.88333, 1000],
		},
		{
			nome: 'Curimataú Ocidental',
			mes: 6,
			ranges: [1.658333, 9.05, 26.15, 50.84167, 1000],
		},
		{
			nome: 'Curimataú Ocidental',
			mes: 7,
			ranges: [0.0, 4.05, 15.9, 40.28333, 1000],
		},
		{
			nome: 'Curimataú Ocidental',
			mes: 8,
			ranges: [0.0, 0.0, 2.2, 11.40833, 1000],
		},
		{ nome: 'Curimataú Ocidental', mes: 9, ranges: [0.0, 0.0, 0.0, 5.6, 1000] },
		{
			nome: 'Curimataú Ocidental',
			mes: 10,
			ranges: [0.0, 0.0, 0.0, 2.725, 1000],
		},
		{
			nome: 'Curimataú Ocidental',
			mes: 11,
			ranges: [0.0, 0.0, 0.0, 10.0, 1000],
		},
		{
			nome: 'Curimataú Ocidental',
			mes: 12,
			ranges: [0.0, 0.0, 15.075, 42.70833, 1000],
		},
	],
	'25011': [
		{
			nome: 'Cariri Oriental',
			mes: 1,
			ranges: [1.086667, 9.56, 38.55, 91.00333, 1000],
		},
		{
			nome: 'Cariri Oriental',
			mes: 2,
			ranges: [7.043333, 28.53, 76.6, 128.30667, 1000],
		},
		{
			nome: 'Cariri Oriental',
			mes: 3,
			ranges: [33.283333, 71.14, 153.87, 221.19333, 1000],
		},
		{
			nome: 'Cariri Oriental',
			mes: 4,
			ranges: [40.156667, 81.4, 157.88, 222.95, 1000],
		},
		{
			nome: 'Cariri Oriental',
			mes: 5,
			ranges: [22.15, 48.39, 93.22, 169.03667, 1000],
		},
		{
			nome: 'Cariri Oriental',
			mes: 6,
			ranges: [15.043333, 40.93, 80.9, 135.07, 1000],
		},
		{
			nome: 'Cariri Oriental',
			mes: 7,
			ranges: [17.923333, 39.07, 83.97, 133.80667, 1000],
		},
		{
			nome: 'Cariri Oriental',
			mes: 8,
			ranges: [0.0, 4.94, 32.88, 64.28, 1000],
		},
		{ nome: 'Cariri Oriental', mes: 9, ranges: [0.0, 0.0, 14.38, 37.91, 1000] },
		{ nome: 'Cariri Oriental', mes: 10, ranges: [0.0, 0.0, 2.6, 11.97, 1000] },
		{
			nome: 'Cariri Oriental',
			mes: 11,
			ranges: [0.0, 0.0, 3.91, 10.11333, 1000],
		},
		{ nome: 'Cariri Oriental', mes: 12, ranges: [0.0, 0.0, 7.97, 30.77, 1000] },
	],
	'25010': [
		{
			nome: 'Cariri Ocidental',
			mes: 1,
			ranges: [0.0566666, 10.0, 35.79, 75.93167, 1000],
		},
		{
			nome: 'Cariri Ocidental',
			mes: 2,
			ranges: [7.7133333, 22.51, 69.2, 127.71667, 1000],
		},
		{
			nome: 'Cariri Ocidental',
			mes: 3,
			ranges: [32.2833333, 67.31, 130.545, 197.69167, 1000],
		},
		{
			nome: 'Cariri Ocidental',
			mes: 4,
			ranges: [33.37, 65.55, 147.515, 221.81333, 1000],
		},
		{
			nome: 'Cariri Ocidental',
			mes: 5,
			ranges: [15.6566666, 41.775, 78.74, 119.00167, 1000],
		},
		{
			nome: 'Cariri Ocidental',
			mes: 6,
			ranges: [12.2566666, 27.41, 59.4, 90.815, 1000],
		},
		{
			nome: 'Cariri Ocidental',
			mes: 7,
			ranges: [11.8566666, 28.01, 60.18, 92.43, 1000],
		},
		{
			nome: 'Cariri Ocidental',
			mes: 8,
			ranges: [0.0, 7.355, 21.18, 41.83, 1000],
		},
		{
			nome: 'Cariri Ocidental',
			mes: 9,
			ranges: [0.0, 0.0, 6.945, 22.57167, 1000],
		},
		{ nome: 'Cariri Ocidental', mes: 10, ranges: [0.0, 0.0, 0.8, 7.4, 1000] },
		{
			nome: 'Cariri Ocidental',
			mes: 11,
			ranges: [0.0, 0.0, 3.545, 10.74333, 1000],
		},
		{
			nome: 'Cariri Ocidental',
			mes: 12,
			ranges: [0.0, 0.0, 9.615, 27.66, 1000],
		},
	],
	'25009': [
		{
			nome: 'Seridó Oriental',
			mes: 1,
			ranges: [0.99, 11.79, 37.67, 77.82667, 1000],
		},
		{
			nome: 'Seridó Oriental',
			mes: 2,
			ranges: [11.84333, 28.99, 67.15, 119.27, 1000],
		},
		{
			nome: 'Seridó Oriental',
			mes: 3,
			ranges: [33.42333, 62.99, 125.48, 203.49667, 1000],
		},
		{
			nome: 'Seridó Oriental',
			mes: 4,
			ranges: [40.31667, 77.32, 150.82, 230.58333, 1000],
		},
		{
			nome: 'Seridó Oriental',
			mes: 5,
			ranges: [32.44667, 65.36, 109.09, 157.91333, 1000],
		},
		{
			nome: 'Seridó Oriental',
			mes: 6,
			ranges: [32.86, 58.35, 108.48, 174.48667, 1000],
		},
		{
			nome: 'Seridó Oriental',
			mes: 7,
			ranges: [28.08667, 61.27, 112.75, 162.26667, 1000],
		},
		{
			nome: 'Seridó Oriental',
			mes: 8,
			ranges: [8.0, 21.79, 47.7, 74.78333, 1000],
		},
		{
			nome: 'Seridó Oriental',
			mes: 9,
			ranges: [0.0, 4.49, 19.71, 43.49, 1000],
		},
		{
			nome: 'Seridó Oriental',
			mes: 10,
			ranges: [0.0, 0.0, 3.62, 11.82667, 1000],
		},
		{ nome: 'Seridó Oriental', mes: 11, ranges: [0.0, 0.0, 6.31, 19.71, 1000] },
		{
			nome: 'Seridó Oriental',
			mes: 12,
			ranges: [0.0, 0.6, 13.61, 32.05333, 1000],
		},
	],
	'25008': [
		{
			nome: 'Seridó Ocidental',
			mes: 1,
			ranges: [4.278333, 12.345, 46.25, 128.10333, 1000],
		},
		{
			nome: 'Seridó Ocidental',
			mes: 2,
			ranges: [10.35666, 36.44, 100.15, 181.28167, 1000],
		},
		{
			nome: 'Seridó Ocidental',
			mes: 3,
			ranges: [56.54333, 92.755, 189.915, 328.65833, 1000],
		},
		{
			nome: 'Seridó Ocidental',
			mes: 4,
			ranges: [66.07166, 113.85, 226.96, 334.26, 1000],
		},
		{
			nome: 'Seridó Ocidental',
			mes: 5,
			ranges: [54.86333, 107.49, 162.465, 240.26667, 1000],
		},
		{
			nome: 'Seridó Ocidental',
			mes: 6,
			ranges: [46.91333, 112.22, 213.99, 281.965, 1000],
		},
		{
			nome: 'Seridó Ocidental',
			mes: 7,
			ranges: [52.55666, 88.11, 180.19, 284.42833, 1000],
		},
		{
			nome: 'Seridó Ocidental',
			mes: 8,
			ranges: [8.283333, 30.925, 68.17, 104.07, 1000],
		},
		{
			nome: 'Seridó Ocidental',
			mes: 9,
			ranges: [0.0, 10.635, 35.57, 57.82833, 1000],
		},
		{
			nome: 'Seridó Ocidental',
			mes: 10,
			ranges: [0.0, 0.5, 7.78, 18.17333, 1000],
		},
		{
			nome: 'Seridó Ocidental',
			mes: 11,
			ranges: [0.0, 0.0, 7.925, 19.90333, 1000],
		},
		{
			nome: 'Seridó Ocidental',
			mes: 12,
			ranges: [0.0, 2.525, 18.81, 37.34667, 1000],
		},
	],
	'25007': [
		{
			nome: 'Serra do Teixeira',
			mes: 1,
			ranges: [9.333333, 20.4, 54.5, 110.96667, 1000],
		},
		{
			nome: 'Serra do Teixeira',
			mes: 2,
			ranges: [20.7, 38.3, 96.3, 181.23333, 1000],
		},
		{
			nome: 'Serra do Teixeira',
			mes: 3,
			ranges: [69.633333, 118.8, 208.8, 289.7, 1000],
		},
		{
			nome: 'Serra do Teixeira',
			mes: 4,
			ranges: [82.566667, 129.0, 212.3, 306.03333, 1000],
		},
		{
			nome: 'Serra do Teixeira',
			mes: 5,
			ranges: [71.666667, 132.8, 216.0, 315.4, 1000],
		},
		{
			nome: 'Serra do Teixeira',
			mes: 6,
			ranges: [90.466667, 143.7, 251.5, 373.9, 1000],
		},
		{
			nome: 'Serra do Teixeira',
			mes: 7,
			ranges: [73.2, 103.9, 213.3, 366.3, 1000],
		},
		{
			nome: 'Serra do Teixeira',
			mes: 8,
			ranges: [25.6, 51.3, 94.4, 156.33333, 1000],
		},
		{
			nome: 'Serra do Teixeira',
			mes: 9,
			ranges: [7.6, 21.1, 41.2, 69.93333, 1000],
		},
		{
			nome: 'Serra do Teixeira',
			mes: 10,
			ranges: [2.133333, 6.4, 15.8, 32.46667, 1000],
		},
		{
			nome: 'Serra do Teixeira',
			mes: 11,
			ranges: [2.2, 7.4, 20.6, 38.33333, 1000],
		},
		{
			nome: 'Serra do Teixeira',
			mes: 12,
			ranges: [3.233333, 12.4, 28.0, 50.86667, 1000],
		},
	],
	'25006': [
		{
			nome: 'Itaporanga',
			mes: 1,
			ranges: [8.82166, 21.32, 77.26, 111.04333, 1000],
		},
		{
			nome: 'Itaporanga',
			mes: 2,
			ranges: [28.315, 72.645, 116.915, 227.67167, 1000],
		},
		{
			nome: 'Itaporanga',
			mes: 3,
			ranges: [84.40333, 137.12, 253.585, 377.06833, 1000],
		},
		{
			nome: 'Itaporanga',
			mes: 4,
			ranges: [130.495, 175.4, 262.02, 421.09833, 1000],
		},
		{
			nome: 'Itaporanga',
			mes: 5,
			ranges: [114.40166, 160.39, 280.19, 406.11667, 1000],
		},
		{
			nome: 'Itaporanga',
			mes: 6,
			ranges: [135.74, 243.24, 375.28, 597.34, 1000],
		},
		{
			nome: 'Itaporanga',
			mes: 7,
			ranges: [123.505, 166.03, 336.715, 416.93333, 1000],
		},
		{
			nome: 'Itaporanga',
			mes: 8,
			ranges: [49.53, 90.29, 131.835, 250.75667, 1000],
		},
		{
			nome: 'Itaporanga',
			mes: 9,
			ranges: [14.43333, 33.715, 57.77, 116.65667, 1000],
		},
		{
			nome: 'Itaporanga',
			mes: 10,
			ranges: [4.17666, 8.64, 19.515, 35.135, 1000],
		},
		{
			nome: 'Itaporanga',
			mes: 11,
			ranges: [5.295, 9.735, 24.015, 53.195, 1000],
		},
		{
			nome: 'Itaporanga',
			mes: 12,
			ranges: [7.765, 16.95, 37.055, 50.85333, 1000],
		},
	],
	'25004': [
		{ nome: 'Patos', mes: 1, ranges: [9.333333, 20.4, 54.5, 110.96667, 1000] },
		{ nome: 'Patos', mes: 2, ranges: [20.7, 38.3, 96.3, 181.23333, 1000] },
		{ nome: 'Patos', mes: 3, ranges: [69.633333, 118.8, 208.8, 289.7, 1000] },
		{
			nome: 'Patos',
			mes: 4,
			ranges: [82.566667, 129.0, 212.3, 306.03333, 1000],
		},
		{ nome: 'Patos', mes: 5, ranges: [71.666667, 132.8, 216.0, 315.4, 1000] },
		{ nome: 'Patos', mes: 6, ranges: [90.466667, 143.7, 251.5, 373.9, 1000] },
		{ nome: 'Patos', mes: 7, ranges: [73.2, 103.9, 213.3, 366.3, 1000] },
		{ nome: 'Patos', mes: 8, ranges: [25.6, 51.3, 94.4, 156.33333, 1000] },
		{ nome: 'Patos', mes: 9, ranges: [7.6, 21.1, 41.2, 69.93333, 1000] },
		{ nome: 'Patos', mes: 10, ranges: [2.133333, 6.4, 15.8, 32.46667, 1000] },
		{ nome: 'Patos', mes: 11, ranges: [2.2, 7.4, 20.6, 38.33333, 1000] },
		{ nome: 'Patos', mes: 12, ranges: [3.233333, 12.4, 28.0, 50.86667, 1000] },
	],
	'25003': [
		{ nome: 'Sousa', mes: 1, ranges: [8.82166, 21.32, 77.26, 111.04333, 1000] },
		{
			nome: 'Sousa',
			mes: 2,
			ranges: [28.315, 72.645, 116.915, 227.67167, 1000],
		},
		{
			nome: 'Sousa',
			mes: 3,
			ranges: [84.40333, 137.12, 253.585, 377.06833, 1000],
		},
		{
			nome: 'Sousa',
			mes: 4,
			ranges: [130.495, 175.4, 262.02, 421.09833, 1000],
		},
		{
			nome: 'Sousa',
			mes: 5,
			ranges: [114.40166, 160.39, 280.19, 406.11667, 1000],
		},
		{ nome: 'Sousa', mes: 6, ranges: [135.74, 243.24, 375.28, 597.34, 1000] },
		{
			nome: 'Sousa',
			mes: 7,
			ranges: [123.505, 166.03, 336.715, 416.93333, 1000],
		},
		{ nome: 'Sousa', mes: 8, ranges: [49.53, 90.29, 131.835, 250.75667, 1000] },
		{
			nome: 'Sousa',
			mes: 9,
			ranges: [14.43333, 33.715, 57.77, 116.65667, 1000],
		},
		{ nome: 'Sousa', mes: 10, ranges: [4.17666, 8.64, 19.515, 35.135, 1000] },
		{ nome: 'Sousa', mes: 11, ranges: [5.295, 9.735, 24.015, 53.195, 1000] },
		{ nome: 'Sousa', mes: 12, ranges: [7.765, 16.95, 37.055, 50.85333, 1000] },
	],
	'25002': [
		{
			nome: 'Cajazeiras',
			mes: 1,
			ranges: [17.41333, 34.7, 67.2, 111.98667, 1000],
		},
		{
			nome: 'Cajazeiras',
			mes: 2,
			ranges: [35.25666, 55.09, 114.26, 172.40333, 1000],
		},
		{
			nome: 'Cajazeiras',
			mes: 3,
			ranges: [51.03, 100.4, 207.48, 277.28667, 1000],
		},
		{
			nome: 'Cajazeiras',
			mes: 4,
			ranges: [87.77666, 117.42, 239.88, 305.85667, 1000],
		},
		{
			nome: 'Cajazeiras',
			mes: 5,
			ranges: [82.61, 125.63, 202.04, 288.09667, 1000],
		},
		{
			nome: 'Cajazeiras',
			mes: 6,
			ranges: [88.17666, 142.73, 218.86, 330.22, 1000],
		},
		{
			nome: 'Cajazeiras',
			mes: 7,
			ranges: [63.05333, 117.19, 217.48, 297.10333, 1000],
		},
		{
			nome: 'Cajazeiras',
			mes: 8,
			ranges: [34.54666, 73.5, 115.68, 153.70333, 1000],
		},
		{
			nome: 'Cajazeiras',
			mes: 9,
			ranges: [10.31666, 23.81, 52.24, 103.54, 1000],
		},
		{
			nome: 'Cajazeiras',
			mes: 10,
			ranges: [0.433333, 7.14, 16.75, 45.20333, 1000],
		},
		{ nome: 'Cajazeiras', mes: 11, ranges: [0.0, 6.07, 15.85, 63.66667, 1000] },
		{
			nome: 'Cajazeiras',
			mes: 12,
			ranges: [4.66, 14.18, 35.89, 64.89667, 1000],
		},
	],
	'25001': [
		{
			nome: 'Catolé do Rocha',
			mes: 1,
			ranges: [17.41333, 34.7, 67.2, 111.98667, 1000],
		},
		{
			nome: 'Catolé do Rocha',
			mes: 2,
			ranges: [35.25666, 55.09, 114.26, 172.40333, 1000],
		},
		{
			nome: 'Catolé do Rocha',
			mes: 3,
			ranges: [51.03, 100.4, 207.48, 277.28667, 1000],
		},
		{
			nome: 'Catolé do Rocha',
			mes: 4,
			ranges: [87.77666, 117.42, 239.88, 305.85667, 1000],
		},
		{
			nome: 'Catolé do Rocha',
			mes: 5,
			ranges: [82.61, 125.63, 202.04, 288.09667, 1000],
		},
		{
			nome: 'Catolé do Rocha',
			mes: 6,
			ranges: [88.17666, 142.73, 218.86, 330.22, 1000],
		},
		{
			nome: 'Catolé do Rocha',
			mes: 7,
			ranges: [63.05333, 117.19, 217.48, 297.10333, 1000],
		},
		{
			nome: 'Catolé do Rocha',
			mes: 8,
			ranges: [34.54666, 73.5, 115.68, 153.70333, 1000],
		},
		{
			nome: 'Catolé do Rocha',
			mes: 9,
			ranges: [10.31666, 23.81, 52.24, 103.54, 1000],
		},
		{
			nome: 'Catolé do Rocha',
			mes: 10,
			ranges: [0.433333, 7.14, 16.75, 45.20333, 1000],
		},
		{
			nome: 'Catolé do Rocha',
			mes: 11,
			ranges: [0.0, 6.07, 15.85, 63.66667, 1000],
		},
		{
			nome: 'Catolé do Rocha',
			mes: 12,
			ranges: [4.66, 14.18, 35.89, 64.89667, 1000],
		},
	],
};

export interface RangeDataMonth {
	nome: string;
	mes: number;
	ranges: number[];
}
