<div>
	<div class="d-flex align-items-center">
		<seira-page-title class="w-100 align-items-center">
			<div class="d-flex justify-content-between align-items-center">
				<div
					style="color: #728187; font-weight: 400; font-size: 24px !important">
					Dados meteorológicos
					<i class="dot bg-primary dot-space"></i>
					Estações pluviométricas
				</div>
				<div class="d-flex justify-content-end">
					<seira-public-page-about
						class="size-about"
						[textosInformativos]="informacoesGerais"></seira-public-page-about>
					<seira-public-a
						class="size-option"
						*seiraIfViewportSize="'mobile'"
						href
						(click)="abrirModalOpcoesDaPagina($event)"
						><i class="ph-list"></i> Opções desta página</seira-public-a
					>
				</div>
			</div>
		</seira-page-title>
	</div>

	<seira-estacoes-pluviometricas></seira-estacoes-pluviometricas>
	<ng-template #legendaMapaMobile>
		<seira-legenda-estacoes></seira-legenda-estacoes>
	</ng-template>
	<ng-container #dynamicComponentContainer></ng-container>
	<div
		class="d-flex w-100 justify-content-start"
		*seiraIfViewportSize="'desktop'">
		<div class="col-6">
			<seira-info-gerais
				id="informacoes-gerais"
				[textosInformativos]="informacoesGerais"></seira-info-gerais>
		</div>
	</div>
</div>
