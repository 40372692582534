import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	Output,
	ViewChild,
} from '@angular/core';
import * as Highcharts from 'highcharts/highcharts';

@Component({
	selector: 'seira-highchart',
	templateUrl: './highchart.component.html',
	styleUrls: ['./highchart.component.scss'],
})
export class HighchartComponent implements OnDestroy {
	@ViewChild('highchartsGrafico') chartContainer!: ElementRef;
	@Output() chartInstance = new EventEmitter<Highcharts.StockChart>();
	@Input() classChart = 'highcharts-light h-auto';
	@Input() options!: Highcharts.Options;
	@Input() styleChart = 'width: 100%; height: 1200px; display: block';

	protected readonly Highcharts = Highcharts;

	private chart: Highcharts.StockChart | undefined;

	ngOnDestroy() {
		if (this.chart && Object.keys(this.chart).length) {
			this.chart.destroy();
		}
	}
	onChartCreated(chart: Highcharts.Chart) {
		this.chart = chart;
		this.chartInstance.emit(chart);
	}
}
