<div class="content d-flex justify-content-center align-items-center">
	<!-- Container -->
	<div class="flex-fill">
		<!-- Error title -->
		<div class="text-center mb-4">
			<img
				src="./assets/images/error_bg.svg"
				class="img-fluid mb-3"
				height="230"
				alt="" />
			<h1 class="display-3 fw-semibold lh-1 mb-3">{{ statusCode }}</h1>
			<h6 class="w-md-25 mx-md-auto">
				Oops, ocorreu um erro. <br />
				{{ msg }}
			</h6>
		</div>
		<!-- /error title -->

		<ng-template [ngIf]="returnHome">
			<!-- Error content -->
			<div class="text-center">
				<a [routerLink]="['']" class="btn btn-teal">
					<i class="ph-house me-2"></i>
					Retornar a página inicial
				</a>
			</div>
		</ng-template>
		<!-- /error wrapper -->
	</div>
	<!-- /container -->
</div>
