<div class="mb-2" style="position: relative">
	<label
		*ngIf="!removeLabel"
		class="form-label fw-semibold {{ labelContentClass }}"
		[for]="id"
		>{{ label }}<span class="text-danger" *ngIf="isRequired">*</span>

		<ng-content></ng-content
	></label>
	<ng-select
		class="select"
		[ngClass]="{
			'is-invalid': seiraError || showsTextRequired
		}"
		[loadingText]="loadingText"
		[loading]="loading"
		[id]="id"
		[searchable]="searchable"
		[placeholder]="placeholder"
		[formControlName]="controlName"
		[notFoundText]="notFound"
		[items]="items"
		[bindLabel]="bindLabel"
		[bindValue]="bindValue"
		[clearable]="clearable"
		[multiple]="multiple"
		[closeOnSelect]="!multiple"
		[required]="isRequired">
		<ng-template *ngIf="multiple" ng-header-tmp>
			<a href="#" (click)="selectAll(); $event.preventDefault()">
				<i class="ph-checks"></i> Selecionar todos
			</a>
		</ng-template>
		<ng-template
			*ngIf="multiple"
			ng-multi-label-tmp
			let-items="items"
			let-clear="clear">
			<div
				class="ng-value d-flex align-items-center bg-primary-light-2 fw-normal rounded px-1 me-1"
				*ngFor="let item of items | slice: 0 : maxShownValues">
				<span class="ng-value-label">{{ $any(item)[bindLabel] }}</span>
				<div class="vr bg-primary mx-1"></div>
				<i
					class="ph-x"
					style="font-size: 14px; cursor: pointer"
					(click)="clear(item)"></i>
			</div>
			<div class="ng-value" *ngIf="items?.length > maxShownValues">
				<div class="bg-primary-light-2 fw-normal rounded px-1">
					<span class="ng-value-label"
						>mais {{ items.length - maxShownValues }}...</span
					>
				</div>
			</div>
		</ng-template>
	</ng-select>
	<div class="invalid-feedback mt-0" *ngIf="showsTextRequired || seiraError">
		{{ textRequired || seiraError }}
	</div>
</div>
