import { Component, Input } from '@angular/core';

@Component({
	selector: 'seira-public-title',
	templateUrl: './public-title.component.html',
	styleUrls: ['./public-title.component.scss'],
})
export class PublicTitleComponent {
	@Input() sobreAPagina = true;
}
