import { Component, HostBinding } from '@angular/core';

@Component({
	selector: 'seira-recuperar-senha',
	templateUrl: './recuperar-senha.component.html',
	styleUrls: ['./recuperar-senha.component.scss'],
})
export class RecuperarSenhaComponent {
	@HostBinding('class') clazz = 'expandir-flex';
}
