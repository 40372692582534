import { Component, Input } from '@angular/core';
import { isNotNuloOuUndefined, numberToBrNumber } from '@utils';
import { ValorPorNdc } from '@utils/interpolacao/interpolacao';

@Component({
	selector: 'seira-popup-informacoes-pcd',
	templateUrl: './popup-informacoes-ndc.component.html',
	styleUrls: ['./popup-informacoes-ncd.component.scss'],
})
export class PopupInformacoesNdcComponent {
	@Input()
	markerData!: ValorPorNdc;
	@Input()
	unidade?: string;

	protected readonly isNotNuloOuUndefined = isNotNuloOuUndefined;
	protected readonly numberToBrNumber = numberToBrNumber;
}
