<div
	class="d-flex px-2 w-100 justify-content-center align-items-center"
	style="
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	">
	<div>
		<div class="d-flex flex-column align-items-center mb-4">
			<img
				src="./assets/images/error_bg.svg"
				class="img-fluid mb-3"
				height="230"
				alt="" />

			<h6 class="text-center">
				Você não possui permissão para ver essa página!
			</h6>
		</div>
		<div class="text-center">
			<seira-public-button [routerLink]="['']">
				<i class="ph-house me-2"></i>
				Retornar a página inicial
			</seira-public-button>
		</div>
	</div>
</div>
