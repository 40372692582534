<div class="accordion" id="accordion_collapsed">
	<div class="accordion-item border-0">
		<h2 class="accordion-header d-inline-flex justify-content-between w-100">
			<button
				class="accordion-button p-0 pb-2 fw-semibold button-label"
				[ngClass]="{ 'hidden-after': ocultarAfter, collapsed: !ariaExpanded }"
				type="button"
				data-bs-toggle="collapse"
				[attr.data-bs-target]="'#' + idElemento"
				[attr.aria-expanded]="!ariaExpanded"
				(click)="toggleAriaExpanded()">
				<i *ngIf="icon" class="{{ icon }} me-2"></i> {{ label }}
				<ng-content select="[label]"></ng-content>
			</button>
			<div class="d-flex gap-3">
				<ng-content select="[header]"></ng-content>

				<button
					class="accordion-button p-0 pb-2 fw-semibold button-arrow"
					[ngClass]="{ 'hidden-after': ocultarAfter, collapsed: !ariaExpanded }"
					type="button"
					data-bs-toggle="collapse"
					[attr.data-bs-target]="'#' + idElemento"
					[attr.aria-expanded]="!ariaExpanded"
					(click)="toggleAriaExpanded()"></button>
			</div>
		</h2>
		<hr class="m-0 w-100" *ngIf="ocultarAfter" />
		<div
			[id]="idElemento"
			class="accordion-collapse collapse"
			[ngClass]="{ show: ariaExpanded }">
			<div class="accordion-body pe-0 ps-0">
				<ng-content></ng-content>
			</div>
		</div>
	</div>
</div>
