/**
 * Configuração de resposividade para ser adicionar nos gráficos do sistema.
 * Configura o título de cada série para ser apresentado na esquerda
 */
export const configResponsividade = {
	rules: [
		{
			condition: {
				maxWidth: 500,
			},
			chartOptions: {
				subtitle: {
					y: 0,
				},
				legend: {
					align: 'center',
					verticalAlign: 'bottom',
					layout: 'horizontal',
				},
				yAxis: {
					labels: {
						align: 'left',
						x: 0,
						y: -5,
					},
					title: {
						text: null,
					},
				},
				credits: {
					enabled: false,
				},
			},
		},
		{
			condition: {
				minWidth: 0,
			},
			chartOptions: {
				rangeSelector: {
					y: -26,
				},
			},
		},
		{
			condition: {
				minWidth: 300,
			},
			chartOptions: {
				rangeSelector: {
					y: -14,
				},
			},
		},
		{
			condition: {
				minWidth: 560,
			},
			chartOptions: {
				rangeSelector: {
					y: 20,
				},
				subtitle: {
					y: 14,
				},
			},
		},
	],
};
