import { Component, Input } from '@angular/core';

export interface Checkbox {
	label: string;
	controlName: string;
}

@Component({
	selector: 'seira-select-checkboxes',
	templateUrl: './select-checkboxes.component.html',
	styleUrls: ['./select-checkboxes.component.scss'],
})
export class SelectCheckboxesComponent {
	@Input() checkboxesList!: Checkbox[];
	@Input() label!: string;
	@Input() open? = false;

	click() {
		this.open = !this.open;
	}
}
