<div class="d-flex flex-column">
	<label class="text-tertiary fw-semibold mt-3">
		{{ quantisType }} - {{ city }} - {{ year }}
	</label>
</div>
<div
	*ngIf="isLoading; else tabela"
	class="d-flex justify-content-center align-items-center w-100 h-50"
	style="min-height: 500px">
	<seira-loading [pageCentered]="false"></seira-loading>
</div>
<ng-template #tabela>
	<div style="max-width: 1300px" *ngIf="tableData; else semDados">
		<seira-card-info aditionalClasses="mt-3">
			Placeholder informativo
		</seira-card-info>
		<seira-tabela-relatorios-quantis
			[relatorios]="tableData"
			[isAnual]="isAnual" />
	</div>
</ng-template>
<ng-template #semDados>
	<seira-sem-dados />
</ng-template>
