<div class="page-content">
	<!-- Main content -->
	<div class="content-wrapper overflow-scroll custom-bg">
		<ng-container *seiraIfViewportSize="'mobile'">
			<ng-container *ngTemplateOutlet="mobileHeader"></ng-container>
		</ng-container>

		<ng-container *seiraIfViewportSize="'desktop'">
			<ng-container *ngTemplateOutlet="desktopHeader"></ng-container>
		</ng-container>

		<div class="container-md mt-4">
			<router-outlet></router-outlet>
			<hr class="hr" />
		</div>

		<seira-public-footer>
			<div
				*seiraIfViewportSize="'desktop'"
				class="d-flex flex-column align-items-center justify-content-center mt-3 mb-3"
				style="color: #838383; font-size: 16px; font-weight: 400">
				© Copyright SEIRA | Todos os direitos reservados
				<div class="text-primary">
					<seira-public-a>Termos de uso</seira-public-a> •
					<seira-public-a>Política de privacidade</seira-public-a>
				</div>
			</div>
		</seira-public-footer>
	</div>
</div>

<ng-template #mobileHeader>
	<seira-mobile-header></seira-mobile-header>
</ng-template>

<ng-template #desktopHeader>
	<div id="header">
		<div
			class="page-header page-header-light page-header-static shadow p-3 bg-primary">
			<div class="page-header-content d-lg-flex container-md">
				<div class="d-flex text-center w-100 justify-content-between">
					<div class="d-flex">
						<a href="/">
							<img
								src="../assets/images/logo_1.svg"
								alt=""
								style="width: 120px" />
						</a>
						<div class="d-flex align-items-center ms-3">
							<seira-tab-menu
								[menu]="menu"
								radioOptionsName="menu-home"></seira-tab-menu>
						</div>
					</div>
					<div class="push">
						<button
							type="button"
							(click)="goToLogin()"
							class="btn btn-primary text-light rounded-pill">
							<i class="ph ph-user-fill"></i>
							<span class="d-none d-md-inline-block mx-md-2"
								>Área restrita</span
							>
						</button>
					</div>
				</div>
			</div>
		</div>
		<div
			class="page-header page-header-static shadow p-2 bg-cyan-dark"
			style="min-height: 42px">
			<div class="container-md">
				<div style="margin-left: 150px">
					<seira-tab-menu
						whenSelected="dark"
						[menu]="subMenu"
						[showDropdown]="true"
						radioOptionsName="submenu-home"></seira-tab-menu>
				</div>
			</div>
		</div>
	</div>
</ng-template>
