import {
	AfterViewInit,
	ApplicationRef,
	ChangeDetectorRef,
	Component,
	ComponentFactoryResolver,
	ComponentRef,
	inject,
	Injector,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import * as Leaflet from 'leaflet';
import { LayerGroup } from 'leaflet';
import { EstacaoMonitorada } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/interfaces/estacao-monitorada';
import { DataMarker } from '@utils/leaflet';
import { PublicAComponent } from '@componentes/public-a/public-a.component';
import { StatusEstacao } from '@modulos/meteorologia/submodulos/estacao/enums/status-estacao';
import { BsModalService } from 'ngx-bootstrap/modal';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { FormGroup } from '@angular/forms';
import { Select } from '@layout/interfaces/select';
import { HttpClient } from '@angular/common/http';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';
import {
	CORES,
	LegendaCores,
	legendaInfo,
} from '@componentes/mapa-paraiba-svg/legenda';
import { AgrupamentoResponse } from '@home/submodulos/dados-meteorologicos/interfaces/agrupamento';
import { PopupAgrupamentoComponent } from '@home/submodulos/dados-meteorologicos/componentes/popup-agrupamento/popup-agrupamento.component';
import { enumAsSelectOptions } from '@utils';
import {
	AgrupamentoGeojson,
	agrupamentoGeojsonMap,
} from '@home/submodulos/dados-meteorologicos/enum/Agrupamento';

@Component({
	selector: 'seira-mapa-pluviometria-observada',
	templateUrl: './mapa-pluviometria-observada.component.html',
	styleUrls: ['./mapa-pluviometria-observada.component.scss'],
})
export class MapaPluviometriaObservadaComponent
	implements OnInit, AfterViewInit, OnDestroy, InstanciaRelatorio
{
	@ViewChild('linkRef', { static: false })
	linkRef!: TemplateRef<PublicAComponent>;

	markersLayer: LayerGroup;
	map!: Leaflet.Map;

	loadingFiltrando = false;
	markersEstacoes: any[] = [];

	status: { label: StatusEstacao; value: keyof typeof StatusEstacao }[] = [];
	show = 'block';

	centerDefault: Leaflet.LatLngExpression = [-7.2605416, -36.7290255];
	zoomDefault = 8;
	inputs = inject(INPUTS_RELATORIOS);
	form!: FormGroup;
	agrupamento: Select[] = [];
	geoJsonLayer?: Leaflet.GeoJSON;
	legenda = enumAsSelectOptions(legendaInfo);
	agrupamentoResult: AgrupamentoResponse[] = [];
	mostrarLegenda = false;
	mostrarLegendaIconesEstacao = true;
	constructor(
		private readonly modalService: BsModalService,
		private resolver: ComponentFactoryResolver,
		private appRef: ApplicationRef,
		private injector: Injector,
		private cdr: ChangeDetectorRef,
		private httpClient: HttpClient,
		private relatorioService: RelatoriosService
	) {
		this.markersLayer = Leaflet.layerGroup();
	}

	ngOnInit() {
		this.form = this.inputs.form;
		this.gerarRelatorio();
	}

	ngAfterViewInit() {
		this.cdr.detectChanges();
	}

	setupMap(mapa: Leaflet.Map) {
		this.map = mapa;
		this.cdr.detectChanges();
		this.setupMapEvents();
	}

	setupMapEvents() {
		this.map?.on('moveend', () => {
			const markers = this.getMarkersInView();
			const markerIds = markers.map(marker => marker?.data?.id);
		});

		this.map?.on('zoomend', () => {
			const zoomToIconSizeMap: Record<number, number> = {
				8: 15,
				9: 25,
				10: 35,
				11: 45,
			};
			const markers = this.getMarkers();
			const zoom = this.map.getZoom();

			for (const marker of markers) {
				if (marker.data) {
					let newIconSize = zoomToIconSizeMap[zoom] || 15;

					if (zoom < 8) {
						newIconSize = 15;
					} else if (zoom > 11) {
						newIconSize = 45;
					}

					marker.setIcon(
						Leaflet.icon({
							iconUrl: `assets/images/estacoes/pluviometro-convencional-1.svg`,
							iconSize: [newIconSize, newIconSize],
						})
					);
				}
			}
		});
	}

	getMarkers() {
		const markerList: DataMarker<EstacaoMonitorada>[] = [];
		this.map?.eachLayer(layer => {
			markerList.push(layer as DataMarker<EstacaoMonitorada>);
		});
		return markerList;
	}

	getMarkersInView() {
		const markerList: DataMarker<EstacaoMonitorada>[] = [];
		this.map?.eachLayer(layer => {
			if (
				layer instanceof Leaflet.Marker &&
				this.map?.getBounds().contains(layer.getLatLng())
			) {
				markerList.push(layer as DataMarker<EstacaoMonitorada>);
			}
		});
		return markerList;
	}

	setupMarkers(zoomIn: boolean) {
		if (this.geoJsonLayer) {
			this.geoJsonLayer.remove();
		}
		this.markersLayer.clearLayers();

		this.markersEstacoes.forEach(markerPoint => {
			const marker = new DataMarker<any>([markerPoint.lat, markerPoint.lng], {
				icon: Leaflet.icon({
					iconUrl: `assets/images/estacoes/pluviometro-convencional-1.svg`,
					iconSize: [15, 15],
				}),
				data: markerPoint,
			});

			let markerPopup = this.compilePopupAgrupamento(c => {
				c.instance.agrupamento = markerPoint;
			});

			marker.bindPopup(markerPopup, {
				closeButton: false,
			});

			marker.addTo(this.markersLayer);
		});

		this.map.addLayer(this.markersLayer);

		if (zoomIn) {
			const markerPositions: Leaflet.LatLngBoundsLiteral =
				this.markersEstacoes.map(m => [m.lat, m.lng]);
			this.map.fitBounds(markerPositions, {
				maxZoom: 15,
			});
		}

		this.map.setView(this.centerDefault, this.zoomDefault);
	}

	compilePopupAgrupamento(
		onAttach: (el: ComponentRef<PopupAgrupamentoComponent>) => void
	) {
		const compFactory = this.resolver.resolveComponentFactory(
			PopupAgrupamentoComponent
		);
		let compRef = compFactory.create(this.injector);

		// onAttach allows you to assign
		if (onAttach) onAttach(compRef);

		this.appRef.attachView(compRef.hostView);
		compRef.onDestroy(() => this.appRef.detachView(compRef.hostView));

		let div = document.createElement('div');
		div.appendChild(compRef.location.nativeElement);
		return div;
	}
	ngOnDestroy(): void {
		this.form.get(FormularioRelatorio.TIPO)?.clearValidators();
		this.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.form.get(FormularioRelatorio.MICRORREGIAO)?.clearValidators();
		this.form.get(FormularioRelatorio.PRECIPITACAO)?.clearValidators();
		this.form.get(FormularioRelatorio.MUNICIPIO)?.clearValidators();
		this.form.get(FormularioRelatorio.ESTACAO)?.clearValidators();
		this.form.get(FormularioRelatorio.AGRUPAMENTO)?.clearValidators();
	}
	gerarRelatorio() {
		const diaInicio = this.getFormItemValue('dataInicio');
		const diaFim = this.getFormItemValue('dataFim');
		const agrupamento = this.getFormItemValue('agrupamento');

		this.loadingFiltrando = true;
		this.relatorioService
			.buscarRelatorioPorAgrupamento({ diaInicio, diaFim, agrupamento })
			.subscribe({
				next: (val: AgrupamentoResponse[]) => {
					this.agrupamentoResult = val;
					this.loadingFiltrando = false;
					this.markersEstacoes = val;
					this.loadMunicipiosMap(this.map);
					this.verificaAgrupamento(agrupamento);
				},
				error: () => {
					this.loadingFiltrando = false;
				},
			});
	}
	verificaAgrupamento(agrupamento: string) {
		if (agrupamento === 'MUNICIPIO_POSTO') {
			this.mostrarLegenda = false;
			this.mostrarLegendaIconesEstacao = true;
			this.setupMarkers(true);
		} else {
			this.mostrarLegenda = true;
			this.mostrarLegendaIconesEstacao = false;
		}
	}
	getGeojsonPorAgrupamento() {
		const agrupamentoStr = this.form?.get('agrupamento')?.value;
		const agrupamento = agrupamentoGeojsonMap[agrupamentoStr];

		return agrupamento ? agrupamento : AgrupamentoGeojson.MUNICIPIO;
	}

	loadMunicipiosMap(map: Leaflet.Map) {
		if (this.geoJsonLayer) {
			this.geoJsonLayer.remove();
			this.geoJsonLayer.clearLayers();
		}
		this.map.removeLayer(this.markersLayer);

		const geojson = this.getGeojsonPorAgrupamento();
		this.httpClient.get<GeoJSON.GeoJsonObject>(geojson).subscribe({
			next: value => {
				this.geoJsonLayer = Leaflet.geoJSON(value, {
					style: feature => this.estiloRegiao(feature),
					onEachFeature: (feature, layer) => {
						layer.on({
							click: e => {
								const nome = this.getPropertyNomeGeoson(feature);

								const dadosRegiao = this.agrupamentoResult.find(
									(d: AgrupamentoResponse) => d.nome === nome
								);

								if (dadosRegiao) {
									let markerPopup = this.compilePopupAgrupamento(c => {
										const headerColor = this.getColor(
											dadosRegiao.precipitacaoAcumulada
										);
										c.instance.agrupamento = dadosRegiao;
										c.instance.headerColor = headerColor;
										c.instance.titleColor =
											headerColor === LegendaCores.LARANJA ||
											headerColor === LegendaCores.BRANCO_NEVE ||
											headerColor === LegendaCores.AMARELO
												? 'gray'
												: 'white';
									});

									layer.bindPopup(markerPopup).openPopup(e.latlng);
								}
							},
						});
					},
				}).addTo(map);
			},
		});
	}

	getColor(valor: number): string {
		for (const key of Object.keys(legendaInfo)
			.map(Number)
			.sort((a, b) => b - a)) {
			if (valor >= key) {
				return legendaInfo[key];
			}
		}
		return CORES.CINZA;
	}
	getPropertyNomeGeoson(feature: {
		properties: {
			name: string;
			NM_MESO: string;
			Nome: string;
			nome: string;
			Regiao: string;
		};
	}) {
		return (
			feature.properties.name ||
			feature.properties.NM_MESO ||
			feature.properties.Nome ||
			feature.properties.nome ||
			feature.properties.Regiao
		);
	}
	estiloRegiao(feature: any): any {
		const nome = this.getPropertyNomeGeoson(feature);
		const dadosRegiao = this.agrupamentoResult.find(
			(d: AgrupamentoResponse) => d.nome === nome
		);
		const valorPrecipitacao = dadosRegiao
			? dadosRegiao.precipitacaoAcumulada
			: 0;
		return {
			fillColor: this.getColor(valorPrecipitacao),
			weight: 2,
			opacity: 1,
			color: '#607d8b',
			fillOpacity: 0.7,
		};
	}

	getFormItemValue(formValue: string) {
		return this.form.get(formValue)!.value;
	}
}
