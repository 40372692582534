import { Component, Input, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';

@Component({
	selector: 'seira-export-button-boletim',
	templateUrl: './export-button-boletim.component.html',
	styleUrls: ['./export-button-boletim.component.scss'],
})
export class ExportButtonBoletimComponent {
	@Input() label = 'exportar...';
	@Input() icon = 'ph-export';
	@Input() buttons: GroupButton[] = [];
	@Input() disabledButton = false;
	modalRef?: BsModalRef;

	constructor(private modalService: BsModalService) {}

	onClick(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template);
	}
}
