<div class="row mt-3 mb-5">
	<div class="mb-2" *ngFor="let relatorio of relatorios">
		<label for="tabela" class="text-tertiary mb-1">
			{{ relatorio.municipio }}
		</label>
		<seira-public-table
			id="tabela"
			[hasFooter]="false"
			[hasHeader]="false"
			[info]="false"
			[paging]="false"
			[searching]="false"
			[columns]="colunas"
			[data]="relatorio.data"
			[scrollX]="true"></seira-public-table>
	</div>
</div>
