<div class="d-flex text-primary align-items-center">
	<div *ngIf="icon">
		<i class="{{ icon }} me-1 fs-1 mt-1 "></i>
	</div>
	<ng-select
		[bindValue]="optionValue"
		[bindLabel]="optionLabel"
		[formControlName]="controlName"
		[items]="options"
		[clearable]="false"
		class="cycle-select me-1">
	</ng-select>
	<div class="d-flex flex-column">
		<i class="ph-caret-up" (click)="selectForward()"></i
		><i class="ph-caret-down" (click)="selectBackward()"></i>
	</div>
</div>
