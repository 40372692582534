import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'seira-button',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
	@Input() loading = false;
	@Input() link: string | null = null;
	@Input() disabledButton = false;
	@Input() type = 'button';
	@Input() buttonStyle:
		| 'add'
		| 'edit'
		| 'cancel'
		| 'remove'
		| 'view'
		| 'save'
		| 'alterar'
		| 'limpar'
		| 'importar'
		| 'custom' = 'cancel';
	@Input() icon = '';
	@Input() classButton = 'btn btn-light';
	@Input() customClass = 'ms-2';
	@Output() clicked = new EventEmitter<Event>();

	ngOnInit() {
		switch (this.buttonStyle) {
			case 'add':
				this.classButton =
					'btn btn-teal btn-labeled btn-labeled-start ' + this.customClass;
				this.icon = 'ph-plus-circle';
				break;
			case 'edit':
				this.classButton =
					'btn btn-teal btn-labeled btn-labeled-start ' + this.customClass;
				this.icon = 'ph-note-pencil';
				break;
			case 'remove':
				this.classButton =
					'btn btn-danger btn-labeled btn-labeled-start ' + this.customClass;
				this.icon = 'ph-trash';
				break;
			case 'view':
				this.classButton =
					'btn btn-teal btn-labeled btn-labeled-start ' + this.customClass;
				this.icon = 'ph-eye';
				break;
			case 'save':
				this.classButton =
					'btn btn-teal btn-labeled btn-labeled-start ' + this.customClass;
				this.icon = 'ph-floppy-disk';
				break;
			case 'limpar':
				this.classButton =
					'btn btn-secondary btn-labeled btn-labeled-start ' + this.customClass;
				this.icon = 'ph-paint-brush-household';
				break;
			case 'alterar':
				this.classButton = 'btn btn-teal w-100';
				break;
			case 'importar':
				this.classButton =
					'btn btn-teal btn-labeled btn-labeled-start ' + this.customClass;
				this.icon = 'ph-upload-simple';
				break;
		}
	}
}
