import { Injectable } from '@angular/core';
import { HttpClientProtectedService } from '@core/services/auth/http-client-protected.service';
import { lastValueFrom, map, Observable, of, switchMap } from 'rxjs';
import { Rotas } from '@core/enums/routes';
import {
	MunicipioBaciaPorCoordenadas,
	ReservatorioItemList,
	ResponseReservatorio,
	SimulacaoCota,
} from '@modulos/recursos-hidricos/submodulos/reservatorio/interfaces/response-reservatorio';
import {
	HistoricoReservatorio,
	HistoricoReservatorioBody,
	PeriodoReservatorio,
	ReservatorioBody,
	ReservatorioPayload,
	ReservatorioPayloadPost,
	ResponsePeriodoReservatorioPorReservatorio,
	ValidacaoCota,
} from '@modulos/recursos-hidricos/submodulos/reservatorio/interfaces/form-reservatorio';
import {
	HistoricosReservatorio,
	Reservatorio,
	ShortReservatorio,
	ShortReservatorioToAdm,
} from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/interfaces/reservatorios';
import { TiposDatePicker } from '@shared/interfaces/date-picker';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
	providedIn: 'root',
})
export class ReservatorioService {
	constructor(private http: HttpClientProtectedService) {}

	adicionar(
		newReservatorio: ReservatorioPayloadPost,
		fichaTecnica?: File | null
	): Observable<void> {
		return this.http
			.post<ReservatorioPayloadPost, any>(
				Rotas.RESERVATORIO + '/salvar',
				newReservatorio
			)
			.pipe(
				switchMap(res => {
					if (!res) of();
					return this.salvarFichaTecnica(res.id, fichaTecnica);
				})
			);
	}

	salvarFichaTecnica(
		reservatorioId: number,
		fichaTecnica?: File | null
	): Observable<void> {
		const formData = new FormData();

		if (fichaTecnica)
			formData.append('fichaTecnica', fichaTecnica, fichaTecnica.name);

		return this.http.postArquivo<FormData, any>(
			Rotas.RESERVATORIO + `/${reservatorioId}/salvar-ficha-tecnica`,
			formData
		);
	}
	adicionarHistorico(newHistoricoReservatorio: HistoricoReservatorioBody) {
		return this.http.post<HistoricoReservatorioBody, HistoricoReservatorio>(
			Rotas.HISTORICO_RESERVATORIO + '/adicionar',
			newHistoricoReservatorio
		);
	}
	adicionarEmMassa(historicos: HistoricoReservatorioBody[]) {
		return this.http.post<HistoricoReservatorioBody[], HistoricoReservatorio[]>(
			Rotas.HISTORICO_RESERVATORIO + '/adicionarEmMassa',
			historicos
		);
	}
	removerHistoricos(ids: number[]) {
		return this.http.delete(
			Rotas.HISTORICO_RESERVATORIO + '/removerHistoricos',
			{
				stringIds: ids.join(','),
			}
		);
	}

	listHistorico(
		reservatorioId: number,
		dataInicio: Date,
		dataFim: Date
	): Observable<HistoricoReservatorio[]> {
		return this.http.get<HistoricoReservatorio[]>(
			Rotas.HISTORICO_RESERVATORIO + '/listar',
			{
				reservatorioId,
				dataInicio: dataInicio.toISOString(),
				dataFim: dataFim.toISOString(),
			}
		);
	}

	listHistoricoPublico(data?: Date | string) {
		return this.http.get<Reservatorio[]>(
			Rotas.HISTORICO_RESERVATORIO + '/por-data',
			{
				data,
			}
		);
	}
	listHistoricoPublicoPorReservatorio(
		reservatorioId: number,
		dataInicio: Date,
		dataFim: Date,
		periodo: TiposDatePicker
	) {
		return this.http.get<HistoricosReservatorio>(
			Rotas.HISTORICO_RESERVATORIO + '/por-reservatorio',
			{
				reservatorioId,
				dataInicio: dataInicio.toISOString(),
				dataFim: dataFim.toISOString(),
				periodo,
			}
		);
	}

	obterFichaTecnica(id: number) {
		return this.http.getBlob<any>(Rotas.RESERVATORIO + `/${id}/ficha-tecnica`);
	}

	listPeriodos(idReservatorio: number): Observable<PeriodoReservatorio[]> {
		return this.http
			.get<ResponsePeriodoReservatorioPorReservatorio>(
				Rotas.PERIODOS_RESERVATORIO +
					'/search/findAllByReservatorio_Id?reservatorio_id=' +
					idReservatorio
			)
			.pipe(map(res => res._embedded.periodos));
	}

	editar(
		editReservatorio: ReservatorioPayload,
		fichaTecnica?: File | null
	): Observable<void> {
		return this.http
			.patch<ReservatorioPayload, any>(
				Rotas.RESERVATORIO + '/editar',
				editReservatorio
			)
			.pipe(
				switchMap(res => {
					if (!res) of();
					return this.salvarFichaTecnica(res, fichaTecnica);
				})
			);
	}

	remover(id: number) {
		return this.salvarFichaTecnica(id).pipe(
			switchMap(() =>
				this.http.delete<ReservatorioBody>(Rotas.RESERVATORIO + '/' + id)
			)
		);
	}

	buscarPorId(id: number): Observable<ReservatorioBody> {
		return this.http.get<ReservatorioBody>(Rotas.RESERVATORIO + '/' + id);
	}

	list(): Observable<ReservatorioItemList[]> {
		return this.http
			.get<ResponseReservatorio>(Rotas.RESERVATORIO)
			.pipe(map(embedded => embedded._embedded.reservatorio));
	}

	listShortReservatorios(): Observable<ShortReservatorio[]> {
		return this.http.get<ShortReservatorio[]>(Rotas.RESERVATORIO + '/listar');
	}

	listShortReservatoriosToAdm(): Observable<ShortReservatorioToAdm[]> {
		return this.http.get<ShortReservatorioToAdm[]>(
			Rotas.RESERVATORIO + '/listar-short-version'
		);
	}

	buscarMunicipioEBaciaPorCoordenadas(latitude: number, longitude: number) {
		return this.http.get<MunicipioBaciaPorCoordenadas>(
			Rotas.RESERVATORIO + '/municipio-bacia',
			{
				latitude,
				longitude,
			}
		);
	}

	simularCota(idReservatorio: number, cota: number) {
		return this.http.get<SimulacaoCota>(Rotas.RESERVATORIO + '/simular-cota', {
			idReservatorio,
			cota,
		});
	}

	async validarCotas(historicos: HistoricoReservatorioBody[]) {
		return lastValueFrom(
			this.http.post<HistoricoReservatorioBody[], ValidacaoCota>(
				Rotas.HISTORICO_RESERVATORIO + '/validar-cotas',
				historicos
			)
		).catch((err: HttpErrorResponse) => err.error);
	}
}
