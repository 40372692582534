import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { mergeMap, Observable, of } from 'rxjs';
import { environment } from '@environment';
import { isNuloOuUndefined } from '@utils';

export interface HeaderObject {
	[header: string]: string | string[];
}

export interface QueryParams {
	[chave: string]: any;
}

@Injectable({
	providedIn: 'root',
})
export class HttpClientProtectedService {
	_baseURL = environment.API_URL;

	public constructor(private http: HttpClient) {}

	public get baseURL(): string {
		return this._baseURL;
	}

	public get<R>(
		caminho: string,
		queryParams?: HttpParams | QueryParams,
		headers?: HeaderObject,
		responseType: 'json' | 'blob' | 'text' = 'json'
	): Observable<R> {
		this.limparQueryParams(queryParams);
		return this.processarHeaders(headers).pipe(
			mergeMap(headers =>
				this.http.get<R>(`${this._baseURL}${caminho}`, {
					headers,
					params: queryParams,
					responseType: responseType as 'json',
				})
			)
		);
	}
	public getBlob<R>(
		caminho: string,
		queryParams?: HttpParams | QueryParams
	): Observable<Blob> {
		this.limparQueryParams(queryParams);
		return this.http.get(`${this._baseURL}${caminho}`, {
			params: queryParams,
			responseType: 'blob', // Defina a responseType como 'blob'
		});
	}
	public post<T, R>(
		caminho: string,
		dados: T,
		headers?: HeaderObject,
		queryParams?: HttpParams | QueryParams
	): Observable<R> {
		this.limparQueryParams(queryParams);
		return this.processarHeaders(headers).pipe(
			mergeMap(headers =>
				this.http.post<R>(`${this._baseURL}${caminho}`, dados, {
					headers,
					params: queryParams,
				})
			)
		);
	}

	public postArquivo<T, R>(
		caminho: string,
		dados: T,
		headers?: HeaderObject,
		queryParams?: HttpParams | QueryParams
	): Observable<R> {
		this.limparQueryParams(queryParams);
		const { 'Content-Type': multipart, ...application } = headers || {};
		return this.processarHeaders(application).pipe(
			mergeMap(headers =>
				this.http.post<R>(`${this._baseURL}${caminho}`, dados, {
					...headers,
					params: queryParams,
				})
			)
		);
	}
	public putArquivo<T, R>(
		caminho: string,
		dados: T,
		headers?: HeaderObject,
		queryParams?: HttpParams | QueryParams
	): Observable<R> {
		this.limparQueryParams(queryParams);
		const { 'Content-Type': multipart, ...application } = headers || {};
		return this.processarHeaders(application).pipe(
			mergeMap(headers =>
				this.http.put<R>(`${this._baseURL}${caminho}`, dados, {
					...headers,
					params: queryParams,
				})
			)
		);
	}

	public put<T, R>(
		caminho: string,
		dados: T,
		headers?: HeaderObject,
		queryParams?: HttpParams | QueryParams
	): Observable<R> {
		this.limparQueryParams(queryParams);
		return this.processarHeaders(headers).pipe(
			mergeMap(headers =>
				this.http.put<R>(`${this._baseURL}${caminho}`, dados, {
					headers,
					params: queryParams,
				})
			)
		);
	}

	public patch<T, R>(
		caminho: string,
		dados: T,
		headers?: HeaderObject,
		queryParams?: HttpParams | QueryParams
	): Observable<R> {
		this.limparQueryParams(queryParams);
		return this.processarHeaders(headers).pipe(
			mergeMap(headers =>
				this.http.patch<R>(`${this._baseURL}${caminho}`, dados, {
					headers,
					params: queryParams,
				})
			)
		);
	}

	public delete<R>(
		caminho: string,
		queryParams?: HttpParams | QueryParams,
		headers?: HeaderObject
	): Observable<R> {
		this.limparQueryParams(queryParams);
		return this.processarHeaders(headers).pipe(
			mergeMap(headers =>
				this.http.delete<R>(`${this._baseURL}${caminho}`, {
					headers,
					params: queryParams,
				})
			)
		);
	}

	public processarHeaders(headers: HeaderObject = {}) {
		return of({
			'X-Origem': 'AESA_WEB',
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...headers,
		});
	}

	public limparQueryParams(queryParams?: HttpParams | QueryParams): void {
		if (isNuloOuUndefined(queryParams) || queryParams instanceof HttpParams) {
			return;
		}

		Object.keys(queryParams).forEach(chave => {
			if (isNuloOuUndefined(queryParams[chave])) {
				delete queryParams[chave];
			}
		});
	}
}
