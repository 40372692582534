import * as Leaflet from 'leaflet';

export class DataMarker<T> extends Leaflet.Marker {
	data?: T;

	constructor(
		latLng: Leaflet.LatLngExpression,
		options: (Leaflet.MarkerOptions & { data: T }) | undefined
	) {
		const { data, ...rest } = options ?? {};
		super(latLng, rest);
		this.data = data;
	}
}
