<div id="container">
	<svg class="circular-progress" width="70" height="70" viewBox="0 0 200 200">
		<circle class="background" cx="100" cy="100" r="90"></circle>
		<circle
			class="progress"
			cx="100"
			cy="100"
			r="90"
			[style.strokeDasharray]="circumference"
			[style.stroke]="color"
			[style.strokeDashoffset]="offset"></circle>
	</svg>
	<div class="percentage font-size-16 fw-semibold" [style.color]="color">
		{{ percentage | number: '1.0-0' : 'pt' }}%
	</div>
</div>
