<seira-public-modal [close]="close">
	<label class="text-tertiary fw-medium d-flex h1 m-0 font-size-24" title>
		Selecionando postos específicos
	</label>
	<form [formGroup]="formSelecao" content>
		<div class="row">
			<form
				[formGroup]="filtroForm"
				class="d-flex flex-column border-end col-4 gap-2 pe-3 pb-5">
				<seira-public-input
					label="Buscar palavra-chave"
					placeholder="Escreva..."
					controlName="palavraChave"></seira-public-input>
				<seira-public-select
					label="Mesorregião"
					placeholder="Selecione a mesorregião"
					formControlName="mesorregiao"
					optionValue="id"
					optionLabel="nome"
					[options]="mesorregioes"></seira-public-select>
				<seira-public-select
					label="Microrregião"
					placeholder="Selecione a microrregião."
					optionValue="id"
					optionLabel="nome"
					formControlName="microrregiao"
					[options]="microrregioes"></seira-public-select>
				<seira-public-select
					label="Município"
					placeholder="Selecione o município"
					optionValue="id"
					optionLabel="nome"
					formControlName="municipio"
					[options]="municipios"></seira-public-select>
				<seira-public-select
					label="Bacia/Sub-bacia"
					placeholder="Selecione uma bacia/sub-bacia"
					optionValue="id"
					optionLabel="nome"
					formControlName="bacia"
					[options]="bacias"></seira-public-select>
				<seira-public-select
					label="Regional"
					placeholder="Selecione uma regional"
					optionValue="id"
					optionLabel="nome"
					formControlName="regional"
					[options]="regionais"></seira-public-select>
				<seira-public-checkbox
					controlName="apenasAtivos"
					id="apenasAtivos"
					label="Apenas postos ativos"></seira-public-checkbox>
				<div class="d-flex justify-content-end">
					<seira-public-button
						buttonStyle="outline"
						[loading]="loadingFiltragem"
						(clicked)="filtrar()"
						>Filtrar</seira-public-button
					>
				</div>
			</form>
			<div class="col-8">
				<seira-select-postos-relatorio
					[postos]="postos"
					(postosSelecionados)="
						_onPostosSelecionados($event)
					"></seira-select-postos-relatorio>
			</div>
		</div>
	</form>
	<seira-button
		footer
		type="button"
		classButton="btn btn-primary"
		buttonStyle="custom"
		(clicked)="selecionar()">
		Selecionar
	</seira-button>
</seira-public-modal>
