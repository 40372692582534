<div
	class="text-tertiary fw-semibold h5 font-size-16"
	style="margin-bottom: 5px">
	DADOS OBSERVADOS
</div>
<div class="bg-white rounded shadow-lg py-3 px-2 text-tertiary">
	<div class="overflow-auto py-2 px-2" style="max-height: 472px">
		<div class="spinner-wrapper" *ngIf="loading">
			<i class="spinner-border"></i>
		</div>
		<div
			*ngIf="!loading && !estacoesMonitoradas.length"
			class="text-tertiary w-100 text-center">
			Nenhuma estação encontrada
		</div>
		<ng-template [ngIf]="!loading && estacoesMonitoradas.length">
			<div
				class="row button-estacao"
				*ngFor="let estacao of estacoesMonitoradas; let i = index"
				(click)="clickEstacao(estacao)">
				<div
					class="col-12 font-size-14"
					*ngIf="estacao.tipoEstacao.toLowerCase() !== 'pluviometro_manual'">
					{{ estacao.ultimaColeta | date: 'dd/MM/yyyy - HH:mm' }}
				</div>
				<div class="col-8 align-items-start">
					<div class="font-weight-light font-size-16">
						{{ estacao.nomeMunicipio }}
					</div>
					<div class="font-size-14">{{ estacao.nomePosto }}</div>
					<div
						class="d-flex justify-content-between fw-semibold-400 font-size-14">
						<div>{{ estacao.latitude.toFixed(7) }}</div>
						<div>{{ estacao.longitude.toFixed(7) }}</div>
					</div>
				</div>
				<div
					class="col-4 d-flex align-items-center justify-content-center"
					style="min-height: 88px">
					<div
						class="border rounded fw-semibold-1 font-size-14 text-center w-100 h-100 d-flex align-items-center justify-content-center"
						style="flex-direction: column">
						{{
							isNotNuloOuUndefined(estacao.precipitacao)
								? numberToBrNumber(estacao.precipitacao.toFixed(1)) + ' mm'
								: 'Não medida'
						}}
					</div>
				</div>
				<div>
					<hr style="height: 1px; opacity: 15%" class="mt-2" />
				</div>
			</div>
		</ng-template>
	</div>
</div>
