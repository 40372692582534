import { Component, Input } from '@angular/core';

@Component({
	selector: 'seira-dashboard-values',
	templateUrl: './dashboard-values.component.html',
	styleUrls: ['./dashboard-values.component.scss'],
})
export class DashboardValuesComponent {
	@Input() items!: { label: string; value: number | string }[];
	@Input() label = '';
	@Input() value = '';
	isMobile: boolean = window.innerWidth < 560;
}
