import { Component, Input } from '@angular/core';
import { EstacaoMonitorada } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/interfaces/estacao-monitorada';
import { isNotNuloOuUndefined, numberToBrNumber } from '@utils';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalHistoricoEstacaoComponent } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/componentes/modal-historico-estacao/modal-historico-estacao.component';

@Component({
	selector: 'seira-public-estacao-popup',
	templateUrl: './public-estacao-popup.component.html',
	styleUrls: ['./public-estacao-popup.component.scss'],
})
export class PublicEstacaoPopupComponent {
	@Input() markerData!: EstacaoMonitorada;
	protected readonly isNotNuloOuUndefined = isNotNuloOuUndefined;
	constructor(private modalService: BsModalService) {}
	clickEstacao(estacao: EstacaoMonitorada) {
		const closeModal = () => {
			this.modalService.hide();
		};
		this.modalService.show(ModalHistoricoEstacaoComponent, {
			backdrop: 'static',
			class: 'modal-xl modal-dialog-centered bg-transparent',
			initialState: {
				estacao: estacao,
				close: closeModal,
			},
		});
	}

	protected readonly numberToBrNumber = numberToBrNumber;
}
