export enum InputType {
	LATITUDE = 'latitude',
	LONGITUDE = 'longitude',
	NUMBERS_ONLY = 'numbersOnly',
	FLOAT_ONLY = 'floatOnly',
}

export const regexInputTypeToRemove = {
	[InputType.LATITUDE]: /(-?|-?[0-9]|-?[1-8][0-9]|-?90)([.]|[.][0-9]+)?$/g,
	[InputType.LONGITUDE]:
		/(-?|-?[0-9]|-?[1-9][0-9]|-?1[0-7][0-9]|-?180)([.]|[.][0-9]+)?$/g,
	[InputType.NUMBERS_ONLY]: /[0-9]*/g,
	[InputType.FLOAT_ONLY]: /-?[0-9]*,?[0-9]*$/g,
};
