import { Component, HostBinding, OnInit } from '@angular/core';

@Component({
	selector: 'seira-redefinir-senha',
	templateUrl: './redefinir-senha.component.html',
	styleUrls: ['./redefinir-senha.component.scss'],
})
export class RedefinirSenhaComponent {
	@HostBinding('class') clazz = 'expandir-flex';
}
