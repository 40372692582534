import { DesvioDTO } from '@modulos/home/submodulos/dados-meteorologicos/interfaces/desvioDTO';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Polygon } from 'geojson';
import Position = GeoJSON.Position;
import FeatureCollection = GeoJSON.FeatureCollection;
import { FormGroup, Validators } from '@angular/forms';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
} from '../../../interfaces/tipos-relatorios';
import {
	InterpolacaoCanvasBounds,
	ValorDesvioInterpolacao,
	criarCanvasInterpolacao,
} from '@utils/interpolacao/interpolacao';
import { VariavelMeteorologica } from '@utils/interpolacao/intepolacao-parametros';
import { obter_erro_request } from '@utils';
import { RelatoriosService } from '../../../services/relatorios.service';

@Component({
	selector: 'seira-mapa-desvio',
	templateUrl: './mapa-desvio.component.html',
	styleUrls: ['./mapa-desvio.component.scss'],
})
export class MapaDesvioComponent implements OnInit {
	inputs = inject(INPUTS_RELATORIOS);
	form!: FormGroup;
	regiao?: Position[][];
	carregando = false;
	medicoes: DesvioDTO[] = [];
	valoresDesvio: DesvioDTO[] = [];
	interpolacaoDesvio!: InterpolacaoCanvasBounds;
	colorJsonInterpolacao: string = '#5a5e66';
	weight: number = 2;
	tipoDesvio: keyof DesvioDTO = 'desvioMilimetro';

	constructor(
		private httpClient: HttpClient,
		private relatoriosService: RelatoriosService,
		private toast: ToastrService
	) {}

	ngOnInit(): void {
		this.form = this.inputs.form;
		const tipoDesvioSemTratamento = this.form.get(FormularioRelatorio.TIPO)
			?.value;
		this.tipoDesvio = this.capitalizeFirtsLetterSecundWord(
			tipoDesvioSemTratamento
		) as keyof DesvioDTO;
		setTimeout(() => {
			this.form.patchValue({
				dataInicio: this.calcularDataInicio(),
				dataFim: new Date(),
			});
			this.setValidators();
		}, 0);
		this.initialValues();
	}

	calcularDataInicio(): Date {
		const dataInicio = new Date();
		dataInicio.setMonth(dataInicio.getMonth() - 6);
		return dataInicio;
	}

	setValidators() {
		this.form.get(FormularioRelatorio.TIPO)?.setValidators(Validators.required);
		this.form
			.get(FormularioRelatorio.PERIODO)
			?.setValidators(Validators.required);
	}

	initialValues() {
		const pbGeoJsonObservable = this.httpClient.get<FeatureCollection>(
			'assets/geoJson/pb-geo.json'
		);
		pbGeoJsonObservable.subscribe(value => {
			this.regiao = (<Polygon>value.features[0].geometry)
				.coordinates as Position[][];
		});
	}

	gerarRelatorio() {
		if (this.form.invalid) {
			return;
		}

		const dataInicio = this.form.get('dataInicio')?.value;
		const dataFim = this.form.get('dataFim')?.value;

		if (!dataInicio || !dataFim) {
			return;
		}

		this.inputs.setLoading(true);
		this.carregando = true;

		this.relatoriosService
			.relatorioMapaInterpolacaoDesvio(dataInicio, dataFim)
			.subscribe({
				next: (resp: DesvioDTO[]) => {
					this.medicoes = resp;
					this.interpolarValores();
					this.carregando = false;
					this.inputs.setLoading(false);
				},
				error: erro => {
					this.carregando = false;
					this.inputs.setLoading(false);
					const msg_erro = obter_erro_request(erro);
					this.toast.error(msg_erro, 'Erro ao obter informações de Desvio');
				},
			});
	}

	capitalizeFirtsLetterSecundWord(input: string): string {
		const words = input.toLowerCase().split('_');
		let camelCaseString = words[0];
		for (let i = 1; i < words.length; i++) {
			camelCaseString += words[i].charAt(0).toUpperCase() + words[i].slice(1);
		}
		return camelCaseString;
	}

	async interpolarValores() {
		try {
			const [interpolacaoDesvio] = await Promise.all([
				this.criarImagemInterpolacao(this.tipoDesvio),
			]);

			this.interpolacaoDesvio = interpolacaoDesvio as InterpolacaoCanvasBounds;
		} catch (error) {
			console.error('Erro ao interpolar valores:', error);
		}
	}

	criarImagemInterpolacao(
		tipoDesvio: keyof DesvioDTO
	): Promise<InterpolacaoCanvasBounds> {
		return new Promise((resolve, reject) => {
			const interpolacao = criarCanvasInterpolacao(
				this.valoresParaInterpolacao(tipoDesvio),
				tipoDesvio as VariavelMeteorologica,
				this.regiao as number[][][],
				0.01,
				true
			);
			if (interpolacao === undefined || interpolacao === null) {
				reject(`Erro ao criar imagem de interpolação de ${tipoDesvio}.`);
			}
			resolve(interpolacao);
		});
	}

	valoresParaInterpolacao(
		tipoDesvio: keyof DesvioDTO
	): ValorDesvioInterpolacao[] {
		return this.medicoes.map(value => ({
			lat: value.latitude,
			lng: value.longitude,
			value: value[tipoDesvio] as number,
			nomeMunicipio: value.nomeMunicipio,
			desvio: tipoDesvio,
		}));
	}
}
