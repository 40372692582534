<div
	class="offcanvas offcanvas-end border-start-0"
	tabindex="-1"
	id="notifications">
	<div class="offcanvas-header py-0">
		<h5 class="offcanvas-title py-3">Notificações</h5>
		<button
			type="button"
			class="btn btn-light btn-sm btn-icon border-transparent rounded-pill"
			data-bs-dismiss="offcanvas">
			<i class="ph-x"></i>
		</button>
	</div>

	<div class="offcanvas-body p-0">
		<div class="bg-light fw-medium py-2 px-3">Novas notificações</div>
		<div class="p-3">
			<div class="text-center">
				<div class="spinner-border" role="status">
					<span class="visually-hidden">Loading...</span>
				</div>
			</div>
		</div>
	</div>
</div>
