import { Boletim } from '@home/submodulos/dados-meteorologicos/interfaces/boletim';
import { PrecipitacaoMunicipio } from '@componentes/mapa-paraiba-svg/mapa.interfaces';

export const formataBoletimParaMapaSvg = (boletim: Boletim) => {
	const geocodigos: PrecipitacaoMunicipio[] = [];
	boletim.chuvasPostosPorMesorregiao.forEach(postoPorMeso => {
		postoPorMeso.precipitacaoPostos.forEach(precipitacaoPostos => {
			geocodigos.push({
				precipitacao: precipitacaoPostos.precipitacaoAcumulada,
				geocodigo: precipitacaoPostos.detalhesPosto.codigoMunicipio,
			});
		});
	});
	return geocodigos;
};
