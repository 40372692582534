export enum RoutesFront {
	ADMINISTRACAO = '/administracao',
	METEOROLOGIA = '/meteorologia',
	RECURSOS_HIDRICOS = '/recursos-hidricos',
	HOME = '/',
	DADOS_METEOROLOGICOS = 'dados-meteorologicos',
	DADOS_HIDROLOGICOS = 'dados-hidrologicos',
	PREVISAO_TEMPO = 'previsao-tempo',
	ZONEAMENTOS_AGRICOLA = 'zoneamentos-agricola',
	PREVISAO_CLIMATICA = 'previsao-climatica',
	IMAGENS_SATELITE = 'imagens-satelite',
	VERANICO = 'veranico',
	RESERVATORIOS = 'reservatorio',
	CONFIGURACAO_VERANICO = 'configuracao-veranico',
	CONFIGURACAO_CLASSIFICACAO_RESERVATORIO = 'configuracao-classificacao-reservatorio',
	HISTORICOS_RESERVATORIO = 'historicos-reservatorio',
	IMAGENS_DO_SISTEMA = 'imagens-sistema',
}
