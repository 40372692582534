import { Injectable } from '@angular/core';
import { HttpClientProtectedService } from '@core/services/auth/http-client-protected.service';
import { Rotas } from '@core/enums/routes';
import { Regiao } from '@administracao/submodulos/ranges-quantis/interfaces/regiao';

@Injectable({
	providedIn: 'root',
})
export class RegiaoPluviometricaService {
	constructor(private http: HttpClientProtectedService) {}
	listarRegioesPluviometricas() {
		return this.http.get<Regiao[]>(Rotas.REGIAO_PLUVIOMETRICA);
	}
}
