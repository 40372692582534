<div
	class="py-2 px-2 d-flex flex-column justify-content-center align-items-center bg-white"
	style="border-radius: 5px">
	<div class="text-tertiary fw-semibold fs-4">
		{{
			conteudoPopup.nome.length > 18
				? (conteudoPopup.nome | slice: 0 : 18) + '...'
				: conteudoPopup.nome
		}}
	</div>
	<div
		class="d-inline-flex align-items-start text-tertiary gap-2 mt-1"
		style="height: 215px">
		<div
			style="min-width: fit-content"
			class="d-flex flex-column h-100 justify-content-between">
			<div
				style="min-width: 120px; height: 60%"
				class="d-flex flex-column justify-content-between align-items-center">
				<seira-circular-progress-percentege
					class="mt-2"
					[percentage]="conteudoPopup.volume.porcentagem"
					[color]="corPorVolume(conteudoPopup.volume.porcentagem)" />
				<seira-public-a
					class="mt-auto"
					color="blue"
					(click)="abrirModalReservatorio()"
					customClass="mx-0 fw-normal"
					>Dados Diários</seira-public-a
				>
			</div>
			<div class="d-flex flex-column align-items-center">
				<div class="font-size-12 text-tertiary-light">Volume atual:</div>
				<div>{{ numberToBrNumber(conteudoPopup.volume.volumeAtual) }} m³</div>
			</div>
			<div class="d-flex flex-column align-items-center">
				<div class="font-size-12 text-tertiary-light">Capacidade:</div>
				<div>{{ numberToBrNumber(conteudoPopup.capacidade) }} m³</div>
			</div>
			<div class="d-flex flex-column align-items-center">
				<div class="font-size-12 text-tertiary-light">Última medição:</div>
				<div>{{ dataRegistro | date: 'dd/MM/yyyy' }}</div>
			</div>
		</div>
	</div>
</div>
