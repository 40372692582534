import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';
import * as Highcharts from 'highcharts/highstock';
import {
	optionsChartReservatorio,
	setXGraficoReservatoriosByArrayOfDates,
} from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/componentes/modal-reservatorios/graficos-reservatorios/chart-options';
import { ReservatorioVolume } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/interfaces/reservatorios';
import { TiposDatePicker } from '@shared/interfaces/date-picker';

interface ObjUnidades {
	sigla: string;
	field: string;
	label: string;
}
enum CoresGrafico {
	AZUL = '#0D6A92',
	VERMELHO = '#8E3623',
}
@Component({
	selector: 'seira-graficos-reservatorios',
	templateUrl: './graficos-reservatorios.component.html',
	styleUrls: ['./graficos-reservatorios.component.scss'],
})
export class GraficosReservatoriosComponent implements OnInit, OnChanges {
	@Input() tipoGrafico!: string;
	@Input() unidade!: keyof ReservatorioVolume;
	@Input() dados!: Array<ReservatorioVolume>;
	@Input() title!: string;
	@Input() loading = false;
	@Output() emitterVolume = new EventEmitter<Highcharts.Chart>();
	@Output() emitterAfluencia = new EventEmitter<Highcharts.Chart>();
	@Input() periodo!: TiposDatePicker;
	chartVolume!: Highcharts.Chart;
	chartAfluencia!: Highcharts.Chart;
	optionsVolume!: Highcharts.Options;
	optionsAfluencia!: Highcharts.Options;

	unidadesGraficos: { [p: string]: ObjUnidades } = {
		porcentagem: { sigla: '%', field: 'porcentagem', label: 'Vol. total (%)' },
		volumeAtual: {
			sigla: 'm³',
			field: 'volumeAtual',
			label: 'Vol. atual (m³)',
		},
	};

	ngOnInit() {
		this.optionsVolume = optionsChartReservatorio([], this.periodo);
		this.optionsAfluencia = optionsChartReservatorio([], this.periodo);
	}
	ngOnChanges(changes: SimpleChanges) {
		if (!this.chartVolume) return;
		if (changes['loading']) {
			this.chartVolume.showLoading();
			this.chartAfluencia.showLoading();
		}
		if (changes['loading'] && !this.loading) {
			this.chartVolume.hideLoading();
			this.chartAfluencia.hideLoading();
			this.setChartVolume();
			this.setChartAfluencia();
		}
		if (changes['tipoGrafico']) {
			this.setChartVolume();
			this.setChartAfluencia();
		}
		if (changes['unidade']) {
			this.setChartVolume();
		}
	}

	intanceChartVolume(chart: Highcharts.Chart) {
		this.emitterVolume.emit(chart);
		this.chartVolume = chart;
		this.setChartVolume();
	}

	intanceChartAfluencia(chart: Highcharts.Chart) {
		this.emitterAfluencia.emit(chart);
		this.chartAfluencia = chart;
		this.setChartAfluencia();
	}
	setChartAfluencia() {
		this.chartAfluencia.series.forEach(serie => serie.remove());

		let data = this.dados
			.filter(el => el.afluencia != null && el.afluencia)
			.sort((a, b) => a.dataColeta - b.dataColeta);

		const afluencias = data.filter(
			el => el.afluencia != undefined && el.afluencia >= 0
		);
		const defluencias = data.filter(
			el => el.afluencia != undefined && el.afluencia < 0
		);

		const optionsAfluencia: any = {
			data: afluencias.map(dado => ({
				x: dado.dataColeta,
				y: dado.afluencia,
			})),
			marker: {
				symbol: 'square',
			},
			name: 'Afluência (m³)',
			color: CoresGrafico.AZUL,
			type: this.tipoGrafico === 'steps' ? 'line' : this.tipoGrafico,
			step: this.tipoGrafico === 'steps',
		};

		const optionsDefluencia: any = {
			data: defluencias.map(dado => ({
				x: dado.dataColeta,
				y: dado.afluencia,
			})),
			marker: {
				symbol: 'square',
			},
			name: 'Defluência (m³)',
			color: CoresGrafico.VERMELHO,
			type: this.tipoGrafico === 'steps' ? 'line' : this.tipoGrafico,
			step: this.tipoGrafico === 'steps',
		};

		this.chartAfluencia.update({
			yAxis: [
				{
					title: {
						text: 'Afluência (m³)',
						align: 'high',
					},
				},
			],
			xAxis: [
				{
					title: {
						align: 'high',
						text: `Período`,
					},
					type: 'datetime',
				},
			],
		});
		this.chartAfluencia.series.forEach(serie => serie.remove());
		this.chartAfluencia.addSeries(optionsAfluencia, false);
		this.chartAfluencia.addSeries(optionsDefluencia, true);
	}

	setChartVolume() {
		this.chartVolume.series.forEach(serie => serie.remove());

		let data = this.dados
			.filter(el => el.volumeAtual != null)
			.sort((a, b) => a.dataColeta - b.dataColeta);

		this.chartVolume.xAxis.forEach(axis => axis.remove());
		this.chartVolume.addAxis(
			setXGraficoReservatoriosByArrayOfDates(
				data.map(el => el.dataColeta),
				this.periodo
			),
			true
		);

		const options: any = {
			data: data.map(dado => ({
				x: new Date(dado.dataColeta).getTime(),
				y: dado[this.unidade],
			})),
			marker: {
				symbol: 'square',
			},
			name: this.unidadesGraficos[this.unidade].label,
			type: this.tipoGrafico === 'steps' ? 'line' : this.tipoGrafico,
			step: this.tipoGrafico === 'steps',
			color: CoresGrafico.AZUL,
		};

		this.chartVolume.update({
			yAxis: [
				{
					title: {
						align: 'high',
						text: `(${this.unidadesGraficos[this.unidade].sigla})`,
					},
				},
			],
			xAxis: [
				{
					title: {
						align: 'high',
						text: `Período`,
					},
					type: 'datetime',
				},
			],
		});

		this.chartVolume.series.forEach(serie => serie.remove());
		this.chartVolume.addSeries(options, true);
	}
}
