<div *ngIf="options" id="highchartsGrafico" style="height: 100%; flex: 1">
	<highcharts-chart
		#highchartsGrafico
		[Highcharts]="Highcharts"
		[callbackFunction]="chartCallback"
		[options]="options"
		[constructorType]="'stockChart'"
		[(update)]="updateFlag"
		[style]="styleChart">
	</highcharts-chart>
</div>
