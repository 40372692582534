import { Component, Input } from '@angular/core';
import {
	DadosEvapotranspiracao,
	Relatorios,
} from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import { ADTColumns } from 'angular-datatables/src/models/settings';
import { format } from 'date-fns';
import { isNotNuloOuUndefined, numberToBrNumber } from '@utils';

@Component({
	selector: 'seira-tabela-relatorio-evapotranspiracao',
	templateUrl: './tabela-relatorio-evapotranspiracao.component.html',
	styleUrls: ['./tabela-relatorio-evapotranspiracao.component.scss'],
})
export class TabelaRelatorioEvapotranspiracaoComponent {
	@Input() relatorios: Relatorios<DadosEvapotranspiracao>[] = [];
	colunas: ADTColumns[] = [
		{
			data: 'dataColeta',
			title: 'Data',
			type: 'string',
			className: 'text-start',
			render: (data: string, type: string) => {
				if (type === 'display') {
					return format(new Date(data), 'dd/MM/yyyy HH:mm');
				}
				return new Date(data).getTime();
			},
		},
		{
			data: 'evapotranspiracaoPotencial',
			title: 'Evapotranspiração potencial (mm)',
			type: 'number',
			className: 'text-end',
			render: evapotranspiracao =>
				isNotNuloOuUndefined(evapotranspiracao)
					? numberToBrNumber(evapotranspiracao)
					: '-',
		},
	];
}
