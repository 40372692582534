<div class="accordion" id="graficosReservatorios">
	<div class="accordion-item">
		<h2 class="accordion-header" id="volumeHeader">
			<button
				class="accordion-button fs-6"
				type="button"
				data-bs-toggle="collapse"
				data-bs-target="#volumeBody"
				aria-expanded="true"
				aria-controls="volumeBody">
				Volume
			</button>
		</h2>
		<div
			id="volumeBody"
			class="accordion-collapse collapse show"
			aria-labelledby="volumeHeader"
			data-bs-parent="#graficosReservatorios">
			<div class="accordion-body">
				<seira-high-stock
					[options]="optionsVolume"
					(chartInstance)="intanceChartVolume($event)"></seira-high-stock>
			</div>
		</div>
	</div>
	<div class="accordion-item">
		<h2 class="accordion-header" id="afluenciaHeader">
			<button
				class="accordion-button collapsed fs-6"
				type="button"
				data-bs-toggle="collapse"
				data-bs-target="#afluencia"
				aria-expanded="false"
				aria-controls="afluencia">
				Afluência / defluência
			</button>
		</h2>
		<div
			id="afluencia"
			class="accordion-collapse collapse"
			aria-labelledby="afluenciaHeader"
			data-bs-parent="#graficosReservatorios">
			<div class="accordion-body">
				<seira-high-stock
					[options]="optionsAfluencia"
					(chartInstance)="intanceChartAfluencia($event)"></seira-high-stock>
			</div>
		</div>
	</div>
</div>
