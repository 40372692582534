import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ValoresFormularioService {
	private filtroModalAtivo = new BehaviorSubject<string>('');
	filtroModalAtivo$ = this.filtroModalAtivo.asObservable();

	atualizarDados(novosDados: string) {
		this.filtroModalAtivo.next(novosDados);
	}
}
