import { Component, HostListener, Input } from '@angular/core';

@Component({
	selector: 'seira-legenda-estacoes',
	templateUrl: './legenda-estacoes.component.html',
	styleUrls: ['./legenda-estacoes.component.scss'],
})
export class LegendaEstacoesComponent {
	isBigScreen: boolean = window.innerWidth > 1200;

	@Input()
	legendaComIconePCD: boolean = true;
	@HostListener('window:resize', ['$event'])
	onResize(event: Event): void {
		this.isBigScreen = window.innerWidth > 1200;
	}
}
