import { microrregioesComRanges } from '@componentes/mapa-paraiba-svg/svg-paths';

export enum LegendaCores {
	BRANCO_NEVE = '#f7f2e4',
	AMARELO = '#F8FAA9',
	AZUL = '#8CB0E5',
	AZUL_CLARO = '#CEDCF0',
	AZUL_CLARO_INTENSO = '#AFC9EF',
	AZUL_ESCURO = '#6594DC',
	LARANJA = '#FAD9A9',
	VERDE = '#BDDE93',
	VERDE_CLARO = '#D6FAA9',
	VERDE_ESCURO = '#9ABE6D',
	VERMELHO = '#FAB8A9',
	VERMELHO_ESCURO = '#FD9B84',
}

export const legendaInfo: { [key: number]: string } = {
	0: LegendaCores.BRANCO_NEVE,
	1: LegendaCores.VERMELHO_ESCURO,
	2: LegendaCores.VERMELHO,
	5: LegendaCores.LARANJA,
	10: LegendaCores.AMARELO,
	20: LegendaCores.VERDE_CLARO,
	30: LegendaCores.VERDE,
	40: LegendaCores.VERDE_ESCURO,
	50: LegendaCores.AZUL_CLARO,
	100: LegendaCores.AZUL_CLARO_INTENSO,
	200: LegendaCores.AZUL,
	1000000: LegendaCores.AZUL_ESCURO,
};

export const CORES = {
	BRANCO_NEVE: '#f7f2e4',
	VERMELHO: '#FD9B84',
	AMARELO: '#F8FAA9',
	AZUL_CLARO: '#AFC9EF',
	AZUL: '#8CB0E5',
	AZUL_ESCURO: '#6594DC',
	CINZA: '#D4D4D4',
};

export const legendaRanges: { [key: number]: string } = {};

export const coresPadrao = [
	CORES.BRANCO_NEVE,
	CORES.VERMELHO,
	CORES.AMARELO,
	CORES.AZUL_CLARO,
	CORES.AZUL,
	CORES.AZUL_ESCURO,
	CORES.CINZA,
];

microrregioesComRanges.forEach(microrregiao => {
	microrregiao.ranges.forEach((range, index) => {
		legendaRanges[range] = coresPadrao[index];
	});
});
