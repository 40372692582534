<div class="navbar navbar-sm navbar-footer border-top w-100">
	<div class="container-fluid justify-content-center justify-content-sm-start">
		<span
			>&copy; {{ ano }}
			<a href="{{ url }}" target="_blank"> {{ nome }} </a></span
		>
		<div
			class="nav d-flex flex-fill flex-column flex-sm-row align-items-center justify-content-center justify-content-sm-end">
			<div *ngIf="versao$ | async as versao; else carregandoVersao">
				<div
					[ngSwitch]="versao"
					class="navbar-text text-muted me-sm-3 pt-2 pb-2 pt-sm-2 pb-sm-2 pe-sm-3 ps-sm-3 border-start-sm border-end-sm">
					<i
						*ngSwitchCase="'?'"
						class="ph-warning"
						container="body"
						tooltip="Houve um problema se comunicar com o servidor"></i>
					<i
						*ngSwitchCase="'offline'"
						class="ph-cloud-slash"
						container="body"
						tooltip="Servidor off-line"></i>
					<span *ngSwitchDefault>{{ versao }}</span>
				</div>
			</div>
			<ng-template #carregandoVersao>
				<div
					class="text-center me-sm-3 navbar-text pt-2 pb-2 pt-sm-2 pb-sm-2 pe-sm-3 ps-sm-3 border-start-sm border-end-sm">
					<div class="spinner-border text-muted" role="status"></div>
				</div>
			</ng-template>
			<img
				src="./assets/images/logomarca_aesa.-removebg-preview (2).png"
				class="h-40px invert-dark"
				alt="" />
			<img
				src="../../../../../assets/images/logoParaiba.png"
				class="h-48px invert-dark"
				alt="" />
		</div>
	</div>
</div>
