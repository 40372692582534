import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { User } from '@core/interfaces/user';
import { UserService } from '@core/services/user/user.service';
import { AdminSelectOptions } from './admin-select-options';
import {
	getMenuOptions,
	menuItemsAdministracao,
	menuItemsMeteorologia,
	menuItemsRecursosHidricos,
} from '@layout/componentes/header/menu-data';
import { UsuarioService } from '@services/usuario/usuario.service';

@Component({
	selector: 'seira-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
	dropdownVisible = true;
	user$: Observable<User | null>;
	menuItems: any[] = [
		{
			items: menuItemsAdministracao,
			label: 'Administração',
			icon: 'ph-gear-six',
			submenu: [],
		},
		{
			items: menuItemsMeteorologia,
			label: 'Meteorologia',
			icon: 'ph-cloud-sun',
			submenu: [],
		},
		{
			items: menuItemsRecursosHidricos,
			label: 'Recursos Hídricos',
			icon: 'ph-drop',
			submenu: [],
		},
	];

	activeItem: any = {};

	constructor(
		private userService: UserService,
		private usuarioService: UsuarioService,
		private router: Router
	) {
		this.user$ = userService.getUser();
	}

	ngOnInit() {
		this.usuarioService.listarPermissoesDoUsuario().subscribe({
			next: () => {
				this.menuItems.forEach(menu => {
					const menuItems = this.setMenu(menu.items);
					if (menuItems.length) {
						menu.submenu = menuItems;
					}
				});
			},
		});
	}

	setMenu(menuKey: AdminSelectOptions[]) {
		const selectOptions = getMenuOptions(menuKey);
		if (selectOptions.length) {
			this.findCurrentPathOnMenu(selectOptions);
		}
		return selectOptions;
	}

	findCurrentPathOnMenu(menuOptions: AdminSelectOptions[]) {
		const url = this.router.url;
		const currentPathOnMenu = menuOptions.find(menu =>
			menu.submenu.some(grupo =>
				grupo.options.some(option =>
					option.path !== '' ? url.includes(option.path) : false
				)
			)
		);

		if (currentPathOnMenu?.href) {
			this.activeItem[currentPathOnMenu?.href] = currentPathOnMenu?.href;
		} else {
			this.activeItem[menuOptions[0].href] = menuOptions[0].href;
		}
	}

	logout() {
		this.userService.logout();
		this.router.navigate(['/']);
	}

	hideDropdown() {
		this.dropdownVisible = false;
		setTimeout(() => {
			this.dropdownVisible = true;
		}, 10);
	}
}
