<div>
	<div class="d-flex align-items-center">
		<seira-page-title class="w-100 align-items-center">
			<div class="d-flex justify-content-between align-items-center">
				<div
					style="color: #728187; font-weight: 400; font-size: 24px !important">
					Dados meteorológicos
					<i class="dot bg-primary dot-space"></i>
					Gerador de relatórios
				</div>
				<div class="d-flex justify-content-end">
					<seira-public-page-about
						class="size-about"
						[textosInformativos]="informativo"></seira-public-page-about>
				</div>
			</div>
		</seira-page-title>
	</div>

	<form
		[formGroup]="form"
		class="form-group"
		[ngSwitch]="getFormItemValue('formato')">
		<div id="filtros" class="col-12 flex-row gap-3">
			<div class="row mt-4">
				<div class="col-lg-3 col-md-4 col-sm-6">
					<seira-public-select
						placeholder="Selecione uma categoria"
						label="Categoria"
						[options]="categorias"
						optionValue="value"
						optionLabel="name"
						notFoundText="Nenhuma categoria encontrada"
						formControlName="categoria">
					</seira-public-select>
				</div>
				<div class="col-lg-3 col-md-4 col-sm-6">
					<seira-public-select
						placeholder="Selecione um formato"
						label="Formato"
						[options]="formatos"
						notFoundText="Nenhum formato encontrado"
						optionValue="value"
						optionLabel="name"
						formControlName="formato">
					</seira-public-select>
				</div>

				<div *ngIf="categoriaPluviometrico" class="col-lg-3 col-md-4 col-sm-6">
					<seira-public-select
						placeholder="Selecione um tipo"
						label="Tipo"
						[options]="tipos"
						optionValue="value"
						optionLabel="name"
						notFoundText="Nenhum tipo encontrado"
						formControlName="tipo">
					</seira-public-select>
				</div>
				<div
					*ngIf="mostrarSelectVariaveisGrafico"
					class="col-lg-3 col-md-4 col-sm-6">
					<seira-public-select
						placeholder="Selecione uma variável"
						label="Variável"
						[options]="variaveis"
						optionValue="value"
						optionLabel="name"
						notFoundText="Nenhuma variável encontrado"
						formControlName="variavel">
					</seira-public-select>
				</div>
				<div
					*ngIf="mostrarDateRangePicker"
					class="col-lg-3 col-md-4 col-sm-6 d-flex flex-row align-items-end gap-1">
					<seira-public-date-range-picker
						style="width: 100%"
						label="Período"
						controlStartName="dataInicio"
						controlEndName="dataFim"
						startPlaceholder="Inicio"
						endPlaceholder="Fim" />
				</div>

				<ng-container *ngIf="mostrarDateRangePickerAno">
					<div class="col-lg-1-5 col-md-4 col-sm-6 d-flex flex-column">
						<seira-public-date-picker
							class="w-100"
							label="Ano inicial"
							placeholder="Selecione o ano inicial"
							controlName="dataInicio"
							datePickerType="year"
							[maxDate]="
								tipoPrecipitacaoAnual ? anoInicialMaxDate : dataMinimaSPI
							" />
					</div>
					<div
						class="col-lg-1-5 col-md-4 col-sm-6 d-flex flex-row align-items-end">
						<seira-public-date-picker
							class="w-100"
							label="Ano final"
							placeholder="Selecione o ano final"
							controlName="dataFim"
							datePickerType="year" />
					</div>
				</ng-container>
				<div
					*ngIf="mostrarDatePickerAno"
					class="col-lg-3 col-md-4 col-sm-6 d-flex flex-row align-items-end gap-1">
					<seira-public-date-picker
						style="width: 100%"
						label="Período"
						placeholder="Selecione um período"
						controlName="periodo"
						datePickerType="year" />
				</div>
				<div
					*ngIf="mostrarDatePickerMesAno"
					class="col-lg-3 col-md-4 col-sm-6 d-flex flex-row align-items-end gap-1">
					<seira-public-date-picker
						style="width: 100%"
						label="Período"
						placeholder="Selecione um período"
						controlName="periodo"
						datePickerType="month" />
				</div>
				<div
					class="col-lg-3 col-md-4 col-sm-6 mt-auto"
					*ngIf="mostrarSelectDePostos">
					<seira-public-select
						placeholder="Selecione um posto"
						label="Posto"
						[searchable]="true"
						[options]="options"
						optionValue="value"
						optionLabel="label"
						notFoundText="Nenhum posto encontrado"
						formControlName="estacao">
					</seira-public-select>
				</div>
				<div
					class="col-lg-3 col-md-4 col-sm-6 mt-auto"
					*ngIf="mostrarMicrorregioes">
					<seira-public-select
						placeholder="Selecione uma microrregião"
						label="Microrregião"
						optionValue="value"
						optionLabel="name"
						notFoundText="Nenhuma microrregião encontrada"
						formControlName="microrregiao"
						[searchable]="true"
						[options]="microrregioes">
					</seira-public-select>
				</div>
				<ng-container *ngIf="mostrarSelectsVariaveisMeteorologicasMapa">
					<div
						class="col-lg-3 col-md-4 col-sm-6 d-flex flex-row align-items-end gap-1">
						<seira-public-select
							[options]="variaveisMapa"
							optionLabel="label"
							optionValue="value"
							style="width: 100%"
							label="Variável"
							placeholder="Selecione uma variável"
							[formControlName]="FormularioRelatorio.VARIAVEL_MAPA" />
					</div>
					<div
						class="col-lg-3 col-md-4 col-sm-6 d-flex flex-row align-items-end gap-1">
						<seira-public-select
							[options]="tiposPeriodos"
							style="width: 100%"
							optionValue="value"
							optionLabel="name"
							label="Tipo de período"
							placeholder="Selecione um tipo de período"
							formControlName="tipoPeriodo" />
					</div>
				</ng-container>
				<ng-container *ngIf="mostrarDateRangePickerMes">
					<div
						class="col-lg-3 col-md-4 col-sm-6 d-flex flex-row align-items-end gap-1">
						<seira-public-date-picker
							[id]="'dataInicio'"
							style="width: 100%"
							label="Data de início"
							placeholder="Início"
							controlName="dataInicio"
							datePickerType="month" />
					</div>
					<div
						class="col-lg-3 col-md-4 col-sm-6 d-flex flex-row align-items-end gap-1">
						<seira-public-date-picker
							[id]="'dataFim'"
							style="width: 100%"
							label="Data de fim"
							placeholder="Selecione uma data"
							controlName="dataFim"
							[minDate]="calcularDataInicio"
							[maxDate]="calcularDataFim"
							datePickerType="month" />
					</div>
				</ng-container>
				<div
					class="col-lg-3 col-md-4 col-sm-6 mt-1"
					*ngIf="mostrarSelectMapaDePrecipitacoes">
					<seira-public-select
						placeholder="Selecione uma métrica"
						label="Mapa de precipitação"
						optionValue="value"
						optionLabel="name"
						formControlName="precipitacao"
						[searchable]="true"
						[options]="precipitacoes">
					</seira-public-select>
				</div>
				<div
					*ngIf="!mostrarRadioPostos"
					class="col mt-auto d-flex justify-content-between align-items-end"
					style="margin-top: 1rem !important">
					<ng-container *ngTemplateOutlet="botaoGerarRelatorio"></ng-container>
					<div class="mr-auto">
						<seira-public-button-group
							[disabledButtons]="loadingEstacoes || loadingForm"
							[buttons]="botoesDeExportacao">
						</seira-public-button-group>
					</div>
				</div>
				<div
					*ngIf="mostrarRadioPostos"
					class="d-flex flex-column flex-lg-row justify-content-lg-between align-items-lg-end mt-2 gap-3">
					<div class="d-flex flex-column flex-md-row align-items-md-end gap-3">
						<div class="d-flex align-items-end gap-3">
							<seira-public-radio-group
								idRadio="opcoesPostosRelatorios"
								[options]="OpcoesPostos"
								label="Postos"
								controlName="posto">
							</seira-public-radio-group>
							<seira-button
								*ngIf="getFormItemValue('posto') === 'personalizado'"
								classButton="btn btn-link p-0"
								type="button"
								buttonStyle="custom"
								(clicked)="openModal()"
								>Editar...</seira-button
							>
						</div>
						<ng-container
							*ngTemplateOutlet="botaoGerarRelatorio"></ng-container>
					</div>
					<seira-public-button-group
						[disabledButtons]="loadingEstacoes || loadingForm"
						[buttons]="botoesDeExportacao"></seira-public-button-group>
				</div>
			</div>
		</div>

		<ng-template #botaoGerarRelatorio>
			<seira-public-button
				[disabledButton]="loadingEstacoes || form.invalid || !instanceRelatorio"
				[loading]="loadingForm"
				(clicked)="gerarRelatorio()"
				>Gerar relatório</seira-public-button
			>
		</ng-template>
		<ng-container #dynamicComponentContainer></ng-container>
	</form>
	<div *seiraIfViewportSize="'desktop'" class="row">
		<div class="col-lg-6">
			<seira-info-gerais
				id="informacoes-gerais"
				[textosInformativos]="informativo"></seira-info-gerais>
		</div>
	</div>
</div>
