import { ADTSettings } from 'angular-datatables/src/models/settings';

export interface ADTSettingsWithButton extends ADTSettings {
	buttons?: Array<any>;
}

export class TableButtons {
	selectAll = false;
	unSelectAll = false;
	aprove = false;
	aproveAction: (selecteds: Array<any>) => void = () => {};
	remove = false;
	removeAction: (selecteds: Array<any>) => void = () => {};
	copy = false;
	csv = false;
}
export class HeaderTableStyle {
	thClass = '';
	radius = false;
	radiusCoeficient = '';
}
