import { Component, Input } from '@angular/core';

@Component({
	selector: 'seira-sem-dados',
	templateUrl: './sem-dados.component.html',
	styleUrls: ['./sem-dados.component.scss'],
})
export class SemDadosComponent {
	@Input() loading: boolean = false;
}
