import { AfterViewInit, Component, HostBinding } from '@angular/core';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
	@HostBinding('class') clazz = 'expandir-flex';
	title = 'seira';

	ngAfterViewInit() {
		$('#app-loading').hide();
	}
}
