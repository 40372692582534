<div class="navbar navbar-dark bg-teal navbar-expand-xl navbar-static">
	<div class="container-fluid">
		<div class="navbar-brand">
			<a routerLink="/" class="d-inline-flex align-items-center">
				<img src="./assets/images/logo_1.svg" alt="logo" style="height: 35px" />
			</a>
		</div>

		<div
			class="d-flex w-100 w-xl-auto overflow-auto overflow-xl-visible scrollbar-hidden border-top border-top-xl-0 order-1 order-xl-0 pt-2 pt-xl-0 mt-2 mt-xl-0">
			<ul
				class="nav gap-1 justify-content-center flex-nowrap flex-xl-wrap mx-auto">
				<li class="nav-item">
					<a
						routerLink="/"
						class="navbar-nav-link rounded"
						routerLinkActive="active">
						<i class="ph-globe me-2"></i>
						Área pública
					</a>
				</li>
				<ng-container *ngFor="let menu of menuItems">
					<li class="nav-item nav-item-dropdown" *ngIf="dropdownVisible">
						<a
							href="#"
							class="navbar-nav-link dropdown-toggle rounded"
							data-bs-toggle="dropdown"
							data-bs-auto-close="outside">
							<i [class]="menu.icon + ' me-2'"></i>
							{{ menu.label }}
						</a>
						<div class="dropdown-menu p-0">
							<div class="d-xl-flex">
								<div
									class="d-flex flex-row flex-xl-column bg-light overflow-auto overflow-xl-visible rounded-top rounded-top-xl-0 rounded-start-xl">
									<div
										class="flex-1 border-bottom border-bottom-xl-0 p-2 p-xl-3">
										<div
											class="fw-semibold border-bottom d-none d-xl-block pb-2 mb-2">
											{{ menu.label }}
										</div>
										<ul
											class="nav nav-pills flex-xl-column flex-nowrap text-nowrap justify-content-center wmin-xl-300"
											role="tablist">
											<li
												class="nav-item"
												role="presentation"
												*ngFor="let option of menu.submenu">
												<a
													[href]="'#' + option.href"
													class="nav-link rounded"
													[ngClass]="
														option.href === activeItem[option.href]
															? 'active'
															: ''
													"
													data-bs-toggle="tab"
													aria-selected="true"
													role="tab">
													<i [class]="option.icon + ' me-2'"></i>
													{{ option.label }}
													<i
														class="ph-arrow-right nav-item-active-indicator d-none d-xl-inline-block ms-auto"></i>
												</a>
											</li>
										</ul>
									</div>
								</div>
								<div class="tab-content flex-xl-1" style="min-height: 320px">
									<div
										*ngFor="let option of menu.submenu"
										class="tab-pane dropdown-scrollable-xl fade show p-3"
										[ngClass]="
											option.href === activeItem[option.href] ? 'active' : ''
										"
										[id]="option.href"
										role="tabpanel"
										(click)="hideDropdown()">
										<div class="row">
											<ng-container *ngFor="let column of option.submenu">
												<div class="col-lg-6 mt-3">
													<div class="fw-semibold border-bottom pb-2 mb-2">
														{{ column.title }}
													</div>
													<a
														*ngFor="let menu of column.options"
														[routerLink]="menu.path"
														class="dropdown-item rounded"
														>{{ menu.label }}</a
													>
												</div>
											</ng-container>
										</div>
									</div>
								</div>
							</div>
						</div>
					</li>
				</ng-container>
			</ul>
		</div>

		<ul class="nav gap-1 flex-xl-1 justify-content-end order-0 order-xl-1">
			<li class="nav-item">
				<a
					href="#"
					class="navbar-nav-link navbar-nav-link-icon rounded-pill"
					data-bs-toggle="offcanvas"
					data-bs-target="#notifications">
					<i class="ph-bell"></i>
					<span
						class="badge bg-yellow text-black position-absolute top-0 end-0 translate-middle-top zindex-1 rounded-pill mt-1 me-1"
						>0</span
					>
				</a>
			</li>
			<li class="nav-item nav-item-dropdown-xl dropdown">
				<a
					href="#"
					class="navbar-nav-link align-items-center rounded-pill p-1"
					data-bs-toggle="dropdown">
					<img
						src="assets/images/user.png"
						class="w-32px h-32px rounded-pill"
						alt="usuario" />
					<span
						*ngIf="user$ | async as user"
						class="d-none d-md-inline-block mx-md-2"
						>{{ user.login }}</span
					>
				</a>
				<div class="dropdown-menu dropdown-menu-end">
					<a routerLink="/perfil" class="dropdown-item">
						<i class="ph-user-circle me-2"></i>
						Meu perfil
					</a>
					<a routerLink="/auth/alterar-senha" class="dropdown-item">
						<i class="ph ph-arrows-counter-clockwise me-2"></i>
						Alterar senha
					</a>
					<div class="dropdown-divider"></div>
					<a routerLink="" class="dropdown-item" (click)="logout()">
						<i class="ph-sign-out me-2"></i>
						Logout
					</a>
				</div>
			</li>
		</ul>
	</div>
</div>
