<div class="mt-2" *ngIf="resultados.length; else semDados">
	<seira-card-info aditionalClasses="mt-3">
		Placeholder informativo
	</seira-card-info>
	<seira-legenda-relatorio-historico-variaveis
		id="legenda"
		[options]="legendaTitle"
		style="width: 100%" />
	<div class="row mt-2" *ngIf="funcaoCoresMapa">
		<div
			*ngFor="let resultado of resultados; let i = index"
			class="col-lg-3 col-md-3 col-sm-4 col-6 mb-3">
			<seira-imagem-historico-relatorio
				[idMapa]="'mapa-' + i"
				[funcaoCoresMapa]="funcaoCoresMapa"
				[legenda]="legendaTitle"
				[imagem]="resultado"></seira-imagem-historico-relatorio>
		</div>
	</div>
</div>
<ng-template #semDados>
	<seira-sem-dados />
</ng-template>
