<div class="d-flex flex-column">
	<label
		for="date-range-{{ controlStartName }}"
		*ngIf="label"
		class="form-label text-tertiary-light"
		>{{ label }}</label
	>
	<mat-form-field id="date-range-{{ controlStartName }}">
		<mat-date-range-input [rangePicker]="picker" [max]="dataMaxima">
			<input
				matStartDate
				[formControlName]="controlStartName"
				[placeholder]="startPlaceholder" />
			<input
				matEndDate
				[formControlName]="controlEndName"
				[placeholder]="endPlaceholder" />
		</mat-date-range-input>
		<mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
		<mat-date-range-picker #picker></mat-date-range-picker>
	</mat-form-field>
</div>
