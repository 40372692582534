import { TooltipFormatterContextObject } from 'highcharts';
import { isColorDarkOrLight, numberToBrNumber } from '@utils';
import { months } from '@utils/date/nomes.mock';

const gerarCalendarioBaseadoEmDados: Highcharts.TooltipOptions['formatter'] =
	function () {
		let s = `<strong>${this.x}</strong>`;
		if (this.points && this.points.length > 0) {
			const data = this.points[0].series.name.split('/');
			switch (data.length) {
				case 3:
					s += calendarioDiario(this.points);
					break;
				case 2:
					s += calendarioMensal(this.points);
					break;
				case 1:
					s += calendarioAnual(this.points);
					break;
			}
		}
		return s;
	};
const calendarioDiario = (
	points: Array<TooltipFormatterContextObject | undefined>
): string => {
	if (!points[0]) return '';

	const [diaInicial, mesInicial, anoInicial] = points[0].series.name.split('/');
	const dataInicial = new Date(+anoInicial, +mesInicial - 1, +diaInicial);
	const longMes = months.find(month => month.value === +mesInicial - 1)?.label;
	let calendario = `<br/>${longMes} de ${anoInicial}<br/>
        <div class="calendar-tooltip">
            <table>
                <thead>
                    <tr>
                        <th>Dom</th>
                        <th>Seg</th>
                        <th>Ter</th>
                        <th>Qua</th>
                        <th>Qui</th>
                        <th>Sex</th>
                        <th>Sáb</th>
                    </tr>
                </thead>
                <tbody id="calendar-body">`;

	const pontosPreenchidos = preencherDiasVazios(points, dataInicial);
	let mesAnterior: string | undefined;
	calendario += '<tr>';

	for (let index = 0; index < pontosPreenchidos.length; index++) {
		const point = pontosPreenchidos[index];
		if (!point) {
			calendario += '<td class="disabled">-</td>';
		} else {
			const [_, mes, __] = point.series.name.split('/');
			if (mesAnterior && mesAnterior !== mes) {
				calendario += fecharTabela();
				return calendario + calendarioDiario(pontosPreenchidos.slice(index));
			}
			mesAnterior = mes;
			calendario += criarCelulaCalendario(
				point.series.name,
				point.y,
				point.series.color?.toString()
			);
		}
		if ((index + 1) % 7 === 0) calendario += '</tr><tr>';
	}

	calendario += fecharTabela();
	return calendario;
};

const preencherDiasVazios = (
	points: Array<TooltipFormatterContextObject | undefined>,
	dataInicial: Date
) => {
	const pontosPreenchidos = points.slice();
	const diasVazios = new Array(dataInicial.getDay()).fill(null);
	pontosPreenchidos.unshift(...diasVazios);
	return pontosPreenchidos;
};
const calendarioMensal = (
	points: Array<TooltipFormatterContextObject>
): string => {
	if (!points[0]) return '';

	const [_, anoInicial] = points[0].series.name.split('/');
	let calendario = `<br/>${anoInicial}<br/>
        <div class="calendar-tooltip">
            <table>
                <tbody id="calendar-body">`;

	let anoAnterior: string | undefined;
	calendario += '<tr>';

	for (let index = 0; index < points.length; index++) {
		const point = points[index];
		const [_, ano] = point.series.name.split('/');
		if (anoAnterior && anoAnterior !== ano) {
			calendario += fecharTabela();
			return calendario + calendarioMensal(points.slice(index));
		}
		anoAnterior = ano;
		calendario += criarCelulaCalendario(
			point.series.name,
			point.y,
			point.series.color?.toString()
		);
		if ((index + 1) % 5 === 0) calendario += '</tr><tr>';
	}

	calendario += fecharTabela();
	return calendario;
};
const calendarioAnual = (points: Array<TooltipFormatterContextObject>) => {
	if (!points[0]) return '';

	const anoInicial = points[0].series.name;
	const anoFinal = points[points.length - 1].series.name;
	let calendario = `<br/>${anoInicial} - ${anoFinal}<br/>
        <div class="calendar-tooltip">
            <table>
                <tbody id="calendar-body">`;

	calendario += '<tr>';
	for (let index = 0; index < points.length; index++) {
		const point = points[index];
		calendario += criarCelulaCalendario(
			point.series.name,
			point.y,
			point.series.color?.toString()
		);
		if ((index + 1) % 5 === 0) calendario += '</tr><tr>';
	}

	calendario += fecharTabela();
	return calendario;
};

const fecharTabela = () => {
	return '</tr></tbody></table></div>';
};

const criarCelulaCalendario = (
	data: string,
	valor: number | null | undefined,
	cor?: string
) => {
	const periodo = data.split('/')[0];
	const corTexto = isColorDarkOrLight(cor || '') === 'clara' ? '#000' : '#fff';
	return `<td style="background-color: ${cor}; color: ${corTexto}">
        ${periodo} <br/>
        ${numberToBrNumber(valor)} mm
    </td>`;
};
export const options: Highcharts.Options = {
	navigator: {
		enabled: false,
	},
	exporting: {
		enabled: false,
	},
	rangeSelector: {
		enabled: false,
	},
	chart: {
		type: 'column',
	},
	credits: {
		enabled: false,
	},
	title: {
		text: 'Pluviometria observada',
	},
	xAxis: [{ categories: [] }],
	yAxis: [
		{
			title: {
				text: 'Precipitação (mm)',
			},
		},
	],
	tooltip: {
		shared: true,
		split: false,
		valueSuffix: ' mm',
		followPointer: true,
		useHTML: true,
		formatter: gerarCalendarioBaseadoEmDados,
	},
	plotOptions: {
		column: {
			borderRadius: '50%',
			groupPadding: 0.1,
		},
		series: {
			animation: false,
		},
	},
	series: [],
};
