import colormap from 'colormap';

const cm: string[] = colormap({
	colormap: 'rainbow',
	nshades: 27,
	format: 'hex',
	alpha: 1,
});

const precipitacaoColormap: string[] = colormap({
	colormap: 'rainbow',
	nshades: 40,
	format: 'hex',
	alpha: 1,
});

const precipitacaoCores = precipitacaoColormap
	.filter((_e, i) => i >= 8)
	.reverse()
	.filter(
		(_e, i) => ![5, 7, 10, 11, 12, 13, 14, 15, 16, 18, 20, 21].includes(i)
	);

const precipitacaoCoresDiasChuva = colormap({
	colormap: [
		{ index: 0, rgb: [255, 255, 255, 1] }, // 9
		{ index: 0.1, rgb: [253, 26, 19, 1] }, // 8
		{ index: 0.2, rgb: [255, 122, 51] }, // 7
		{ index: 0.3, rgb: [255, 218, 51, 1] }, // 6
		{ index: 0.4, rgb: [129, 255, 111, 1] }, // 5
		{ index: 0.5, rgb: [65, 205, 221, 1] }, // 4
		{ index: 0.6, rgb: [51, 173, 255, 1] }, // 3
		{ index: 0.7, rgb: [41, 48, 207, 1] }, // 2
		{ index: 0.8, rgb: [51, 132, 255, 1] }, // 1
	],
	nshades: 9,
	format: 'hex',
	alpha: 0,
});

const desvioMilimetroColormap = colormap({
	colormap: [
		{ index: 0, rgb: [254, 2, 0, 1] }, // 12
		{ index: 0.1, rgb: [253, 26, 19, 1] }, // 11
		{ index: 0.2, rgb: [254, 100, 65, 1] }, // 10
		{ index: 0.3, rgb: [254, 126, 16, 1] }, // 9
		{ index: 0.4, rgb: [255, 199, 41, 1] }, // 8
		{ index: 0.5, rgb: [255, 252, 157, 1] }, // 7
		{ index: 0.6, rgb: [203, 205, 252, 1] }, // 6
		{ index: 0.7, rgb: [149, 159, 251, 1] }, // 5
		{ index: 0.8, rgb: [96, 115, 252, 1] }, // 4
		{ index: 0.9, rgb: [0, 60, 151, 1] }, // 3
		{ index: 1.0, rgb: [0, 16, 102, 1] }, // 2
		{ index: 1.0, rgb: [49, 15, 101, 1] }, // 1
	],
	nshades: 12,
	format: 'hex',
	alpha: 0,
});

const desvioPorcentagemColormap = colormap({
	colormap: [
		{ index: 0, rgb: [254, 1, 0, 1] }, // 12
		{ index: 0.1, rgb: [252, 126, 16, 1] }, // 11
		{ index: 0.2, rgb: [255, 199, 41, 1] }, // 10
		{ index: 0.3, rgb: [255, 253, 155, 1] }, // 9
		{ index: 0.4, rgb: [231, 231, 231, 1] }, // 8
		{ index: 0.5, rgb: [203, 205, 250, 1] }, // 7
		{ index: 0.6, rgb: [149, 159, 251, 1] }, // 6
		{ index: 0.7, rgb: [100, 160, 251, 1] }, // 5
		{ index: 0.8, rgb: [96, 115, 252, 1] }, // 4
		{ index: 0.9, rgb: [100, 108, 201, 1] }, // 3
		{ index: 1.0, rgb: [0, 60, 151, 1] }, // 2
		{ index: 1.0, rgb: [1, 17, 101, 1] }, // 1
	],
	nshades: 12,
	format: 'hex',
	alpha: 0,
});

const climatologiaColormap = colormap({
	colormap: [
		{ index: 0, rgb: [249, 53, 55, 1] }, // vermelho
		{ index: 0.1, rgb: [255, 95, 53, 1] }, // laranja
		{ index: 0.2, rgb: [252, 169, 116, 1] }, // salmão
		{ index: 0.3, rgb: [252, 204, 155, 1] }, // laranja pastel
		{ index: 0.4, rgb: [253, 254, 153, 1] }, // amarelo
		{ index: 0.5, rgb: [155, 253, 1, 1] }, // verde 1
		{ index: 0.6, rgb: [115, 217, 115, 1] }, // verde 2
		{ index: 0.7, rgb: [50, 205, 49, 1] }, //verde 3
		{ index: 0.8, rgb: [0, 154, 55, 1] }, // verde 4
		{ index: 0.9, rgb: [50, 93, 92, 1] }, // verde 5
	],

	nshades: 11,
	format: 'hex',
	alpha: 0,
});
export interface ParametrosVariavel {
	model: 'exponential' | 'gaussian' | 'spherical';
	sigma2: number;
	alpha: number;
	unidade: string;
	colormap: string[];
	valorMin: number;
	valorMax: number;
	intervalos: number[];
}

export type VariavelMeteorologica =
	| 'temperatura'
	| 'umidade'
	| 'precipitacao'
	| 'mrai'
	| 'desvioAcumulado'
	| 'desvioMilimetro'
	| 'desvioPorcentagem'
	| 'pressao'
	| 'temperaturaSolo'
	| 'climatologia'
	| 'precipitacao_dias_chuva';

export const variavelParametros = {
	temperatura: {
		model: 'exponential',
		sigma2: 0,
		alpha: 100,
		valorMin: 15,
		valorMax: 38,
		intervalos: [0, 12, 16, 18, 20, 22, 24, 26, 28, 30, 33, 35, 40],
		unidade: '°C',
		colormap: colormap({
			colormap: 'rainbow',
			nshades: 14,
			format: 'hex',
			alpha: 1,
		}).filter((_e, i) => i !== 1 && i !== 2),
	},
	umidade: {
		model: 'exponential',
		sigma2: 0,
		alpha: 100,
		valorMin: 0,
		valorMax: 100,
		intervalos: [10, 20, 30, 40, 50, 60, 70, 80, 90],
		unidade: '%',
		colormap: colormap({
			colormap: 'rainbow',
			nshades: 14,
			format: 'hex',
			alpha: 1,
		})
			.filter((_e, i) => i > 3 && i !== 7)
			.reverse(),
	},
	precipitacao: {
		model: 'exponential',
		sigma2: 0,
		alpha: 100,
		valorMax: null,
		valorMin: null,
		intervalos: [0, 0.1, 0.5, 1, 10, 20, 50, 80, 100, 120, 150, 200],
		unidade: 'mm',
		colormap: precipitacaoCores,
	},
	precipitacao_dias_chuva: {
		model: 'exponential',
		sigma2: 0,
		alpha: 100,
		valorMax: 35,
		valorMin: 0,
		intervalos: [0, 5, 10, 15, 20, 25, 30, 35],
		unidade: 'dias',
		colormap: precipitacaoCoresDiasChuva,
	},
	mrai: {
		model: 'exponential',
		sigma2: 0,
		alpha: 100,
		valorMax: 10,
		valorMin: -10,
		intervalos: [-10, -5, -3, -2, -1, 0, 1, 2, 3, 5, 10],
		unidade: '',
		colormap: cm
			.filter((_e, i) => ![0, 1, 2, 3, 4, 5, 6, 7].includes(i))
			.reverse()
			.filter((_e, i) => i % 2 === 0),
	},

	desvioAcumulado: {
		model: 'exponential',
		sigma2: 0,
		alpha: 100,
		valorMax: 100,
		valorMin: -100,
		intervalos: [-100, -50, -20, -10, -5, 20, 30, 50, 100],
		unidade: '%',
		colormap: precipitacaoColormap
			.filter((_e, i) => i >= 8)
			.reverse()
			.filter(
				(_e, i) => ![4, 6, 7, 9, 11, 12, 13, 14, 15, 16, 17, 18].includes(i)
			)
			.slice(4, 13),
	},
	desvioMilimetro: {
		model: 'exponential',
		sigma2: 0,
		alpha: 100,
		valorMax: 150,
		valorMin: -150,
		intervalos: [-150, -100, -75, -50, -25, -10, 0, 10, 25, 50, 75, 100, 150],
		unidade: 'mm',
		colormap: desvioMilimetroColormap,
	},
	desvioPorcentagem: {
		model: 'exponential',
		sigma2: 0,
		alpha: 100,
		valorMax: 200,
		valorMin: -100,
		intervalos: [-100, -75, -50, -25, 0, 25, 50, 75, 100, 125, 150, 175, 200],
		unidade: '%',
		colormap: desvioPorcentagemColormap,
	},
	pressao: {
		model: 'exponential',
		sigma2: 0,
		alpha: 100,
		valorMin: null,
		valorMax: null,
		intervalos: [900, 912, 924, 936, 948, 961, 973, 985, 997, 1010],
		unidade: 'hPa',
		colormap: cm
			.filter((_e, i) => ![0, 1, 2, 3, 4, 5, 6, 7].includes(i))
			.filter((_e, i) => i % 2 === 0),
	},
	temperaturaSolo: {
		model: 'exponential',
		sigma2: 0,
		alpha: 100,
		valorMin: 15,
		valorMax: 38,
		intervalos: [0, 12, 16, 18, 20, 22, 24, 26, 28, 30, 33, 35, 40],
		unidade: '°C',
		colormap: colormap({
			colormap: 'rainbow',
			nshades: 14,
			format: 'hex',
			alpha: 1,
		}).filter((_e, i) => i !== 1 && i !== 2),
	},
	climatologia: {
		model: 'exponential',
		sigma2: 0,
		alpha: 100,
		valorMin: 0,
		valorMax: 350,
		intervalos: [0, 10, 25, 50, 75, 100, 150, 200, 250, 300, 350],
		unidade: 'mm',
		colormap: climatologiaColormap,
	},
};
