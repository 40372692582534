import { Injectable } from '@angular/core';
import { HttpClientProtectedService } from '@core/services/auth/http-client-protected.service';
import { map, Observable } from 'rxjs';
import { Rotas } from '@core/enums/routes';
import { Boletim, RespostaBoletimPeriodo } from '@home/submodulos/dados-meteorologicos/interfaces/boletim';
import { formataBoletimParaMapaSvg } from '@home/submodulos/dados-meteorologicos/utils/boletim-diario';
import { PeriodoBoletim } from '../enum/PeriodoBoletim';

@Injectable({
	providedIn: 'root',
})
export class BoletimDiarioService {
	constructor(private http: HttpClientProtectedService) {}

	listarUltimosBoletinsDoDiaPorMes(dataInicio: Date, dataFim: Date) {
		return this.http
			.get<Boletim[]>(Rotas.BOLETIM_PUBLICO + '/ultimos-boletins-dia-mes', {
				dataInicio: dataInicio.toISOString(),
				dataFim: dataFim.toISOString(),
			})
			.pipe(
				map(boletins =>
					boletins
						.filter(
							boletim =>
								boletim.chuvasPostosPorMesorregiao &&
								boletim.chuvasPostosPorMesorregiao.length
						)
						.map(boletim => ({
							...boletim,
							precipitacaoMunicipio: formataBoletimParaMapaSvg(boletim),
						}))
				)
			);
	}
	listarBoletinsAgrupadoPorPeriodo(dataInicio: Date | null, dataFim: Date | null, periodo: PeriodoBoletim) {
		return this.http
			.get<RespostaBoletimPeriodo>(Rotas.BOLETIM_PUBLICO + '/boletins-agrupado-por-periodo', {
				dataInicio: dataInicio?.toISOString() || null,
				dataFim: dataFim?.toISOString() || null,
				periodo,
			})
	}

	consultarUltimoBoletim(): Observable<Boletim> {
		return this.http.get<Boletim>(Rotas.BOLETIM_PUBLICO + `/ultimo-dia-atual`);
	}

	buscarHorariosDeBoletins(): Observable<any> {
		return this.http.get(Rotas.BOLETIM_PUBLICO + '/horarios-do-dia');
	}
	buscarBoletimPorHorario(horario: string): Observable<Boletim> {
		return this.http.get(
			Rotas.BOLETIM_PUBLICO + `/por-horario?horarioInformado=${horario}`
		);
	}
	buscarBoletinsPorDataEspecifica(data: any) {
		return this.http.get<Boletim>(
			Rotas.BOLETIM_PUBLICO + `/por-data?data=${data}`
		);
	}
}
