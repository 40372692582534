export enum PermissoesEnum {
	VISUALIZAR_USUARIO = 'permissao.usuario.visualizar',
	GERENCIAR_USUARIO = 'permissao.usuario.gerenciar',
	VISUALIZAR_GRUPO = 'permissao.grupo.visualizar',
	GERENCIAR_GRUPO = 'permissao.grupo.gerenciar',
	VISUALIZAR_MUNICIPIO = 'permissao.municipio.visualizar',
	GERENCIAR_MUNICIPIO = 'permissao.municipio.gerenciar',
	VISUALIZAR_MESORREGIAO = 'permissao.mesorregiao.visualizar',
	GERENCIAR_MESORREGIAO = 'permissao.mesorregiao.gerenciar',
	VISUALIZAR_MICRORREGIAO = 'permissao.microrregiao.visualizar',
	GERENCIAR_MICRORREGIAO = 'permissao.microrregiao.gerenciar',
	VISUALIZAR_ORGAO = 'permissao.orgao.visualizar',
	GERENCIAR_ORGAO = 'permissao.orgao.gerenciar',
	GERENCIAR_VERANICO = 'permissao.configuracao.veranico.gerenciar',
	VISUALIZAR_VERANICO = 'permissao.configuracao.veranico.visualizar',
	VISUALIZAR_MODELO_DE_EXECUCAO = 'permissao.execucao.modelo.visualizar',
	EXPORTAR_DADOS_DO_METEOGRAMA = 'permissao.meteograma.exportar.dados',
	VISUALIZAR_ESTACAO = 'permissao.estacao.visualizar',
	GERENCIAR_ESTACAO = 'permissao.estacao.gerenciar',
	PERMISSAO_DE_APLICATIVO = 'permissao.app',
	GERENCIAR_CARTA_CAMPO = 'permissao.gerenciar-carta-campo',
	VISUALIZAR_EDAFO = 'permissao.edafo.visualizar',
	GERENCIAR_EDAFO = 'permissao.edafo.gerenciar',
	CONFIGURAR_LINKS_PUBLICOS = 'permissao.configuracao.links-publicos',
	GERENCIAR_BOLETIM_DIARIO = 'permissao.gerenciar-boletim-diario',
	GERENCIAR_BACIA = 'permissao.bacia.gerenciar',
	SINCRONIZAR_PCD = 'permissao.pcds.sincronizar',
	GERENCIAR_RESERVATORIO = 'permissao.reservatorios.gerenciar',
	VISUALIZAR_SOLO = 'permissao.solo.visualizar',
	GERENCIAR_SOLO = 'permissao.solo.gerenciar',
	VISUALIZAR_CULTURA = 'permissao.cultura.visualizar',
	GERENCIAR_CULTURA = 'permissao.cultura.gerenciar',
	VISUALIZAR_RISCO_CLIMATICO = 'permissao.risco.climatico.visualizar',
	GERENCIAR_RISCO_CLIMATICO = 'permissao.risco.climatico.gerenciar',
	VISUALIZAR_REGIONAL = 'permissao.regional.visualizar',
	GERENCIAR_REGIONAL = 'permissao.regional.gerenciar',
	VISUALIZAR_RESERVATORIO = 'permissao.reservatorios.visualizar',
	VISUALIZAR_BACIA = 'permissao.bacia.visualizar',
	VISUALIZAR_CONFIGURACAO_CLASSIFICACAO_RESERVATORIO = 'permissao.configuracao.classificacao.reservatorio.visualizar',
	GERENCIAR_CONFIGURACAO_CLASSIFICACAO_RESERVATORIO = 'permissao.configuracao.classificacao.reservatorio.gerenciar',
	VISUALIZAR_HISTORICO_DE_RESERVATORIO = 'permissao.historico.reservatorio.visualizar',
	GERENCIAR_HISTORICO_DE_RESERVATORIO = 'permissao.historico.reservatorio.gerenciar',
	VISUALIZAR_CONFIGURACAO_DE_AVISO_METEOROLOGICO = 'permissao.configuracao.aviso.meteorologico.visualizar',
	GERENCIAR_CONFIGURACAO_DE_AVISO_METEOROLOGICO = 'permissao.configuracao.aviso.meteorologico.gerenciar',
	VISUALIZAR_CONFIGURACAO_DE_RANGES_QUANTIS = 'permissao.configuracao.ranges.quantis.visualizar',
	GERENCIAR_CONFIGURACAO_DE_RANGES_QUANTIS = 'permissao.configuracao.ranges.quantis.gerenciar',
	GERENCIAR_CONFIGURACAO_DE_CLIMATOLOGIA = 'permissao.configuracao.climatologia.gerenciar',
	VISUALIZAR_CONFIGURACAO_DE_CLIMATOLOGIA = 'permissao.configuracao.climatologia.visualizar',
	GERENCIAR_IMAGENS_DO_SISTEMA = 'permissao.imagens.do.sistema.gerenciar',
}

export enum KEY_LOCAL_STORAGE_PERMISSIONS {
	PERMISSIONS = 'permissions',
	TOKEN = 'authTokenSeira',
}
