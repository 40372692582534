<div class="row mt-3 mb-5">
	<seira-public-pagination [items]="relatorios" [itemsPerPage]="5">
	  <ng-template let-item="item">
		<div class="mb-2">
		  <label class="text-tertiary mb-2 fw-semibold">
			{{ item.municipio }}
		  </label>
		  <seira-public-table
			[hasFooter]="false"
			[hasHeader]="false"
			[info]="false"
			[paging]="false"
			[searching]="false"
			[columns]="colunas"
			[scrollX]="true"
			[data]="item.data"
			[orderOption]="[1, 'desc']"
			[dtOptions]="dtOptions"
		  />
		</div>
	  </ng-template>
	</seira-public-pagination>
  </div>
  