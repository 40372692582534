import { Component, Input } from '@angular/core';

@Component({
	selector: 'seira-icon-with-label',
	templateUrl: './icon-with-label.component.html',
	styleUrls: ['./icon-with-label.component.scss'],
})
export class IconWithLabelComponent {
	@Input() customClass = '';
}
