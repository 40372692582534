import * as Leaflet from 'leaflet';
import { TileLayer } from 'leaflet';

export enum MAPAS_ESTACOES_AUTOMATICAS {
	INTERPOLACAO = 'Interpolação',
	SATELITE = 'Satélite',
}

export const mapEstacoeslayers: Record<MAPAS_ESTACOES_AUTOMATICAS, TileLayer> =
	{
		[MAPAS_ESTACOES_AUTOMATICAS.INTERPOLACAO]: Leaflet.tileLayer(
			'https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}.png',
			{
				zIndex: 1,
				id: MAPAS_ESTACOES_AUTOMATICAS.INTERPOLACAO,
				maxZoom: 19,
				attribution:
					'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
			}
		),
		[MAPAS_ESTACOES_AUTOMATICAS.SATELITE]: Leaflet.tileLayer(
			'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
			{
				zIndex: 1,
				id: MAPAS_ESTACOES_AUTOMATICAS.SATELITE,
				maxZoom: 19,
				attribution:
					'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
			}
		),
	};
