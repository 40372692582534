<button
	type="button"
	class="btn button-fill btn-primary d-flex align-items-center gap-1 flex-column vstack px-4"
	style="height: 84px; width: 90px"
	(click)="modal.show()"
	[disabled]="disabledButton">
	<i [ngStyle]="{ fontSize: '36px' }" class="{{ icon }}"></i>
	{{ label }}
</button>

<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog">
	<div class="modal-dialog modal-sm modal-dialog-centered" style="width: 15em">
		<div class="modal-content">
			<div class="modal-body text-primary d-flex flex-column">
				<div>
					<h4 class="modal-title pull-left text-tertiary mb-2">Exportar em:</h4>
					<button
						type="button"
						class="btn-close close pull-right"
						aria-label="Close"
						(click)="modal.hide()">
						<span aria-hidden="true" class="visually-hidden">&times;</span>
					</button>
				</div>
				<div class="d-flex flex-column align-items-center">
					<a
						class="my-1"
						href
						*ngFor="let button of buttons"
						(click)="button.onClick(); $event.preventDefault()"
						><i class="{{ button.icon }}"></i> {{ button.label }}</a
					>
				</div>
			</div>
		</div>
	</div>
</div>
