<label class="text-tertiary fw-semibold mt-3">
	GRÁFICO DE EVOLUÇÃO ANUAL DE PRECIPITAÇÃO
</label>
<div class="d-flex justify-content-center align-items-center w-100">
	<seira-loading
		*ngIf="carregandoRelatorio; else graficoSPI"
		[pageCentered]="false"></seira-loading>

	<ng-template #graficoSPI>
		<div *ngIf="!!graficoSPIUrl; else semDados">
			<seira-card-info aditionalClasses="mt-3">
				Placeholder informativo
			</seira-card-info>

			<div class="d-flex justify-content-center">
				<img class="my-4" [src]="graficoSPIUrl" alt="Gráfico SPI" />
			</div>
		</div>
	</ng-template>

	<ng-template #semDados>
		<seira-sem-dados />
	</ng-template>
</div>
