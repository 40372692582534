import { Injectable } from '@angular/core';
import { HttpClientProtectedService } from '@core/services/auth/http-client-protected.service';
import { Rotas } from '@core/enums/routes';
import { DadosMapaQuantis } from '../interfaces/mapa-quantis-mensal';
import { RespostaGraficoVariaveis } from '../interfaces/variaveis-meteologicas';
import { Iapm } from '../interfaces/iapm';

@Injectable({
	providedIn: 'root',
})
export class GraficosPrecipitacaoService {
	constructor(private http: HttpClientProtectedService) {}

	buscarMapaQuantisMensal(
		regioesIds: number[],
		diaInicio: string | null,
		diaFim: string
	) {
		return this.http.post<any, DadosMapaQuantis[]>(
			Rotas.GRAFICOS + `/precipitacao/mapas-quantis-mensal`,
			{
				regioesIds,
				diaInicio,
				diaFim,
			}
		);
	}

	buscarGraficoVariaveisMetereologicas(
		postoId: number,
		diaInicio: string | null,
		diaFim: string
	) {
		return this.http.post<any, RespostaGraficoVariaveis>(
			Rotas.GRAFICOS + `/variavel`,
			{
				postoId,
				diaInicio,
				diaFim,
			}
		);
	}

	buscarIAPM(dataInicial: string | null, dataFinal: string) {
		return this.http.get<Iapm[]>(
			Rotas.GRAFICOS + `/mrai?dataInicial=${dataInicial}&dataFinal=${dataFinal}`
		);
	}
}
