<div class="d-flex flex-column">
	<label class="text-tertiary fw-semibold mt-3">
		{{ tituloTabela }}
	</label>

	<div style="max-width: 1300px">
		<div
			style="
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
			">
			<seira-card-info aditionalClasses="my-3">
				Placeholder informativo
			</seira-card-info>

			<seira-select-list-menu
				[opcoes]="opcoesColunas"
				[opcoesSelecionadas]="colunasSelecionadas"
				[disabled]="disabledSelectList || carregando"
				[tituloBotao]="'Personalizar colunas'"
				(atualizarOpcoesSelecionadas)="
					setColunasSelecionadas($event)
				"></seira-select-list-menu>
		</div>

		<div
			*ngIf="carregando; else tabela"
			class="d-flex justify-content-center align-items-center w-100 h-50"
			style="min-height: 500px">
			<seira-loading [pageCentered]="false"></seira-loading>
		</div>

		<ng-template #tabela>
			<seira-public-table
				id="tabela-vec"
				[loading]="carregando"
				[searching]="false"
				[info]="false"
				[hasHeader]="false"
				[scrollX]="true"
				[data]="resultados"
				[columns]="colunasVEC">
			</seira-public-table>
		</ng-template>
	</div>

	<ng-template #nenhumResultado>
		<seira-sem-dados></seira-sem-dados>
	</ng-template>
</div>

