import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthRequiredGuard } from '../../core/guards/auth/auth-required.guard';
import { AlterarSenhaComponent } from './trocar-senha/pages/alterar-senha/alterar-senha.component';
import { LoginPageComponent } from '@autenticacao/login/pages/login-page/login-page.component';
import { RecuperarSenhaComponent } from '@autenticacao/recuperar-senha/pages/recuperar-senha/recuperar-senha.component';
import { RedefinirSenhaComponent } from '@autenticacao/redefinir-senha/pages/redefinir-senha/redefinir-senha.component';

@NgModule({
	exports: [RouterModule],
	imports: [
		RouterModule.forChild([
			{
				path: 'login',
				component: LoginPageComponent,
				title: 'Login - SEIRA',
			},
			{
				path: 'recuperar-senha',
				component: RecuperarSenhaComponent,
				title: 'Recuperar senha - SEIRA',
			},
			{
				path: 'redefinir-senha/:token',
				component: RedefinirSenhaComponent,
				title: 'Recuperar senha - SEIRA',
			},
			{
				path: 'alterar-senha',
				component: AlterarSenhaComponent,
				canActivate: [AuthRequiredGuard],
				title: 'Alterar senha - SEIRA',
			},
		]),
	],
})
export class AutenticacaoRoutingModule {}
