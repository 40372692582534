import { Injectable } from '@angular/core';
import * as jtw_decode from 'jwt-decode';
import { TokenInterface } from '../../interfaces/token';
import { KEY_LOCAL_STORAGE_PERMISSIONS } from '@administracao/submodulos/grupo/enums/permissoesEnum';

@Injectable({
	providedIn: 'root',
})
export class TokenService {
	hasToken(): boolean {
		return !!this.getToken();
	}

	setToken(token: string) {
		window.localStorage.setItem(KEY_LOCAL_STORAGE_PERMISSIONS.TOKEN, token);
	}

	getToken(): string {
		return window.localStorage.getItem(
			KEY_LOCAL_STORAGE_PERMISSIONS.TOKEN
		) as string;
	}

	removeToken(): void {
		window.localStorage.removeItem(KEY_LOCAL_STORAGE_PERMISSIONS.TOKEN);
		window.localStorage.removeItem(KEY_LOCAL_STORAGE_PERMISSIONS.PERMISSIONS);
	}

	isTokenExpired(): boolean {
		const token = this.getToken();
		if (!token) {
			return true;
		}

		const decodedToken = this.decodeToken(token);
		if (!decodedToken || !decodedToken.exp) {
			return true;
		}

		const expTimestamp = decodedToken.exp * 1000;
		const currentDate = new Date().getTime();
		return expTimestamp <= currentDate;
	}

	decodeToken(token: string): any {
		try {
			return jtw_decode.default(token) as TokenInterface;
		} catch (error) {
			return null;
		}
	}
}
