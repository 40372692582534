<div class="d-flex flex-column mt-2 align-items-center">
	<div>
		<div class="d-flex flex-row gap-2 mt-3 justify-content-center">
			<div
				class="py-3 px-3 bg-white rounded shadow-lg d-flex flex-column"
				id="legenda">
				<div class="card-body d-flex flex-row align-items-center gap-3">
					<label class="text-tertiary fw-semibold"> Legenda</label>
					<seira-icon-with-label>
						<i icon style="color: #f56565" class="ph ph-circle-fill me-1"></i>
						<label label class="text-tertiary">Muito seco</label>
					</seira-icon-with-label>
					<seira-icon-with-label>
						<i icon style="color: #f8c02f" class="ph ph-circle-fill me-1"></i>
						<label label class="text-tertiary">Seco</label>
					</seira-icon-with-label>
					<seira-icon-with-label>
						<i icon style="color: #b0e2ff" class="ph ph-circle-fill me-1"></i>
						<label label class="text-tertiary">Normal</label>
					</seira-icon-with-label>
					<seira-icon-with-label>
						<i icon style="color: #4f94cd" class="ph ph-circle-fill me-1"></i>
						<label label class="text-tertiary">Chuvoso</label>
					</seira-icon-with-label>
					<seira-icon-with-label>
						<i icon style="color: #243d88" class="ph ph-circle-fill me-1"></i>
						<label label class="text-tertiary">Muito chuvoso</label>
					</seira-icon-with-label>
					<seira-icon-with-label>
						<i icon style="color: #d4d4d4" class="ph ph-circle-fill me-1"></i>
						<label label class="text-tertiary">Sem informação</label>
					</seira-icon-with-label>
				</div>
			</div>
			<button
				type="button"
				style="max-width: 81px"
				class="w-auto btn btn-primary d-flex align-items-center flex-column py-3 vstack gap-1">
				<img
					style="height: 35px"
					src="./assets/images/files/pdf.svg"
					alt="pdf" />
				.pdf
			</button>
		</div>
	</div>
</div>
