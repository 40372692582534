export enum Microrregiao {
	serraDoTeixeira = 'Serra do Teixeira',
	pianco = 'Piancó',
	brejo = 'Brejo',
	guarabira = 'Guarabira',
	caririOriental = 'Cariri Oriental',
	curimatauOcidental = 'Curimataú Ocidental',
	litoralSul = 'Litoral Sul',
	caririOcidental = 'Cariri Ocidental',
	sousa = 'Sousa',
	curimatauOriental = 'Curimataú Oriental',
	patos = 'Patos',
	esperanca = 'Esperança',
	umbuzeiro = 'Umbuzeiro',
	litoralNorte = 'Litoral Norte',
	seridoOriental = 'Seridó Oriental',
	joaoPessoa = 'João Pessoa',
	catoleDoRocha = 'Catolé do Rocha',
	cajazeiras = 'Cajazeiras',
	itaporanga = 'Itaporanga',
	campinaGrande = 'Campina Grande',
	itabaiana = 'Itabaiana',
	sape = 'Sapé',
	seridoOcidental = 'Seridó Ocidental',
}
