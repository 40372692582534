import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from '@shared/shared.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RequestInterceptorService } from '@core/services/auth/request-interceptor.service';
import { ToastrModule } from 'ngx-toastr';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { AutenticacaoModule } from '@autenticacao/autenticacao.module';
import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';
import { AdministracaoModule } from '@administracao/administracao.module';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { BsModalService } from 'ngx-bootstrap/modal';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSliderModule } from '@angular/material/slider';
import { NgxMaskDirective, provideEnvironmentNgxMask } from 'ngx-mask';
import { SemPermissaoComponent } from '@modulos/sem-permissao/sem-permissao.component';
import { HomeModule } from '@modulos/home/home.module';
import { MeteorologiaModule } from '@modulos/meteorologia/meteorologia.module';
import { RecursosHidricosModule } from '@modulos/recursos-hidricos/recursos-hidricos.module';

registerLocaleData(localePt);

@NgModule({
	declarations: [AppComponent, SemPermissaoComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		SharedModule,
		MatSliderModule,
		AutenticacaoModule,
		HomeModule,
		AdministracaoModule,
		MeteorologiaModule,
		RecursosHidricosModule,
		BrowserAnimationsModule,
		LeafletModule,
		MatDatepickerModule,
		MatFormFieldModule,
		MatMomentDateModule,
		ToastrModule.forRoot({
			timeOut: 3000,
			closeButton: true,
			progressBar: true,
			preventDuplicates: true,
		}),
		SweetAlert2Module.forRoot({
			provideSwal: () => import('sweetalert2/dist/sweetalert2.js'),
		}),
		NgxLoadingModule.forRoot({
			animationType: ngxLoadingAnimationTypes.threeBounce,
			backdropBackgroundColour: 'rgba(49, 165, 177,0.1)',
			primaryColour: '#0d6a92',
			secondaryColour: '#0d6a92',
			tertiaryColour: '#0d6a92',
		}),
		NgxMaskDirective,
		LeafletModule,
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: RequestInterceptorService,
			multi: true,
		},
		{
			provide: LOCALE_ID,
			useValue: 'pt-BR',
		},
		{ provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
		BsModalService,
		provideEnvironmentNgxMask(),
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
