<!-- <seira-page-title> Imagens de Satélite </seira-page-title> -->
<div class="col-lg-12 d-flex flex-xl-row flex-column">
	<div class="d-flex align-items-center">
		<seira-title> Gerador de imagens de satélite </seira-title>
		<div class="d-flex align-items-center">
			<seira-public-page-about
				[textosInformativos]="informacoesGerais"></seira-public-page-about>
		</div>
	</div>
</div>
<form [formGroup]="imagensSateliteForm" class="d-flex mt-4">
	<div class="d-flex gap-2 flex-wrap flex-row align-items-end">
		<div class="d-flex flex-nowrap flex-row gap-2">
			<div class="min-width-select">
				<seira-public-select
					optionLabel="tipoSatelite"
					optionValue="nomeSatelite"
					label="Tipo de satélite"
					placeholder="Selecione o tipo de satélite"
					formControlName="opcoesSatelite"
					[options]="opcoesSateliteOpts" />
			</div>
			<div class="min-width-select">
				<seira-public-select
					label="Canal do satélite"
					placeholder="Selecione o canal do satélite"
					formControlName="canaisSatelite"
					[options]="canaisSateliteOpts" />
			</div>
		</div>
		<div
			class="p-3 mb-0 bg-white flex-row rounded shadow-lg card text-tertiary gap-1 fw-semibold">
			Visualizando:
			<div class="fw-normal">
				AESA Meteosat • IR 10.8 • 28 de julho de 2023 (03h UTC)
			</div>
		</div>
	</div>
</form>
<div class="d-flex align-items-center justify-content-center p-5">
	<div
		class="position-relative p-2 mt-4"
		[ngClass]="
			imagensSateliteForm.valid && !loading ? '' : 'content-container'
		">
		<i
			*ngIf="!loading && !imagensSateliteForm.valid"
			class="ph ph-monitor-play"
			style="transform: scale(10); opacity: 0.25"></i>
		<seira-loading [show]="loading && imagensSateliteForm.valid" />
	</div>

	<div class="bg-black p-3" *ngIf="!loading && imagensSateliteForm.valid">
		<img [src]="sateliteGifUrls" style="width: 45vw; height: auto" />
	</div>
</div>

<div class="col-lg-6">
	<seira-info-gerais
		id="informacoes-gerais"
		[textosInformativos]="informacoesGerais"></seira-info-gerais>
</div>
