import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TokenService } from '../token/token.service';
import * as jtw_decode from 'jwt-decode';
import { User } from '../../interfaces/user';
import { TokenInterface } from '../../interfaces/token';

@Injectable({
	providedIn: 'root',
})
export class UserService {
	private userSubject: BehaviorSubject<User | null> =
		new BehaviorSubject<User | null>(null);
	private userName = '';
	private userId = -1;

	constructor(private tokenService: TokenService) {
		this.tokenService.hasToken() && this.decodeAndNotify();
	}

	setToken(token: string) {
		this.tokenService.setToken(token);
		this.decodeAndNotify();
	}

	getUser() {
		return this.userSubject.asObservable();
	}

	private decodeAndNotify() {
		const token: string = this.tokenService.getToken();
		const tokenRead = jtw_decode.default(token) as TokenInterface;
		const user = JSON.parse(tokenRead.sub) as User;
		this.userName = user.login;
		this.userId = user.id;
		this.userSubject.next(user);
	}

	logout() {
		this.tokenService.removeToken();
		this.userSubject.next(null);
	}

	isLogged() {
		return this.tokenService.hasToken();
	}

	getUserName() {
		return this.userName;
	}

	getUserId() {
		return this.userId;
	}
}
