<div class="relative" style="position: relative">
	<label class="form-label fw-semibold" [for]="id"
		>{{ label }}<span class="text-danger" *ngIf="isRequired">*</span></label
	>
	<ng-select
		(search)="pesquisa($event)"
		[items]="options"
		[bindValue]="optionsValue"
		[bindLabel]="optionsLabel"
		class="custom"
		[required]="isRequired"
		[id]="id"
		[formControl]="control"
		[readonly]="isReadOnly"
		[multiple]="true"
		[closeOnSelect]="false"
		[placeholder]="placeholder"
		[ngClass]="showsTextRequired || seiraError ? 'is-invalid' : ''">
		<ng-template ng-header-tmp>
			<a href="#" (click)="selectAll(); $event.preventDefault()">
				<i class="ph-checks"></i> Selecionar todos
			</a>
		</ng-template>
	</ng-select>
	<div class="invalid-feedback mt-0" *ngIf="showsTextRequired || seiraError">
		{{ textRequired || seiraError }}
	</div>
</div>
