import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from '@layout/layout.module';
import { CardComponent } from '@componentes/card/card.component';
import { CustomErrorPageComponent } from '@componentes/custom-error-page/custom-error-page.component';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { PageNotFoundComponent } from '@componentes/page-not-found/page-not-found.component';
import { AccordionComponent } from '@componentes/accordion/accordion.component';
import { SelectComponent } from '@componentes/select/select.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CheckboxComponent } from '@componentes/checkbox/checkbox.component';
import { TextAreaComponent } from '@componentes/text-area/text-area.component';
import { FooterFormComponent } from '@componentes/footer-form/footer-form.component';
import { ButtonComponent } from '@componentes/button/button.component';
import { TableComponent } from '@componentes/table/table.component';
import { DataTablesModule } from 'angular-datatables';
import { DropdownComponent } from '@componentes/dropdown/dropdown.component';
import { MultiselectComponent } from '@componentes/multiselect/multiselect.component';
import { InputComponent } from '@componentes/input/input.component';
import { InputMaskComponent } from '@componentes/input-mask/input-mask.component';
import { PipesModule } from '@utils/pipes/pipes.module';
import { AlertComponent } from '@componentes/alert/alert.component';
import { InputFileComponent } from '@componentes/input-file/input-file.component';
import { AngularEditorComponent } from '@componentes/angular-editor/angular-editor.component';
import { TabMenuComponent } from '@componentes/tab-menu/tab-menu.component';
import { CustomSelectComponent } from '@componentes/custom-select/custom-select.component';
import { MultiColorBarComponent } from '@componentes/multi-color-bar/multi-color-bar.component';
import { PublicTableComponent } from '@componentes/public-table/public-table.component';
import { PublicSelectComponent } from '@componentes/public-select/public-select.component';
import { PublicInputComponent } from '@componentes/public-input/public-input.component';
import { IconWithLabelComponent } from '@componentes/icon-with-label/icon-with-label.component';
import { PublicCheckboxComponent } from '@componentes/public-checkbox/public-checkbox.component';
import { PublicRadioGroupComponent } from '@componentes/public-radio-group/public-radio-group.component';
import { PublicAComponent } from '@componentes/public-a/public-a.component';
import { PublicButtonGroupComponent } from '@componentes/public-button-group/public-button-group.component';
import { VerticalSeparatorComponent } from '@componentes/vertical-separator/vertical-separator.component';
import { PublicButtonComponent } from '@componentes/public-button/public-button.component';
import { PublicModalComponent } from '@componentes/public-modal/public-modal.component';
import { MaterialAngularModule } from '@shared/material-angular/material-angular.module';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { PublicDateRangePickerComponent } from '@componentes/public-date-range-picker/public-date-range-picker.component';
import { NgxMaskDirective } from 'ngx-mask';
import { HighStockComponent } from '@componentes/high-stock/high-stock.component';
import { HighchartsChartModule } from 'highcharts-angular';
import * as Highstock from 'highcharts/highstock';
import * as Highchart from 'highcharts/highcharts';

import HC_exporting from 'highcharts/modules/exporting';
import HC_windbarb from 'highcharts/modules/windbarb';
import HC_noData from 'highcharts/modules/no-data-to-display';
import HC_accessibility from 'highcharts/modules/accessibility';
import { highChartsOptions } from '@utils/highcharts';
import { DirectivesModule } from '@utils/directives/directives.module';
import { MapaParaibaSvgComponent } from '@componentes/mapa-paraiba-svg/mapa-paraiba-svg.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { DatePickerComponent } from '@componentes/date-picker/date-picker.component';
import { PrecipitacaoContainerComponent } from '@componentes/mapa-paraiba-svg/componentes/precipitacao-container/precipitacao-container.component';
import { MunicipiosContainerComponent } from '@componentes/mapa-paraiba-svg/componentes/municipios-container/municipios-container.component';
import { PopupPadraoMapaParaibaComponent } from '@componentes/mapa-paraiba-svg/componentes/popup-padrao-mapa-paraiba/popup-padrao-mapa-paraiba.component';
import { PublicDatePickerComponent } from '@componentes/public-date-picker/public-date-picker.component';
import { PublicSelectGradeComponent } from '@componentes/public-select/public-select-grade.component';
import { PublicSelectVariavelComponent } from '@componentes/public-select/public-select-variavel.component';
import { PublicSelectExecucaoMeteogramaComponent } from '@componentes/public-select/public-select-execucao-meteograma.component';
import { PublicSelectMunicipioExecucaoMeteogramaComponent } from '@componentes/public-select/public-select-municipio-execucao-meteograma.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PublicPageOptionsComponent } from '@componentes/public-page-options/public-page-options.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PublicPageAboutComponent } from '@componentes/public-page-about/public-page-about.component';
import { PublicSelectMunicipioComponent } from '@componentes/public-select/public-select-municipio.component';
import { LoadingComponent } from '@componentes/loading/loading.component';
import { PublicTitleComponent } from '@componentes/public-title/public-title.component';
import { ScrollToTopButtonComponent } from '@componentes/scroll-to-top-button/scroll-to-top-button.component';
import { SelectCheckboxesComponent } from '@componentes/select-checkboxes/select-checkboxes.component';
import { CircularProgressPercentegeComponent } from '@componentes/circular-progress-percentege/circular-progress-percentege.component';
import { TransferListComponent } from '@componentes/transfer-list/transfer-list.component';
import { DragNDropInputFileComponent } from '@componentes/drag-n-drop-input-file/drag-n-drop-input-file.component';
import { NgxFileDragDropModule } from 'ngx-file-drag-drop';
import { ErrorListComponent } from '@componentes/error-list/error-list.component';
import { IndisponivelMobileComponent } from '@componentes/indisponivel-mobile/indisponivel-mobile.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { InfoGeraisComponent } from '@componentes/info-gerais/info-gerais.component';
import { TitleComponent } from '@componentes/title/title.component';
import { MapaParaibaLeafletComponent } from '@componentes/mapa-paraiba-leaflet/mapa-paraiba-leaflet.component';
import { PublicSelectVariavelEstacoesAutomaticasComponent } from '@componentes/public-select/public-select-variavel-estacoes-automaticas.component';
import { PublicSelectVariavesEstacoesComponent } from '@componentes/public-select/public-select-variaveis-estacoes';
import { CardCalendarioComponent } from '@componentes/card-calendario/card-calendario.component';
import { ModalComponent } from '@componentes/modal/modal.component';
import { SelectPeriodoCalendarioComponent } from '@componentes/calendario/select-periodo-calendario/select-periodo-calendario.component';
import { CalendarCommonModule } from 'angular-calendar';
import { DayMonthPickerComponent } from '@componentes/date-picker/variants/day-month-picker.component';
import { PublicSelectVariavelChuvaEstadoComponent } from '@componentes/public-select/public-select-variavel-chuva-estado.component';
import { AComponent } from '@componentes/a/a.component';
import { PublicSelectExecucaoClimaComponent } from '@componentes/public-select/public-select-execucao-clima.component';
import { HighchartComponent } from '@componentes/highchart/highchart.component';
import { CardInfoComponent } from '@componentes/card-info/card-info.component';
import { PublicPaginationComponent } from '@componentes/public-pagination/public-pagination.component';
import { PageTitleComponent } from '@componentes/page-title/page-title.component';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartBoostCanva from 'highcharts/modules/boost-canvas';
import HighchartBoost from 'highcharts/modules/boost';
import { SelectListMenuComponent } from './componentes/select-list-menu/select-list-menu.component';

HC_windbarb(Highstock);
HC_exporting(Highstock);
HC_noData(Highstock);
HC_accessibility(Highstock);
HighchartsMore(Highstock);
HighchartBoost(Highstock);
HighchartBoostCanva(Highstock);

Highstock.setOptions(highChartsOptions);

HC_windbarb(Highchart);
HC_exporting(Highchart);
HC_noData(Highchart);
HC_accessibility(Highchart);
HighchartsMore(Highchart);
HighchartBoost(Highchart);
HighchartBoostCanva(Highchart);

Highchart.setOptions(highChartsOptions);

@NgModule({
	declarations: [
		CardComponent,
		CustomErrorPageComponent,
		PageNotFoundComponent,
		AccordionComponent,
		SelectComponent,
		InputComponent,
		CheckboxComponent,
		TextAreaComponent,
		MultiselectComponent,
		FooterFormComponent,
		ButtonComponent,
		TableComponent,
		DropdownComponent,
		AlertComponent,
		InputFileComponent,
		AngularEditorComponent,
		TabMenuComponent,
		CustomSelectComponent,
		MultiColorBarComponent,
		PublicTableComponent,
		PublicSelectComponent,
		PublicSelectGradeComponent,
		PublicSelectVariavelComponent,
		PublicSelectVariavelChuvaEstadoComponent,
		PublicSelectExecucaoMeteogramaComponent,
		PublicSelectExecucaoClimaComponent,
		PublicSelectMunicipioExecucaoMeteogramaComponent,
		PublicInputComponent,
		IconWithLabelComponent,
		PublicCheckboxComponent,
		PublicRadioGroupComponent,
		PublicAComponent,
		PublicButtonGroupComponent,
		VerticalSeparatorComponent,
		PublicButtonComponent,
		PublicModalComponent,
		PublicButtonGroupComponent,
		VerticalSeparatorComponent,
		PublicDateRangePickerComponent,
		HighStockComponent,
		DatePickerComponent,
		MapaParaibaSvgComponent,
		PrecipitacaoContainerComponent,
		MunicipiosContainerComponent,
		PopupPadraoMapaParaibaComponent,
		PublicDatePickerComponent,
		PublicPageOptionsComponent,
		PublicPageAboutComponent,
		PublicSelectMunicipioComponent,
		LoadingComponent,
		ScrollToTopButtonComponent,
		PublicTitleComponent,
		SelectCheckboxesComponent,
		CircularProgressPercentegeComponent,
		TransferListComponent,
		DragNDropInputFileComponent,
		ErrorListComponent,
		IndisponivelMobileComponent,
		InfoGeraisComponent,
		TitleComponent,
		MapaParaibaLeafletComponent,
		PublicSelectVariavelEstacoesAutomaticasComponent,
		PublicSelectVariavesEstacoesComponent,
		CardCalendarioComponent,
		ModalComponent,
		SelectPeriodoCalendarioComponent,
		DayMonthPickerComponent,
		PublicSelectVariavelChuvaEstadoComponent,
		AComponent,
		HighchartComponent,
		CardInfoComponent,
		PublicPaginationComponent,
		PageTitleComponent,
		SelectListMenuComponent,
	],
	exports: [
		LayoutModule,
		CardComponent,
		CustomErrorPageComponent,
		PageNotFoundComponent,
		AccordionComponent,
		MultiselectComponent,
		SelectComponent,
		InputComponent,
		CheckboxComponent,
		TextAreaComponent,
		FooterFormComponent,
		ButtonComponent,
		InputMaskComponent,
		TableComponent,
		DropdownComponent,
		PipesModule,
		AlertComponent,
		InputFileComponent,
		AngularEditorComponent,
		AngularEditorModule,
		TabMenuComponent,
		CustomSelectComponent,
		MaterialAngularModule,
		PublicTableComponent,
		PublicSelectComponent,
		PublicSelectGradeComponent,
		PublicSelectVariavelComponent,
		PublicSelectVariavelChuvaEstadoComponent,
		PublicSelectExecucaoMeteogramaComponent,
		PublicSelectMunicipioExecucaoMeteogramaComponent,
		PublicInputComponent,
		IconWithLabelComponent,
		PublicCheckboxComponent,
		PublicRadioGroupComponent,
		PublicAComponent,
		PublicButtonGroupComponent,
		VerticalSeparatorComponent,
		PublicButtonComponent,
		PublicModalComponent,
		PublicButtonGroupComponent,
		VerticalSeparatorComponent,
		PublicDateRangePickerComponent,
		HighStockComponent,
		MultiColorBarComponent,
		DirectivesModule,
		MapaParaibaSvgComponent,
		PopupPadraoMapaParaibaComponent,
		PublicDatePickerComponent,
		DatePickerComponent,
		PublicPageAboutComponent,
		PublicSelectMunicipioComponent,
		LoadingComponent,
		ScrollToTopButtonComponent,
		PublicTitleComponent,
		SelectCheckboxesComponent,
		CircularProgressPercentegeComponent,
		TransferListComponent,
		DragNDropInputFileComponent,
		ErrorListComponent,
		IndisponivelMobileComponent,
		InfoGeraisComponent,
		TitleComponent,
		MapaParaibaLeafletComponent,
		PublicSelectVariavelEstacoesAutomaticasComponent,
		CardCalendarioComponent,
		ModalComponent,
		SelectPeriodoCalendarioComponent,
		DayMonthPickerComponent,
		PublicSelectVariavesEstacoesComponent,
		PublicSelectExecucaoClimaComponent,
		AComponent,
		HighchartComponent,
		PublicPaginationComponent,
		CardInfoComponent,
		PageTitleComponent,
		SelectListMenuComponent,
	],
	imports: [
		CommonModule,
		RouterLink,
		InputMaskComponent,
		FormsModule,
		NgSelectModule,
		ReactiveFormsModule,
		DataTablesModule,
		PipesModule,
		AngularEditorModule,
		MaterialAngularModule,
		HighchartsChartModule,
		NgxMaskDirective,
		DirectivesModule,
		BsDropdownModule,
		PopoverModule,
		TooltipModule,
		ModalModule,
		RouterLinkActive,
		NgxFileDragDropModule,
		LayoutModule,
		LeafletModule,
		CalendarCommonModule,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
