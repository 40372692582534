import { Component, Input } from '@angular/core';
import { legendaInfo } from '../../legenda';

@Component({
	selector: 'seira-precipitacao-container',
	templateUrl: './precipitacao-container.component.svg',
	styleUrls: ['./precipitacao-container.component.scss'],
})
export class PrecipitacaoContainerComponent {
	@Input() id!: string | number;
	fonte = 'Fonte: AESA-PB';
	legendaInfo = legendaInfo;
	legendaLabelInfo = Object.keys(legendaInfo)
		.slice(0, 10)
		.map((label, index) => ({
			label,
			position: 101.71127 + 21.350759 * index,
		}));
	legendaColorInfo = Object.values(legendaInfo)
		.slice(1, 10)
		.map((color, index) => ({
			position: 27.612005 + 18.384108 * index,
			color,
		}));
	eixoVerticalLabels = [
		'5,91° S',
		'6,22° S',
		'6,53° S',
		'6,84° S',
		'7,15° S',
		'7,46° S',
		'7,77° S',
		'8,08° S',
		'8,39° S',
	].map((label, index) => ({ position: 94.485519 + 12.99848 * index, label }));
	eixoHorizontalLabels = [
		'38.91° W',
		'38,38° W',
		'37,84° W',
		'37,31° W',
		'36,77° W',
		'36,24° W',
		'35,7° W',
		'35,17° W',
		'34.63° W',
	].map((label, index) => ({ position: 2.706544 + 21.339022 * index, label }));
}
