<div class="d-flex flex-column">
	<label class="text-tertiary fw-semibold mt-3">
		RELATÓRIO PLUVIOMÉTRICO
		{{
			getFormItemValue('tipo')
				? ' - ' +
				  capturaTipoTabelas(getFormItemValue('tipo')) +
				  ' (mm) - ' +
				  formataData(getFormItemValue('periodo'))
				: ''
		}}
	</label>
	<div
		*ngIf="carregandoRelatorio; else tabela"
		class="d-flex justify-content-center align-items-center w-100 h-50"
		style="min-height: 500px">
		<seira-loading [pageCentered]="false"></seira-loading>
	</div>

	<ng-template #tabela>
		<div style="max-width: 1300px" *ngIf="tamanhoTabela; else semDados">
			<seira-card-info aditionalClasses="mt-3">
				Placeholder informativo
			</seira-card-info>
			<seira-tabela-relatorio-media-historica
				[relatorios]="dadosTabelaRelatorio" />
		</div>
	</ng-template>
</div>
<ng-template #semDados>
	<seira-sem-dados />
</ng-template>
