<div class="d-flex w-100 rounded-pill shadow-lg flex-row bg-transparent">
	<div
		*ngFor="let color of colors; let i = index"
		[style.border-radius]="setRadius(i)"
		[style.background-color]="color"
		[style.height]="24 + 'px'"
		[style.width]="percentage()"></div>
</div>

<div class="row">
	<div class="d-flex flex-row col-12 justify-content-center text-tertiary">
		<div
			*ngFor="let label of labels"
			[style.width]="percentage()"
			class="d-flex justify-content-center">
			{{ label }}
		</div>
	</div>
</div>
