<div>
	<form
		[formGroup]="form"
		class="form-group"
		[ngSwitch]="getFormItemValue('tipoVisualizacao')">
		<div id="filtros" class="col-12 flex-row gap-3">
			<div class="col-lg-3">
				<div class="col-lg-11 col-md-4 col-sm-6 mt-3">
					<seira-public-radio-group
						idRadio="modoVisualizacaoEstacoes"
						[options]="OpcoesTipoVisualizacao"
						label="Modo de visualização"
						controlName="tipoVisualizacao"></seira-public-radio-group>
				</div>
				<div class="col-lg-11 col-md-4 col-sm-6 mt-1">
					<seira-public-select
						placeholder="Selecione um tipo"
						label="Tipo"
						[options]="tipos"
						optionValue="value"
						optionLabel="name"
						notFoundText="Nenhum tipo encontrado"
						formControlName="tipo">
					</seira-public-select>
				</div>
				<div
					class="col-lg-11 col-md-4 col-sm-6 mt-1"
					*ngIf="variaveisPeriodoChuvoso">
					<seira-public-select
						placeholder="Selecione um período chuvoso"
						label="Periodo Chuvoso"
						[options]="tiposPeriodosChuvosos"
						optionValue="value"
						optionLabel="name"
						notFoundText="Nenhum período encontrado"
						formControlName="periodoChuvoso">
					</seira-public-select>

					<seira-public-select
						placeholder="Selecione uma região"
						label="Região pluviométrica"
						[options]="regioes"
						optionValue="value"
						optionLabel="name"
						notFoundText="Nenhuma região encontrada"
						formControlName="regiao">
					</seira-public-select>
				</div>

				<div class="col-lg-11 col-md-4 col-sm-6">
					<ng-container *ngIf="variaveisPluviometricas">
						<seira-public-select
							[options]="variaveisMapa"
							optionLabel="label"
							optionValue="value"
							style="width: 100%"
							label="Variável"
							placeholder="Selecione uma variável"
							[formControlName]="FormularioRelatorio.VARIAVEL_MAPA" />
					</ng-container>
				</div>

				<div class="col-lg-11 col-md-4 col-sm-6" *ngIf="mostrarAgrupamento">
					<seira-public-select
						placeholder="selecione um agrupamento"
						label="Agrupamento"
						[options]="optionsAgrupamento"
						optionValue="value"
						optionLabel="name"
						formControlName="agrupamento">
					</seira-public-select>
				</div>
				<div
					class="col-lg-11 col-md-4 col-sm-6 mt-auto"
					*ngIf="mostrarSelectDePostos">
					<seira-public-select
						placeholder="Selecione um posto"
						label="Municipio/Posto"
						[searchable]="true"
						[options]="options"
						optionValue="value"
						optionLabel="name"
						notFoundText="Nenhum posto encontrado"
						formControlName="estacao">
					</seira-public-select>
				</div>
				<div
					class="col-lg-11 col-md-4 col-sm-6 mt-auto"
					*ngIf="mostrarMicrorregioes">
					<seira-public-select
						placeholder="Selecione uma microrregião"
						label="Microrregião"
						optionValue="value"
						optionLabel="name"
						notFoundText="Nenhuma microrregião encontrada"
						formControlName="microrregiao"
						[searchable]="true"
						[options]="microrregioes">
					</seira-public-select>
				</div>
				<div class="col-lg-11 col-md-4 col-sm-6 mt-auto" *ngIf="mostrarRegioes">
					<seira-public-select
						placeholder="Selecione uma região pluviométrica"
						label="Região pluviométrica"
						optionValue="value"
						optionLabel="name"
						notFoundText="Nenhuma região pluviométrica encontrada"
						formControlName="regiao"
						[searchable]="true"
						[options]="regioes">
					</seira-public-select>
				</div>
				<div class="col-lg-11 col-md-4 col-sm-6 mt-auto" *ngIf="mostrarBacias">
					<seira-public-select
						placeholder="Selecione uma bacia"
						label="Bacia"
						optionValue="value"
						optionLabel="name"
						notFoundText="Nenhuma bacia encontrada"
						formControlName="bacia"
						[searchable]="true"
						[options]="bacias">
					</seira-public-select>
				</div>
				<div
					class="col-lg-11 col-md-4 col-sm-6 mt-auto"
					*ngIf="mostrarMesorregioes">
					<seira-public-select
						placeholder="Selecione uma mesorregião"
						label="Mesorregião"
						optionValue="value"
						optionLabel="name"
						notFoundText="Nenhuma mesorregião encontrada"
						formControlName="mesorregiao"
						[searchable]="true"
						[options]="mesorregioes">
					</seira-public-select>
				</div>
				<div
					class="col-lg-11 col-md-4 col-sm-6 mt-auto"
					*ngIf="mostrarMunicipios">
					<seira-public-select
						placeholder="Selecione um município"
						label="Município"
						optionValue="id"
						optionLabel="nome"
						notFoundText="Nenhum município encontrado"
						formControlName="municipio"
						[searchable]="true"
						[options]="municipios">
					</seira-public-select>
				</div>
				<div
					class="col-lg-12 col-md-4 col-sm-6"
					*ngIf="deveMostrarPeriodoBusca">
					<seira-public-radio-group
						[controlName]="FormularioRelatorio.PERIODO_BUSCA"
						[options]="botoesPeriodo"></seira-public-radio-group>
				</div>
				<div
					*ngIf="mostrarDateRangePicker || periodoDiario"
					class="col-lg-11 col-md-4 col-sm-6 d-flex flex-row align-items-end gap-1">
					<seira-public-date-range-picker
						style="width: 100%"
						label="Período"
						controlStartName="dataInicio"
						controlEndName="dataFim"
						startPlaceholder="Inicio"
						endPlaceholder="Fim" />
				</div>

				<ng-container *ngIf="relatorioAtual?.periodos && labelAdptativaPeriodo">
					<div class="col-lg-11 col-md-4 col-sm-6 d-flex flex-column">
						<seira-public-date-picker
							class="w-100"
							controlName="dataInicio"
							[label]="labelAdptativaPeriodo.inicio?.label"
							[placeholder]="labelAdptativaPeriodo.inicio?.placeholder || ''"
							[datePickerType]="
								labelAdptativaPeriodo.inicio?.dataType || 'year'
							"
							[maxDate]="dataFim" />
					</div>
					<div
						class="col-lg-11 col-md-4 col-sm-6 d-flex flex-row align-items-end">
						<seira-public-date-picker
							class="w-100"
							controlName="dataFim"
							[label]="labelAdptativaPeriodo.fim?.label"
							[placeholder]="labelAdptativaPeriodo.fim?.placeholder || ''"
							[datePickerType]="labelAdptativaPeriodo.fim?.dataType || 'year'"
							[minDate]="dataInicio"
							[maxDate]="limiteDataFimSuperior" />
					</div>
				</ng-container>

				<ng-container *ngIf="mostrarDateRangePickerAno">
					<div class="col-lg-11 col-md-4 col-sm-6 d-flex flex-column">
						<seira-public-date-picker
							class="w-100"
							label="Ano inicial"
							placeholder="Selecione o ano inicial"
							controlName="dataInicio"
							datePickerType="year"
							[maxDate]="
								tipoPrecipitacaoAnual ? anoInicialMaxDate : dataMinimaSPI
							" />
					</div>
					<div
						class="col-lg-11 col-md-4 col-sm-6 d-flex flex-row align-items-end">
						<seira-public-date-picker
							class="w-100"
							label="Ano final"
							placeholder="Selecione o ano final"
							controlName="dataFim"
							datePickerType="year" />
					</div>
				</ng-container>
				<div
					*ngIf="mostrarDatePickerAno || periodoAnual"
					class="col-lg-11 col-md-4 col-sm-6 d-flex flex-row align-items-end gap-1">
					<seira-public-date-picker
						style="width: 100%"
						label="Período"
						placeholder="Selecione um período"
						controlName="periodo"
						datePickerType="year" />
				</div>
				<div
					*ngIf="mostrarDatePickerMesAno"
					class="col-lg-11 col-md-4 col-sm-6 d-flex flex-row align-items-end gap-1">
					<seira-public-date-picker
						style="width: 100%"
						label="Período"
						placeholder="Selecione um período"
						controlName="periodo"
						datePickerType="month" />
				</div>
				<ng-container *ngIf="mostrarDateRangePickerMes || periodoMensal">
					<div
						class="col-lg-11 col-md-4 col-sm-6 d-flex flex-row align-items-end gap-1">
						<seira-public-date-picker
							[id]="'dataInicio'"
							style="width: 100%"
							label="Data de início"
							placeholder="Início"
							controlName="dataInicio"
							datePickerType="month" />
					</div>
					<div
						class="col-lg-11 col-md-4 col-sm-6 d-flex flex-row align-items-end gap-1">
						<seira-public-date-picker
							[id]="'dataFim'"
							style="width: 100%"
							label="Data de fim"
							placeholder="Selecione uma data"
							controlName="dataFim"
							[minDate]="dataInicio"
							datePickerType="month" />
					</div>
				</ng-container>
				<div
					class="col-lg-11 col-md-4 col-sm-6 mt-1"
					*ngIf="mostrarSelectMapaDePrecipitacoes">
					<seira-public-select
						placeholder="Selecione uma métrica"
						label="Mapa de precipitação"
						optionValue="value"
						optionLabel="name"
						formControlName="precipitacao"
						[searchable]="true"
						[options]="precipitacoes">
					</seira-public-select>
				</div>

				<div
					*ngIf="deveMostrarRadioPostos"
					class="d-flex flex-column flex-lg-row justify-content-lg-between align-items-lg-end mt-2 gap-3">
					<div class="d-flex flex-column flex-md-row align-items-md-end gap-3">
						<div>
							<seira-public-radio-group
								classRadio="flex-row d-flex gap-3 align-items-center"
								idRadio="opcoesPostosRelatorios"
								[options]="OpcoesPostos"
								label="Postos"
								controlName="posto">
							</seira-public-radio-group>
						</div>
					</div>
				</div>

				<div
					class="col-lg-11 mt-auto d-flex justify-content-between align-items-end"
					style="margin-top: 1rem !important">
					<ng-container *ngTemplateOutlet="botaoGerarRelatorio"></ng-container>

					<seira-button
						*ngIf="
							getFormItemValue('posto') === 'personalizado' &&
							deveMostrarRadioPostos
						"
						classButton="btn btn-link p-0"
						type="button"
						buttonStyle="custom"
						(clicked)="openModal()"
						>Editar...</seira-button
					>
				</div>
			</div>
			<div class="col-lg-6">
				<div class="d-flex flex-colum justify-content-end">
					<seira-public-button-group
						[buttons]="botoesDeExportacao"></seira-public-button-group>
				</div>

				<ng-container #dynamicComponentContainer></ng-container>
			</div>
		</div>

		<ng-template #botaoGerarRelatorio>
			<seira-public-button
				[disabledButton]="form.invalid || !instanceRelatorio"
				[loading]="loadingForm"
				(clicked)="gerarRelatorio()"
				>Gerar</seira-public-button
			>
		</ng-template>
	</form>
</div>
