import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ComponentFactoryResolver,
	inject,
	Injector,
	OnInit,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from '@angular/forms';
import { TextoInformativo } from '@home/submodulos/dados-meteorologicos/interfaces/texto-informativo';
import { PostosRelatorios } from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import { OptionRadio } from '@shared/interfaces/public-radio-group';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalRelatorioPostosComponent } from '@home/submodulos/dados-meteorologicos/componentes/modal-relatorio-postos/modal-relatorio-postos.component';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { Select } from '@layout/interfaces/select';
import { enumAsSelectOptions, obter_erro_request } from '@utils';
import {
	TipoRelatorio,
	Formato,
	FormatoVariaveisMeteorologicas,
	TipoGraficos,
	TiposMapas,
	TipoTabelas,
	TipoVariaveisMeteorologicas,
} from '@home/submodulos/dados-meteorologicos/interfaces/relatorioEnum';
import { Estacao } from '../../interfaces/filtros-opcoes';
import { ToastrService } from 'ngx-toastr';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';
import moment, { Moment } from 'moment';
import { ComponentesRelatoriosService } from '@home/submodulos/dados-meteorologicos/services/componentes-relatorios.service';
import {
	ComponenteRelatorio,
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { PERIODO_MINIMO_SPI_EM_ANOS } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/pluviometro-grafico-spi/pluviometro-grafico-spi.component';
import { TipoPrecipitacao } from '@home/submodulos/dados-meteorologicos/pages/relatorios/enums/tipo-precipitacao';

import {
	LabelVariavelComUnidade,
	ValuesVariaveis,
} from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/interfaces/estacao-monitorada';
import { TiposPeriodosVariaveisMeteorologicas } from '@home/submodulos/dados-meteorologicos/interfaces/variavel-meteorologica-mapa';

/**
 * @deprecated Este é o componente cuja a qual a rota foi removida. Estou marcando como deprecated para
 * em um caso futuro seja removido e sabermos onde irá remover.
 */
@Component({
	selector: 'seira-relatorios',
	templateUrl: './relatorios.component.html',
	styleUrls: ['./relatorios.component.scss'],
})
export class RelatoriosComponent implements OnInit, AfterViewInit {
	@ViewChild('dynamicComponentContainer', { read: ViewContainerRef })
	dynamicComponentContainer!: ViewContainerRef;
	form!: FormGroup;
	private listaRelatorios = inject(
		ComponentesRelatoriosService
	).getRelatorios();
	relatorioAtual?: ComponenteRelatorio;
	relatorioAnterior?: ComponenteRelatorio;
	instanceRelatorio?: InstanciaRelatorio;
	categorias: Select[] = [];
	formatos: Select[] = [];
	variaveis: Select[] = [];

	tipos: Select[] = [];
	microrregioes: Select[] = [{ value: '0', name: 'Estado completo' }];
	estacoes: Estacao[] = [];
	loadingEstacoes = false;
	postos: PostosRelatorios[] = [];
	options: any[] = [];
	informativo: TextoInformativo = {
		label: 'INFORMAÇÕES GERAIS',
		texts: [
			{
				title: 'O que são relatórios?',
				text: 'São documentos horários, diários, mensal ou anual que informam sobre o comportamento das chuvas em determinado local. Compostos por mapas, gráficos, tabelas e textos que retratam o comportamento da chuva.',
			},
		],
	};

	botoesDeExportacao: GroupButton[] = [];
	tiposPeriodos = enumAsSelectOptions(TiposPeriodosVariaveisMeteorologicas);
	variaveisMapa = [
		{
			label: LabelVariavelComUnidade.PRECIPITACAO_ACUMULADA,
			value: ValuesVariaveis.PRECIPITACAO,
		},
		{
			label: LabelVariavelComUnidade.TEMPERATURA_MEDIA_DO_AR,
			value: ValuesVariaveis.TEMPERATURA_DO_AR,
		},
	];

	OpcoesPostos: OptionRadio<'todos_estados' | 'personalizado'>[] = [
		{ label: 'Selecionar todos do Estado', value: 'todos_estados' },
		{ label: 'Personalizado', value: 'personalizado' },
	];

	precipitacoes: Select[] = Object.values(TipoPrecipitacao).map(value => ({
		value,
		name: value.charAt(0).toUpperCase() + value.slice(1),
	}));

	inputsRelatorioInjector!: Injector;

	constructor(
		private formBuilder: FormBuilder,
		private modalService: BsModalService,
		private toastr: ToastrService,
		private relatoriosService: RelatoriosService,
		private cdr: ChangeDetectorRef,
		private componentFactoryResolver: ComponentFactoryResolver
	) {
		const hoje = new Date();
		const mesPassado = new Date();
		mesPassado.setDate(hoje.getDate() - 30);

		this.form = this.formBuilder.group({
			[FormularioRelatorio.CATEGORIA]: new FormControl<
				keyof typeof TipoRelatorio | null
			>('EVAPOTRANSPIRACAO', Validators.required),
			[FormularioRelatorio.FORMATO]: new FormControl<
				keyof typeof Formato | null
			>('TABELA', Validators.required),
			[FormularioRelatorio.TIPO]: new FormControl<
				| keyof typeof TipoTabelas
				| keyof typeof TipoGraficos
				| keyof typeof TiposMapas
				| null
			>('PRECIPITACAO_DIARIA'),
			[FormularioRelatorio.VARIAVEL]: new FormControl<string | null>(
				'PRECIPITACAO'
			),
			[FormularioRelatorio.MICRORREGIAO]: new FormControl<string | null>(null),
			[FormularioRelatorio.PRECIPITACAO]: new FormControl<string | null>(
				TipoPrecipitacao.ACUMULADA
			),
			[FormularioRelatorio.DATA_INICIO]: new FormControl<Moment>(
				moment(mesPassado)
			),
			[FormularioRelatorio.DATA_FIM]: new FormControl<Moment>(moment(hoje)),
			[FormularioRelatorio.POSTO]: new FormControl('todos_estados'),
			[FormularioRelatorio.ESTACAO]: new FormControl(),
			[FormularioRelatorio.PERIODO]: new FormControl<moment.Moment | null>(
				moment(hoje)
			),
			[FormularioRelatorio.LOADING_GERAR]: new FormControl<boolean>(false),
			[FormularioRelatorio.VARIAVEL_MAPA]: new FormControl<null | string>(
				ValuesVariaveis.PRECIPITACAO
			),
			[FormularioRelatorio.TIPO_PERIODO]: new FormControl<null | string>(
				'ANUAL'
			),
		});
		this.setInjector();
	}
	setInjector() {
		this.inputsRelatorioInjector = Injector.create({
			providers: [
				{
					provide: INPUTS_RELATORIOS,
					useValue: {
						form: this.form,
						postos: this.postos,
						estacoes: this.estacoes,
						microrregioes: this.microrregioes,
						setLoading: this.setLoading,
					},
				},
			],
		});
	}
	get loadingForm() {
		return !!this.form.get('loadingGerar')!.value;
	}
	setLoading(state: boolean) {
		this.form.get('loadingGerar')?.setValue(state);
	}
	ngAfterViewInit() {
		this.relatorioAtual =
			this.listaRelatorios.PLUVIOMETRICO.TABELA.PRECIPITACAO_DIARIA;
		this.loadDynamicComponent(this.relatorioAtual.componente);
		this.cdr.detectChanges();
	}

	formato(formato: string[]) {
		return formato.includes(this.getFormItemValue(FormularioRelatorio.FORMATO));
	}
	categoriaFormatoTipo(
		categorias: Array<keyof typeof TipoRelatorio> | null = null,
		formatos: Array<keyof typeof Formato> | null = null,
		tipos: Array<
			| keyof typeof TipoTabelas
			| keyof typeof TipoGraficos
			| keyof typeof TiposMapas
		> | null = null
	) {
		const categoria = this.getFormItemValue('categoria');
		const formato = this.getFormItemValue('formato');
		const tipo = this.getFormItemValue('tipo');
		return (
			this.condicaoCategoriaFormatoTipo(categorias, categoria) &&
			this.condicaoCategoriaFormatoTipo(formatos, formato) &&
			this.condicaoCategoriaFormatoTipo(tipos, tipo)
		);
	}
	condicaoCategoriaFormatoTipo(array: string[] | null, valorNoForm: string) {
		if (array === null) {
			return true;
		}
		return array.includes(valorNoForm);
	}
	get mostrarSelectVariaveisGrafico() {
		return this.categoriaFormatoTipo(
			['VARIAVEL_METEOROLOGICA'],
			['GRAFICO'],
			null
		);
	}
	get mostrarDateRangePicker() {
		return (
			this.categoriaFormatoTipo(['EVAPOTRANSPIRACAO'], ['MAPA'], ['IAPM']) ||
			this.categoriaFormatoTipo(
				['EVAPOTRANSPIRACAO'],
				['TABELA'],
				['PRECIPITACAO_DIARIA']
			) ||
			this.categoriaFormatoTipo(
				['VARIAVEL_METEOROLOGICA'],
				['TABELA', 'GRAFICO'],
				null
			) ||
			this.categoriaFormatoTipo(['EVAPOTRANSPIRACAO'], ['TABELA'], null)
		);
	}

	get mostrarDateRangePickerAno() {
		return (
			this.categoriaFormatoTipo(
				['EVAPOTRANSPIRACAO'],
				['TABELA'],
				['PRECIPITACAO_ANUAL']
			) ||
			this.categoriaFormatoTipo(['EVAPOTRANSPIRACAO'], ['GRAFICO'], ['SPI'])
		);
	}
	get mostrarSelectsVariaveisMeteorologicasMapa() {
		return this.categoriaFormatoTipo(['VARIAVEL_METEOROLOGICA'], ['MAPA']);
	}

	get mostrarDateRangePickerMes() {
		return (
			this.categoriaFormatoTipo(
				['EVAPOTRANSPIRACAO'],
				['MAPA'],
				['CHUVA_ESTADO']
			) ||
			(this.mostrarSelectsVariaveisMeteorologicasMapa &&
				this.getFormItemValue(FormularioRelatorio.TIPO_PERIODO) === 'MENSAL')
		);
	}
	get mostrarDatePickerMesAno() {
		return (
			this.categoriaFormatoTipo(
				['EVAPOTRANSPIRACAO'],
				['TABELA'],
				['PLUVIOMETRICO_DO_ESTADO']
			) ||
			this.categoriaFormatoTipo(
				['EVAPOTRANSPIRACAO'],
				['MAPA'],
				['QUANTIS_MENSAL']
			)
		);
	}
	get mostrarDatePickerAno() {
		return (
			this.categoriaFormatoTipo(
				['EVAPOTRANSPIRACAO'],
				['GRAFICO'],
				['QUANTIS_ANUAL', 'QUANTIS_MENSAL']
			) ||
			this.categoriaFormatoTipo(
				['EVAPOTRANSPIRACAO'],
				['TABELA'],
				['PLUVIOMETRICO_POR_POSTO', 'PRECIPITACAO_ACUMULADA', 'MEDIA_HISTORICA']
			) ||
			this.categoriaFormatoTipo(
				['EVAPOTRANSPIRACAO'],
				['MAPA'],
				['QUANTIS_ANUAL']
			)
		);
	}
	get mostrarMicrorregioes() {
		return this.categoriaFormatoTipo(
			['EVAPOTRANSPIRACAO'],
			['MAPA'],
			['QUANTIS_MENSAL', 'QUANTIS_ANUAL']
		);
	}
	get mostrarSelectDePostos() {
		return (
			this.categoriaFormatoTipo(
				['EVAPOTRANSPIRACAO'],
				['GRAFICO'],
				['QUANTIS_MENSAL', 'QUANTIS_ANUAL']
			) ||
			this.categoriaFormatoTipo(
				['VARIAVEL_METEOROLOGICA'],
				['TABELA', 'GRAFICO'],
				null
			)
		);
	}
	get mostrarSelectMapaDePrecipitacoes() {
		return this.categoriaFormatoTipo(
			['EVAPOTRANSPIRACAO'],
			['MAPA'],
			['CHUVA_ESTADO']
		);
	}
	get mostrarRadioPostos() {
		return this.categoriaFormatoTipo(
			['EVAPOTRANSPIRACAO'],
			['TABELA'],
			[
				'PRECIPITACAO_ACUMULADA',
				'PRECIPITACAO_ANUAL',
				'PRECIPITACAO_DIARIA',
				'MEDIA_HISTORICA',
				'PLUVIOMETRICO_POR_POSTO',
			]
		);
	}

	get tipoPrecipitacaoAnual() {
		return this.getFormItemValue('tipo') === 'PRECIPITACAO_ANUAL';
	}

	get categoriaPluviometrico() {
		return this.getFormItemValue('categoria') === 'PLUVIOMETRICO';
	}

	get isGerarRelatorioDisabled() {
		const categoria = this.getFormItemValue('categoria');
		const formato = this.getFormItemValue('formato');
		const tipo = this.getFormItemValue('tipo');
		const dataInicio = this.getFormItemValue('dataInicio');
		const dataFim = this.getFormItemValue('dataFim');

		if (!categoria || !formato || (categoria === 'PLUVIOMETRICO' && !tipo))
			return true;

		switch (categoria) {
			case 'VARIAVEL_METEOROLOGICA':
				const variavel = this.getFormItemValue('variavel');
				if (formato && dataInicio && dataFim && variavel) return false;
				break;
			case 'EVAPOTRANSPIRACAO':
				if (dataFim && dataInicio) return false;
				break;
			case 'PLUVIOMETRICO':
				const estacao = this.getFormItemValue('estacao');
				const periodo = this.getFormItemValue('periodo');

				if (tipo === 'PRECIPITACAO_ANUAL') return !!dataInicio && !!dataFim;

				if (
					estacao &&
					periodo &&
					formato === 'GRAFICO' &&
					(tipo === 'QUANTIS_MENSAL' || tipo === 'QUANTIS_ANUAL')
				)
					return false;

				if (dataInicio && dataFim && formato === 'GRAFICO' && tipo === 'SPI')
					return false;

				if (formato === 'TABELA' && dataInicio && dataFim) return false;

				break;
			default:
				return true;
		}
		return true;
	}

	observarRadioPostos() {
		this.form.get('posto')?.valueChanges.subscribe(value => {
			if (value === 'todos_estados') {
				this.buscarEstacoes();
			}
		});
	}

	observarCategorias() {
		this.form.get('categoria')?.valueChanges.subscribe(categoria => {
			switch (categoria) {
				case 'EVAPOTRANSPIRACAO':
					this.formatos = enumAsSelectOptions<string>(Formato).filter(
						value => value.name === Formato.TABELA
					);
					break;
				case 'PLUVIOMETRICO':
					this.formatos = enumAsSelectOptions(Formato);
					this.retornarTodasAsEstacoes();
					break;
				case 'VARIAVEL_METEOROLOGICA':
					this.formatos = enumAsSelectOptions(Formato);
					const formato = this.getFormItemValue('formato');
					break;
				default:
					this.formatos = enumAsSelectOptions(Formato);
			}
			if (this.formatos.length == 1) {
				this.form.patchValue(
					{ formato: this.formatos[0].value },
					{ emitEvent: false }
				);
			}
			const formato = this.getFormItemValue('formato');
			this.analiseFormato(formato);
		});
	}

	retornarTodasAsEstacoes() {
		this.options = [];
		this.estacoes.forEach(estacao => {
			this.options.push({
				label:
					estacao.tipoEstacao === 'PLUVIOMETRO_CONVENCIONAL'
						? `${estacao.nomePosto} - Pluviômetro Convencional`
						: `${estacao.nomePosto} - ${estacao.tipoEstacao}`,
				value: estacao.id,
				tipo: estacao.tipoEstacao,
			});
		});
	}
	observarFormatos() {
		this.form.get('formato')?.valueChanges.subscribe(formato => {
			this.analiseFormato(formato);
		});
	}
	analiseFormato(formato: string) {
		switch (formato) {
			case 'GRAFICO':
				this.tipos = enumAsSelectOptions(TipoGraficos);
				if (this.getFormItemValue('categoria') === 'VARIAVEL_METEOROLOGICA')
					this.retornarTodasAsEstacoes();
				break;
			case 'MAPA':
				this.tipos = enumAsSelectOptions(TiposMapas);
				if (this.getFormItemValue('categoria') === 'VARIAVEL_METEOROLOGICA')
					this.ajustarEstacoesSomentePCD();
				break;
			default:
				if (this.getFormItemValue('categoria') === 'VARIAVEL_METEOROLOGICA')
					this.ajustarEstacoesSomentePCD();
				this.tipos = enumAsSelectOptions(TipoTabelas);
		}
		this.form.patchValue(
			{
				tipo: this.tipos[0].value,
			},
			{ emitEvent: false }
		);
	}

	ajustarEstacoesSomentePCD() {
		this.options = this.options.filter(o => o.tipo === 'PCD');
		this.options.unshift({ label: 'Estado completo', value: 0 });
	}

	gerarRelatorio() {
		this.instanceRelatorio?.gerarRelatorio();
	}

	ngOnInit(): void {
		this.categorias = enumAsSelectOptions(TipoRelatorio);
		this.formatos = enumAsSelectOptions(Formato);
		this.tipos = enumAsSelectOptions(TipoTabelas);
		this.variaveis = enumAsSelectOptions(TipoVariaveisMeteorologicas);
		this.buscarEstacoes();
		this.observarRadioPostos();
		this.observarCategorias();
		this.observarFormulario();
		this.observarFormatos();
	}

	loadDynamicComponent(component: any): void {
		if (this.relatorioAtual !== this.relatorioAnterior) {
			if (this.dynamicComponentContainer.get(0)) {
				this.dynamicComponentContainer.remove(0);
			}
			if (component) {
				const componentFactory =
					this.componentFactoryResolver.resolveComponentFactory(component);
				const componentRef = this.dynamicComponentContainer.createComponent(
					componentFactory,
					undefined,
					this.inputsRelatorioInjector
				);
				this.instanceRelatorio = componentRef.instance as InstanciaRelatorio;
				this.botoesDeExportacao =
					this.instanceRelatorio.botoesDeExportacao || [];
				if (
					!this.instanceRelatorio.postos?.length ||
					!this.instanceRelatorio.estacoes?.length ||
					!this.instanceRelatorio.microrregioes?.length ||
					!this.instanceRelatorio.microrregioes?.length
				) {
					this.instanceRelatorio.postos = this.postos;
					this.instanceRelatorio.estacoes = this.estacoes;
					this.instanceRelatorio.microrregioes = this.microrregioes;
					this.instanceRelatorio.precipitacao = this.precipitacoes;
				}
			} else {
				this.instanceRelatorio = undefined;
			}
		}
		this.relatorioAnterior = this.relatorioAtual;
	}

	observarFormulario() {
		this.form.valueChanges.subscribe({
			next: ({
				categoria,
				formato,
				tipo,
			}: {
				categoria?: keyof typeof TipoRelatorio;
				formato?:
					| keyof typeof Formato
					| keyof typeof FormatoVariaveisMeteorologicas;
				tipo?:
					| keyof typeof TipoTabelas
					| keyof typeof TipoGraficos
					| keyof typeof TiposMapas
					| keyof typeof TipoVariaveisMeteorologicas;
			}) => {
				if (categoria && this.listaRelatorios.hasOwnProperty(categoria)) {
					const categorias = this.listaRelatorios[categoria] as any;
					if (formato && categorias.hasOwnProperty(formato)) {
						const formatos = categorias[formato];
						if (tipo && formatos.hasOwnProperty(tipo)) {
							this.relatorioAtual = formatos[tipo];
						} else {
							this.relatorioAtual = formatos;
						}
					} else {
						this.relatorioAtual = categorias;
					}
				}
				this.loadDynamicComponent(this.relatorioAtual?.componente);
			},
		});
	}

	buscarEstacoes() {
		this.loadingEstacoes = true;
		this.relatoriosService.consultarOpcoesFiltros().subscribe({
			next: resp => {
				this.estacoes = resp.estacoes;
				this.form.patchValue({
					estacao: resp.estacoes?.[0].id,
				});
				this.postos = resp.estacoes.map(estacao => ({
					id: estacao.id,
					municipio: estacao.nomeMunicipio,
					nomePosto: estacao.nomePosto,
					tipo: estacao.tipoEstacao,
					responsavel: estacao.responsavel ?? '-',
				}));
				if (this.instanceRelatorio) {
					this.instanceRelatorio.estacoes = this.estacoes;
					this.instanceRelatorio.postos = this.postos;
				}
				if (this.microrregioes.length < 2) {
					resp.microrregioes.map(val => {
						this.microrregioes.push({
							value: `${val.id}`,
							name: val.nome,
						});
					});
				}
				if (this.options.length < 1) {
					resp.estacoes.map(value => {
						if (value.statusEstacao === 'ATIVA') {
							this.options.push({
								label:
									value.tipoEstacao === 'PLUVIOMETRO_CONVENCIONAL'
										? `${value.nomePosto} - Pluviômetro Convencional`
										: `${value.nomePosto} - ${value.tipoEstacao}`,
								value: value.id,
								tipo: value.tipoEstacao,
							});
						}
					});
				}
				this.setInjector();
				this.loadingEstacoes = false;
			},
			error: err => {
				const msg_erro = obter_erro_request(err);
				this.toastr.error(
					msg_erro,
					'Erro ao buscar as informações das estações'
				);
				this.loadingEstacoes = false;
			},
		});
	}

	openModal() {
		const closeModal = () => {
			this.modalService.hide();
		};
		const postosSelecionados = (postos: PostosRelatorios[]) => {
			this.postos = postos;
			if (this.instanceRelatorio) {
				this.instanceRelatorio.postos = postos;
			}
			closeModal();
		};
		this.modalService.show(ModalRelatorioPostosComponent, {
			backdrop: 'static',
			class: 'modal-xl modal-dialog-centered bg-transparent',
			initialState: {
				dadosSelecionados: this.postos.filter(posto => posto.selected),
				close: closeModal,
				onPostosSelecionados: postosSelecionados,
			},
		});
	}

	getFormItemValue(field: string) {
		return this.form.get(field)!.value;
	}

	get dataMinimaSPI() {
		const dataFim: Moment = this.getFormItemValue('dataFim');
		return moment(dataFim)
			.subtract(PERIODO_MINIMO_SPI_EM_ANOS, 'years')
			.toDate();
	}

	get anoInicialMaxDate() {
		const dataFim: Moment = this.getFormItemValue('dataFim');
		return dataFim.toDate();
	}

	get calcularDataInicio() {
		const dataInicio = new Date(this.getFormItemValue('dataInicio'));
		return dataInicio;
	}

	get calcularDataFim() {
		if (this.getFormItemValue(FormularioRelatorio.TIPO_PERIODO) !== 'MENSAL') {
			const dataInicio = new Date(this.getFormItemValue('dataInicio'));
			const dataAtual = new Date();

			const diferencaRangeInicioAtual = Math.abs(
				(dataAtual.getFullYear() - dataInicio.getFullYear()) * 12 +
					dataAtual.getMonth() -
					dataInicio.getMonth()
			);

			if (diferencaRangeInicioAtual <= 6) {
				const newDataInicio = new Date(dataInicio);
				newDataInicio.setMonth(
					newDataInicio.getMonth() + diferencaRangeInicioAtual
				);
				return newDataInicio;
			}

			const dataFim = new Date(this.getFormItemValue('dataFim'));

			if (dataFim) {
				const diferencaRangeInicioFim = Math.abs(
					(dataFim.getFullYear() - dataInicio.getFullYear()) * 12 +
						dataFim.getMonth() -
						dataInicio.getMonth()
				);

				if (diferencaRangeInicioFim > 6) {
					this.form.get('dataFim')?.setValue(null);
					const newDataInicio = new Date(dataInicio);
					newDataInicio.setMonth(newDataInicio.getMonth() + 6);
					return newDataInicio;
				}

				if (
					diferencaRangeInicioFim === 0 &&
					dataInicio.getMonth() > dataFim.getMonth()
				) {
					this.form.get('dataFim')?.setValue(null);
				}
			}

			const newDataInicio = new Date(dataInicio);
			newDataInicio.setMonth(newDataInicio.getMonth() + 6);
			return newDataInicio;
		}
		return new Date();
	}

	protected readonly FormularioRelatorio = FormularioRelatorio;
}
