import { Component, Input, OnInit } from '@angular/core';
import {
	ControlContainer,
	FormControl,
	FormGroupDirective,
} from '@angular/forms';

@Component({
	selector: 'seira-public-date-range-picker',
	templateUrl: './public-date-range-picker.component.html',
	styleUrls: ['./public-date-range-picker.component.scss'],
	viewProviders: [
		{ provide: ControlContainer, useExisting: FormGroupDirective },
	],
})
export class PublicDateRangePickerComponent implements OnInit {
	@Input() controlStartName!: string;
	@Input() controlEndName!: string;
	@Input() startPlaceholder = '';
	@Input() endPlaceholder = '';
	@Input() label?: string;
	@Input() intervaloDias?: number = 0;

	dataMaxima!: Date;

	controlStart = new FormControl<Date | null>(null);
	controlEnd = new FormControl<Date | null>(null);

	constructor(private controlContainer: ControlContainer) {}

	ngOnInit(): void {
		this.controlStart = this.controlContainer.control?.get(
			this.controlStartName
		) as FormControl;
		this.controlEnd = this.controlContainer.control?.get(
			this.controlEndName
		) as FormControl;

		if (this.intervaloDias && this.controlStart?.value) {
			this.dataMaxima = this.aumentaDias(
				this.controlStart.value,
				this.intervaloDias as number
			);
			this.controlStart?.valueChanges.subscribe((newValue: any) => {
				if (newValue) {
					this.dataMaxima = this.aumentaDias(
						newValue,
						this.intervaloDias as number
					);
				}
			});

			this.controlEnd?.valueChanges.subscribe(value => {
				if (value) {
					this.dataMaxima = new Date();
				}
			});
		}
	}

	aumentaDias(dateValue: Date | string, dias: number) {
		const dataMaxima = new Date(dateValue);
		dataMaxima.setDate(dataMaxima.getDate() + dias);
		const dataAtual = new Date();

		if (dataMaxima > dataAtual) {
			return dataAtual;
		}
		return dataMaxima;
	}
}
