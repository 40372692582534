import { NgModule } from '@angular/core';
import { InputTypeDirective } from './input-type.directive';
import { DatePickerFormatDirective } from './date-picker-format.directive';
import { ClickOutsideDirective } from './click-outside.directive';

@NgModule({
	declarations: [
		InputTypeDirective,
		DatePickerFormatDirective,
		ClickOutsideDirective,
	],
	imports: [],
	exports: [
		InputTypeDirective,
		DatePickerFormatDirective,
		ClickOutsideDirective,
	],
})
export class DirectivesModule {}
