<div class="d-flex flex-column">
	<label class="text-tertiary fw-semibold mt-3">
		RELATÓRIO DE VARIÁVEIS METEOROLÓGICAS
	</label>
	<div style="max-width: 1300px">
		<div *ngIf="!todas_estacoes">
			<div *ngIf="historicolength; else semDados">
				<seira-card-info aditionalClasses="mt-3">
					Placeholder informativo
				</seira-card-info>
				<seira-tabela-relatorio-variaveis-meteorologicas
					[dadosTabelaVariaveis]="
						dadosTabelaVariaveis
					"></seira-tabela-relatorio-variaveis-meteorologicas>
			</div>
		</div>
	</div>
</div>
<ng-template #semDados>
	<seira-sem-dados />
</ng-template>
<div *ngIf="todas_estacoes">
	<div class="d-flex justify-content-center mt-4 mb-2 w-100">
		<div
			class="py-2 px-3 my-2 me-2 bg-white d-inline-flex flex-row align-items-center rounded shadow-lg card text-tertiary fw-light">
			Registros disponíveis somente para exportação
		</div>
	</div>
</div>
