import { Component, inject, OnInit, ViewChild } from '@angular/core';
import {
	DadosDesvioRelatorio,
	RelatorioDesvio,
} from '../../interfaces/response';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
} from '../../interfaces/tipos-relatorios';
import { Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Moment } from 'moment';
import { RelatoriosService } from '../../services/relatorios.service';
import { obter_erro_request } from '@utils';
import { PublicTableComponent } from '@componentes/public-table/public-table.component';
import { ADTColumns } from 'angular-datatables/src/models/settings';
import { format } from 'date-fns';
import { DateTimeUtils } from '@utils/datetime-util';
import { DesvioService } from '@services/desvio/desvio.service';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import * as pdfseira from '@utils/pdf-seira';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { DocumentExporter } from '@utils/document-exporter';
import { Agrupamento } from '../../submodulos/monitoramento/interfaces/estacao-monitorada';

export enum TabelaRelatorioDesvioPeriodos {
	DIARIO = 365,
	MENSAL = 12,
	ANUAL = 10,
}

interface OpcaoColuna {
	name: string;
	isFixed: boolean;
}

@Component({
	selector: 'tabela-relatorio-desvio',
	templateUrl: './tabela-relatorio-desvio.component.html',
	styleUrls: ['./tabela-relatorio-desvio.component.scss'],
})
// TODO: refatorar métodos dentro do componente (estão longe da melhor forma de se fazer)
export class TabelaRelatorioDesvioComponent implements OnInit {
	relatorios: RelatorioDesvio[] = [];
	taCarregando = false;
	@ViewChild('tabelaRelatorioDesvios', { static: false })
	readonly tabela?: PublicTableComponent;
	private readonly inputs = inject(INPUTS_RELATORIOS);
	private readonly colunasPadrao: ADTColumns[] = [
		{
			data: 'data',
			title: 'Data',
			type: 'date',
			className: 'text-start',
			render: valor => this.getStringDataDesvio(valor),
		},
		{
			data: 'desvioMilimetro',
			title: 'Desvio (mm)',
			type: 'number',
			className: 'text-start',
			render: texto => texto + ' mm',
		},
		{
			data: 'desvioPorcentagem',
			title: 'Desvio (%)',
			type: 'number',
			className: 'text-start',
			render: texto => texto + '%',
		},
	];
	private readonly colunasAdicionais: ADTColumns[] = [
		{
			title: 'Microrregião',
			type: 'string',
			className: 'text-start',
			defaultContent: '-',
			render: _ => this.relatorios[0]?.microrregiao,
		},
		{
			title: 'Mesorregião',
			type: 'string',
			defaultContent: '-',
			className: 'text-start',
			render: _ => this.relatorios[0]?.mesorregiao,
		},
		{
			title: 'Região',
			type: 'string',
			defaultContent: '-',
			className: 'text-start',
			render: _ => this.relatorios[0]?.regiaoPluviometrica,
		},
		{
			title: this.relatorios[0]?.subBacia ? 'Sub-bacia' : 'Bacia',
			type: 'string',
			className: 'text-start',
			defaultContent: '-',
			render: _ => {
				if (this.relatorios.length == 0) {
					return '';
				}
				return this.relatorios[0]?.bacia || this.relatorios[0]?.subBacia;
			},
		},
		{
			title: 'Município',
			type: 'string',
			defaultContent: '-',
			className: 'text-start',
			render: _ => this.relatorios[0]?.municipio,
		},
	];
	colunas: ADTColumns[] = [...this.colunasPadrao, ...this.colunasAdicionais];
	dataInicioRelatorioGerado?: Date;
	dataFimRelatorioGerado?: Date;
	agrupamentoRelatorioGerado?: string;
	tipoPeriodoRelatorioGerado?: string;
	_colunasOcultas: string[] = [];
	jaGerouAoMenosUmaVez = false;

	get colunasOcultas(): string[] {
		return this._agrupamento === 'MUNICIPIO_POSTO' ? this._colunasOcultas : [];
	}

	set colunasOcultas(array: string[]) {
		this._colunasOcultas = array;
	}

	setColunasSelecionadas(colunasSelecionadas: string[]): void {
		this.colunas = [...this.colunasPadrao, ...this.colunasAdicionais];
		const opcoes = this.opcoesColunas.map(option => option.name);
		this.colunasOcultas = opcoes.filter(e => {
			return !colunasSelecionadas.includes(e!);
		});
		this.colunas = this.colunas.filter(
			e => !this.colunasOcultas.includes(e.title!)
		);
		this.gerarRelatorio(this.colunas);
	}

	constructor(
		private toast: ToastrService,
		private readonly relatorioService: RelatoriosService
	) {
		this.setValidators();
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.valueChanges.subscribe({
				next: () => {
					this.colunasOcultas = [];
					this.setValidators();
				},
			});
	}

	get opcoesColunas(): OpcaoColuna[] {
		return [...this.colunasPadrao, ...this.colunasAdicionais].map(e => {
			return { name: e.title!, isFixed: false };
		});
	}

	ngOnInit(): void {
		this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)?.setValue('mensal');
	}

	ngOnDestroy(): void {
		this.clearValidators();
	}

	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => this.exportarSePossivel(() => this.exportarPDF()),
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => this.exportarSePossivel(() => this.exportarCSV()),
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => this.exportarSePossivel(() => this.exportarTXT()),
		},
	];

	exportarSePossivel(funcaoExportacao: () => void) {
		if (!this.jaGerouAoMenosUmaVez) {
			this.toast.info(
				`Não é possível gerar o arquivo para um relatório sem dados`,
				'Relatório sem dados'
			);
			return;
		}
		if (this.colunas.length === 0) {
			this.toast.info(
				`Não é possível gerar o arquivo com todas as colunas da tabela ocultas`,
				'Sem colunas para exibir'
			);
			return;
		}
		funcaoExportacao();
	}

	private get nomeArquivo(): string {
		return `${
			this.agrupamentoRelatorioGerado === 'MUNICIPIO_POSTO'
				? 'municipio_posto'
				: (this.agrupamentoRelatorioGerado || '').toLowerCase()
		}_${this.getStringDataDesvio(
			this.dataInicio
		)}_ate_${this.getStringDataDesvio(this.dataFim)}`;
	}

	exportarTXT(): void {
		let dadosTxt = '';
		this.relatorios.forEach(rel => {
			rel.desvios.forEach(({ data, desvioMilimetro, desvioPorcentagem }) => {
				dadosTxt += this.getColunaExportacao(
					'Data',
					`${this.getStringDataDesvio(data)}\n`
				);
				dadosTxt += this.getColunaExportacao(
					'Desvio (mm)',
					`${desvioMilimetro}\n`
				);
				dadosTxt += this.getColunaExportacao(
					'Desvio (%)',
					`${desvioPorcentagem}\n`
				);
				if (this.agrupamentoRelatorioGerado === 'MUNICIPIO_POSTO') {
					const {
						microrregiao,
						mesorregiao,
						regiaoPluviometrica,
						subBacia,
						bacia,
						municipio,
					} = this.relatorios[0];
					dadosTxt += this.getColunaExportacao(
						'Microrregião',
						`${microrregiao}\n`
					);
					dadosTxt += this.getColunaExportacao(
						'Mesorregião',
						`${mesorregiao}\n`
					);
					dadosTxt += this.getColunaExportacao(
						'Região pluviométrica',
						`${regiaoPluviometrica}\n`
					);
					dadosTxt += this.getColunaExportacao(
						`${subBacia ? 'Sub-bacia' : 'Bacia'}`,
						`${subBacia || bacia || '-'}\n`
					);
					dadosTxt += `Município: ${municipio}\n`;
				} else {
					if (this.agrupamentoRelatorioGerado === 'MICRORREGIAO') {
						dadosTxt += `Município / Posto: ${rel.municipio} / ${rel.estacao}\n`;
					} else if (this.agrupamentoRelatorioGerado === 'MUNICIPIO') {
						dadosTxt += `Estação: ${rel.estacao}\n`;
					} else {
						const labelAgrupamento =
							Agrupamento[
								this.agrupamentoRelatorioGerado as keyof typeof Agrupamento
							];
						const chave =
							this.agrupamentoRelatorioGerado === 'REGIAO_PLUVIOMETRICA'
								? 'regiaoPluviometrica'
								: (this.agrupamentoRelatorioGerado || '').toLowerCase();
						dadosTxt += `${labelAgrupamento}: ${
							rel[chave as keyof RelatorioDesvio]
						}\n`;
					}
				}
			});
		});
		DocumentExporter.gerarTXT(dadosTxt, this.nomeArquivo);
	}

	private getColunaExportacao(label: string, valor: string): string {
		if (
			this.colunasOcultas
				.map(e => (e === 'Região' ? 'Região pluviométrica' : e))
				.includes(label)
		) {
			return '';
		}
		return `${label}: ${valor}`;
	}

	exportarCSV(): void {
		const dadosTabela: (string | number)[][] = [];
		const cabecalhoCompleto: (string | number)[] = this.cabecalhos.filter(
			cab => !this.colunasOcultas.includes(cab)
		);
		if (this.agrupamentoRelatorioGerado !== 'MUNICIPIO_POSTO') {
			let labelAgrupamento: string;
			if (this.agrupamentoRelatorioGerado === 'MICRORREGIAO') {
				labelAgrupamento = 'Município / Posto';
			} else if (this.agrupamentoRelatorioGerado === 'MUNICIPIO') {
				labelAgrupamento = 'Estação';
			} else {
				labelAgrupamento =
					Agrupamento[
						this.agrupamentoRelatorioGerado as keyof typeof Agrupamento
					];
			}
			cabecalhoCompleto.push(labelAgrupamento);
		}
		dadosTabela.push(cabecalhoCompleto);
		const desviosAgrupados: Array<Record<string, any>> = [];
		this.relatorios.forEach(rel => {
			desviosAgrupados.push(
				...rel.desvios.map(d => {
					let obj: Object = { ...d };
					if (this.agrupamentoRelatorioGerado === 'MUNICIPIO_POSTO') {
						const [relatorio] = this.relatorios;
						obj = {
							...obj,
							...{
								microrregiao: relatorio.microrregiao,
								mesorregiao: relatorio.mesorregiao,
								regiaoPluviometrica: relatorio.regiaoPluviometrica,
								subBacia: relatorio.subBacia,
								bacia: relatorio.bacia,
								municipio: relatorio.municipio,
							},
						};
					} else if (
						['MICRORREGIAO', 'MUNICIPIO'].includes(
							this.agrupamentoRelatorioGerado || ''
						)
					) {
						obj = {
							...obj,
							estacao: rel.estacao,
							municipio: rel.municipio,
						};
					} else {
						obj = {
							...obj,
							bacia: rel.bacia,
							regiaoPluviometrica: rel.regiaoPluviometrica,
							mesorregiao: rel.mesorregiao,
						};
					}
					obj = this.deletarColunasOcultasDesvio(obj);
					return obj;
				})
			);
		});
		desviosAgrupados.forEach(d => {
			const valores: any[] = [];
			const titulosColsOcultas = this.colunasOcultas.map(tituloCol =>
				this.tituloColunaToChaveDesvio(tituloCol)
			);
			this.colunas
				.map(col => this.tituloColunaToChaveDesvio(col.title!))
				.forEach(chave => {
					if (!titulosColsOcultas.includes(chave)) {
						valores.push(
							chave === 'data'
								? this.getStringDataDesvio(new Date(d[chave]))
								: d[chave] || '-'
						);
					}
				});
			if (this.agrupamentoRelatorioGerado === 'MICRORREGIAO') {
				valores.push(`${d['municipio']} / ${d['estacao']}`);
			} else if (this.agrupamentoRelatorioGerado === 'MUNICIPIO') {
				valores.push(d['estacao']);
			} else if (
				['MESORREGIAO', 'REGIAO_PLUVIOMETRICA', 'BACIA'].includes(
					this.agrupamentoRelatorioGerado || ''
				)
			) {
				const chave =
					this.agrupamentoRelatorioGerado === 'REGIAO_PLUVIOMETRICA'
						? 'regiaoPluviometrica'
						: this.agrupamentoRelatorioGerado!.toLowerCase();
				valores.push(d[chave]);
			}
			dadosTabela.push(valores);
		});
		console.log(dadosTabela);
		DocumentExporter.gerarCSV(dadosTabela, this.nomeArquivo);
	}

	private tituloColunaToChaveDesvio(titulo: string): string {
		const tituloToCampoMap: { [key: string]: string } = {
			'Desvio (mm)': 'desvioMilimetro',
			'Desvio (%)': 'desvioPorcentagem',
			Microrregião: 'microrregiao',
			Região: 'regiaoPluviometrica',
			Data: 'data',
			Bacia: 'bacia',
			'Sub-bacia': 'subBacia',
			Mesorregião: 'mesorregiao',
			Município: 'municipio',
		};
		return tituloToCampoMap[titulo] || '';
	}

	private get cabecalhos(): string[] {
		return this.colunas.map(c => c.title!);
	}

	async exportarPDF(): Promise<void> {
		const cabecalhoCompleto: (string | number)[] = this.cabecalhos.filter(
			cab => !this.colunasOcultas.includes(cab)
		);
		const documentDefinition: any = await pdfseira.documentDefinitions();
		documentDefinition.content.push({
			text: `Tabela de desvios ${this.getStringDataDesvio(
				this.dataInicioRelatorioGerado!
			)} à ${this.getStringDataDesvio(this.dataFimRelatorioGerado!)}`,
			fontSize: 12,
			alignment: 'center',
			margin: [0, 10],
		});
		this.relatorios.forEach(relatorio => {
			const dadosTabela: (string | number | Date)[][] = [
				[...cabecalhoCompleto],
			];
			documentDefinition.content.push({
				text: this.getTituloTabela(relatorio),
				style: [pdfseira.tituloTabela],
			});
			relatorio.desvios.forEach(desvio => {
				const valoresColunas = [];
				if (!this.colunasOcultas.includes('Data')) {
					valoresColunas.push(this.getStringDataDesvio(desvio.data));
				}
				if (!this.colunasOcultas.includes('Desvio (mm)')) {
					valoresColunas.push(desvio.desvioMilimetro);
				}
				if (!this.colunasOcultas.includes('Desvio (%)')) {
					valoresColunas.push(desvio.desvioPorcentagem);
				}
				if (this.agrupamentoRelatorioGerado === 'MUNICIPIO_POSTO') {
					const {
						mesorregiao,
						microrregiao,
						regiaoPluviometrica,
						subBacia,
						bacia,
						municipio,
					} = this.relatorios[0];
					if (!this.colunasOcultas.includes('Microrregião')) {
						valoresColunas.push(microrregiao);
					}
					if (!this.colunasOcultas.includes('Mesorregião')) {
						valoresColunas.push(mesorregiao);
					}
					if (!this.colunasOcultas.includes('Região')) {
						valoresColunas.push(regiaoPluviometrica);
					}
					if (
						!this.colunasOcultas.includes('Bacia') &&
						!this.colunasOcultas.includes('Sub-bacia')
					) {
						valoresColunas.push(subBacia || bacia || '-');
					}
					if (!this.colunasOcultas.includes('Município')) {
						valoresColunas.push(municipio);
					}
				}
				dadosTabela.push(valoresColunas);
			});
			documentDefinition.content.push({
				table: {
					body: dadosTabela,
					layout: {
						noWrap: false,
						fontSize: 5,
					},
				},
			});
		});

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	private deletarColunasOcultasDesvio(d: Object) {
		const obj: any = { ...d };
		this.colunasOcultas.forEach(c => {
			switch (c) {
				case 'Desvio (mm)':
					delete obj.desvioMilimetro;
					break;
				case 'Desvio (%)':
					delete obj.desvioPorcentagem;
					break;
				case 'Microrregião':
					delete obj['microrregiao'];
					break;
				case 'Região':
					delete obj['regiaoPluviometrica'];
					break;
				case 'Data':
					delete obj['data'];
					break;
				case 'Bacia':
					delete obj['bacia'];
					break;
				case 'Sub-bacia':
					delete obj['subBacia'];
					break;
				case 'Mesorregião':
					delete obj['mesorregiao'];
					break;
			}
		});
		return obj;
	}

	get dataFim(): Date {
		const dataFim = this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.value;
		return (dataFim as Moment).toDate();
	}

	get dataInicio(): Date {
		const data = this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.value;
		return (data as Moment).toDate();
	}

	get ehDesvioPorcentagem(): boolean {
		const tipo = this.inputs.form.get(FormularioRelatorio.TIPO)?.value;
		return tipo === 'DESVIO_PORCENTAGEM';
	}

	get _agrupamento(): string {
		return this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.value;
	}

	getStringDataDesvio(valor: Date, tipoPeriodo?: string): string {
		const tipo = tipoPeriodo || this.tipoPeriodo;
		const data = DateTimeUtils.getDataComHorasAdicionais(
			valor,
			DateTimeUtils.getDiferencaHorasUTC(valor)
		);
		return format(data, `${tipo === 'mensal' ? 'MM/' : ''}yyyy`);
	}

	getTituloTabela(relatorio: RelatorioDesvio, agrupamento?: string): string {
		const mapeamento: Record<string, string> = {
			MICRORREGIAO: `${relatorio.municipio} / ${relatorio.estacao}`,
			MUNICIPIO_POSTO: relatorio.estacao,
			MUNICIPIO: relatorio.estacao,
			REGIAO_PLUVIOMETRICA: relatorio.regiaoPluviometrica,
			MESORREGIAO: relatorio.mesorregiao,
			BACIA: relatorio.bacia,
		};
		return mapeamento[agrupamento || this._agrupamento];
	}

	private clearValidators(): void {
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MUNICIPIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.ESTACAO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MICRORREGIAO)?.clearValidators();
	}

	private setValidators(): void {
		this.inputs.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.MUNICIPIO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.ESTACAO)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.MICRORREGIAO)
			?.setValidators(Validators.required);
	}

	private getRelatorioDesviosPorAno(
		relatorios: RelatorioDesvio[]
	): RelatorioDesvio[] {
		const retorno = relatorios.map(r => {
			const anos = new Set(r.desvios.map(d => DateTimeUtils.getAno(d.data)));
			const desvios: DadosDesvioRelatorio[] = [];
			anos.forEach(ano => {
				const desviosDoAno = r.desvios.filter(
					d => DateTimeUtils.getAno(d.data) === ano
				);
				const objChuvaEClimaSomados = desviosDoAno.reduce<DadosDesvioRelatorio>(
					(a, b) => ({
						desvioMilimetro: 0,
						chuva: a.chuva + b.chuva,
						desvioPorcentagem: 0,
						climatologia: a.climatologia + b.climatologia,
						data: new Date(DateTimeUtils.getAno(b.data), 0, 1),
					}),
					{
						desvioMilimetro: 0,
						chuva: 0,
						desvioPorcentagem: 0,
						climatologia: 0,
						data: new Date(),
					}
				);
				const { length } = desviosDoAno;
				const { chuva, climatologia, data } = objChuvaEClimaSomados;
				const chuvaMedia = chuva / length;
				const climatologiaMedia = climatologia / length;
				const desvioMedioAno = {
					chuva: chuva / length,
					climatologia: chuva / length,
					data: data,
					desvioMilimetro: DesvioService.calcularDesvioMilimetro(
						chuvaMedia,
						climatologiaMedia
					),
					desvioPorcentagem: DesvioService.calcularDesvioPorcentagem(
						chuvaMedia,
						climatologiaMedia
					),
				};
				desvios.push(desvioMedioAno);
			});
			const relatorio: RelatorioDesvio = {
				...r,
				desvios,
			};
			return relatorio;
		});
		return retorno;
	}

	private get tipoPeriodo(): string {
		return this.inputs.form.get(FormularioRelatorio.PERIODO_BUSCA)?.value;
	}

	gerarRelatorio(colunasParaSetar?: ADTColumns[]): void {
		this.jaGerouAoMenosUmaVez = true;
		if (
			![
				'MUNICIPIO_POSTO',
				'MUNICIPIO',
				'MICRORREGIAO',
				'MESORREGIAO',
				'REGIAO_PLUVIOMETRICA',
				'BACIA',
			].includes(this._agrupamento)
		) {
			this.toast.error(
				`A visualização de tabela de desvios não é permitida para este agrupamento`,
				'Opção não permitida'
			);
			return;
		}
		let chave: keyof typeof FormularioRelatorio | undefined;
		if (
			!['MESORREGIAO', 'REGIAO_PLUVIOMETRICA', 'BACIA'].includes(
				this._agrupamento
			)
		) {
			chave =
				this._agrupamento === 'MUNICIPIO_POSTO'
					? 'ESTACAO'
					: (this._agrupamento as keyof typeof FormularioRelatorio);
		}
		this.taCarregando = true;
		this.dataInicioRelatorioGerado = this.dataInicio;
		this.dataFimRelatorioGerado = this.dataFim;
		this.agrupamentoRelatorioGerado = this._agrupamento;
		this.tipoPeriodoRelatorioGerado = this.tipoPeriodo;
		this.relatorioService
			.buscarRelatorioDesvio({
				agrupamento: this._agrupamento,
				idEntidade: chave
					? this.inputs.form.get(FormularioRelatorio[chave])?.value
					: null,
				dataFim: this.dataFim,
				dataInicio: this.dataInicio,
				incluirDetalhes: this._agrupamento === 'MUNICIPIO_POSTO',
			})
			.subscribe({
				next: relatorios => {
					if (relatorios.length === 0) {
						this.toast.info(
							'Não há desvios para os parametros selecionados',
							'Sem dados'
						);
					}
					this.relatorios =
						this.tipoPeriodo === 'anual'
							? this.getRelatorioDesviosPorAno(relatorios)
							: relatorios;
					if (colunasParaSetar) {
						this.colunas = [...colunasParaSetar];
					} else {
						this.colunas =
							this._agrupamento === 'MUNICIPIO_POSTO'
								? [...this.colunasPadrao, ...this.colunasAdicionais]
								: [...this.colunasPadrao];
					}

					this.taCarregando = false;
				},
				error: err => {
					this.taCarregando = false;
					const msgErro = obter_erro_request(err);
					this.toast.error(
						msgErro,
						'Erro ao obter informações de desvio no servidor'
					);
				},
			});
	}
}
