export enum ValuesVariaveis {
	PRECIPITACAO = 'precipitacao',
	TEMPERATURA_AR = 'temperatura',
	UMIDADE = 'umidade',
	PRESSAO = 'pressao',
	TEMPERATURA_SOLO = 'temperaturaSolo',
}

export enum Unidade {
	PRECIPITACAO = 'mm',
	UMIDADE = '%',
	PRESSAO = 'hPa',
	TEMPERATURA_AR = 'ºC',
	TEMPERATURA_SOLO = 'ºC',
}

export enum UnidadeNdc {
  PRECIPITACAO = 'mm',
  DIAS_CHUVA = 'dias',
}

export const getUnidade = (variavel: ValuesVariaveis): string => {
	switch (variavel) {
		case ValuesVariaveis.PRECIPITACAO:
			return Unidade.PRECIPITACAO;
		case ValuesVariaveis.UMIDADE:
			return Unidade.UMIDADE;
		case ValuesVariaveis.PRESSAO:
			return Unidade.PRESSAO;
		case ValuesVariaveis.TEMPERATURA_AR:
		case ValuesVariaveis.TEMPERATURA_SOLO:
			return Unidade.TEMPERATURA_AR;
		default:
			return '';
	}
};

export const getLabelVariavel = (
	variavel: ValuesVariaveis | string
): string => {
	switch (variavel) {
		case ValuesVariaveis.PRECIPITACAO:
			return 'Precipitação (mm)';
		case ValuesVariaveis.UMIDADE:
			return 'Umidade (%)';
		case ValuesVariaveis.PRESSAO:
			return 'Pressão atmosférica (hPa)';
		case ValuesVariaveis.TEMPERATURA_AR:
			return 'Temperatura do ar (ºC)';
		case ValuesVariaveis.TEMPERATURA_SOLO:
			return 'Temperatura do solo (ºC)';
		default:
			return '';
	}
};

const labelCardMap: { [key in ValuesVariaveis]: string } = {
	[ValuesVariaveis.PRECIPITACAO]: 'Precipitação acumulada (mm)',
	[ValuesVariaveis.UMIDADE]: 'Umidade média (%)',
	[ValuesVariaveis.PRESSAO]: 'Pressão atmosférica média (hPa)',
	[ValuesVariaveis.TEMPERATURA_AR]: 'Temperatura média do ar (ºC)',
	[ValuesVariaveis.TEMPERATURA_SOLO]: 'Temperatura média do solo (ºC)',
};

export const getLabelCard = (variavel: ValuesVariaveis | string): string => {
	return labelCardMap[variavel as ValuesVariaveis] || '';
};

export enum JanelaTempo {
	'Últimas 6 horas' = '6',
	'Últimas 24 horas' = '24',
	'Últimas 48 horas' = '48',
	'Últimas 72 horas' = '72',
	'Personalizada' = 'Personalizada',
}
