import { DadoGraficoQuantis } from '@home/submodulos/dados-meteorologicos/interfaces';
import { Table, TableCell } from 'pdfmake/interfaces';
import { numberToBrNumber } from '@utils';
import { MesAbreviadoPorCompleto } from '@shared/enum/datas';

export function gerarTabelaParaRelatorioDeQuantisPdf(
	arrayDados: DadoGraficoQuantis[],
	mensal = false
) {
	const tableData: TableCell[][] = [];
	const fontSize = 8;
	const header = [
		{ fontSize, text: 'Prec. observada' },
		{ fontSize, text: 'Muito seco' },
		{ fontSize, text: 'Seco' },
		{ fontSize, text: 'Normal' },
		{ fontSize, text: 'Chuvoso' },
		{ fontSize, text: 'Muito chuvoso' },
	];
	if (mensal) {
		header.unshift({ fontSize, text: 'Mês' });
	}
	tableData.push(header);

	const formataIntervalos = (val1: number, val2: number): TableCell => {
		return {
			fontSize,
			text: `${numberToBrNumber(val1)} a ${numberToBrNumber(val2)}`,
		};
	};

	arrayDados.forEach((dado: DadoGraficoQuantis) => {
		if (dado.categorias) {
			const linha = [
				formataIntervalos(dado.categorias[0].from, dado.categorias[0].to),
				formataIntervalos(dado.categorias[1].from, dado.categorias[1].to),
				formataIntervalos(dado.categorias[2].from, dado.categorias[2].to),
				formataIntervalos(dado.categorias[3].from, dado.categorias[3].to),
				formataIntervalos(dado.categorias[4].from, dado.categorias[4].to),
				formataIntervalos(dado.categorias[5].from, dado.categorias[5].to),
			];
			if (mensal) {
				const title =
					MesAbreviadoPorCompleto[
						dado.titulo!.toUpperCase() as keyof typeof MesAbreviadoPorCompleto
					];
				linha.unshift({
					fontSize,
					text: title,
				});
			}
			tableData.push(linha);
		}
	});
	const table: Table = {
		widths: '*',
		body: tableData,
	};
	return table;
}
