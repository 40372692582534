import {
	Component,
	EventEmitter,
	HostListener,
	Input,
	Output,
} from '@angular/core';
import * as Leaflet from 'leaflet';
import L, {
	GeoJSON,
	latLng,
	latLngBounds,
	LayerGroup,
	MapOptions,
} from 'leaflet';
import { v4 as uuidv4 } from 'uuid';
import { HttpClient } from '@angular/common/http';
import {
	MAPAS_LEAFLET,
	mapLayers,
} from '@componentes/mapa-paraiba-leaflet/utils';
import html2canvas from 'html2canvas';

@Component({
	selector: 'seira-mapa-paraiba-leaflet',
	templateUrl: './mapa-paraiba-leaflet.component.html',
	styleUrls: ['./mapa-paraiba-leaflet.component.scss'],
})
export class MapaParaibaLeafletComponent {
	@Input() monitoramentoMapa!: Leaflet.Map;
	@Input() markersLayer?: LayerGroup;
	@Input() loading = false;
	@Input() zoomOnlyWithCtrl = false;
	@Input() custonsLayers = false;
	@Input() titulo = false;
	@Output() mapaEmitter = new EventEmitter<Leaflet.Map>();
	ctrlAtivo = false;
	mouseEmCimaDoMapa = false;
	mapaId = `mapa-${uuidv4()}`;
	mapa!: Leaflet.Map;
	options: MapOptions = {
		zoom: 8,
		preferCanvas: true,
		minZoom: 6,
		maxZoom: 15,
		center: latLng(-7.2605416, -36.7290255),
		layers: [mapLayers[MAPAS_LEAFLET.OPEN_STREET_MAP]],
		maxBounds: latLngBounds(latLng(-4.5, -39.2), latLng(-9.5, -34.2)),
	};
	constructor(private httpClient: HttpClient) {}

	coordenadas: [number, number] = [-7.2605416, -36.7290255];

	ativarMomentaneamenteMensagem() {
		if (!this.ctrlAtivo && this.zoomOnlyWithCtrl) {
			this.mouseEmCimaDoMapa = true;
			setTimeout(() => {
				this.mouseEmCimaDoMapa = false;
			}, 3000);
		}
	}
	loadMap(map: Leaflet.Map) {
		this.mapa = map;
		this.mapaEmitter.emit(map);
		this.loadMunicipiosMap(map);
		this.loadMarkers(map);
		if (this.zoomOnlyWithCtrl) {
			this.mapa.scrollWheelZoom.disable();
		}

		this.addResetViewControl(map);
	}

	addResetViewControl(map: Leaflet.Map) {
		const resetViewControl = L.Control.extend({
			options: {
				position: 'topleft',
				title: 'Reset view',
			},
			onAdd: (map: Leaflet.Map) => {
				const container = L.DomUtil.create('div', 'leaflet-control-reset');
				container.style.backgroundColor = '#0D6A92';
				container.style.width = '30px';
				container.style.height = '30px';
				container.style.display = 'flex';
				container.style.alignItems = 'center';
				container.style.justifyContent = 'center';
				container.style.cursor = 'pointer';
				container.style.borderBottom = '2px solid transparent';

				this.loadSvg('assets/images/reset_icon.svg').then(svgContent => {
					const svgContainer = L.DomUtil.create('div', '');
					svgContainer.innerHTML = svgContent;
					svgContainer.style.display = 'flex';
					svgContainer.style.alignItems = 'center';
					svgContainer.style.justifyContent = 'center';
					container.innerHTML = '';
					container.appendChild(svgContainer);
				});

				container.onclick = () => {
					this.resetMapView();
				};

				container.onmouseover = () => {
					container.style.backgroundColor = '#3e9536';
				};

				container.onmouseout = () => {
					container.style.backgroundColor = '#0D6A92';
				};

				const existingZoomControls = document.querySelectorAll(
					'.leaflet-control-container .leaflet-top .leaflet-control-zoom a'
				);
				if (existingZoomControls.length === 2) {
					existingZoomControls[1].parentNode!.insertBefore(
						container,
						existingZoomControls[1].nextSibling
					);
				} else {
					const fallbackContainer = L.DomUtil.create(
						'div',
						'leaflet-bar leaflet-control leaflet-control-custom'
					);
					fallbackContainer.appendChild(container);
					return fallbackContainer;
				}

				return L.DomUtil.create('div');
			},
		});

		map.addControl(new resetViewControl());
	}

	loadSvg(url: string): Promise<string> {
		// @ts-ignore
		return this.httpClient.get(url, { responseType: 'text' }).toPromise();
	}

	resetMapView() {
		const defaultZoom = 8;
		this.mapa.setView(this.coordenadas, defaultZoom);
	}

	@HostListener('window:keydown.control', ['$event'])
	handleKeyDown() {
		if (this.zoomOnlyWithCtrl) {
			this.mapa.scrollWheelZoom.enable();
			this.ctrlAtivo = true;
		}
	}
	@HostListener('window:keyup.control', ['$event'])
	handleKeyUp() {
		if (this.zoomOnlyWithCtrl) {
			this.mapa.scrollWheelZoom.disable();
			this.ctrlAtivo = false;
		}
	}

	loadMunicipiosMap(map: Leaflet.Map) {
		if (!this.custonsLayers) {
			this.httpClient
				.get<GeoJSON.GeoJsonObject>('assets/geoJson/pb-municipios-geo.json')
				.subscribe({
					next: value => {
						Leaflet.geoJSON(value, {
							style(_) {
								return {
									color: '#607d8b',
									opacity: 0.5,
									weight: 2,
									fillOpacity: 0.05,
								};
							},
						}).addTo(map);
					},
				});
		}
	}

	loadMarkers(map: Leaflet.Map) {
		if (this.markersLayer) {
			this.markersLayer.clearLayers();
			this.markersLayer.addTo(map);
		}
	}

	async getMapImage() {
		const elementoHtml = this.mapa.getContainer();
		const canva = await html2canvas(elementoHtml, {
			useCORS: true,
			allowTaint: true,
			logging: false,
			scale: 2,
		});

		return canva.toDataURL('image/png', 1);
	}
}
