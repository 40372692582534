export class DropdownOption {
	title!: string;
	icon = '';
	routerLink: string | null | undefined = null;
	className? = '';
	click = () => {};

	constructor(
		title = '',
		icon = '',
		routerLink: string | null | undefined = null,
		className = '',
		click = () => {}
	) {
		this.routerLink = routerLink;
		this.icon = icon;
		this.className = className;
		this.title = title;
		this.click = click;
	}
}
