import { Component, Input, OnInit } from '@angular/core';
import {
	ControlContainer,
	FormControl,
	FormGroupDirective,
	Validators,
} from '@angular/forms';

@Component({
	selector: 'seira-checkbox',
	templateUrl: './checkbox.component.html',
	styleUrls: ['./checkbox.component.scss'],
	viewProviders: [
		{ provide: ControlContainer, useExisting: FormGroupDirective },
	],
})
export class CheckboxComponent implements OnInit {
	@Input() componentClass = 'form-check mb-2';
	@Input() label!: string;
	@Input() labelPlacement: 'start' | 'end' | 'top' = 'end';
	@Input() id!: string;
	@Input() controlName!: string;
	@Input() required!: boolean;
	@Input() showsTextRequired = false;
	@Input() textRequired = '';
	@Input() disabled = true;
	@Input() iosStyle = false;

	control = new FormControl();

	constructor(private controlContainer: ControlContainer) {}

	ngOnInit(): void {
		this.control = this.controlContainer.control?.get(
			this.controlName
		) as FormControl;
	}
	changeCheck() {
		if (this.control.enabled) {
			this.control.setValue(!this.control.value);
		}
	}
	get isRequired(): boolean {
		return !!this.control?.hasValidator(Validators.required);
	}
	get seiraError() {
		if (this.control.errors) {
			const erros = Object.keys(this.control.errors);
			if (erros.length) {
				if (erros[0] === 'required') {
					if (this.control.touched) return 'Campo obrigatório.';
				} else {
					return this.control?.errors[erros[0]]?.label;
				}
			}
		}
		return '';
	}
}
