<label class="text-tertiary fw-semibold mt-3">
	RELATÓRIO DE DESVIOS -
	{{
		getStringDataDesvio(
			dataInicioRelatorioGerado || dataInicio,
			tipoPeriodoRelatorioGerado
		)
	}}
	à
	{{
		getStringDataDesvio(
			dataFimRelatorioGerado || dataFim,
			tipoPeriodoRelatorioGerado
		)
	}}
</label>
<!-- TODO: separar em componente próprio. Este mesmo pedaço de código está também em public-table.component.html -->
<div class="row">
	<div class="d-flex flex-row justify-content-between align-items-end gap-2">
		<seira-card-info
			aditionalClasses="mt-3"
			texto="Define-se como desvio a diferença de chuva de um local com relação a sua climatologia">
		</seira-card-info>

		<seira-select-list-menu
			[opcoes]="opcoesColunas"
			[disabled]="
				taCarregando ||
				_agrupamento !== 'MUNICIPIO_POSTO' ||
				!jaGerouAoMenosUmaVez
			"
			[tituloBotao]="'Personalizar colunas'"
			[disabled]="!jaGerouAoMenosUmaVez"
			(atualizarOpcoesSelecionadas)="
				setColunasSelecionadas($event)
			"></seira-select-list-menu>
	</div>
	<div
		*ngIf="taCarregando"
		style="height: 400px; width: 100%"
		class="d-flex align-items-center justify-content-center">
		<div
			class="spinner-border text-primary"
			style="width: 4rem; height: 4rem"
			role="status">
			<span class="visually-hidden">Carregando tabela...</span>
		</div>
	</div>
	<div *ngIf="!taCarregando">
		<div *ngIf="relatorios.length === 0">
			<seira-sem-dados />
		</div>
		<div class="mt-3" *ngFor="let relatorio of relatorios">
			<div class="justify-between" *ngIf="!taCarregando">
				<label
					[for]="
						'tabela-relatorio-desvios-' +
						getTituloTabela(relatorio, agrupamentoRelatorioGerado).toLowerCase()
					"
					class="text-tertiary mb-2 fw-semibold">
					{{ getTituloTabela(relatorio, agrupamentoRelatorioGerado) }}
				</label>
			</div>
			<div class="mb-4">
				<seira-public-table
					[id]="
						'tabela-relatorio-desvios-' +
						getTituloTabela(relatorio, agrupamentoRelatorioGerado).toLowerCase()
					"
					[hasFooter]="true"
					[hasHeader]="false"
					[loading]="taCarregando"
					[info]="true"
					[paging]="true"
					[searching]="false"
					[scrollX]="true"
					[spaceReduceTh]="true"
					[columns]="colunas"
					[order]="[0, 'asc']"
					#tabelaRelatorioDesvios
					[data]="relatorio.desvios || []"></seira-public-table>
			</div>
		</div>
	</div>
</div>
