import { Component, Input, OnInit } from '@angular/core';
import { LegendaOptions } from '@home/submodulos/dados-meteorologicos/componentes/legenda-relatorio-historico-variaveis/legenda-relatorio-historico-variaveis.component';
import {
	LabelsHistoricoVariaveisMeteorologicas,
	VariaveisMeteorologicasPorPeriodoETipoVariavel,
} from '@home/submodulos/dados-meteorologicos/interfaces/variavel-meteorologica-mapa';
import {
	capitalizeFirstLetter,
	decapitalizeFirstLetter,
	isColorDarkOrLight,
	isNotNuloOuUndefined,
	numberToBrNumber,
} from '@utils';
import { FormBuilder, FormControl } from '@angular/forms';
import { ADTColumns } from 'angular-datatables/src/models/settings';
import { TipoEstacao } from '@modulos/meteorologia/submodulos/estacao/enums/tipo-estacao';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import * as pdfseira from '@utils/pdf-seira';
import { ValuesVariaveis } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/interfaces/estacao-monitorada';
import html2canvas from 'html2canvas';
import moment from 'moment';
import { PublicTableComponent } from '@componentes/public-table/public-table.component';

@Component({
	selector: 'seira-modal-relatorio-historico-imagens',
	templateUrl: './modal-relatorio-historico-imagens.component.html',
	styleUrls: ['./modal-relatorio-historico-imagens.component.scss'],
})
export class ModalRelatorioHistoricoImagensComponent implements OnInit {
	@Input() imagem!: VariaveisMeteorologicasPorPeriodoETipoVariavel;
	@Input() legenda!: LegendaOptions;
	@Input() funcaoCoresMapa!: (valor: number) => string;
	@Input() close!: () => void;
	tabelaRef!: PublicTableComponent;
	botoesExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => {
				this.exportPdf().then();
			},
		},
	];
	labelTipoVariavel!: string;
	OpcoesTipoVisualizacao = [
		{ label: 'Mapa', value: 'mapa' },
		{ label: 'Tabela', value: 'tabela' },
	];
	colunasTabela: ADTColumns[] = [];
	form = this.fb.group({
		visualizacao: new FormControl<'mapa' | 'tabela'>('mapa'),
	});
	constructor(private fb: FormBuilder) {}

	ngOnInit() {
		this.labelTipoVariavel =
			this.imagem.tipoVariavel === 'precipitacao'
				? LabelsHistoricoVariaveisMeteorologicas.PRECIPITACAO
				: decapitalizeFirstLetter(
						LabelsHistoricoVariaveisMeteorologicas.TEMPERATURA
				  );
		this.setColumnsTable();
	}
	getMapaElemento() {
		const svgContainer = document.getElementById('mapa-svg');
		const svgContent = svgContainer?.querySelector('svg')!;
		return svgContent.outerHTML;
	}

	async exportPdf() {
		const svg = this.getMapaElemento();
		const pdfDefinitions: any = await pdfseira.documentDefinitions();

		let tipoVariavelLabel: string = capitalizeFirstLetter(
			LabelsHistoricoVariaveisMeteorologicas.TEMPERATURA
		);
		if (this.imagem.tipoVariavel == ValuesVariaveis.PRECIPITACAO) {
			tipoVariavelLabel = LabelsHistoricoVariaveisMeteorologicas.PRECIPITACAO;
		}
		pdfDefinitions.content.push({
			text: [{ text: 'Relatório: ', bold: true }, `Mosaico de chuvas`],
			fontSize: 10,
			alignment: 'start',
			margin: [0, 10, 0, 0],
		});
		pdfDefinitions.content.push({
			text: [{ text: 'Formato: ', bold: true }, 'Mapa'],
			fontSize: 10,
			alignment: 'start',
		});
		pdfDefinitions.content.push({
			text: [
				{ text: 'Tipo de variável: ', bold: true },
				`${tipoVariavelLabel}`,
			],
			fontSize: 10,
			alignment: 'start',
		});
		pdfDefinitions.content.push({
			text: [
				{ text: 'Agrupado por: ', bold: true },
				`${
					this.imagem.periodo === 'ANUAL'
						? 'Anual'
						: this.imagem.periodo === 'MENSAL'
						? 'Mensal'
						: this.imagem.periodo === 'DIARIO'
						? 'Diário'
						: ''
				}`,
			],
			fontSize: 10,
			alignment: 'start',
		});
		pdfDefinitions.content.push({
			text: [
				{ text: 'Período: ', bold: true },
				`${this.formatDate(this.imagem.periodo)}`,
			],
			fontSize: 10,
			alignment: 'start',
		});
		pdfDefinitions.content.push({
			svg: svg,
			width: 500,
			height: 300,
			alignment: 'center',
		});
		const legendaHtml = document.getElementById('legenda');
		if (legendaHtml) {
			const img = await html2canvas(legendaHtml).then(canvas =>
				canvas.toDataURL('image/png')
			);
			pdfDefinitions.content.push({
				image: img,
				margin: [0, 10, 0, 10],
				alignment: 'center',
				width: 500,
			});
		}
		pdfDefinitions.content.push({
			table: {
				body: this.tableData(),
				layout: {
					noWrap: false,
					fontSize: 5,
				},
			},
		});
		pdfseira.generatePdf(pdfDefinitions);
	}

	tableData() {
		const dadosTabela: Array<any[]> = [];
		const cabecalho = this.colunasTabela.map(col => ({
			text: col.title,
			fillColor: '#DCDCDC',
		}));
		dadosTabela.push(cabecalho);

		const dadosHistorico = this.imagem.historico
			.map(hist => [
				hist.nomeMunicipio === hist.nomeEstacao
					? hist.nomeMunicipio
					: `${hist.nomeMunicipio}/${hist.nomeEstacao}`,
				`${TipoEstacao[hist.tipoEstacao]}`,
				this.formatDecimal(hist.valor),
			])
			.sort((a, b) => {
				const nomeA = a[0].toLowerCase();
				const nomeB = b[0].toLowerCase();

				if (nomeA < nomeB) {
					return -1;
				} else if (nomeA > nomeB) {
					return 1;
				} else {
					return 0;
				}
			});

		dadosTabela.push(...dadosHistorico);
		return dadosTabela;
	}
	get tipoVisualizacao() {
		return this.form.get('visualizacao')?.value;
	}
	handleTextColorBasedOnBackgroundColor(
		backgroundColor: string
	): string | undefined {
		if (isColorDarkOrLight(backgroundColor) == 'escura') {
			return 'text-white';
		}
		return;
	}

	setColumnsTable() {
		this.colunasTabela = [
			{
				title: 'Município/posto',
				data: null,
				render: (
					data: VariaveisMeteorologicasPorPeriodoETipoVariavel['historico'][0]
				) => {
					const nomes = [data.nomeMunicipio, data.nomeEstacao].sort();
					return nomes[0] === nomes[1] ? nomes[0] : `${nomes[0]}/${nomes[1]}`;
				},
				className: 'text-tertiary text-start',
			},

			{
				title: 'Tipo',
				data: null,
				render: (
					data: VariaveisMeteorologicasPorPeriodoETipoVariavel['historico'][0]
				) => {
					return `${TipoEstacao[data.tipoEstacao]}`;
				},
				className: 'text-tertiary text-center',
			},
			{
				title: this.labelTipoVariavel,
				data: 'valor',
				render: valor => {
					return this.formatDecimal(valor);
				},
				className: 'text-tertiary text-center',
			},
		];
	}

	formatDecimal(valor: number): string {
		return valor.toFixed(1).replace('.', ',');
	}

	labelValorMedido(value?: number) {
		if (this.imagem.tipoVariavel === 'precipitacao') {
			return `${numberToBrNumber(value)} mm`;
		} else {
			return `${numberToBrNumber(value)} °C`;
		}
	}
	getDateFormat(periodo: string): string {
		switch (periodo.toUpperCase()) {
			case 'ANUAL':
				return 'yyyy';
			case 'MENSAL':
				return 'MM/yyyy';
			case 'DIARIO':
				return 'dd/MM/yyyy';
			default:
				return 'MM/yyyy';
		}
	}

	formatDate(periodo: string): string {
		if (periodo === 'DIARIO') {
			return moment(this.imagem.data).format('DD/MM/yyyy').toString();
		}
		const formatString = this.getDateFormat(periodo);
		return moment(this.imagem.data).format(formatString).toString();
	}

	protected readonly isNotNuloOuUndefined = isNotNuloOuUndefined;
	protected readonly decapitalizeFirstLetter = decapitalizeFirstLetter;
}
