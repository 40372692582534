import { Component, inject, OnDestroy, ViewChild } from '@angular/core';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { FormGroup, Validators } from '@angular/forms';
import { PostosRelatorios } from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import { Estacao } from '@home/submodulos/dados-meteorologicos/interfaces/filtros-opcoes';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';
import { ADTColumns } from 'angular-datatables/src/models/settings';
import { numberToBrNumber } from '@utils';
import { formatISO } from 'date-fns';
import { Agrupamento } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/interfaces/estacao-monitorada';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import * as pdfseira from '@utils/pdf-seira';
import { PublicTableComponent } from '@componentes/public-table/public-table.component';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { ClimatologiaResponseDTO } from '@home/submodulos/dados-meteorologicos/interfaces/climatologia';
import { DocumentExporter } from '@utils/document-exporter';
import { DateTimeUtils } from '@utils/datetime-util';
import { isAuto } from 'html2canvas/dist/types/render/background';

@Component({
	selector: 'seira-tabela-climatologia',
	templateUrl: './tabela-climatologia.component.html',
	styleUrls: ['./tabela-climatologia.component.scss'],
})
export class TabelaClimatologiaComponent
	implements OnDestroy, InstanciaRelatorio
{
	form!: FormGroup;
	postos: PostosRelatorios[] = [];
	estacoes: Estacao[] = [];
	inputs = inject(INPUTS_RELATORIOS);
	dadosTabelaClimatologia?: ClimatologiaResponseDTO[];
	carregandoRelatorio = false;
	private subscription = new Subscription();
	loading: boolean = false;
	periodo: string = '';
	tipoAgrupamento: Agrupamento = Agrupamento.MUNICIPIO_POSTO;
	descricaoRelatorio =
		'Define-se como climatologia a média histórica de chuva superior a 30 anos de\n' +
		'um determinado local ou região do estado da Paraíba';

	tituloTabela: string = 'Tabela de Climatologia';
	dadosColunas: any[] = [];
	colunas: ADTColumns[] = [];

	colunasSelecionadas: string[] = [];
	@ViewChild('tabelaClimatologia', { static: false })
	tabela?: PublicTableComponent;
	opcoesColunas = [
		{ name: 'Município/Posto', isFixed: true },
		{ name: 'Latitude', isFixed: false },
		{ name: 'Longitude', isFixed: false },
		{ name: 'Altitude', isFixed: false },
		{ name: 'Microrregião', isFixed: false },
		{ name: 'Mesorregião', isFixed: false },
		{ name: 'Região Pluviométrica', isFixed: false },
		{ name: 'Bacia', isFixed: false },
		{ name: 'Sub-bacia', isFixed: false },
	];
	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => {
				this.exportarPDF();
			},
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => {
				this.exportarCSV();
			},
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => {
				this.exportarTXT();
			},
		},
	];

	disabledSelectList: boolean = false;

	tituloColunaAgrupamento: string = 'Nome';

	constructor(
		private toastr: ToastrService,
		private climatologiaService: RelatoriosService
	) {
		this.form = this.inputs.form;
		this.postos = this.inputs.postos;
		this.estacoes = this.inputs.estacoes;
		this.setValidators();
		this.colunasSelecionadas = this.opcoesColunas.map(option => option.name);
	}
	setColunasSelecionadas(colunas: any) {
		this.colunasSelecionadas = colunas;
		this.gerarRelatorio();
	}
	atualizarTituloColuna() {
		const agrupamento = this.getFormItemValue(FormularioRelatorio.AGRUPAMENTO);
		switch (agrupamento) {
			case 'MUNICIPIO_POSTO':
				this.tituloColunaAgrupamento = 'Município/Posto';
				break;
			case 'MICRORREGIAO':
				this.tituloColunaAgrupamento = 'Microrregião';
				break;
			case 'MUNICIPIO':
				this.tituloColunaAgrupamento = 'Município';
				break;
			case 'MESORREGIAO':
				this.tituloColunaAgrupamento = 'Mesorregião';
				break;
			case 'REGIAO_PLUVIOMETRICA':
				this.tituloColunaAgrupamento = 'Região Pluviométrica';
				break;
			case 'BACIA':
				this.tituloColunaAgrupamento = 'Bacia';
				break;
			case 'SUB_BACIA':
				this.tituloColunaAgrupamento = 'Sub bacia';
				break;
		}
	}

	atualizarColunasTabela() {
		const agrupamento = this.getFormItemValue(FormularioRelatorio.AGRUPAMENTO);

		if (agrupamento === 'MUNICIPIO_POSTO') {
			this.disabledSelectList = false;
			const todasColunasDisponiveis = [
				{
					data: null,
					title: this.tituloColunaAgrupamento,
					type: 'text',
					className: 'text-center',
					render: (data: ClimatologiaResponseDTO) =>
						data.dadosPosto.municipio + '/' + data.nome,
				},
				{
					data: 'dadosPosto.latitude',
					title: 'Latitude',
					type: 'number',
					className: 'text-center',
					render: (data: number) => (data ? data : '-'),
				},

				{
					data: 'dadosPosto.longitude',
					title: 'Longitude',
					type: 'number',
					className: 'text-center',
					render: (data: number) => (data ? data : '-'),
				},

				{
					data: 'dadosPosto.altitude',
					title: 'Altitude',
					type: 'number',
					className: 'text-center',
					render: (data: number) => (data ? data : '-'),
				},

				{
					data: 'dadosPosto.microrregiao',
					title: 'Microrregião',
					type: 'string',
					className: 'text-center',
					render: (data: string) => (data ? data : '-'),
				},

				{
					data: 'dadosPosto.mesorregiao',
					title: 'Mesorregião',
					type: 'string',
					className: 'text-center',
				},

				{
					data: 'dadosPosto.regiao',
					title: 'Região Pluviométrica',
					type: 'string',
					className: 'text-center',
					render: (data: string) => (data ? data : '-'),
				},
				{
					data: 'dadosPosto.bacia',
					title: 'Bacia',
					type: 'string',
					className: 'text-center',
					render: (data: string) => (data ? data : '-'),
				},

				{
					data: 'dadosPosto.subbacia',
					title: 'Sub-bacia',
					type: 'string',
					className: 'text-center',
					render: (data: string) => (data ? data : '-'),
				},
			];

			const colunasParaExibir = todasColunasDisponiveis.filter(coluna =>
				this.colunasSelecionadas.includes(coluna.title)
			);

			this.colunas = [...colunasParaExibir];
		} else {
			this.disabledSelectList = true;
			this.colunas = [
				{
					data: 'nome',
					title: this.tituloColunaAgrupamento,
					type: 'text',
					className: 'text-center',
				},
			];
		}

		this.colunas.push(
			...[
				{
					data: 'mes',
					title: 'Mês',
					className: 'text-start',
				},
				{
					data: 'climatologia',
					render: (data: number) => {
						return numberToBrNumber(data);
					},
					title: 'Climatologia (mm)',
					className: 'text-start',
				},
			]
		);
	}
	setValidators() {
		this.lidarComAgrupamento();
		this.form.get(FormularioRelatorio.TIPO)?.setValidators(Validators.required);
		this.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.inputs.form
			.get(FormularioRelatorio.AGRUPAMENTO)
			?.setValidators(Validators.required);
	}
	lidarComAgrupamento() {
		const agrupamento = this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO);
		const municipio = this.inputs.form.get(FormularioRelatorio.MUNICIPIO);
		const microrregiao = this.inputs.form.get(FormularioRelatorio.MICRORREGIAO);
		const municipioPosto = this.inputs.form.get(FormularioRelatorio.ESTACAO);
		municipio?.clearValidators();
		microrregiao?.clearValidators();
		municipioPosto?.clearValidators();
		switch (agrupamento?.value) {
			case 'MUNICIPIO_POSTO':
				municipioPosto?.setValidators(Validators.required);
				break;
			case 'MICRORREGIAO':
				microrregiao?.setValidators(Validators.required);
				break;
			case 'MUNICIPIO':
				municipio?.setValidators(Validators.required);
				break;
		}
	}
	ngOnDestroy() {
		this.inputs.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.TIPO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MUNICIPIO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.AGRUPAMENTO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.ESTACAO)?.clearValidators();
		this.inputs.form.get(FormularioRelatorio.MICRORREGIAO)?.clearValidators();
		this.subscription.unsubscribe();
	}

	gerarRelatorio(): void {
		if (this.form.invalid) {
			return;
		}
		this.loading = true;
		this.carregandoRelatorio = true;
		this.inputs.setLoading(true);
		const diaInicio = this.inputs.form.get(FormularioRelatorio.DATA_INICIO)
			?.value;
		const diaFim = this.inputs.form.get(FormularioRelatorio.DATA_FIM)?.value;
		this.periodo =
			DateTimeUtils.formatarData(new Date(diaInicio).toISOString(), 'MM/yyyy') +
			' à ' +
			DateTimeUtils.formatarData(new Date(diaFim).toISOString(), 'MM/yyyy');
		this.tipoAgrupamento = this.inputs.form.get('agrupamento')?.value;

		this.inputs.setLoading(true);

		this.climatologiaService
			.buscarClimatologiaPorMunicipio(
				this.tipoAgrupamento,
				formatISO(new Date(diaInicio)),
				formatISO(new Date(diaFim))
			)
			.subscribe({
				next: climatologias => {
					if (this.tipoAgrupamento === 'Município/posto') {
						this.dadosColunas = this.ordenaMes(climatologias);
					}
					this.dadosColunas = climatologias;
					this.inputs.setLoading(false);
					this.dadosTabelaClimatologia = this.dadosColunas;
					this.atualizarTituloColuna();
					this.atualizarColunasTabela();
					this.loading = false;
					this.carregandoRelatorio = false;
				},
				error: () => {
					this.inputs.setLoading(false);
					this.loading = false;
					this.carregandoRelatorio = false;
					this.dadosTabelaClimatologia = undefined;
				},
			});
	}

	getFormItemValue(field: string) {
		return this.form.get(field)!.value;
	}

	async exportarTXT() {
		let txtData = '';

		const tableData = this.prepararDadosParaTabela();

		if (tableData.length > 0) {
			const colunas = tableData[0];

			tableData.slice(1).forEach(dadoRelatorio => {
				colunas.forEach((coluna: string, index: number) => {
					const valor = dadoRelatorio[index];
					txtData += `${coluna}: ${valor ?? '-'}\n`;
				});

				txtData += '\n';
			});
		}
		DocumentExporter.gerarTXT(
			txtData,
			`Relatório de climatologia -(${this.periodo})`
		);
	}

	exportarCSV() {
		if (this.temDadosParaTabela()) {
			const tableData = this.prepararDadosParaTabela();
			DocumentExporter.gerarCSV(
				tableData,
				`Relatório de climatologia -(${this.periodo})`
			);
		}
	}
	async exportarPDF() {
		const documentDefinition: any = await this.criarDefinicaoDocumentoPDF();

		documentDefinition.pageOrientation = 'landscape';

		this.adicionarTitulo(documentDefinition);

		if (this.temDadosParaTabela()) {
			const tableData = this.prepararDadosParaTabela();
			this.adicionarTabelaAoDocumento(documentDefinition, tableData);
		} else {
			this.adicionarMensagemSemDados(documentDefinition);
		}

		this.gerarDocumentoPDF(documentDefinition);
	}

	private criarDefinicaoDocumentoPDF(): any {
		return pdfseira.documentDefinitions('paisagem');
	}

	private adicionarTitulo(documentDefinition: any): void {
		documentDefinition.content.push({
			text: `${this.tituloTabela}(${this.periodo})`,
			fontSize: 12,
			alignment: 'center',
			margin: [0, 10],
		});
	}

	private temDadosParaTabela(): boolean {
		return !!this.dadosTabelaClimatologia && !!this.tabela;
	}

	private ordenaMes(dados: any[]) {
		const meses = [
			'janeiro',
			'fevereiro',
			'março',
			'abril',
			'maio',
			'junho',
			'julho',
			'agosto',
			'setembro',
			'outubro',
			'novembro',
			'dezembro',
		];

		dados.forEach(item => {
			if (item.mes === 'marco') {
				item.mes = 'março';
			}
		});

		const dadosOrdenados = dados.sort((a, b) => {
			const municipioA = a?.dadosPosto?.municipio;
			const municipioB = b?.dadosPosto?.municipio;
			const nomeComparacao = municipioA.localeCompare(municipioB);

			if (nomeComparacao !== 0) {
				return nomeComparacao;
			}

			return meses.indexOf(a.mes) - meses.indexOf(b.mes);
		});

		return dadosOrdenados;
	}

	private prepararDadosParaTabela(): string[][] {
		const visibleColumns = this.getVisibleColumns();
		const tableData = [visibleColumns];

		this.tabela?.data.forEach((row: any) => {
			const rowData = this.prepararDadosDaLinha(row, visibleColumns);
			tableData.push(rowData);
		});

		return tableData;
	}

	private getVisibleColumns(): string[] {
		return this.tabela!.columns.map(col => col.title as string);
	}

	private prepararDadosDaLinha(row: any, visibleColumns: string[]): any[] {
		return visibleColumns.map(columnTitle => {
			if (columnTitle === 'Município/Posto') {
				return this.formatarMunicipioPosto(row);
			} else if (columnTitle === 'Latitude' || columnTitle === 'Longitude') {
				const value = this.getCellValue(row, columnTitle);
				const response = value !== '-' ? Number(value).toFixed(4) : value;
				return response;
			} else if (columnTitle === 'Climatologia (mm)') {
				const value = this.getCellValue(row, columnTitle);
				const response = value !== '-' ? numberToBrNumber(value) : value;
				return response;
			} else {
				return this.getCellValue(row, columnTitle);
			}
		});
	}

	private formatarMunicipioPosto(row: any): string {
		return row.dadosPosto?.municipio
			? `${row.dadosPosto.municipio}/${row.nome}`
			: row.nome || '-';
	}

	private getCellValue(row: any, columnTitle: string): string {
		const column = this.tabela!.columns.find(col => col.title === columnTitle);
		if (column && column.data) {
			return this.extractNestedValue(row, column.data as string) ?? '-';
		}
		return '-';
	}

	private extractNestedValue(obj: any, path: string): any {
		return path.split('.').reduce((value, key) => value?.[key], obj);
	}

	private adicionarTabelaAoDocumento(
		documentDefinition: any,
		tableData: string[][]
	): void {
		documentDefinition.content.push({
			table: {
				body: tableData,
				layout: 'lightHorizontalLines',
			},
		});
	}

	private adicionarMensagemSemDados(documentDefinition: any): void {
		documentDefinition.content.push({
			text: 'Nenhum dado encontrado na tabela',
			alignment: 'center',
			fontSize: 10,
			margin: [0, 10],
		});
	}

	private gerarDocumentoPDF(documentDefinition: any): void {
		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		pdfDocGenerator.open();
	}
}
