import L from 'leaflet';

export const medicaoIcon = L.icon({
	iconUrl: 'assets/images/leaflet-markers/medicao-icon.png',
	iconSize: [20, 20],
	iconAnchor: [10, 10],
	popupAnchor: [0, 0],
	tooltipAnchor: [10, 0],
});

export const circleIcon = L.icon({
	iconUrl: 'assets/images/leaflet-markers/circle.png',
	iconSize: [10, 10],
	iconAnchor: [2.5, 2.5],
	popupAnchor: [0, 0],
	tooltipAnchor: [5, 0],
});
export const agroIcon = L.icon({
	iconUrl: 'assets/images/leaflet-markers/agrometeorologica.png',
	iconSize: [10, 10],
	iconAnchor: [2.5, 2.5],
	popupAnchor: [0, 0],
	tooltipAnchor: [5, 0],
});
export const climaIcon = L.icon({
	iconUrl: 'assets/images/leaflet-markers/meteorologica.png',
	iconSize: [10, 10],
	iconAnchor: [2.5, 2.5],
	popupAnchor: [0, 0],
	tooltipAnchor: [5, 0],
});
