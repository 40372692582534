<form [formGroup]="form" class="d-flex">
	<div class="transfer-list d-flex flex-column">
		<div class="fw-semibold mb-2">{{ inputLabel }}</div>
		<div class="d-flex flex-column">
			<seira-input
				type="text"
				placeholder="Pesquisar..."
				controlName="inputFilter"></seira-input>
			<div
				class="border overflow-scroll rounded border-1"
				style="height: 250px">
				<div
					*ngIf="loading; else list"
					class="w-100 h-100 d-flex justify-content-center align-items-center">
					<seira-loading
						[show]="loading"
						[pageCentered]="false"></seira-loading>
				</div>

				<ng-template #list>
					<div class="m-2 text-tertiary" *ngIf="inputList.length === 0">
						Nenhum item disponível
					</div>
					<ul class="list-group list-group-flush">
						<li *ngFor="let item of filteredInputList" class="list-group-item">
							<seira-checkbox
								componentClass="form-check"
								[disabled]="disabled"
								[label]="item[labelProperty]"
								[id]="item[valueProperty]"
								[controlName]="item[labelProperty]">
							</seira-checkbox>
						</li>
					</ul>
				</ng-template>
			</div>
		</div>
		<div class="d-flex justify-content-end">
			{{ filteredInputList.length }} / {{ inputList.length }}
		</div>
	</div>
	<div class="d-flex flex-column justify-content-center mx-4">
		<button
			class="btn btn-teal my-1"
			(click)="addSelectedItems()"
			[disabled]="disabled">
			<i class="ph-caret-right"></i>
		</button>
		<button
			class="btn btn-teal my-1"
			(click)="addAllItems()"
			[disabled]="disabled">
			<i class="ph-caret-double-right"></i>
		</button>
		<button
			class="btn btn-teal my-1"
			(click)="removeSelectedItems()"
			[disabled]="disabled">
			<i class="ph-caret-left"></i>
		</button>
		<button
			class="btn btn-teal my-1"
			(click)="removeAllItems()"
			[disabled]="disabled">
			<i class="ph-caret-double-left"></i>
		</button>
	</div>
	<div class="d-flex flex-column">
		<div class="fw-semibold mb-2">
			{{ outputLabel }}<span *ngIf="required" class="text-danger">*</span>
		</div>
		<div class="transfer-list d-flex flex-column">
			<seira-input
				type="text"
				placeholder="Pesquisar..."
				controlName="outputFilter"></seira-input>
			<div
				class="border overflow-scroll rounded border-1"
				[class.border-danger]="showRequiredMessage"
				style="height: 250px">
				<div class="m-2 text-tertiary" *ngIf="outputList.length === 0">
					Nenhum item selecionado
				</div>
				<ul class="list-group list-group-flush">
					<li *ngFor="let item of filteredOutputList" class="list-group-item">
						<seira-checkbox
							componentClass="form-check"
							[label]="item[labelProperty]"
							[id]="item[valueProperty]"
							[controlName]="item[labelProperty]">
						</seira-checkbox>
					</li>
				</ul>
			</div>
		</div>
		<div class="d-flex justify-content-between">
			<div>
				<small class="text-danger mt-0" *ngIf="showRequiredMessage">
					Campo obrigatório.
				</small>
			</div>

			<span> {{ filteredOutputList.length }} / {{ outputList.length }} </span>
		</div>
	</div>
</form>
