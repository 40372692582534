import { Component, Input, TemplateRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

export type OpcaoPagina<T> = {
	label: string;
	template: TemplateRef<T>;
};

@Component({
	selector: 'seira-public-page-options',
	templateUrl: './public-page-options.component.html',
	styleUrls: ['./public-page-options.component.scss'],
})
export class PublicPageOptionsComponent<T> {
	@Input() opcoes!: OpcaoPagina<T>[];

	paginaAtual?: OpcaoPagina<any>;

	constructor(public readonly bsModalRef: BsModalRef) {}

	navegarParaPagina(opcao: OpcaoPagina<any>) {
		this.paginaAtual = opcao;
	}

	navegarParaInicio() {
		this.paginaAtual = undefined;
	}
}
