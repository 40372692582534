import { ResponseHistoricoEstacao } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/interfaces/estacao-monitorada';
import * as Highcharts from 'highcharts/highstock';

export const getChartSeries = (
	dataMenu: ResponseHistoricoEstacao,
	valueField: keyof ResponseHistoricoEstacao,
	name: string
): Highcharts.SeriesOptionsType[] => {
	const formatData = (
		dataset: Array<number | null>
	): Array<[number | string, number | null]> =>
		dataMenu.data.map((data, i) => [data, dataset[i]]);

	switch (valueField) {
		case 'precipitacao':
		case 'precipitacaoAcumulada':
			return [
				{
					data: formatData(dataMenu['precipitacao']),
					name,
					type: 'column',
					color: '#4f8acf',
					states: {
						hover: {
							color: '#9BC1D4',
						},
					},
				},
			];
		case 'umidadeInstantanea':
			return [
				{
					data: formatData(dataMenu[valueField]),
					name,
					type: 'area',
					color: '#4f8acf',
					lineWidth: 3,
					fillColor: {
						linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
						stops: [
							[0, '#b7e6fd'],
							[
								1,
								Highcharts.color('#b7e6fd').setOpacity(0.05).get().toString(),
							],
						],
					},
					marker: {
						enabled: false,
					},
				},
			];
		case 'temperaturaAr':
			return [
				{
					data: formatData(dataMenu[valueField]),
					name,
					type: 'area',
					color: '#4f8acf',
					lineWidth: 3,
					fillColor: {
						linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
						stops: [
							[0, '#b7e6fd'],
							[
								1,
								Highcharts.color('#b7e6fd').setOpacity(0.05).get().toString(),
							],
						],
					},
					marker: {
						enabled: false,
					},
				},
			];

		case 'ventoVelocidade':
			const direcao: any = {
				data: dataMenu.data.map((data, i) => [
					data,
					dataMenu.ventoVelocidade[i],
					dataMenu.ventoDirecao[i],
				]),
				name: 'Direção',
				type: 'windbarb',
				showInLegend: false,
			};

			const velocidade: any = {
				data: formatData(dataMenu.ventoVelocidade),
				name: 'Velocidade',
				type: 'area',
				color: '#4f8acf',
				lineWidth: 3,
				fillColor: {
					linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
					stops: [
						[0, '#b7e6fd'],
						[1, Highcharts.color('#b7e6fd').setOpacity(0.25).get().toString()],
					],
				},
				marker: {
					enabled: false,
				},
			};

			if (dataMenu[valueField].length < 49) {
				return [direcao, velocidade];
			}
			return [velocidade];

		case 'ventoVelocidade1Maxima':
			const velocidade1Maxima: any = {
				data: formatData(dataMenu.ventoVelocidade1Maxima),
				name: 'Velocidade',
				type: 'area',
				color: '#4f8acf',
				lineWidth: 3,
				fillColor: {
					linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
					stops: [
						[0, '#b7e6fd'],
						[1, Highcharts.color('#b7e6fd').setOpacity(0.25).get().toString()],
					],
				},
				marker: {
					enabled: false,
				},
			};

			return [velocidade1Maxima];
		case 'ventoVelocidade2Maxima':
			const velocidade2Maxima: any = {
				data: formatData(dataMenu.ventoVelocidade2Maxima),
				name: 'Velocidade',
				type: 'area',
				color: '#4f8acf',
				lineWidth: 3,
				fillColor: {
					linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
					stops: [
						[0, '#b7e6fd'],
						[1, Highcharts.color('#b7e6fd').setOpacity(0.25).get().toString()],
					],
				},
				marker: {
					enabled: false,
				},
			};

			return [velocidade2Maxima];
		case 'ventoDirecao1DesvioPadrao':
			const direcaoDesvio1: any = {
				data: dataMenu.data.map((data, i) => [
					data,
					dataMenu.ventoVelocidade[i],
					dataMenu.ventoDirecao1DesvioPadrao[i],
				]),
				name: 'Direção',
				type: 'windbarb',
				color: '#4f8acf',
				showInLegend: false,
			};
			const velocidade1: any = {
				data: formatData(dataMenu.ventoVelocidade),
				type: 'area',
				name: 'Velocidade',
				color: '#4f8acf',
				lineWidth: 3,
				fillColor: {
					linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
					stops: [
						[0, '#b7e6fd'],
						[1, Highcharts.color('#b7e6fd').setOpacity(0.25).get().toString()],
					],
				},
				marker: {
					enabled: false,
				},
			};
			return [direcaoDesvio1, velocidade1];
		case 'ventoDirecao2DesvioPadrao':
			const direcao2DesvioPadrao: any = {
				data: dataMenu.data.map((data, i) => [
					data,
					dataMenu.ventoVelocidade[i],
					dataMenu.ventoDirecao2DesvioPadrao[i],
				]),
				name: 'Direção',
				type: 'windbarb',
				showInLegend: false,
			};

			const velocidade2Desvio: any = {
				data: formatData(dataMenu.ventoVelocidade),
				name: 'Velocidade',
				type: 'area',
				color: '#4f8acf',
				lineWidth: 3,
				fillColor: {
					linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
					stops: [
						[0, '#b7e6fd'],
						[1, Highcharts.color('#b7e6fd').setOpacity(0.25).get().toString()],
					],
				},
				marker: {
					enabled: false,
				},
			};
			return [direcao2DesvioPadrao, velocidade2Desvio];
		case 'pressaoAtmosferica':
			return [
				{
					data: formatData(dataMenu[valueField]),
					name,
					type: 'line',
					color: '#4f8acf',
					lineWidth: 3,
					marker: {
						enabled: false,
					},
				},
			];
		default:
			return [
				{
					data: formatData(dataMenu[valueField]),
					name,
					type: 'area',
					color: '#4f8acf',
					lineWidth: 3,
					fillColor: {
						linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
						stops: [
							[0, '#b7e6fd'],
							[
								1,
								Highcharts.color('#b7e6fd').setOpacity(0.05).get().toString(),
							],
						],
					},
					marker: {
						enabled: false,
					},
				},
			];
	}
};
