import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@core/services/auth/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'seira-form-login',
	templateUrl: './form-login.component.html',
	styleUrls: ['./form-login.component.scss'],
})
export class FormLoginComponent implements OnInit {
	loginForm!: FormGroup;
	carregando = false;

	constructor(
		private formBuilder: FormBuilder,
		private authService: AuthService,
		private toastr: ToastrService,
		private router: Router
	) {}

	ngOnInit(): void {
		this.loginForm = this.formBuilder.group({
			login: ['', [Validators.required]],
			senha: ['', [Validators.required]],
		});
	}

	login() {
		const login = this.loginForm.get('login')?.value;
		const senha = this.loginForm.get('senha')?.value;
		this.carregando = true;
		this.authService.authenticate(login, senha).subscribe({
			next: () => {
				this.router
					.navigate(['meteorologia', 'medicoes-pluviometricas'])
					.then();
				this.carregando = false;
			},
			error: err => {
				if (err.status === 401) {
					this.toastr.error(err.error?.mensagem, 'Falha ao realizar login');
				} else {
					const erroDetalhes: string | undefined = err.error?.detail;
					this.toastr.error(
						erroDetalhes ??
							'Ocorreu um erro inesperado ao tentar realizar login',
						'Falha ao realizar login'
					);
				}
				this.carregando = false;
			},
		});
	}

	goToPagePublic() {
		this.router.navigate(['/']).then();
	}

	goToRecovery() {
		window.open('/auth/recuperar-senha', '_blank');
	}
}
