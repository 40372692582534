import { HttpClientProtectedService } from '@core/services/auth/http-client-protected.service';
import { Observable } from 'rxjs';
import { Rotas } from '@core/enums/routes';
import { Regional } from '@modulos/meteorologia/submodulos/regional/interfaces/Regional';
import { Injectable } from '@angular/core';
import { Projection } from '@core/enums/projections';
@Injectable({
	providedIn: 'root',
})
export class RegionalService {
	constructor(private http: HttpClientProtectedService) {}

	adicionar(regional: Regional): Observable<Regional> {
		return this.http.post<Regional, Regional>(Rotas.REGIONAIS, regional);
	}
	list(): Observable<any> {
		return this.http.get<Regional>(Rotas.REGIONAIS);
	}
	remover(regionalId: number): Observable<any> {
		return this.http.delete<Regional>(Rotas.REGIONAIS + '/' + regionalId);
	}

	buscarPorId(regionalId: number): Observable<any> {
		return this.http.get<Regional>(
			Rotas.REGIONAIS +
				'/' +
				regionalId +
				'?projection=' +
				Projection.REGIONAL_ALL
		);
	}
	editar(regional: Regional): Observable<Regional> {
		return this.http.patch<Regional, any>(
			Rotas.REGIONAIS + '/' + regional.id,
			regional
		);
	}
}
