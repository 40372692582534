<div class="mb-2">
	<label class="form-label fw-semibold" [for]="id"
		>{{ label }}<span class="text-danger" *ngIf="required">*</span></label
	>
	<input
		[type]="type"
		[id]="id"
		[placeholder]="placeholder"
		class="form-control"
		[mask]="mask"
		autocomplete="off"
		[ngClass]="showsTextRequired ? 'is-invalid' : ''"
		[(ngModel)]="value" />
	<div class="invalid-feedback" *ngIf="required && showsTextRequired">
		{{ textRequired }}
	</div>
</div>
