import { Component, Input } from '@angular/core';

@Component({
	selector: 'seira-public-modal',
	templateUrl: './public-modal.component.html',
	styleUrls: ['./public-modal.component.scss'],
})
export class PublicModalComponent {
	@Input() close!: () => void;
	@Input() titleClass = 'd-flex';
}
