import { DEFAULT_OPTIONS } from '@utils/datetime-util';
import { zonedTimeToUtc } from 'date-fns-tz';
import { DateTime, DateTimeFormatOptions } from 'luxon';

interface ZonedDateTime {
	dateInstance: Date;

	toISOString(): string;

	toString(): string;
}

interface ZonedDateTimeStatic {
	new (): ZonedDateTime;

	createCloneFrom(dateInstance: Date): ZonedDateTime;
}

/* class decorator */
function staticImplements<T>() {
	return <U extends T>(constructor: U) => {
		// eslint-disable-next-line @typescript-eslint/no-unused-expressions
		constructor;
	};
}

@staticImplements<ZonedDateTimeStatic>()
class ZonedDateTime implements ZonedDateTime {
	dateInstance: Date;

	constructor(valorUnico?: number | string | Date);

	constructor(
		ano: number,
		mes: number,
		dia: number,
		hora: number,
		minuto: number,
		segundo: number,
		milissegundo: number
	);
	constructor(...args: any) {
		if (args.length === 1) {
			this.dateInstance = args[0] instanceof Date ? args[0] : new Date(args[0]);
		} else if (args.length > 1) {
			const [ano, mes, dia, hora, minuto, segundo, milisegundo] = args;
			this.dateInstance = new Date(
				ano,
				mes,
				dia,
				hora,
				minuto,
				segundo,
				milisegundo
			);
		} else {
			this.dateInstance = new Date();
		}
	}

	public static createCloneFrom(dateInstance: Date) {
		return new ZonedDateTime(dateInstance);
	}
}

function formatarParaStringComTimezoneOffset(this: any) {
	return DateTime.fromISO(this.dateInstance.toISOString()).toString();
}

ZonedDateTime.prototype.toString = formatarParaStringComTimezoneOffset;
ZonedDateTime.prototype.toISOString = formatarParaStringComTimezoneOffset;

export default ZonedDateTime;
