<seira-header></seira-header>

<!-- Page content -->
<div class="page-content">
	<!-- Main content -->
	<div class="content-wrapper">
		<seira-page-header
			[listPathPage]="listPathPage"
			[breadcrumbElements]="breadcrumbElements"
			[activePathPage]="activePathPage"></seira-page-header>

		<!-- Inner content -->
		<div class="content-inner">
			<!-- Content area -->
			<div class="content">
				<ng-content></ng-content>
			</div>
			<seira-footer></seira-footer>
			<!-- /content area -->
		</div>
		<!-- /inner content -->
	</div>
	<!-- /main content -->
</div>
<!-- /page content -->
