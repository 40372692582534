import { Component, Input } from '@angular/core';
import { EstacaoMonitorada } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/interfaces/estacao-monitorada';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalHistoricoEstacaoComponent } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/componentes/modal-historico-estacao/modal-historico-estacao.component';
import { isNotNuloOuUndefined, numberToBrNumber } from '@utils';

@Component({
	selector: 'seira-box-select-estacoes',
	templateUrl: './box-select-estacoes.component.html',
	styleUrls: ['./box-select-estacoes.component.scss'],
})
export class BoxSelectEstacoesComponent {
	@Input() loading = false;
	@Input() estacoesMonitoradas!: EstacaoMonitorada[];

	constructor(private modalService: BsModalService) {}
	clickEstacao(estacao: EstacaoMonitorada) {
		const closeModal = () => {
			this.modalService.hide();
		};
		this.modalService.show(ModalHistoricoEstacaoComponent, {
			class: 'modal-xl modal-dialog-centered bg-transparent',
			initialState: {
				estacao: estacao,
				close: closeModal,
			},
		});
	}
	shouldShowDate(estacao: any): boolean {
		return estacao.tipoEstacao.toLowerCase() !== 'pluviometro_convencional';
	}

	protected readonly isNotNuloOuUndefined = isNotNuloOuUndefined;
	protected readonly numberToBrNumber = numberToBrNumber;
}
