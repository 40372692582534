import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'seira-card-calendario',
	templateUrl: './card-calendario.component.html',
	styleUrls: ['./card-calendario.component.scss'],
})
export class CardCalendarioComponent implements OnInit {
	@Input() date!: Date;
	@Input() isWeekend!: boolean;
	@Input() inMonth!: boolean;
	color!: string;
	opacity!: string;
	ngOnInit() {
		this.color = !this.isWeekend ? 'text-dark' : 'text-danger';
		this.opacity = this.inMonth ? 'opacity-100' : 'opacity-25';
	}
}
