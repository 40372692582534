import { Component, HostBinding, Input } from '@angular/core';
import { BreadcrumbElement } from '../interfaces/breadcrumb-element';

@Component({
	selector: 'seira-layout-padrao',
	templateUrl: './layout-padrao.component.html',
	styleUrls: ['./layout-padrao.component.scss'],
})
export class LayoutPadraoComponent {
	@HostBinding('class') clazz = 'expandir-flex';
	@Input() listPathPage: string[] = [];
	@Input() activePathPage: string | undefined = undefined;
	@Input() breadcrumbElements: BreadcrumbElement[] = [];
}
