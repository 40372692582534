import {
	Component,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
} from '@angular/core';
import {
	QuantisRegiaoAnualTableValores,
	QuantisRegiaoMensalTableValores,
	Relatorios,
} from '../../interfaces';
import {
	ADTColumns,
	ADTSettings,
} from 'angular-datatables/src/models/settings';
import { Meses } from '../../enum';
import { isNotNuloOuUndefined } from '@utils';
import { CondicaoMap } from '../../utils';

@Component({
	selector: 'seira-tabela-relatorios-quantis',
	templateUrl: './tabela-relatorios-quantis.component.html',
	styleUrls: ['./tabela-relatorios-quantis.component.scss'],
})
export class TabelaRelatoriosQuantisComponent implements OnInit, OnChanges {
	@Input() relatorios: Relatorios<
		QuantisRegiaoMensalTableValores | QuantisRegiaoAnualTableValores
	> = {
		data: [],
		municipio: '',
	};
	@Input() isAnual = false;
	dtOptions: ADTSettings = {};
	dados: any;

	colunas: ADTColumns[] = [];

	ngOnInit(): void {
		this.dados = this.handleGenerateDataByAnual(this.isAnual);
		this.colunas = this.handleGenerateColumnsByAnual(this.isAnual);
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.dados = this.handleGenerateDataByAnual(
			changes['isAnual'].currentValue
		);
		this.dados = this.colunas = this.handleGenerateColumnsByAnual(
			changes['isAnual'].currentValue
		);
	}

	private handleGenerateDataByAnual(isAnual: boolean) {
		if (isAnual) {
			return (
				this.relatorios as Relatorios<QuantisRegiaoAnualTableValores>
			).data.map(d => ({
				tipo: d.tipo,
				valor: d.valor!,
			}));
		}
		return (
			this.relatorios as Relatorios<QuantisRegiaoMensalTableValores>
		).data.map(d => ({
			tipo: d.tipo,
			...d.valores,
		}));
	}

	private handleGenerateColumnsByAnual(isAnual: boolean): ADTColumns[] {
		if (isAnual) {
			return [
				{
					data: 'tipo',
					title: 'Tipo',
					type: 'string',
					className: 'text-center',
					render: condicao => CondicaoMap.get(condicao),
				},
				{
					data: 'valor',
					title: `${this.relatorios.ano} (mm)`,
					type: 'number',
					className: 'text-center',
					orderable: false,
					render: (precipitacao: number | null | undefined) =>
						isNotNuloOuUndefined(precipitacao)
							? this.handleFormatNumber(precipitacao)
							: '-',
				},
			];
		}
		return [
			{
				data: 'tipo',
				title: 'Tipo',
				type: 'string',
				className: 'text-start',
				render: condicao => CondicaoMap.get(condicao),
			},
			...Array.from({ length: 12 }, (_, i) => ({
				data: i,
				title: `${Meses[i]} (mm)`,
				type: 'number',
				className: 'text-start',
				orderable: false,
				render: (precipitacao: number | null | undefined) =>
					isNotNuloOuUndefined(precipitacao)
						? this.handleFormatNumber(precipitacao)
						: '-',
			})),
		];
	}

	private handleFormatNumber(value: string | number | null) {
		if (value === 0 || value === '0') {
			return Number(0).toFixed(1).replace('.', ',');
		}
		return Number(value).toFixed(1).replace('.', ',');
	}
}

