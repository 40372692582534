<!-- alterar senha form -->
<form
	[formGroup]="redefinirSenhaForm"
	class="redefinir-senha-form"
	(submit)="redefinirSenha()">
	<div class="card mb-0">
		<div class="card-body">
			<div class="text-center mb-3">
				<div
					class="d-inline-flex align-items-center justify-content-center mb-4 mt-2">
					<img src="/assets/images/logo_1_1.svg" class="h-64px" alt="logo" />
				</div>
				<h5 class="mb-0">Alterar senha</h5>
				<span class="d-block text-muted">Preencha os campos abaixo</span>
			</div>

			<seira-input
				[showsTextRequired]="conditionShowTextRequired('novaSenha')"
				textRequired="Nova senha é obrigatória."
				[required]="true"
				type="password"
				controlName="novaSenha"
				label="Nova senha"
				placeholder="Informe a nova senha"
				id="novaSenha_form"></seira-input>

			<seira-input
				[showsTextRequired]="conditionShowTextRequired('confirmarSenha')"
				textRequired="Confirmar senha é obrigatório."
				[required]="true"
				type="password"
				controlName="confirmarSenha"
				label="Confirmar senha"
				placeholder="Confirme a nova senha"
				id="confirmarSenha_form"></seira-input>

			<seira-button
				buttonStyle="alterar"
				class="w-100"
				[loading]="carregando"
				type="submit"
				[disabledButton]="redefinirSenhaForm.invalid || carregando">
				Alterar senha
			</seira-button>
		</div>
	</div>
</form>
