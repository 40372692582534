import { Component, Input } from '@angular/core';

@Component({
	selector: 'seira-loading',
	templateUrl: './loading.component.html',
	styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {
	@Input() show = true;
	@Input() customStyle = '';
	@Input() pageCentered = true;
}
