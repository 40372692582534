<div style="display: flex; height: 100%; align-items: center">
	<div
		class="my-1"
		style="
		width: 1px;
		height: 70%;
		z-index: 2;
		position: absolute;
		 {{ color }}"></div>
</div>
