export interface DadosSerieVariavelMeteorologica {
	eixo: Object;
	serie: Object[];
}

export interface DadosGraficoVariaveis {
	datas: number[];
}

export abstract class SerieVariavelMeteorologica {
	abstract obterDadosSerie(
		dadosGrafico: DadosGraficoVariaveis,
		indexEixo: number
	): DadosSerieVariavelMeteorologica;

	/**
	 * Formata o dado no array de da série entendida pelo highchart.
	 * @returns {any[]} um objeto que representa a série
	 */
	protected prepararSerie(datas: number[], valores: number[]): any {
		const serie = [];
		const tamanho = datas.length;

		const valoresCorrigidos = valores.map(dado => {
			if (dado === undefined || dado === null) {
				return null;
			} else {
				return Number(dado.toFixed(2));
			}
		});

		for (let i = 0; i < tamanho; i++) {
			serie.push([datas[i], valoresCorrigidos[i]]);
		}

		return serie;
	}
}
