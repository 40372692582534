<button
	[title]="tituloBotao"
	[matMenuTriggerFor]="menu"
	style="height: 40px"
	class="btn btn-primary"
	[disabled]="disabled">
	<mat-icon>dehaze</mat-icon>
</button>

<mat-menu #menu="matMenu" class="menu-scroll">
	<div class="menu-content">
		<button
			mat-menu-item
			*ngFor="let item of opcoes"
			(click)="toggleSelection(item, $event)"
			[disabled]="item.isFixed">
			<mat-icon>{{
				isSelected(item) ? 'check_box' : 'check_box_outline_blank'
			}}</mat-icon>
			<span>{{ item.name }}</span>
		</button>
	</div>

	<mat-divider></mat-divider>

	<div class="search-button-container">
		<button
			type="button"
			(click)="_atualizarOpcoesSelecionadas()"
			class="btn btn-primary">
			<i class="ph ph-floppy-disk"></i>
			<span class="d-none d-md-inline-block mx-md-2">Salvar</span>
		</button>
	</div>
</mat-menu>
