<label
	for="texto-informativo"
	style="color: #576469 !important"
	class="p-0 text-muted fw-semibold my-1 font-size-16">
	{{ textosInformativos.label }}
</label>
<div
	id="texto-informativo"
	class="card px-2 text-white bg-frost d-flex flex-row font-size-16">
	<div *ngFor="let textoInformativo of textosInformativos.texts">
		<div class="card-body fw-semibold mb-0 pb-1">
			{{ textoInformativo.title }}
		</div>
		<div
			class="card-body mt-0 pt-0 fw-light"
			style="text-align: justify; text-justify: auto">
			{{ textoInformativo.text }}
		</div>
	</div>
</div>
