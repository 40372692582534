import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Rotas } from '@core/enums/routes';
import { Projection } from '@core/enums/projections';
import {
	Estacao,
	EstacaoFiltrada,
	ListagemEstacao,
	PCDsSincronizacao,
	TipoMedicao,
} from '../interfaces/estacao';
import { HttpClientProtectedService } from '@core/services/auth/http-client-protected.service';
import { TipoEstacao } from '@modulos/meteorologia/submodulos/estacao/enums/tipo-estacao';
import { HttpParams } from '@angular/common/http';

@Injectable({
	providedIn: 'root',
})
export class EstacaoService {
	constructor(private http: HttpClientProtectedService) {}

	adicionar(newEstacao: Estacao): Observable<Estacao> {
		return this.http.post<Estacao, Estacao>(Rotas.ESTACAO, newEstacao);
	}

	editar(editEstacao: Estacao): Observable<Estacao> {
		const data: any = editEstacao;
		for (const dataKey in new Estacao(data)) {
			if (
				typeof data[dataKey] === 'object' &&
				data[dataKey] !== null &&
				dataKey !== '_links'
			) {
				if (data[dataKey]?._links) {
					data[dataKey] = data[dataKey]._links.self.href;
					continue;
				}
				if (dataKey === 'tiposMedicao') {
					data[dataKey] = data[dataKey];
					continue;
				}
				if (dataKey === 'municipio') {
					data[dataKey] = data[dataKey];
					continue;
				}
				delete data[dataKey];
			}
		}

		return this.http.patch<Estacao, any>(
			Rotas.ESTACAO + '/' + editEstacao.id,
			data
		);
	}

	remover(estacaoId: number): Observable<any> {
		return this.http.delete<Estacao>(Rotas.ESTACAO + '/' + estacaoId);
	}

	buscarPorId(estacaoId: number): Observable<Estacao> {
		const projection = Projection.ESTACAO_All;
		return this.http.get<Estacao>(
			Rotas.ESTACAO + '/' + estacaoId + '?projection=' + projection
		);
	}

	list(): Observable<Estacao[]> {
		return this.http.get<Estacao[]>(Rotas.ESTACAO);
	}

	listar(): Observable<ListagemEstacao[]> {
		return this.http.get<ListagemEstacao[]>(Rotas.ESTACAO + '/listar');
	}

	listarTiposDeMedicoees(): Observable<TipoMedicao[]> {
		return this.http.get<TipoMedicao[]>(Rotas.TIPO_MEDICAO);
	}

	filteredList(
		apenasAtivas: boolean,
		estacaoFiltrada: EstacaoFiltrada,
		tiposEstacao?: Array<keyof typeof TipoEstacao>,
		palavraChave?: string
	): Observable<EstacaoFiltrada[]> {
		let params = new HttpParams().append('apenas_ativas', String(apenasAtivas));

		if (tiposEstacao) {
			params = params.append('tipos_estacao', String(tiposEstacao));
		}

		if (estacaoFiltrada.regional) {
			params = params.append('regional_id', estacaoFiltrada.regional);
		}

		if (estacaoFiltrada.microrregiao) {
			params = params.append('microrregiao_id', estacaoFiltrada.microrregiao);
		}

		if (estacaoFiltrada.mesorregiao) {
			params = params.append('mesorregiao_id', estacaoFiltrada.mesorregiao);
		}

		if (estacaoFiltrada.municipio) {
			params = params.append('municipio_id', estacaoFiltrada.municipio);
		}

		if (estacaoFiltrada.bacia) {
			params = params.append('bacia_id', estacaoFiltrada.bacia);
		}

		if (palavraChave) {
			params = params.append('palavra_chave', palavraChave);
		}
		if (estacaoFiltrada.codigoInterno) {
			params = params.append('codigo_interno', estacaoFiltrada.codigoInterno);
		}
		return this.http.get<EstacaoFiltrada[]>(Rotas.ESTACAO + '/filtro', params);
	}

	sincronizarPCDS(pcdSinc: PCDsSincronizacao): Observable<any> {
		return this.http.post(
			Rotas.ACOMPANHAMENTO_COLETA + '/sincronizarPCD',
			pcdSinc
		);
	}
}
