<div class="p-3" [ngClass]="opacity" style="overflow: hidden; min-height: 85px">
	<div class="d-inline-flex justify-content-between w-100">
		<div>
			<ng-content select="[topContent]"></ng-content>
		</div>
		<div [class]="color + ' h6 m-0'">{{ date | date: 'd' }}</div>
	</div>
	<div class="align-bottom p-0 mt-2">
		<ng-content select="[bottomContent]"></ng-content>
	</div>
	<ng-content select="[footerContent]"></ng-content>
</div>
