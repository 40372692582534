export enum Projection {
	MUNICIPIO_All = 'municipioProjectionAll',
	MICRORREGIAO_All = 'microrregiaoProjectionAll',
	MESORREGIAO_All = 'mesorregiaoProjectionAll',
	ESTACAO_All = 'estacaoProjectionAll',
	GRUPO_All = 'grupoProjectionAll',
	ORGAO_All = 'orgaoProjectionAll',
	USUARIO_ALL = 'usuarioProjectionAll',
	CULTURA_ALL = 'culturaProjectionAll',
	ZONEAMENTO_ALL = 'zoneamentoProjectionAll',
	RISCO_AGRICOLA = 'riscoAgricolaProjectionAll',
	BOLETIM = 'boletimDiarioProjectionAll',
	REGIONAL_ALL = 'regionalProjectionAll',
	BACIA_ALL = 'baciaProjectionAll',
	SUBBACIA_ALL = 'subBaciaProjectionAll',
	RESERVATORIOS_ALL = 'reservatorioProjectionAll',
	PERIODOS_RESERVATORIOS = 'periodosReservatorioProjectionAll',
	DADOS_PERIODOS_RESERVATORIOS = 'periodosReservatorioProjectionAll',
}
