<nav aria-label="breadcrumb">
	<ol class="breadcrumb">
		<li
			dropdown
			dropdownToggle
			class="d-inline-flex"
			style="position: relative; display: inline-block"
			*ngFor="let item of menu; let index = index">
			<i
				*ngIf="index !== 0"
				class="icon text-primary {{
					item.icon ? item.icon : 'dot bg-light'
				}}"></i>
			<div>
				<a
					[routerLink]="!showDropdown || !item.submenu ? item.path : null"
					ngClass="menu-item text-light {{ menuItemSelectedClass(item.path) }}"
					>{{ item.label }}</a
				>
			</div>
			<div *ngIf="showDropdown && item.submenu">
				<ul
					*dropdownMenu
					class="dropdown-menu ms-4 mt-3 p-1 border-0 bg-cyan-light"
					role="menu"
					style="width: max-content; z-index: 1001">
					<li
						*ngFor="let submenuItem of item.submenu"
						class="d-flex my-1 index">
						<seira-public-a href [redirectLink]="submenuItem.path">
							<i class="icon text-primary dot bg-primary mx-1 index"></i
							>{{ submenuItem.label }}</seira-public-a
						>
					</li>
				</ul>
			</div>
		</li>
	</ol>
</nav>
