import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription, takeUntil } from 'rxjs';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment/moment';
import { FormGroup, Validators } from '@angular/forms';
import { PostosRelatorios } from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import { Estacao } from '@home/submodulos/dados-meteorologicos/interfaces/filtros-opcoes';
import { handleGenerateQuantisDataHighchart } from '@home/submodulos/dados-meteorologicos/utils/graficoUtils';
import { criarImagemBase64FromChart } from '@utils';
import { Chart } from 'highcharts';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { Select } from '@layout/interfaces/select';
import * as pdfseira from '@utils/pdf-seira';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { ContentText, TDocumentDefinitions } from 'pdfmake/interfaces';
import { gerarTabelaParaRelatorioDeQuantisPdf } from '@home/submodulos/dados-meteorologicos/utils/relatorio-quantis';
import { Meses, PeriodoChuvosoMap } from '../../../enum';
import {
	DadoGraficoQuantis,
	DadosGraficoQuantisMensal,
	TipoGraficos,
} from '../../../interfaces';
import { QuantisMicrorregiaoService } from '../../../services';

@Component({
	selector: 'seira-pluviometro-grafico-quantis',
	templateUrl: './pluviometro-grafico-quantis.component.html',
	styleUrls: ['./pluviometro-grafico-quantis.component.scss'],
})
export class PluviometroGraficoQuantisComponent
	implements OnInit, OnDestroy, InstanciaRelatorio
{
	inputs = inject(INPUTS_RELATORIOS);
	form!: FormGroup;
	microrregioes!: Select<string>[];
	postos: PostosRelatorios[] = [];
	estacoes: Estacao[] = [];
	chart!: Chart;
	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => {
				return this.exportarPDF();
			},
		},
	];

	loading = false;
	gerou = false;
	useMaximumLimit = 500;
	isAnual = false;
	isPeriodosPluviometricos = false;
	resultado?: DadosGraficoQuantisMensal;
	private subscription = new Subscription();
	private _destroyed = new Subject();

	constructor(
		private toastr: ToastrService,
		private readonly quantisService: QuantisMicrorregiaoService
	) {}

	ngOnInit() {
		this.form = this.inputs.form;
		setTimeout(() => {
			this.submitFirstSearch();
		}, 0);
	}

	gerarRelatorio() {
		if (this.form.invalid) {
			return;
		}
		this.loading = true;
		this.inputs.setLoading(true);
		const tipoInput = this.inputs.form.get(FormularioRelatorio.TIPO);
		this.handleFetchQuantisByType(tipoInput?.value);
		this.handleSetValidatorsByType(tipoInput?.value);
		tipoInput?.valueChanges.pipe(takeUntil(this._destroyed)).subscribe({
			next: values => {
				this.handleFetchQuantisByType(values);
				this.handleSetValidatorsByType(values);
			},
		});
	}

	handleFetchQuantisByType(type: string) {
		if (type === 'QUANTIS_PLUVIOMETRICOS') {
			this.handleFetchQuantisByRegiaoAndPeriodoChuvoso();
		}

		if (type === 'QUANTIS_MENSAL') {
			this.handleFetchQuantisMensal();
		}

		if (type === 'QUANTIS_ANUAL') {
			this.handleFetchQuantisAnual();
		}
	}

	handleFetchQuantisByRegiaoAndPeriodoChuvoso() {
		const periodoChuvoso = this.form.get('periodoChuvoso')?.value!;
		const regiao = this.form.get('regiao')?.value!;

		this.quantisService
			.handleFetchQuantisByRegiaoAndPeriodoChuvoso(regiao, periodoChuvoso)
			.subscribe({
				next: resp => {
					const dadosGraficosQuantis: DadoGraficoQuantis[] = [
						{
							categorias: handleGenerateQuantisDataHighchart(resp.valores),
							titulo: resp.periodo,
						},
					];

					const tituloGeral = `Região: ${
						resp.regiao
					} - Período: ${PeriodoChuvosoMap.get(resp.periodo)} (${new Date(
						resp.dataComeco
					).toLocaleDateString('pt-BR')} - ${new Date(
						resp.dataFim
					).toLocaleDateString('pt-BR')})`;
					const legenda =
						'Os limiares utilizados para definir a precipitação mensal, na geração dos gráficos e mapas de quantis originaram-se do estudo intitulado: Determinação De Limiares para A Precipitação Mensal Das Regiões Homogêneas Da Paraíba Usando Quantis dos autores: Silva, E. A. da, Brito, J. I. B. de, Becker, C. T., Mandú, T. B., & Lima, I. P. C., publicado em 2022, pela Revista Brasileira de Climatologia ISSN - 2237-8642.';

					this.resultado = {
						tituloGeral,
						legenda,
						dadosGraficosQuantis,
					};
					this.useMaximumLimit =
						Math.max(
							...Object.values(resp.valores).map(values => values as number)
						) + 100;
				},
				error: () => {
					this.resultado = undefined;
					this.gerou = false;
					this.loading = false;
					this.inputs.setLoading(false);
					this.toastr.error('Erro ao obter informações do posto selecionado');
				},
				complete: () => {
					this.isPeriodosPluviometricos = true;
					this.loading = false;
					this.inputs.setLoading(false);
					this.gerou = true;
				},
			});
	}

	handleFetchQuantisMensal() {
		const ano = this.form.get('periodo')?.value!;
		const estacao = Number.parseInt(this.form.get('estacao')?.value!);

		this.quantisService
			.handleFetchQuantisMensaisGraphByEstacaoId(
				estacao,
				new Date(ano).getFullYear()
			)
			.subscribe({
				next: resp => {
					const dadosGraficosQuantis: DadoGraficoQuantis[] = resp.quantis.map(
						q => {
							const newCategorias = handleGenerateQuantisDataHighchart(
								q.valores
							);

							return {
								titulo: `${Meses[q.mes! - 1]}`,
								categorias: newCategorias,
							};
						}
					);

					const tituloGeral = `Posto: ${resp.posto} (${resp.municipio}) - ${resp.ano}`;
					const legenda =
						'Os limiares utilizados para definir a precipitação mensal, na geração dos gráficos e mapas de quantis originaram-se do estudo intitulado: Determinação De Limiares para A Precipitação Mensal Das Regiões Homogêneas Da Paraíba Usando Quantis dos autores: Silva, E. A. da, Brito, J. I. B. de, Becker, C. T., Mandú, T. B., & Lima, I. P. C., publicado em 2022, pela Revista Brasileira de Climatologia ISSN - 2237-8642.';

					this.resultado = {
						tituloGeral,
						legenda,
						dadosGraficosQuantis,
					};
				},
				error: () => {
					this.resultado = undefined;
					this.gerou = false;
					this.loading = false;
					this.inputs.setLoading(false);
					this.toastr.error('Erro ao obter informações do posto selecionado');
				},
				complete: () => {
					this.loading = false;
					this.inputs.setLoading(false);
					this.gerou = true;
				},
			});
	}

	handleFetchQuantisAnual() {
		const ano = this.form.get('periodo')?.value!;
		const estacao = Number.parseInt(this.form.get('estacao')?.value!);

		this.quantisService
			.handleFetchQuantilAnualGraphByEstacaoId(
				estacao,
				new Date(ano).getFullYear()
			)
			.subscribe({
				next: resp => {
					const dadosGraficosQuantis: DadoGraficoQuantis[] = [
						{
							titulo: new Date(ano).getFullYear().toString(),
							categorias: handleGenerateQuantisDataHighchart(
								resp.quantis.valores
							),
						},
					];

					const tituloGeral = `Posto: ${resp.posto} - ${resp.ano}`;
					const legenda =
						'Os limiares utilizados para definir a precipitação mensal, na geração dos gráficos e mapas de quantis originaram-se do estudo intitulado: Determinação De Limiares para A Precipitação Mensal Das Regiões Homogêneas Da Paraíba Usando Quantis dos autores: Silva, E. A. da, Brito, J. I. B. de, Becker, C. T., Mandú, T. B., & Lima, I. P. C., publicado em 2022, pela Revista Brasileira de Climatologia ISSN - 2237-8642.';

					this.resultado = {
						tituloGeral,
						legenda,
						dadosGraficosQuantis,
					};
					this.useMaximumLimit =
						Math.max(
							...Object.values(resp.quantis.valores).map(
								values => values as number
							)
						) + 100;
				},
				error: () => {
					this.resultado = undefined;
					this.gerou = false;
					this.loading = false;
					this.inputs.setLoading(false);
					this.toastr.error('Erro ao obter informações do posto selecionado');
				},
				complete: () => {
					this.isAnual = true;
					this.loading = false;
					this.inputs.setLoading(false);
					this.gerou = true;
				},
			});
	}

	onChartCreated(chart: Chart) {
		this.chart = chart;
	}

	submitFirstSearch() {
		this.form.patchValue({
			periodo: moment(),
		});
		this.gerarRelatorio();
	}

	getPeriodo() {
		return this.form.get('periodo')?.value.year() || '';
	}

	async exportarPDF() {
		const chartsConverted: TDocumentDefinitions['content'] = [];

		if (
			!this.resultado ||
			!this.chart ||
			!this.resultado.dadosGraficosQuantis.length
		) {
			this.toastr.error(
				'Não é possível gerar um pdf pois nenhum gráfico foi gerado.'
			);
			return;
		}
		chartsConverted.push({
			image: await criarImagemBase64FromChart(this.chart),
			fontSize: 10,
			alignment: 'center',
			height: 450,
			width: 420,
		});

		const documentDefinition: any = await pdfseira.documentDefinitions();
		const quantisTypeTitle = Object.entries(TipoGraficos).find(
			att => att[0].toString() === this.form.get('tipo')!.value
		)?.[1];
		const content = [
			{
				text: `Relatório - ${quantisTypeTitle} - ${this.getPeriodo()}`,
				alignment: 'center',
				margin: [15, 15, 5, 15],
			},
			...chartsConverted,
		];

		if (this.resultado?.dadosGraficosQuantis) {
			const table = {
				table: gerarTabelaParaRelatorioDeQuantisPdf(
					this.resultado.dadosGraficosQuantis,
					true
				),
			};
			const labelTabela: ContentText = {
				text: `Todos os dados de medidas na tabela estão expressos em milímetros(mm).`,
				margin: [0, 15, 0, 5],
				fontSize: 10,
			};
			content.push(labelTabela);
			content.push(table);
		}
		documentDefinition.content.push(content);

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	handleSetValidatorsByType(type: string) {
		if (type === 'QUANTIS_PLUVIOMETRICOS') {
			this.setValidatorsToQuantisPluviometricos();
		}
		if (type === 'QUANTIS_MENSAL' || type === 'QUANTIS_ANUAL') {
			this.setValidatorsToQuantisMensalOrAnual();
		}
	}

	setValidatorsToQuantisMensalOrAnual() {
		this.form.get(FormularioRelatorio.TIPO)?.setValidators(Validators.required);
		this.form
			.get(FormularioRelatorio.PERIODO)
			?.setValidators(Validators.required);
		this.form
			.get(FormularioRelatorio.ESTACAO)
			?.setValidators(Validators.required);
	}

	setValidatorsToQuantisPluviometricos() {
		this.form.get(FormularioRelatorio.TIPO)?.setValidators(Validators.required);
		this.form
			.get(FormularioRelatorio.PERIODO_CHUVOSO)
			?.setValidators(Validators.required);
		this.form
			.get(FormularioRelatorio.REGIAO_PLUVIOMETRICA)
			?.setValidators(Validators.required);
	}

	ngOnDestroy() {
		this.form.get(FormularioRelatorio.TIPO)?.clearValidators();
		this.form.get(FormularioRelatorio.PERIODO)?.clearValidators();
		this.form.get(FormularioRelatorio.ESTACAO)?.clearValidators();
		this.subscription.unsubscribe();
		this._destroyed.next(undefined);
	}
}
