import { Component, Input } from '@angular/core';

@Component({
	selector: 'seira-monitoramento-legenda-pcd',
	templateUrl: './monitoramento-legenda-pcd.component.html',
	styleUrls: ['./monitoramento-legenda-pcd.component.scss'],
})
export class MonitoramentoLegendaPCDComponent {
	@Input() maxWidth!: string;
	@Input() class = 'mb-1 me-2 w-100';
	baseUrl = window.location.origin;

	tipos = [
		{
			nome: 'Meteorológica',
			imagem: this.baseUrl + '/assets/images/leaflet-markers/meteorologica.png',
		},
		{
			nome: 'Agrometeorológica',
			imagem:
				this.baseUrl + '/assets/images/leaflet-markers/agrometeorologica.png',
		},
	];
}
