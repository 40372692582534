export enum ModeloVariavel {
	PRECIPITACAO = 'PRECIPITACAO',
	PRESSAO = 'PRESSAO',
	TEMPERATURA_2_METROS = 'TEMPERATURA_2_METROS',
	UMIDADE_AR_2_METROS = 'UMIDADE_AR_2_METROS',
	VENTO_10_METROS = 'VENTO_10_METROS',
	PRECIPITACAO_ACUMULADA_24H = 'PRECIPITACAO_ACUMULADA_24H',
}

export const getLabelModeloVariavel = (
	variavel: ModeloVariavel | string
): string => {
	switch (variavel) {
		case ModeloVariavel.PRECIPITACAO:
			return 'Precipitação (mm)';
		case ModeloVariavel.PRESSAO:
			return 'Pressão (hPa)';
		case ModeloVariavel.TEMPERATURA_2_METROS:
			return 'Temperatura 2m (ºC)';
		case ModeloVariavel.UMIDADE_AR_2_METROS:
			return 'Umidade relativa 2m (%)';
		case ModeloVariavel.VENTO_10_METROS:
			return 'Vento 10m (m/s)';
		case ModeloVariavel.PRECIPITACAO_ACUMULADA_24H:
			return 'Precipitação acumulada 24h (mm)';
		default:
			return '';
	}
};

export const getTimeIndexStepsVariavel = (variavel: ModeloVariavel): number => {
	switch (variavel) {
		case ModeloVariavel.PRECIPITACAO_ACUMULADA_24H:
			return 24;
		default:
			return 1;
	}
};
