<label
	*ngIf="label"
	class="form-label text-tertiary-light fw-normal font-size-16"
	[for]="id">
	{{ label }}<span class="text-danger" *ngIf="required">&nbsp;*</span>
</label>
<ng-select
	[id]="id"
	[bindValue]="optionValue"
	[bindLabel]="optionLabel"
	[(ngModel)]="value"
	(ngModelChange)="onValueChange($event)"
	(blur)="onTouched()"
	[notFoundText]="notFoundText"
	[placeholder]="placeholder"
	[items]="options"
	[loading]="carregando"
	[loadingText]="carregandoLabel"
	[searchable]="searchable"
	[clearable]="clearable"
	[disabled]="disabled"
	[multiple]="multiple"
	[closeOnSelect]="!multiple"
	dropdownPosition="bottom"
	class="public-select  {{ disabled ? 'disabled' : '' }} {{
		!optionsCentralized && 'align-opt-start'
	}} fw-semibold-2">
	<ng-template *ngIf="multiple" ng-header-tmp>
		<seira-public-a
			class="text-primary"
			style="margin-left: -0.4rem"
			(click)="selectAll()">
			Selecionar todos
		</seira-public-a>
	</ng-template>
	<ng-template
		*ngIf="multiple"
		ng-multi-label-tmp
		let-items="items"
		let-clear="clear">
		<div
			class="ng-value d-flex align-items-center bg-primary-light fw-normal rounded px-1 me-1"
			*ngFor="let item of items | slice: 0 : maxShownValues">
			<span class="ng-value-label">{{ $any(item)[optionLabel] }}</span>
			<div class="vr bg-primary mx-1"></div>
			<i
				class="ng-value-icon right ph-x"
				style="font-size: 14px; cursor: pointer"
				(click)="clear(item)"></i>
		</div>
		<div class="ng-value" *ngIf="items?.length > maxShownValues">
			<div class="bg-primary-light fw-normal rounded px-1">
				<span class="ng-value-label"
					>mais {{ items.length - maxShownValues }}...</span
				>
			</div>
		</div>
	</ng-template>
</ng-select>
