import {
	Directive,
	Input,
	OnDestroy,
	TemplateRef,
	ViewContainerRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

type Size = 'sm' | 'md' | 'lg' | 'xl' | 'mobile' | 'desktop';
export interface BreakpointInterface {
	sm: string;
	md: string;
	lg: string;
	xl: string;
	mobile: string;
	desktop: string;
}

export const configBreakpoints: BreakpointInterface = {
	sm: '(max-width: 767.98px)',
	md: '(min-width: 768px) and (max-width: 1199.98px)',
	lg: '(min-width: 1200px) and (max-width: 1919.98px)',
	xl: '(min-width: 1920px)',
	mobile: '(max-width: 1199.98px)',
	desktop: '(min-width: 1200px)',
};

@Directive({
	selector: '[seiraIfViewportSize]',
})
export class IfViewportSizeDirective implements OnDestroy {
	private subscription = new Subscription();

	@Input('seiraIfViewportSize') set size(value: Size | Size[]) {
		this.subscription.unsubscribe();
		this.subscription = this.observer
			.observe(this.getObservable(value))
			.subscribe(this.updateView);
	}

	constructor(
		private observer: BreakpointObserver,
		private vcRef: ViewContainerRef,
		private templateRef: TemplateRef<any>
	) {}

	updateView = ({ matches }: BreakpointState) => {
		if (matches && !this.vcRef.length) {
			this.vcRef.createEmbeddedView(this.templateRef);
		} else if (!matches && this.vcRef.length) {
			this.vcRef.clear();
		}
	};

	getObservable(value: Size | Size[]) {
		let observable: string[] = [];
		if (Array.isArray(value)) {
			for (const v of value) {
				observable.push(configBreakpoints[v]);
			}
		} else {
			observable = [configBreakpoints[value]];
		}
		return observable;
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
