import { Component, Input, OnInit } from '@angular/core';
import { HTMLInputTypeAttribute } from '@layout/interfaces/htmlTypeInput';
import {
	ControlContainer,
	FormControl,
	FormGroupDirective,
} from '@angular/forms';

@Component({
	selector: 'seira-public-input',
	templateUrl: './public-input.component.html',
	styleUrls: ['./public-input.component.scss'],
	viewProviders: [
		{ provide: ControlContainer, useExisting: FormGroupDirective },
	],
})
export class PublicInputComponent implements OnInit {
	@Input() id!: string;
	@Input() label: string = '';
	@Input() required = false;
	@Input() controlName!: string;
	@Input() placeholder!: string;
	@Input() showsTextRequired = false;
	@Input() textRequired = '';
	@Input() type: HTMLInputTypeAttribute = 'text';

	control = new FormControl();

	constructor(private controlContainer: ControlContainer) {}

	ngOnInit(): void {
		this.control = this.controlContainer.control?.get(
			this.controlName
		) as FormControl;
	}
}
