export enum MesAbreviadoPorCompleto {
	JAN = 'Janeiro',
	FEV = 'Fevereiro',
	MAR = 'Março',
	ABR = 'Abril',
	MAI = 'Maio',
	JUN = 'Junho',
	JUL = 'Julho',
	AGO = 'Agosto',
	SET = 'Setembro',
	OUT = 'Outubro',
	NOV = 'Novembro',
	DEZ = 'Dezembro',
}
