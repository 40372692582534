/**
 * Range de seleção para o período de 5 dias
 */
export const rangeSelector5Dias = {
	y: 20,
	buttons: [
		{
			type: 'day',
			count: 1,
			text: '1d',
		},
		{
			type: 'day',
			count: 2,
			text: '2d',
		},
		{
			type: 'day',
			count: 3,
			text: '3d',
		},
		{
			type: 'day',
			count: 4,
			text: '4d',
		},
		{
			type: 'day',
			count: 5,
			text: '5d',
		},
		{
			type: 'all',
			text: 'Todo',
		},
	],
};
