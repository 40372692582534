import { Component, Input } from '@angular/core';
import { ValoresBusca } from '@utils/interpolacao/interpolacao';
import { isNotNuloOuUndefined, numberToBrNumber } from '@utils';

@Component({
	selector: 'seira-popup-padrao',
	templateUrl: './popup-padrao.component.html',
	styleUrls: ['./popup-padrao.component.scss'],
})
export class PopupPadraoComponent {
	@Input()
	markerData!: ValoresBusca;

	formatarNumero(numero: number): string {
		if (isNotNuloOuUndefined(numero)) {
			return (
				numberToBrNumber(numero.toFixed(2)) +
				(this.markerData.unidade ? ' ' + this.markerData.unidade : ' ')
			);
		} else {
			return ' ' + this.markerData.unidade;
		}
	}
	protected readonly isNotNuloOuUndefined = isNotNuloOuUndefined;
	protected readonly numberToBrNumber = numberToBrNumber;
}
