import { Component, Input } from '@angular/core';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';

export interface GroupButton {
	label: string;
	icon: string;
	size: 'big' | 'small' | 'medium';
	onClick: Function;
}

export type GroupButtonMode = 'full' | 'dropdown';

@Component({
	selector: 'seira-public-button-group',
	templateUrl: './public-button-group.component.html',
	styleUrls: ['./public-button-group.component.scss'],
	providers: [
		{
			provide: BsDropdownConfig,
			useValue: { isAnimated: true, autoClose: true },
		},
	],
})
export class PublicButtonGroupComponent {
	@Input() buttons!: GroupButton[];
	@Input() mode: GroupButtonMode = 'full';
	@Input() disabledButtons = false;
	@Input() dropdownContainer?: string;
}
