<div role="group" aria-label="Basic example">
	<div class="fw-semibold mb-1 text-tertiary">
		<ng-content select="[label]"></ng-content>
	</div>
	<div
		class="bg-white d-inline-flex flex-row shadow-lg rounded"
		style="padding-right: 0.85rem; padding-left: 0.85rem">
		<div
			*ngFor="let item of items"
			class="d-flex align-items-center flex-column"
			[class]="isMobile ? 'mx-1 my-1' : 'mx-2 my-2'">
			<ng-container *seiraIfViewportSize="'desktop'">
				<div class="h1 m-0 text-aero fw-normal">
					{{ item.value }}
				</div>
				<div class="m-0 fw-normal text-quaternary">
					{{ item.label }}
				</div>
			</ng-container>

			<ng-container *seiraIfViewportSize="'mobile'">
				<div class="h1 m-0 text-aero fw-normal">
					{{ item.value }}
				</div>
				<div class="m-0 fw-normal text-quaternary font-size-3">
					{{ item.label }}
				</div>
			</ng-container>
		</div>
	</div>
</div>
