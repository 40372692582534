import { Injectable } from '@angular/core';
import { HttpClientProtectedService } from '@core/services/auth/http-client-protected.service';
import { Rotas } from '@core/enums/routes';
import { Observable } from 'rxjs';
import {
	QuantisPrecipitacao,
	QuantisRegiaoAnualTableValores,
	QuantisRegiaoMensalTableValores,
	QuantisRegiaoPluviometrica,
	QuantisResponse,
} from '../interfaces';

/**
 * @description Serviço para buscar os dados PUBLICOS de quantis
 */
@Injectable({
	providedIn: 'root',
})
export class QuantisMicrorregiaoService {
	constructor(private http: HttpClientProtectedService) {}

	handleFetchQuantisMensalTableByEstacaoId(
		postoId: number,
		ano: number
	): Observable<QuantisResponse<QuantisRegiaoMensalTableValores[]>> {
		return this.http.get(
			Rotas.QUANTIS + `/mensal/tabela/estacao?ano=${ano}&postoId=${postoId}`
		);
	}

	handleFetchQuantilAnualTableByEstacaoId(
		postoId: number,
		ano: number
	): Observable<QuantisResponse<QuantisRegiaoAnualTableValores[]>> {
		return this.http.get(
			Rotas.QUANTIS + `/anual/tabela/estacao?ano=${ano}&postoId=${postoId}`
		);
	}

	handleFetchQuantisMensaisGraphByEstacaoId(
		postoId: number,
		ano: number
	): Observable<QuantisResponse<QuantisPrecipitacao[]>> {
		return this.http.get(
			Rotas.QUANTIS + `/mensal/grafico/estacao?ano=${ano}&postoId=${postoId}`
		);
	}

	handleFetchQuantisMensaisGraphByRegiaoId(
		regiaoId: number,
		ano: number
	): Observable<QuantisResponse<QuantisPrecipitacao[]>> {
		return this.http.get(
			Rotas.QUANTIS + `/mensal/grafico/regiao?ano=${ano}&regiaoId=${regiaoId}`
		);
	}

	handleFetchQuantilAnualGraphByEstacaoId(
		postoId: number,
		ano: number
	): Observable<QuantisResponse<QuantisPrecipitacao>> {
		return this.http.get(
			Rotas.QUANTIS + `/anual/grafico/estacao?ano=${ano}&postoId=${postoId}`
		);
	}

	handleFetchQuantisByRegiaoAndPeriodoChuvoso(
		regiaoId: string,
		periodoChuvoso: string
	): Observable<QuantisRegiaoPluviometrica> {
		return this.http.get(
			Rotas.QUANTIS +
				`/pluviometrico/mensal/grafico/regiao?regiaoId=${regiaoId}&periodo=${periodoChuvoso}`
		);
	}
}
