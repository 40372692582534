<div class="container">
	<form [formGroup]="form" class="form-group">
		<div
			*ngIf="carregando; else mapa"
			class="d-flex justify-content-center align-items-center w-100 h-50"
			style="min-height: 500px">
			<seira-loading [pageCentered]="false"></seira-loading>
		</div>
		<ng-template #mapa>
			<div class="mt-4">
				<div class="row" *ngIf="interpolacao; else nenhumResultado">
					<label class="text-center text-tertiary fw-semibold mb-2 w-100">
						MAPA DE CLIMATOLOGIA (mm)
					</label>
					<div class="col-12">
						<div class="mb-3" style="position: relative">
							<seira-mapa-interpolacao
								[mapaId]="'climatologia'"
								[imageOpacity]="0.8"
								[interpolacao]="interpolacao"
								[carregando]="carregando"
								[valores]="climatologias"
								[zoomOnlyWithCtrl]="true">
							</seira-mapa-interpolacao>
						</div>
					</div>
				</div>
			</div>
		</ng-template>
		<ng-template #nenhumResultado>
			<div class="row">
				<div class="col-12">
					<seira-sem-dados></seira-sem-dados>
				</div>
			</div>
		</ng-template>
	</form>
</div>
