import { Component, Input } from '@angular/core';

interface ItemLegenda {
	numberCurrentAndNext: number | string;
	color: string;
}
export interface LegendaOptions {
	label: string;
	items: ItemLegenda[];
}

@Component({
	selector: 'seira-legenda-relatorio-historico-variaveis',
	templateUrl: './legenda-relatorio-historico-variaveis.component.html',
	styleUrls: ['./legenda-relatorio-historico-variaveis.component.scss'],
})
export class LegendaRelatorioHistoricoVariaveisComponent {
	@Input() options!: LegendaOptions;

	percentage() {
		const itemCount = this.options.items.length;

		// Convertendo strings para números antes de realizar o cálculo
		const numericItems = this.options.items.map(item => {
			return typeof item.numberCurrentAndNext === 'string'
				? parseFloat(item.numberCurrentAndNext.replace(',', '.'))
				: item.numberCurrentAndNext;
		});

		// Realizando a operação com os valores convertidos
		// Neste exemplo, a conversão é feita mas não utilizada no cálculo da porcentagem
		return 100 / itemCount + '%';
	}
}
