<!-- Page header -->
<ng-container *ngIf="activePathPage">
	<div
		class="page-header page-header-light page-header-static sticky-top shadow">
		<div class="page-header-content d-lg-flex border-top">
			<div class="d-flex">
				<div class="breadcrumb py-2">
					<a routerLink="/" class="breadcrumb-item"><i class="ph-house"></i></a>
					<ng-container *ngFor="let path of listPathPage">
						<span class="breadcrumb-item">{{ path }}</span>
					</ng-container>
					<span class="breadcrumb-item active">{{ activePathPage }}</span>
				</div>
				<ng-template [ngIf]="breadcrumbElements.length > 0">
					<a
						href="#breadcrumb_elements"
						class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto"
						data-bs-toggle="collapse">
						<i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
					</a>
				</ng-template>
			</div>
			<ng-template [ngIf]="breadcrumbElements.length > 0">
				<div class="collapse d-lg-block ms-lg-auto" id="breadcrumb_elements">
					<div class="d-lg-flex mb-2 mb-lg-0">
						<ng-container *ngFor="let elemento of breadcrumbElements">
							<a
								routerLink="{{ elemento.link }}"
								class="d-flex align-items-center text-body py-2">
								<i
									[ngClass]="
										isNotNuloOuUndefined(elemento.icone)
											? elemento.icone
											: 'd-none'
									"
									class="me-2"></i>
								{{ elemento.text }}
							</a>
						</ng-container>
					</div>
				</div>
			</ng-template>
		</div>
	</div>
</ng-container>
<!-- /page header -->
