import { TipoEstacao } from '@modulos/meteorologia/submodulos/estacao/enums/tipo-estacao';

export interface EstacaoMonitorada {
	id: number;
	tipoEstacao: keyof typeof TipoEstacao;
	statusEstacao: string;
	nomeBacia: string;
	nomeRegiao: string;
	nomeMesorregiao: string;
	nomeMicrorregiao: string;
	latitude: number;
	longitude: number;
	nomeMunicipio: string;
	nomePosto: string;
	nomeResponsavel?: string | null;
	ultimaColeta: Date;
	precipitacao: number;
	mesorregiaoId?: number;
	regiaoId?: number;
	microrregiaoId?: number;
	municipioId?: number;
	baciaId?: number;
	altitude?: number;
	[key: string]: any;
}
export interface EstacaoAgrupamento {
	id: number;
	latitude: number;
	longitude: number;
	precipitacao: number;
	nomeMunicipio: number;
	nomeMesorregiao: string;
	nomeMicrorregiao: string;
	nomeBacia: string;
	nomeRegiao: string;
	nomePosto: string;
}

export interface IntervaloHorariosValues {
	horaMinima: number;
	horaMaxima: number | null;
}

export interface EstacoesMonitoradasRequestParams {
	estacaoId?: number | null;
	tipoEstacao?: TipoEstacao | null;
	mesorregiaoId?: number | null;
	municipioId?: number | null;
	horaMinima?: number | null;
	horaMaxima?: number | null;
}
export class FiltrosEstacaoMonitordada {
	estacaoId?: number | null = null;
	tipoEstacao?: TipoEstacao | null = null;
	mesorregiaoId?: number | null = null;
	municipioId?: number | null = null;
	statusEstacao?: string | null = null;
	baciaId?: number | null = null;
	regiaoId?: number | null = null;
	microrregiaoId?: number | null = null;

	status?: IntervaloHorariosValues | undefined;
	constructor(
		estacaoId?: number | null,
		tipoEstacao?: TipoEstacao | null,
		mesorregiaoId?: number | null,
		municipioId?: number | null,
		statusEstacao?: string | null,
		baciaId?: number | null,
		regiaoId?: number | null,
		microrregiaoId?: number | null
	) {
		this.estacaoId = estacaoId;
		this.tipoEstacao = tipoEstacao;
		this.mesorregiaoId = mesorregiaoId;
		this.municipioId = municipioId;
		this.baciaId = baciaId;
		this.statusEstacao = statusEstacao;
		this.regiaoId = regiaoId;
		this.microrregiaoId = microrregiaoId;
	}
}

export interface DataHistoricoEstacao {
	postoId: number;
	diaInicio: Date;
	diaFim: Date;
	variavel: LabelVariaveis;
}

export class ResponseHistoricoEstacao {
	data: Array<number> = [];
	precipitacao: Array<number | null> = [];
	precipitacaoAcumulada: Array<number | null> = [];
	pressaoAtmosferica: Array<number | null> = [];
	temperaturaAr: Array<number | null> = [];
	temperaturaSolo: Array<number | null> = [];
	umidadeInstantanea: Array<number | null> = [];
	ventoVelocidade: Array<number | null> = [];
	ventoDirecao: Array<number | null> = [];
	radiacao: Array<number | null> = [];
	evapotranspiracaoPotencial: Array<number | null> = [];
	tensaoBateria: Array<number | null> = [];
	vento2m: Array<number | null> = [];
	temperaturaSolo10cm: Array<number | null> = [];
	temperaturaSolo20cm: Array<number | null> = [];
	temperaturaSolo30cm: Array<number | null> = [];
	temperaturaSolo40cm: Array<number | null> = [];
	temperaturaSolo50cm: Array<number | null> = [];
	temperaturaSolo60cm: Array<number | null> = [];
	conteudoAguaSolo10cm: Array<number | null> = [];
	conteudoAguaSolo20cm: Array<number | null> = [];
	conteudoAguaSolo30cm: Array<number | null> = [];
	conteudoAguaSolo40cm: Array<number | null> = [];
	conteudoAguaSolo50cm: Array<number | null> = [];
	conteudoAguaSolo60cm: Array<number | null> = [];
	umidadeInterna: Array<number | null> = [];
	temperaturaInterna: Array<number | null> = [];

	sensorPortaAberta: Array<number | null> = [];
	correnteRecarga: Array<number | null> = [];
	ventoDirecao1DesvioPadrao: Array<number | null> = [];
	ventoDirecao2DesvioPadrao: Array<number | null> = [];
	ventoVelocidade1Maxima: Array<number | null> = [];
	ventoVelocidade2Maxima: Array<number | null> = [];
}
export interface VariavelValue {
	label: LabelVariaveis;
	value: {
		textY: LabelVariavelComUnidade;
		valueField: keyof ResponseHistoricoEstacao;
	};
}
export enum LabelVariaveis {
	PRECIPITACAO = 'Precipitação',
	PRECIPITACAO_ACUMULADA = 'Precipitação acumulada',
	VENTO = 'Vento',
	PRESSAO_ATMOSFERICA = 'Pressão atmosférica',
	TEMPERATURA_DO_AR = 'Temperatura do ar',
	TEMPERATURA_DO_SOLO = 'Temperatura do solo',
	UMIDADE_INSTANTANEA = 'Umidade instantânea',
	RADIACAO = 'Radiação',
	EVAPOTRANSPIRACAO = 'Evapotranspiração',
	TENSAOBATERIA = 'Tensão de bateria',
	TEMPERATURA_SOLO_10CM = 'Temperatura do solo a 10 cm',
	TEMPERATURA_SOLO_20CM = 'Temperatura do solo a 20 cm',
	TEMPERATURA_SOLO_30CM = 'Temperatura do solo a 30 cm',
	TEMPERATURA_SOLO_40CM = 'Temperatura do solo a 40 cm',
	TEMPERATURA_SOLO_50CM = 'Temperatura do solo a 50 cm',
	TEMPERATURA_SOLO_60CM = 'Temperatura do solo a 60 cm',
	CONTEUDO_AGUA_SOLO_10CM = 'Conteúdo de água no solo 10cm',
	CONTEUDO_AGUA_SOLO_20CM = 'Conteúdo de água no solo 20cm',
	CONTEUDO_AGUA_SOLO_30CM = 'Conteúdo de água no solo 30cm',
	CONTEUDO_AGUA_SOLO_40CM = 'Conteúdo de água no solo 40cm',
	CONTEUDO_AGUA_SOLO_50CM = 'Conteúdo de água no solo 50cm',
	CONTEUDO_AGUA_SOLO_60CM = 'Conteúdo de água no solo 60cm',
	UMIDADE_INTERNA = 'Umidade interna',
	TEMPERATURA_INTERNA = 'Temperatura interna',
	SENSOR_PORTA_ABERTA = 'Sensor de porta aberta',
	CORRENTE_RECARGA = 'Corrente de recarga',
	VENTO_VELOCIDADE_2_MAXIMA = 'Vento velocidade 2 máxima',
	VENTO_DIRECAO_2_DESVIO_PADRAO = 'Vento direção 2 desvio Padrão',
	VENTO_VELOCIDADE_1_MAXIMA = 'Vento velocidade 1 máxima',
	VENTO_DIRECAO_1_DESVIO_PADRAO = 'Vento direção 1 desvio Padrão ',
}

export enum LabelVariavelComUnidade {
	PRECIPITACAO = 'Precipitação (mm)',
	PRECIPITACAO_ACUMULADA = 'Precipitação acumulada (mm)',
	VENTO = 'Vento (m/s)',
	PRESSAO_ATMOSFERICA = 'Pressão atmosférica (hPa)',
	TEMPERATURA_DO_AR = 'Temperatura do ar (ºC)',
	TEMPERATURA_MEDIA_DO_AR = 'Temperatura média do ar (ºC)',
	TEMPERATURA_DO_SOLO = 'Temperatura do solo (ºC)',
	UMIDADE_INSTANTANEA = 'Umidade instantânea (%)',
	RADIACAO = 'Radiação (W/m²)',
	EVAPOTRANSPIRACAO = 'Evapotranspiração (mm)',
	TENSAOBATERIA = 'Tensão de bateria (V)',
	VENTO2m = 'Vento a dois metros (m/s)',
	TEMPERATURA_SOLO_10CM = 'Temperatura do solo a 10 cm (ºC)',
	TEMPERATURA_SOLO_20CM = 'Temperatura do solo a 20 cm (ºC)',
	TEMPERATURA_SOLO_30CM = 'Temperatura do solo a 30 cm (ºC)',
	TEMPERATURA_SOLO_40CM = 'Temperatura do solo a 40 cm (ºC)',
	TEMPERATURA_SOLO_50CM = 'Temperatura do solo a 50 cm (ºC)',
	TEMPERATURA_SOLO_60CM = 'Temperatura do solo a 60 cm (ºC)',
	CONTEUDO_AGUA_SOLO_10CM = 'Conteúdo de água no solo 10cm',
	CONTEUDO_AGUA_SOLO_20CM = 'Conteúdo de água no solo 20cm',
	CONTEUDO_AGUA_SOLO_30CM = 'Conteúdo de água no solo 30cm',
	CONTEUDO_AGUA_SOLO_40CM = 'Conteúdo de água no solo 40cm',
	CONTEUDO_AGUA_SOLO_50CM = 'Conteúdo de água no solo 50cm',
	CONTEUDO_AGUA_SOLO_60CM = 'Conteúdo de água no solo 60cm',
	UMIDADE_INTERNA = 'Umidade interna (%)',
	TEMPERATURA_INTERNA = 'Temperatura interna (°C)',
	SENSOR_PORTA_ABERTA = 'Sensor de porta aberta',
	CORRENTE_RECARGA = 'Corrente de recarga (mA)',
	VENTO_VELOCIDADE_2_MAXIMA = 'Vento velocidade 2 máxima (m/s)',
	VENTO_DIRECAO_2_DESVIO_PADRAO = 'Vento direção 2 desvio Padrão (°)',
	VENTO_VELOCIDADE_1_MAXIMA = 'Vento velocidade 1 máxima (m/s)',
	VENTO_DIRECAO_1_DESVIO_PADRAO = 'Vento direção 1 desvio Padrão (°)',
}
export enum ValuesVariaveis {
	PRECIPITACAO = 'precipitacao',
	VENTO = 'ventoVelocidade',
	PRESSAO_ATMOSFERICA = 'pressaoAtmosferica',
	TEMPERATURA_DO_AR = 'temperaturaAr',
	TEMPERATURA_DO_SOLO = 'temperaturaSolo',
	UMIDADE_INSTANTANEA = 'umidadeInstantanea',
	RADIACAO = 'radiacao',
	PRECIPITACAO_ACUMULADA = 'precipitacaoAcumulada',
	EVAPOTRANSPIRACAO = 'evapotranspiracaoPotencial',
	TENSAOBATERIA = 'tensaoBateria',
	TEMPERATURA_SOLO_10CM = 'temperaturaSolo10cm',
	TEMPERATURA_SOLO_20CM = 'temperaturaSolo20cm',
	TEMPERATURA_SOLO_30CM = 'temperaturaSolo30cm',
	TEMPERATURA_SOLO_40CM = 'temperaturaSolo40cm',
	TEMPERATURA_SOLO_50CM = 'temperaturaSolo50cm',
	TEMPERATURA_SOLO_60CM = 'temperaturaSolo60cm',
	CONTEUDO_AGUA_SOLO_10CM = 'conteudoAguaSolo10cm',
	CONTEUDO_AGUA_SOLO_20CM = 'conteudoAguaSolo20cm',
	CONTEUDO_AGUA_SOLO_30CM = 'conteudoAguaSolo30cm',
	CONTEUDO_AGUA_SOLO_40CM = 'conteudoAguaSolo40cm',
	CONTEUDO_AGUA_SOLO_50CM = 'conteudoAguaSolo50cm',
	CONTEUDO_AGUA_SOLO_60CM = 'conteudoAguaSolo60cm',
	UMIDADE_INTERNA = 'umidadeInterna',
	TEMPERATURA_INTERNA = 'temperaturaInterna',
	SENSOR_PORTA_ABERTA = 'sensorPortaAberta',
	CORRENTE_RECARGA = 'correnteRecarga',
	VENTO_VELOCIDADE_2_MAXIMA = 'ventoVelocidade2Maxima',
	VENTO_DIRECAO_2_DESVIO_PADRAO = 'ventoDirecao2DesvioPadrao',
	VENTO_VELOCIDADE_1_MAXIMA = 'ventoVelocidade1Maxima',
	VENTO_DIRECAO_1_DESVIO_PADRAO = 'ventoDirecao1DesvioPadrao',
}

export const getLabelVariaveisEstacoes = (
	variavel: ValuesVariaveis | string
): string => {
	switch (variavel) {
		case ValuesVariaveis.PRECIPITACAO:
			return LabelVariavelComUnidade.PRECIPITACAO;
		case ValuesVariaveis.VENTO:
			return LabelVariavelComUnidade.VENTO;
		case ValuesVariaveis.PRESSAO_ATMOSFERICA:
			return LabelVariavelComUnidade.PRESSAO_ATMOSFERICA;
		case ValuesVariaveis.TEMPERATURA_DO_AR:
			return LabelVariavelComUnidade.TEMPERATURA_DO_AR;
		case ValuesVariaveis.TEMPERATURA_DO_SOLO:
			return LabelVariavelComUnidade.TEMPERATURA_DO_SOLO;
		case ValuesVariaveis.UMIDADE_INSTANTANEA:
			return LabelVariavelComUnidade.UMIDADE_INSTANTANEA;
		case ValuesVariaveis.RADIACAO:
			return LabelVariavelComUnidade.RADIACAO;
		case ValuesVariaveis.EVAPOTRANSPIRACAO:
			return LabelVariavelComUnidade.EVAPOTRANSPIRACAO;
		case ValuesVariaveis.TENSAOBATERIA:
			return LabelVariavelComUnidade.TENSAOBATERIA;
		case ValuesVariaveis.TEMPERATURA_SOLO_10CM:
			return LabelVariavelComUnidade.TEMPERATURA_SOLO_10CM;
		case ValuesVariaveis.TEMPERATURA_SOLO_20CM:
			return LabelVariavelComUnidade.TEMPERATURA_SOLO_20CM;
		case ValuesVariaveis.TEMPERATURA_SOLO_30CM:
			return LabelVariavelComUnidade.TEMPERATURA_SOLO_30CM;
		case ValuesVariaveis.TEMPERATURA_SOLO_40CM:
			return LabelVariavelComUnidade.TEMPERATURA_SOLO_40CM;
		case ValuesVariaveis.TEMPERATURA_SOLO_50CM:
			return LabelVariavelComUnidade.TEMPERATURA_SOLO_50CM;
		case ValuesVariaveis.TEMPERATURA_SOLO_60CM:
			return LabelVariavelComUnidade.TEMPERATURA_SOLO_60CM;
		case ValuesVariaveis.CONTEUDO_AGUA_SOLO_10CM:
			return LabelVariavelComUnidade.CONTEUDO_AGUA_SOLO_10CM;
		case ValuesVariaveis.CONTEUDO_AGUA_SOLO_20CM:
			return LabelVariavelComUnidade.CONTEUDO_AGUA_SOLO_20CM;
		case ValuesVariaveis.CONTEUDO_AGUA_SOLO_30CM:
			return LabelVariavelComUnidade.CONTEUDO_AGUA_SOLO_30CM;
		case ValuesVariaveis.CONTEUDO_AGUA_SOLO_40CM:
			return LabelVariavelComUnidade.CONTEUDO_AGUA_SOLO_40CM;
		case ValuesVariaveis.CONTEUDO_AGUA_SOLO_50CM:
			return LabelVariavelComUnidade.CONTEUDO_AGUA_SOLO_50CM;
		case ValuesVariaveis.CONTEUDO_AGUA_SOLO_60CM:
			return LabelVariavelComUnidade.CONTEUDO_AGUA_SOLO_60CM;
		case ValuesVariaveis.UMIDADE_INTERNA:
			return LabelVariavelComUnidade.UMIDADE_INTERNA;
		case ValuesVariaveis.SENSOR_PORTA_ABERTA:
			return LabelVariavelComUnidade.SENSOR_PORTA_ABERTA;
		case ValuesVariaveis.CORRENTE_RECARGA:
			return LabelVariavelComUnidade.CORRENTE_RECARGA;
		case ValuesVariaveis.VENTO_VELOCIDADE_1_MAXIMA:
			return LabelVariavelComUnidade.VENTO_VELOCIDADE_1_MAXIMA;
		case ValuesVariaveis.VENTO_VELOCIDADE_2_MAXIMA:
			return LabelVariavelComUnidade.VENTO_VELOCIDADE_2_MAXIMA;
		case ValuesVariaveis.VENTO_DIRECAO_1_DESVIO_PADRAO:
			return LabelVariavelComUnidade.VENTO_DIRECAO_1_DESVIO_PADRAO;
		case ValuesVariaveis.VENTO_DIRECAO_2_DESVIO_PADRAO:
			return LabelVariavelComUnidade.VENTO_DIRECAO_2_DESVIO_PADRAO;
		default:
			return '';
	}
};
export const variaveis: VariavelValue[] = [
	{
		label: LabelVariaveis.PRECIPITACAO,
		value: {
			textY: LabelVariavelComUnidade.PRECIPITACAO,
			valueField: ValuesVariaveis.PRECIPITACAO,
		},
	},
	{
		label: LabelVariaveis.PRECIPITACAO_ACUMULADA,
		value: {
			textY: LabelVariavelComUnidade.PRECIPITACAO_ACUMULADA,
			valueField: ValuesVariaveis.PRECIPITACAO_ACUMULADA,
		},
	},
	{
		label: LabelVariaveis.VENTO,
		value: {
			textY: LabelVariavelComUnidade.VENTO,
			valueField: ValuesVariaveis.VENTO,
		},
	},
	{
		label: LabelVariaveis.PRESSAO_ATMOSFERICA,
		value: {
			textY: LabelVariavelComUnidade.PRESSAO_ATMOSFERICA,
			valueField: ValuesVariaveis.PRESSAO_ATMOSFERICA,
		},
	},
	{
		label: LabelVariaveis.TEMPERATURA_DO_AR,
		value: {
			textY: LabelVariavelComUnidade.TEMPERATURA_DO_AR,
			valueField: ValuesVariaveis.TEMPERATURA_DO_AR,
		},
	},
	{
		label: LabelVariaveis.TEMPERATURA_DO_SOLO,
		value: {
			textY: LabelVariavelComUnidade.TEMPERATURA_DO_SOLO,
			valueField: ValuesVariaveis.TEMPERATURA_DO_SOLO,
		},
	},
	{
		label: LabelVariaveis.UMIDADE_INSTANTANEA,
		value: {
			textY: LabelVariavelComUnidade.UMIDADE_INSTANTANEA,
			valueField: ValuesVariaveis.UMIDADE_INSTANTANEA,
		},
	},
	{
		label: LabelVariaveis.RADIACAO,
		value: {
			textY: LabelVariavelComUnidade.RADIACAO,
			valueField: ValuesVariaveis.RADIACAO,
		},
	},
	{
		label: LabelVariaveis.EVAPOTRANSPIRACAO,
		value: {
			textY: LabelVariavelComUnidade.EVAPOTRANSPIRACAO,
			valueField: ValuesVariaveis.EVAPOTRANSPIRACAO,
		},
	},
	{
		label: LabelVariaveis.TENSAOBATERIA,
		value: {
			textY: LabelVariavelComUnidade.TENSAOBATERIA,
			valueField: ValuesVariaveis.TENSAOBATERIA,
		},
	},

	{
		label: LabelVariaveis.TEMPERATURA_SOLO_10CM,
		value: {
			textY: LabelVariavelComUnidade.TEMPERATURA_SOLO_10CM,
			valueField: ValuesVariaveis.TEMPERATURA_SOLO_10CM,
		},
	},
	{
		label: LabelVariaveis.TEMPERATURA_SOLO_20CM,
		value: {
			textY: LabelVariavelComUnidade.TEMPERATURA_SOLO_20CM,
			valueField: ValuesVariaveis.TEMPERATURA_SOLO_20CM,
		},
	},
	{
		label: LabelVariaveis.TEMPERATURA_SOLO_30CM,
		value: {
			textY: LabelVariavelComUnidade.TEMPERATURA_SOLO_30CM,
			valueField: ValuesVariaveis.TEMPERATURA_SOLO_30CM,
		},
	},
	{
		label: LabelVariaveis.TEMPERATURA_SOLO_40CM,
		value: {
			textY: LabelVariavelComUnidade.TEMPERATURA_SOLO_40CM,
			valueField: ValuesVariaveis.TEMPERATURA_SOLO_40CM,
		},
	},
	{
		label: LabelVariaveis.TEMPERATURA_SOLO_50CM,
		value: {
			textY: LabelVariavelComUnidade.TEMPERATURA_SOLO_50CM,
			valueField: ValuesVariaveis.TEMPERATURA_SOLO_50CM,
		},
	},
	{
		label: LabelVariaveis.TEMPERATURA_SOLO_60CM,
		value: {
			textY: LabelVariavelComUnidade.TEMPERATURA_SOLO_60CM,
			valueField: ValuesVariaveis.TEMPERATURA_SOLO_60CM,
		},
	},
	{
		label: LabelVariaveis.CONTEUDO_AGUA_SOLO_10CM,
		value: {
			textY: LabelVariavelComUnidade.CONTEUDO_AGUA_SOLO_10CM,
			valueField: ValuesVariaveis.CONTEUDO_AGUA_SOLO_10CM,
		},
	},
	{
		label: LabelVariaveis.CONTEUDO_AGUA_SOLO_20CM,
		value: {
			textY: LabelVariavelComUnidade.CONTEUDO_AGUA_SOLO_20CM,
			valueField: ValuesVariaveis.CONTEUDO_AGUA_SOLO_20CM,
		},
	},
	{
		label: LabelVariaveis.CONTEUDO_AGUA_SOLO_30CM,
		value: {
			textY: LabelVariavelComUnidade.CONTEUDO_AGUA_SOLO_30CM,
			valueField: ValuesVariaveis.CONTEUDO_AGUA_SOLO_30CM,
		},
	},
	{
		label: LabelVariaveis.CONTEUDO_AGUA_SOLO_40CM,
		value: {
			textY: LabelVariavelComUnidade.CONTEUDO_AGUA_SOLO_40CM,
			valueField: ValuesVariaveis.CONTEUDO_AGUA_SOLO_40CM,
		},
	},
	{
		label: LabelVariaveis.CONTEUDO_AGUA_SOLO_50CM,
		value: {
			textY: LabelVariavelComUnidade.CONTEUDO_AGUA_SOLO_50CM,
			valueField: ValuesVariaveis.CONTEUDO_AGUA_SOLO_50CM,
		},
	},
	{
		label: LabelVariaveis.CONTEUDO_AGUA_SOLO_60CM,
		value: {
			textY: LabelVariavelComUnidade.CONTEUDO_AGUA_SOLO_60CM,
			valueField: ValuesVariaveis.CONTEUDO_AGUA_SOLO_60CM,
		},
	},
	{
		label: LabelVariaveis.UMIDADE_INTERNA,
		value: {
			textY: LabelVariavelComUnidade.UMIDADE_INTERNA,
			valueField: ValuesVariaveis.UMIDADE_INTERNA,
		},
	},
	{
		label: LabelVariaveis.TEMPERATURA_INTERNA,
		value: {
			textY: LabelVariavelComUnidade.TEMPERATURA_INTERNA,
			valueField: ValuesVariaveis.TEMPERATURA_INTERNA,
		},
	},
	{
		label: LabelVariaveis.SENSOR_PORTA_ABERTA,
		value: {
			textY: LabelVariavelComUnidade.SENSOR_PORTA_ABERTA,
			valueField: ValuesVariaveis.SENSOR_PORTA_ABERTA,
		},
	},
	{
		label: LabelVariaveis.CORRENTE_RECARGA,
		value: {
			textY: LabelVariavelComUnidade.CORRENTE_RECARGA,
			valueField: ValuesVariaveis.CORRENTE_RECARGA,
		},
	},

	{
		label: LabelVariaveis.VENTO_VELOCIDADE_1_MAXIMA,
		value: {
			textY: LabelVariavelComUnidade.VENTO_VELOCIDADE_1_MAXIMA,
			valueField: ValuesVariaveis.VENTO_VELOCIDADE_1_MAXIMA,
		},
	},
	{
		label: LabelVariaveis.VENTO_VELOCIDADE_2_MAXIMA,
		value: {
			textY: LabelVariavelComUnidade.VENTO_VELOCIDADE_2_MAXIMA,
			valueField: ValuesVariaveis.VENTO_VELOCIDADE_2_MAXIMA,
		},
	},
	{
		label: LabelVariaveis.VENTO_DIRECAO_1_DESVIO_PADRAO,
		value: {
			textY: LabelVariavelComUnidade.VENTO_DIRECAO_1_DESVIO_PADRAO,
			valueField: ValuesVariaveis.VENTO_DIRECAO_1_DESVIO_PADRAO,
		},
	},
	{
		label: LabelVariaveis.VENTO_DIRECAO_2_DESVIO_PADRAO,
		value: {
			textY: LabelVariavelComUnidade.VENTO_DIRECAO_2_DESVIO_PADRAO,
			valueField: ValuesVariaveis.VENTO_DIRECAO_2_DESVIO_PADRAO,
		},
	},
];

export enum Agrupamento {
	MUNICIPIO_POSTO = 'Município/posto',
	MUNICIPIO = 'Município',
	MICRORREGIAO = 'Microrregião',
	MESORREGIAO = 'Mesorregião',
	BACIA = 'Bacia',
	// REGIAO_GEOADMINISTRATIVA = 'Região geoadministrativa',
	REGIAO_PLUVIOMETRICA = 'Região pluviométrica',
	SUBBACIA = 'Sub-bacia',
}
export enum TipoGraficos {
	QUANTIS_MENSAL = 'Quantis mensal',
	QUANTIS_ANUAL = 'Quantis anual',
	SPI = 'SPI',
}

export enum TiposMapas {
	QUANTIS_MENSAL = 'Quantis mensal',
	QUANTIS_ANUAL = 'Quantis anual',
	CHUVA_ESTADO = 'Chuva no estado',
	IAPM = 'IAPM',
}
export enum TipoTabelas {
	PRECIPITACAO_DIARIA = 'Precipitação diária',
	PRECIPITACAO_ANUAL = 'Precipitação anual',
	PRECIPITACAO_ACUMULADA = 'Precipitação acumulada',
	MEDIA_HISTORICA = 'Média histórica',
	PLUVIOMETRICO_POR_POSTO = 'Pluviométrico por posto',
	PLUVIOMETRICO_DO_ESTADO = 'Pluviométrico do estado',
}
