import { Component, Input } from '@angular/core';

@Component({
	selector: 'seira-card-info',
	templateUrl: './card-info.component.html',
	styleUrls: ['./card-info.component.scss'],
})
export class CardInfoComponent {
	@Input() aditionalClasses: string = '';
	@Input() texto: string = 'Placeholder informativo';
}
