<div
	[id]="mapaId"
	style="height: 500px; width: 100%"
	(wheel)="ativarMomentaneamenteMensagem()"
	(mouseleave)="mouseEmCimaDoMapa = false"
	class="mapa-paraiba rounded shadow-lg h-100"
	[id]="mapaId"
	[leafletOptions]="options"
	leaflet
	(leafletMapReady)="loadMap($event)">
	<seira-loading *ngIf="loading"></seira-loading>
	<div
		*ngIf="!ctrlAtivo && zoomOnlyWithCtrl && mouseEmCimaDoMapa"
		class="fullscreen-message">
		Pressione ctrl + scroll para dar zoom
	</div>
	<ng-content />
</div>
