<div>
	<label
		for="legenda"
		class="text-muted fw-semibold my-1"
		*seiraIfViewportSize="'desktop'">
		LEGENDA
	</label>
	<div class="bg-white rounded shadow-lg d-flex flex-column card" id="legenda">
		<div class="card-body pt-4 pb-2">
			<label
				class="text-tertiary fw-semibold-400 mb-1"
				*seiraIfViewportSize="'mobile'"
				>Tipo de estação</label
			>
			<div
				class="d-flex flex-row align-items-center gap-3 mt-1"
				[ngClass]="{ 'd-flex flex-row': isBigScreen }">
				<label
					class="text-tertiary fw-semibold-400 mb-1"
					*seiraIfViewportSize="'desktop'"
					>Tipo de estação</label
				>
				<seira-icon-with-label>
					<img
						icon
						src="assets/images/estacoes/pluviometro-convencional.svg"
						alt="pcd"
						width="28px"
						class="me-2 mb-1" />
					<label label class="text-tertiary">Pluviômetro Convencional</label>
				</seira-icon-with-label>
				<seira-icon-with-label *ngIf="legendaComIconePCD">
					<img
						icon
						src="assets/images/estacoes/pcd.svg"
						alt="pcd"
						width="28px"
						class="me-2 mb-1" />
					<label label class="text-tertiary"
						>Plataforma de Coleta de Dados (PCD)</label
					>
				</seira-icon-with-label>
			</div>
		</div>

		<div class="card-body pb-4">
			<label
				class="text-tertiary fw-semibold-400 mb-1"
				*seiraIfViewportSize="'mobile'">
				Status de envio</label
			>
			<div
				class="d-flex flex-row align-items-center gap-3 mt-1"
				[ngClass]="{ 'd-flex flex-row': isBigScreen }">
				<label
					class="text-tertiary fw-semibold-400 mb-1"
					*seiraIfViewportSize="'desktop'">
					Status de envio</label
				>
				<seira-icon-with-label>
					<i
						icon
						class="ph ph-circle-fill text-estacao-status-1 me-1"
						style="font-size: 28px"></i>
					<label label class="text-tertiary">Dado enviado no dia</label>
				</seira-icon-with-label>
				<seira-icon-with-label>
					<i
						icon
						style="font-size: 28px"
						class="ph ph-circle-fill text-estacao-status-4 me-1"></i>
					<label label class="text-tertiary">Dado não recebido no dia</label>
				</seira-icon-with-label>
			</div>
		</div>
	</div>
</div>
