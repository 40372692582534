import * as Highcharts from 'highcharts/highstock';
import { TiposDatePicker } from '@shared/interfaces/date-picker';

const configResponsividade: Highcharts.Options['responsive'] = {
	rules: [
		{
			condition: {
				maxWidth: 500,
			},
			chartOptions: {
				subtitle: {
					y: 0,
				},
				legend: {
					enabled: true,
					align: 'center',
					verticalAlign: 'bottom',
					layout: 'horizontal',
				},
				yAxis: {
					labels: {
						align: 'left',
						x: 0,
						y: -5,
					},
					title: {
						text: null,
					},
				},
				credits: {
					enabled: false,
				},
			},
		},
		{
			condition: {
				minWidth: 0,
			},
			chartOptions: {
				rangeSelector: {
					y: -26,
				},
			},
		},
		{
			condition: {
				minWidth: 300,
			},
			chartOptions: {
				rangeSelector: {
					y: -14,
				},
			},
		},
		{
			condition: {
				minWidth: 560,
			},
			chartOptions: {
				rangeSelector: {
					y: 20,
				},
				subtitle: {
					y: 14,
				},
			},
		},
	],
};
export const optionsChartReservatorio = (
	dates: Array<number>,
	periodo: TiposDatePicker
): Highcharts.Options => ({
	navigator: {
		enabled: false,
	},
	scrollbar: {
		enabled: false,
	},
	legend: {
		enabled: true,
		align: 'center',
		verticalAlign: 'bottom',
		layout: 'horizontal',
	},
	tooltip: {
		split: false,
		shared: true,
		dateTimeLabelFormats: {
			day: '%A, %e %b %Y',
			hour: '%A, %e %b, %H:%M',
			millisecond: '%A, %e %b, %H:%M:%S.%L',
			minute: '%A, %e %b, %H:%M',
			month: '%B %Y',
			second: '%A, %e %b, %H:%M:%S',
			week: 'Week from %A, %e %b %Y',
			year: '%Y',
		},
	},
	series: [{ type: 'column' }],
	noData: {
		useHTML: true,
	},
	exporting: {
		enabled: false,
	},
	credits: {
		enabled: false,
	},
	xAxis: setXGraficoReservatoriosByArrayOfDates(dates, periodo),
	rangeSelector: {
		enabled: false,
	},
	yAxis: {
		lineWidth: 1,
		tickWidth: 1,
		opposite: false,
		offset: 0,
		className: 'text-white',
	},
	responsive: configResponsividade,
});

export const setXGraficoReservatoriosByArrayOfDates = (
	dates: Array<number>,
	periodo: TiposDatePicker
): Highcharts.XAxisOptions => {
	return {
		lineWidth: 1,
		tickWidth: 1,
		type: 'datetime',
		ordinal: true,
		labels: {
			rotation: -45,
			formatter: function () {
				const date = new Date(this.value).getTime();
				if (periodo == 'month') return Highcharts.dateFormat('%m/%Y', date);
				if (periodo == 'year') return Highcharts.dateFormat('%Y', date);

				return Highcharts.dateFormat('%d/%m/%Y', date);
			},
		},
	};
};
