import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@core/services/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '@core/services/user/user.service';
import { User } from '@core/interfaces/user';

@Component({
	selector: 'seira-form-alterar-senha',
	templateUrl: './form-alterar-senha.component.html',
	styleUrls: ['./form-alterar-senha.component.scss'],
})
export class FormAlterarSenhaComponent implements OnInit {
	alterarSenhaForm!: FormGroup;
	carregando: boolean = false;
	user: User | null;

	constructor(
		private formBuilder: FormBuilder,
		private authService: AuthService,
		private toastr: ToastrService,
		private router: Router,
		private userService: UserService
	) {
		this.user = null;
	}

	ngOnInit(): void {
		this.userService.getUser().subscribe((user: User | null) => {
			this.user = user;
		});

		this.alterarSenhaForm = this.formBuilder.group({
			senhaAtual: ['', [Validators.required]],
			novaSenha: ['', [Validators.required]],
			confirmarSenha: ['', [Validators.required]],
		});
	}

	alterarSenha() {
		const login = this.user?.login;
		const senhaAtual = this.alterarSenhaForm.get('senhaAtual')?.value;
		const novaSenha = this.alterarSenhaForm.get('novaSenha')?.value;
		const confirmarSenha = this.alterarSenhaForm.get('confirmarSenha')?.value;

		if (novaSenha !== confirmarSenha) {
			this.toastr.error(
				'A nova senha e a confirmação de senha não correspondem.',
				'Erro'
			);
			return;
		}

		this.carregando = true;
		this.authService.changePassword(login, senhaAtual, novaSenha).subscribe({
			next: () => {
				this.toastr.success('Senha alterada com sucesso.');
				this.router.navigate(['home']).then();
				this.carregando = false;
			},
			error: err => {
				let erroDetalhes: string | undefined = err.error?.detail;
				if (erroDetalhes === undefined) {
					this.toastr.error('Falha ao alterar a senha.', 'Erro');
				} else {
					this.toastr.error(`${erroDetalhes}`, 'Falha ao alterar a senha');
				}
				this.carregando = false;
			},
		});
	}

	conditionShowTextRequired(field: string) {
		return (
			this.alterarSenhaForm.get(field)?.errors?.['required'] &&
			this.alterarSenhaForm.get(field)?.touched
		);
	}
}
