<div
	class="col d-flex {{
		labelPlacement === 'start'
			? 'flex-row gap-2 align-items-center'
			: 'flex-column justify-content-start'
	}}">
	<label
		class="form-check-label fw-normal label-group-input text-tertiary-light mb-2"
		[for]="controlName"
		>{{ label }}</label
	>
	<div [id]="idRadio" [class]="classRadio">
		<div *ngFor="let option of options" class="gap-2 d-flex align-items-center">
			<input
				[formControlName]="controlName"
				class="form-check-input p-0 m-0"
				type="radio"
				[id]="option.value"
				[value]="option.value" />
			<label class="form-check-label text-tertiary" [for]="option.value"
				>{{ option.label }}
			</label>
		</div>
	</div>
</div>
