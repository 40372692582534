import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TabMenuItem } from '@shared/interfaces/menu';

@Component({
	selector: 'seira-tab-menu',
	templateUrl: './tab-menu.component.html',
	styleUrls: ['./tab-menu.component.scss'],
})
export class TabMenuComponent {
	@Input() menu!: TabMenuItem[];
	@Input() radioOptionsName!: string;
	@Input() whenSelected: 'light' | 'dark' | 'blue' = 'light';
	@Input() showDropdown = false;

	constructor(private router: Router) {}

	menuItemSelectedClass(menuItemPath: string) {
		const currentPath = this.menu.find(item =>
			this.router.url.includes(item.path)
		);

		if (this.router.url.split('/').some(p => p.includes(menuItemPath)))
			return 'opacity-50';

		if (
			this.router.url === '/' + menuItemPath ||
			currentPath?.submenu?.some(item => item.path.includes(menuItemPath))
		)
			return 'fw-semibold';

		return '';
	}
}
