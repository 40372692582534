<div class="row mt-3 mb-5">
	<div class="mb-2">
		<div>
			<seira-public-table
				id="tabela-variaveis"
				[hasFooter]="true"
				[hasHeader]="false"
				[info]="true"
				[paging]="true"
				[searching]="false"
				[scrollX]="true"
				[spaceReduceTh]="true"
				[columns]="colunas"
				[data]="
					dadosTabelaVariaveis ? dadosTabelaVariaveis[0].historico : []
				"></seira-public-table>
		</div>
	</div>
</div>
