import { Component, Input } from '@angular/core';

@Component({
	selector: 'seira-municipios-container',
	templateUrl: './municipios-container.component.svg',
	styleUrls: ['./municipios-container.component.scss'],
})
export class MunicipiosContainerComponent {
	@Input() id!: string | number;
}
