<form [formGroup]="form">
	<seira-public-modal [close]="close" titleClass="w-100">
		<div title class="row gap-2">
			<div class="col-lg-auto">
				Detalhes do açude
				<span class="fw-semibold">{{
					dadosGrafico.reservatorio.nome || '-'
				}}</span>
			</div>
			<div class="col-lg-auto row">
				<seira-public-select
					class="col-sm-auto"
					style="min-width: 290px"
					[searchable]="true"
					optionLabel="nome"
					optionValue="id"
					[carregando]="loadingReservatorios"
					[options]="reservatorios"
					placeholder="Escolher outro..."
					formControlName="reservatorio" />
				<seira-public-button
					class="col-sm-auto mt-lg-0 mt-md-0 mt-sm-0 mt-2"
					(clicked)="buscarHistorico()"
					[loading]="loading"
					buttonStyle="outline"
					style="display: flex; align-items: center"
					>Atualizar</seira-public-button
				>
			</div>
		</div>
		<div content>
			<div class="d-inline-flex gap-3">
				<seira-text-with-label label="Bacia">{{
					dadosGrafico.reservatorio.bacia.nome || '-'
				}}</seira-text-with-label>
				<seira-text-with-label label="Capacidade"
					>{{
						numberToBrNumber(dadosGrafico.reservatorio.capacidade.toFixed(2)) ||
							'-'
					}}
					m³</seira-text-with-label
				>
				<seira-text-with-label label="Município">{{
					dadosGrafico.reservatorio.municipio.nome || '-'
				}}</seira-text-with-label>
			</div>
			<hr class="hr" />
			<div [ngSwitch]="getFormItemValue('modoVisualizacao')">
				<div class="row align-items-end">
					<seira-public-select
						class="col-lg-2"
						[clearable]="false"
						placeholder="Selecione..."
						formControlName="periodo"
						label="Agrupamento"
						optionValue="value"
						optionLabel="label"
						[options]="periodos" />
					<div class="row col-lg-5 align-items-end">
						<seira-public-date-picker
							class="col-6"
							label="Faixa de tempo"
							controlName="dataInicio"
							[maxDate]="dataFim"
							placeholder="Início"
							[datePickerType]="tipoPeriodo" />
						<seira-public-date-picker
							class="col-6"
							controlName="dataFim"
							placeholder="Fim"
							[datePickerType]="tipoPeriodo" />
					</div>
					<div class="mb-auto col-lg-2">
						<seira-public-radio-group
							controlName="modoVisualizacao"
							label="Modo de visualização"
							[options]="opcoesVisualizacao" />
					</div>
				</div>
				<div class="p-2 dashed-box mt-2">
					<ng-container *ngIf="modoVisualizacao === 'grafico'">
						<ng-container *ngTemplateOutlet="menuGrafico"></ng-container>
					</ng-container>
					<ng-container *ngIf="modoVisualizacao === 'tabelaModal'">
						<ng-container *ngTemplateOutlet="menuTabela"></ng-container>
					</ng-container>
				</div>
				<form [formGroup]="formGrafico">
					<div [style]="modoVisualizacao === 'grafico' ? '' : 'display: none'">
						<ng-container *ngTemplateOutlet="grafico"></ng-container>
					</div>
					<div
						[style]="modoVisualizacao === 'tabelaModal' ? '' : 'display: none'">
						<ng-container *ngTemplateOutlet="tabela"></ng-container>
					</div>
				</form>
			</div>
		</div>
	</seira-public-modal>
</form>

<ng-template #menuGrafico>
	<form [formGroup]="formGrafico" class="d-inline-flex gap-3 align-items-end">
		<seira-public-select
			[clearable]="false"
			optionValue="value"
			optionLabel="label"
			[options]="unidadesGrafico"
			formControlName="unidade"
			label="Unidade"
			placeholder="Selecione..." />
		<seira-public-select
			[clearable]="false"
			optionValue="value"
			optionLabel="label"
			[options]="tiposGrafico"
			formControlName="tipoGrafico"
			label="Gráfico"
			placeholder="Selecione..." />
		<seira-public-button-group [buttons]="buttonsGrafico" />
	</form>
</ng-template>
<ng-template #menuTabela>
	<form [formGroup]="formTabela" class="d-inline-flex gap-3 align-items-end">
		<seira-public-input
			type="search"
			placeholder="Digite sua busca aqui..."
			label="Buscar"
			controlName="busca" />
		<seira-public-button-group [buttons]="buttonsTabela" />
	</form>
</ng-template>
<ng-template #grafico>
	<div class="mt-2">
		<seira-graficos-reservatorios
			[periodo]="tipoPeriodo"
			[loading]="loading"
			(emitterVolume)="setChartRefVolume($event)"
			[title]="dadosGrafico.reservatorio.nome"
			[dados]="dadosGrafico.historico"
			[tipoGrafico]="getFormGraficoValue('tipoGrafico')"
			[unidade]="getFormGraficoValue('unidade')" />
	</div>
</ng-template>
<ng-template #tabela>
	<div class="mt-2 w-lg-75">
		<seira-public-table
			#tabelaModal
			classFooter="d-flex justify-content-start align-items-center mt-2"
			[searching]="false"
			[loading]="loading"
			[hasHeader]="false"
			[columns]="colunasTabela"
			[data]="dadosGrafico.historico" />
	</div>
</ng-template>
