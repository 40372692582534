<div class="mb-2" style="position: relative">
	<label *ngIf="!removeLabel" class="form-label fw-semibold" [for]="id"
		>{{ label }}<span class="text-danger" *ngIf="isRequired">*</span></label
	>
	<mat-form-field
		class="custom-form-field"
		[ngClass]="showsTextRequired || seiraError ? 'is-invalid' : ''">
		<input
			[placeholder]="placeholder"
			[required]="isRequired"
			matInput
			[matDatepicker]="dp"
			[formControl]="control"
			[seiraDatePickerFormat]="parseDate"
			[min]="minDate"
			[max]="maxDate" />
		<mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
		<mat-datepicker
			#dp
			[startView]="startView"
			(yearSelected)="setYear($event, dp)"
			(monthSelected)="setMonthAndYear($event, dp)"
			panelClass="example-month-picker">
		</mat-datepicker>
	</mat-form-field>
	<div class="invalid-feedback mt-0" *ngIf="showsTextRequired || seiraError">
		{{ textRequired || seiraError }}
	</div>
</div>
