<div class="d-flex">
	<div class="col-12 vh-100 pe-lg-0 pe-1" style="max-height: 500px">
		<seira-mapa-paraiba-leaflet
			(mapaEmitter)="setupMap($event)"
			[zoomOnlyWithCtrl]="true"
			[loading]="loadingFiltrando"
			[monitoramentoMapa]="map"
			[markersLayer]="markersLayer" />
	</div>
</div>
<div class="d-flex col-12 mt-2" *ngIf="mostrarLegenda">
	<seira-legenda-pluviometria-observada />
</div>
<div class="col-lg-12 mt-2" *ngIf="mostrarLegendaIconesEstacao">
	<seira-legenda-estacoes [legendaComIconePCD]="false"></seira-legenda-estacoes>
</div>
