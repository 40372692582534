<seira-loading [show]="loading" />

<div *ngIf="gerou && !loading">
	<seira-card-info aditionalClasses="mt-3 mb-3">
		Placeholder informativo
	</seira-card-info>
	<seira-high-stock
		*ngIf="chartOptions"
		(chartInstance)="setChart($event)"
		classChart="highcharts-light h-auto"
		styleChart="width: 100%; height: 550px; display: block;"
		[options]="chartOptions" />
</div>

<ng-container *ngIf="!gerou && !loading">
	<seira-sem-dados />
</ng-container>
