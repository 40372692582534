import {
	ChangeDetectorRef,
	Component,
	inject,
	OnDestroy,
	OnInit,
} from '@angular/core';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
} from '../../../interfaces/tipos-relatorios';
import { FormGroup, Validators } from '@angular/forms';
import { PostosRelatorios } from '../../../interfaces/tabela-relatorio';
import { Estacao } from '../../../interfaces/filtros-opcoes';
import { Select } from '@layout/interfaces/select';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { GraficosPrecipitacaoService } from '../../../services/graficos-precipitacao.service';
import {
	criarImagemBase64FromChart,
	enumAsSelectOptions,
	obter_erro_request,
} from '@utils';
import { ToastrService } from 'ngx-toastr';
import {
	ConfiguracaoGrafico,
	RespostaGraficoVariaveis,
	Variavel,
	VariavelUnidade,
} from '../../../interfaces/variaveis-meteologicas';
import { FabricaGraficoVariavel } from '@componentes/charts/fabrica-grafico-variavel';
import { obterDadosSeriePorResultado } from './obter-serie-resultado';
import { normalizeCommonJSImport } from '@utils/normalize-commonjs-import';
import { rangeSelector5Dias } from '@componentes/charts/range-selector';
import { DateTimeUtils } from '@utils/datetime-util';
import { StockChart } from 'highcharts';
import * as pdfseira from '@utils/pdf-seira';
import * as pdfMake from 'pdfmake/build/pdfmake';

const importHighcharts = normalizeCommonJSImport(
	import('highcharts/highstock')
);

const importWindbarbHighchartsModule = normalizeCommonJSImport(
	import('highcharts/modules/windbarb')
);

@Component({
	selector: 'seira-grafico-variaveis-meteorologicas',
	templateUrl: './grafico-variaveis-meteorologicas.component.html',
	styleUrls: ['./grafico-variaveis-meteorologicas.component.scss'],
})
export class GraficoVariaveisMeteorologicasComponent
	implements OnInit, OnDestroy, InstanciaRelatorio
{
	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => {
				return this.exportarPdf();
			},
		},
	];
	inputs = inject(INPUTS_RELATORIOS);
	form!: FormGroup;
	postos: PostosRelatorios[] = [];
	estacoes: Estacao[] = [];
	dados!: RespostaGraficoVariaveis;

	loading: boolean = false;
	//variaveis = VARIAVEIS_HISTORICO;
	possuiDadosGrafico: boolean = false;
	chart!: StockChart;
	variaveisUnidade: Select<string>[] = [];
	variaveisArray: Select<string>[] = [];
	variavelSelecionada: string = '';
	constructor(
		private graficosPrecipitacaoService: GraficosPrecipitacaoService,
		private toastr: ToastrService,
		private cdr: ChangeDetectorRef
	) {}
	get getDataInicio() {
		const dataInicio = this.form.get('dataInicio');
		if (!dataInicio?.valid) return '';
		return DateTimeUtils.formatarData(
			dataInicio?.value.toISOString(),
			'dd/MM/yyyy'
		);
	}
	get getDataFinal() {
		const dataFim = this.form.get('dataFim');
		if (!dataFim?.valid) return '';
		else
			return DateTimeUtils.formatarData(
				dataFim?.value.toISOString(),
				'dd/MM/yyyy'
			);
	}
	get getVariavelName() {
		return this.variaveisArray.find(
			value => value.value === this.variavelSelecionada
		);
	}
	get getVariavelUnidade() {
		return this.variaveisUnidade.find(
			value => value.value === this.variavelSelecionada
		)?.name;
	}
	private getConfiguracoesGrafico(): ConfiguracaoGrafico {
		const botao5Dias = rangeSelector5Dias.buttons.findIndex(
			botao => botao.count === 5
		);
		const todosResultados = rangeSelector5Dias.buttons.findIndex(
			botao => botao.type === 'all'
		);
		return new ConfiguracaoGrafico('grafico', '', '', '', {
			exporting: {
				enabled: false,
			},
			rangeSelector: {
				selected:
					this.variavelSelecionada === Variavel.VENTO
						? botao5Dias
						: todosResultados,
			},
		});
	}
	ngOnInit() {
		this.variaveisArray = enumAsSelectOptions(Variavel);
		this.variaveisUnidade = enumAsSelectOptions(VariavelUnidade);
		this.form = this.inputs.form;

		setTimeout(() => {
			this.setValidators();
		}, 0);
	}

	setValidators() {
		this.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
		this.form
			.get(FormularioRelatorio.VARIAVEL)
			?.setValidators(Validators.required);
		this.form
			.get(FormularioRelatorio.ESTACAO)
			?.setValidators(Validators.required);
		this.form?.get('variavel')?.setValue('PRECIPITACAO');
	}

	getPeriodo() {
		return this.getDataInicio + ' a ' + this.getDataFinal;
	}

	async exportarPdf() {
		const dataFormatada =
			DateTimeUtils.formatarData(
				new Date().toUTCString(),
				'dd/MM/yyyy HH:mm:ss'
			) || '';
		const tipoVariavel = this.getVariavelName;
		const estacao = Number.parseInt(this.form.get('estacao')?.value!);
		const nomeEstacao = this.estacoes.find(value => value.id === estacao);
		if (this.chart) {
			const documentDefinition: any = await pdfseira.documentDefinitions();
			documentDefinition.content.push(
				{
					text: `Relatório - Variáveis meteorológicas - ${this.getPeriodo()}`,
					alignment: 'center',
					margin: [15, 15, 5, 15],
				},
				{
					text: 'Relatório exportado em: ' + dataFormatada,
					alignment: 'center',
					margin: [0, 0, 0, 15],
				},
				{
					text:
						'Gráfico de ' +
							(tipoVariavel ? `${tipoVariavel.name} ` : '') +
							this.getVariavelUnidade +
							' para o posto ' +
							(nomeEstacao
								? `${nomeEstacao.nomePosto} do município ${nomeEstacao.nomeMunicipio}`
								: '') || '',
					margin: [0, 0, 0, 15],
					alignment: 'center',
				},
				{
					image: await criarImagemBase64FromChart(this.chart),
					width: 450,
					height: 370,
					alignment: 'center',
				}
			);
			const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
			pdfDocGenerator.open();
		}
	}
	gerarRelatorio() {
		this.variavelSelecionada = this.form.get('variavel')?.value;
		const estacao = Number.parseInt(this.form.get('estacao')?.value!);
		const dataInicio = this.form.get('dataInicio')?.value;
		const dataFim = this.form.get('dataFim')?.value;
		if (!this.variavelSelecionada || !estacao || !dataInicio || !dataFim)
			return;
		this.loading = true;
		this.inputs.setLoading(true);
		this.graficosPrecipitacaoService
			.buscarGraficoVariaveisMetereologicas(estacao, dataInicio, dataFim)
			.subscribe({
				next: resp => {
					this.dados = resp;
					this.loading = false;
					this.inputs.setLoading(false);
					this.montarGrafico();
				},
				error: erro => {
					this.loading = false;
					this.inputs.setLoading(false);
					const msg_erro = obter_erro_request(erro);
					this.toastr.error(
						msg_erro,
						'Erro ao obter informações do posto selecionado'
					);
				},
			});
	}
	async montarGrafico() {
		if (this.variavelSelecionada) {
			const fabricaGrafico = new FabricaGraficoVariavel();
			const dadosGrafico = obterDadosSeriePorResultado(
				this.variavelSelecionada,
				0,
				this.dados
			);
			if (dadosGrafico) {
				this.possuiDadosGrafico = true;
				this.cdr.detectChanges();
				const Chart = await importHighcharts;
				if (this.variavelSelecionada === 'VENTO') {
					const { default: WindbarbModule } =
						await importWindbarbHighchartsModule;
					WindbarbModule(Chart);
				}

				const config = fabricaGrafico.obterConfiguracao(
					this.getConfiguracoesGrafico(),
					dadosGrafico
				);
				this.chart = Chart.stockChart(config);
			} else {
				this.possuiDadosGrafico = false;
			}
		} else {
			this.possuiDadosGrafico = false;
		}

		this.loading = false;
	}
	ngOnDestroy() {
		this.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
		this.form.get(FormularioRelatorio.ESTACAO)?.clearValidators();
		this.form.get(FormularioRelatorio.VARIAVEL)?.clearValidators();
		this.form.get(FormularioRelatorio.POSTO)?.clearValidators();
	}
}
