import { getBase64ImageFromURL } from '@utils/index';
import { StyleDictionary, TDocumentDefinitions } from 'pdfmake/interfaces';
import * as pdfMake from 'pdfmake/build/pdfmake';

export const generatePdf = (documentDefinition: TDocumentDefinitions) => {
	const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
	return pdfDocGenerator.open();
};

export const tituloTabela = 'tituloTabela';

const styles: StyleDictionary = {
	[tituloTabela]: {
		fontSize: 13.5,
		marginBottom: 5,
		marginTop: 15,
	},
};

export const documentDefinitions = async (value = 'retrato') => {
	const images = await preloadImages();

	const headerContent = [
		{
			columns: [
				{
					image: images.logo1,
					width: 80,
					height: 30,
					margin: [40, 10, 0, 0],
				},
				{
					text: 'Agência Executiva de Gestão das Águas do Estado da \nParaíba',
					alignment: 'center',
					margin: [0, 20, 0, 0],
				},
				{
					image: images.logo2,
					width: 90,
					height: 40,
					margin: [-40, 10, 0, 0],
				},
			],
		},

		{
			canvas: [
				{
					type: 'line',
					x1: 50,
					y1: 0,
					x2: value === 'retrato' ? 555 : 795,
					y2: 0,
					lineWidth: 1,
					lineColor: 'black',
				},
			],
		},
	];

	return {
		pageMargins: [40, 90, 40, 40],
		pageSize: 'A4',
		fontSize: 8,
		font: 'Roboto',
		layout: 'autowidth',
		header: function (currentPage, pageCount, pageSize) {
			const width = pageSize.orientation === 'portrait' ? 515 : 755;
			return [
				{
					columns: headerContent[0].columns,
				},
				{
					canvas: [
						{
							type: 'line',
							x1: 40,
							y1: 10,
							x2: value === 'retrato' ? 555 : 795,
							y2: 10,
							lineWidth: 1,
							lineColor: 'black',
						},
					],
				},
			];
		},
		footer: function (currentPage: number, pageCount: number) {
			return [
				{
					canvas: [
						{
							type: 'line',
							x1: 40,
							y1: 0,
							x2: value === 'retrato' ? 555 : 795,
							y2: 0,
							lineWidth: 1,
							lineColor: 'black',
						},
					],
				},
				{
					text:
						'AGÊNCIA EXECUTIVA DE GESTÃO DAS ÁGUAS DO ESTADO DA PARAÍBA - AESA\n' +
						'AV. Duarte da Silveira, S/N - Anexo ao DER, Torre - João Pessoa/PB, CEP:58013-280 - Contato: (83) 3225-5508',
					alignment: 'start',
					margin: [40, 10, 0, 0],
					fontSize: 8,
				},
				{
					text: `Pág. ${currentPage.toString()} de ${pageCount}`,
					alignment: 'right',
					margin: [0, 0, 30, 0],
					fontSize: 8,
				},
			];
		},
		content: [],
		styles,
	} as TDocumentDefinitions;
};

async function preloadImages() {
	const logo1 = await getBase64ImageFromURL('assets/images/logo_1_1.svg');
	const logo2 = await getBase64ImageFromURL('assets/images/logoParaiba.png');

	return { logo1, logo2 };
}

