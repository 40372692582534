import { Component } from '@angular/core';

@Component({
	selector: 'seira-public-footer',
	templateUrl: './public-footer.component.html',
	styleUrls: ['./public-footer.component.scss'],
})
export class PublicFooterComponent {
	carouselItems = [
		{
			src: 'assets/images/logos/cooperar.png',
			alt: 'Logo Projeto Cooperar',
		},
		{
			src: 'assets/images/logos/aesa.png',
			alt: 'Logo AESA',
		},
		{
			src: 'assets/images/logos/governo_pb.png',
			alt: 'Logo Governo da Paraíba',
		},
		{
			src: 'assets/images/logos/banco_mundial.png',
			alt: 'Logo Banco Mundial',
		},
	];
	sobreInfo = [
		{
			title: 'SOBRE O SEIRA',
			links: [
				{ label: 'Visão geral', path: '#' },
				{ label: 'Termos de serviço', path: '#' },
				{ label: 'Política de privacidade', path: '#' },
				{ label: 'Política de cookies', path: '#' },
			],
		},
		{
			title: 'SOBRE A AESA',
			links: [
				{
					label: 'Informações Básicas',
					path: 'https://www.aesa.pb.gov.br/aesa-website/institucional/informacoes-basicas-2/',
				},
				{
					label: 'Quem somos',
					path: 'https://www.aesa.pb.gov.br/aesa-website/institucional/pessoal/',
				},
				{
					label: 'Conselho Estadual',
					path: 'https://www.aesa.pb.gov.br/aesa-website/institucional/plano-estadual/',
				},
				{
					label: 'Fiscalização',
					path: 'https://www.aesa.pb.gov.br/aesa-website/institucional/fiscalizacao/',
				},
			],
		},
	];
}
