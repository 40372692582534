export enum ModeloGrade {
	PARAIBA = 'PARAIBA',
	NORDESTE = 'NORDESTE',
}

export const getLabelModeloGrade = (grade: ModeloGrade | string): string => {
	switch (grade) {
		case ModeloGrade.PARAIBA:
			return 'Paraíba (1km)';
		case ModeloGrade.NORDESTE:
			return 'Nordeste (27km)';
		default:
			return '';
	}
};

export const getResolucaoEspacialGrade = (
	grade: ModeloGrade
): number | undefined => {
	switch (grade) {
		case ModeloGrade.PARAIBA:
			return 1;
		case ModeloGrade.NORDESTE:
			return 27;
		default:
			return undefined;
	}
};
