import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { LayoutPadraoComponent } from './layout-padrao/layout-padrao.component';
import { FooterComponent } from './componentes/footer/footer.component';
import { HeaderComponent } from './componentes/header/header.component';
import { ListagemNotificacoesTabComponent } from './componentes/listagem-notificacoes-tab/listagem-notificacoes-tab.component';
import { PageHeaderComponent } from './componentes/page-header/page-header.component';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PublicFooterComponent } from './componentes/public-footer/public-footer.component';
import { MobileHeaderComponent } from './componentes/mobile-header/mobile-header.component';
import { IfViewportSizeDirective } from './directives/if-viewport-size.directive';

@NgModule({
	declarations: [
		LayoutPadraoComponent,
		FooterComponent,
		HeaderComponent,
		ListagemNotificacoesTabComponent,
		PageHeaderComponent,
		PublicFooterComponent,
		MobileHeaderComponent,
		IfViewportSizeDirective,
	],
	exports: [
		ListagemNotificacoesTabComponent,
		LayoutPadraoComponent,
		PageHeaderComponent,
		FooterComponent,
		PublicFooterComponent,
		MobileHeaderComponent,
		IfViewportSizeDirective,
	],
	imports: [
		CommonModule,
		RouterLink,
		RouterLinkActive,
		NgOptimizedImage,
		TooltipModule,
	],
})
export class LayoutModule {}
