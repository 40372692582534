import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AutenticacaoRoutingModule } from './autenticacao-routing.module';
import { SharedModule } from '@shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { NgxLoadingModule } from 'ngx-loading';
import { FormAlterarSenhaComponent } from './trocar-senha/componentes/form-alterar-senha/form-alterar-senha.component';
import { AlterarSenhaComponent } from './trocar-senha/pages/alterar-senha/alterar-senha.component';
import { LoginPageComponent } from './login/pages/login-page/login-page.component';
import { FormLoginComponent } from './login/componentes/form-login/form-login.component';
import { FormRecuperarSenhaComponent } from './recuperar-senha/componentes/form-recuperar-senha/form-recuperar-senha.component';
import { RecuperarSenhaComponent } from './recuperar-senha/pages/recuperar-senha/recuperar-senha.component';
import { FormRedefinirSenhaComponent } from './redefinir-senha/componentes/form-redefinir-senha/form-redefinir-senha.component';
import { RedefinirSenhaComponent } from './redefinir-senha/pages/redefinir-senha/redefinir-senha.component';

@NgModule({
	declarations: [
		LoginPageComponent,
		FormLoginComponent,
		FormAlterarSenhaComponent,
		AlterarSenhaComponent,
		FormRecuperarSenhaComponent,
		RecuperarSenhaComponent,
		FormRedefinirSenhaComponent,
		RedefinirSenhaComponent,
	],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		RouterModule,
		AutenticacaoRoutingModule,
		SharedModule,
		HttpClientModule,
		ToastrModule,
		NgxLoadingModule,
	],
})
export class AutenticacaoModule {}
