<div
	class="mb-1 me-2 w-100"
	[ngStyle]="(maxWidth && { 'max-width': maxWidth }) || null">
	<div
		class="bg-white px-2 py-2 shadow-lg rounded text-tertiary d-flex justify-content-center">
		<div class="d-flex flex-column">
			<div *ngIf="title" class="mb-2 fw-semibold">{{ title }}</div>
			<div
				class="d-flex"
				[ngStyle]="{ margin: '0.2em 0em' }"
				*ngFor="let item of legendaDeCores">
				<div
					class="color-sample me-2"
					[ngStyle]="{ background: item.color }"></div>
				{{ item.label }}
			</div>
		</div>
	</div>
	<ng-container *ngTemplateOutlet="actionsTemplate"></ng-container>
</div>
