import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'seira-vertical-separator',
	templateUrl: './vertical-separator.component.html',
	styleUrls: ['./vertical-separator.component.scss'],
})
export class VerticalSeparatorComponent implements OnInit {
	@Input() color = '';
	@Input() style = {};
	ngOnInit() {
		if (this.color) {
			this.color = `background-color: ${this.color}`;
		} else {
			this.color = 'background-color: rgba(0, 0, 0, 0.25)';
		}
	}
}
