<div
	style="
		font-size: 2.25rem;
		color: #728187;
		font-style: normal;
		font-weight: 275;
		line-height: normal;
	">
	<ng-content></ng-content>
</div>
