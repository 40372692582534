<div
	id="card"
	class="carousel slide d-flex flex-column align-items-center justify-content-between px-4 pt-2"
	data-bs-touch="true"
	data-bs-ride="carousel"
	[ngClass]="dadosSelos.length ? '' : 'informativo'">
	<ng-container *ngIf="dadosSelos.length || (loading && !gerou)">
		<div class="carousel-inner h-100">
			<div
				class="carousel-item h-100"
				*ngFor="let dadoSelo of dadosSelos; let i = index"
				[ngClass]="i === 0 ? 'active' : ''">
				<div
					class="d-flex flex-column align-items-center justify-content-between w-100 h-100">
					<div class="d-flex flex-column align-items-center w-100">
						<div class="font-size-24 fw-normal text-white w-100">
							{{ dadoSelo.municipio }}
						</div>
						<div class="text-white w-100 fs-4">
							{{ dadoSelo.dia | capitalize }}
						</div>
					</div>
					<div>
						<div
							class="text-white w-100 d-inline-flex gap-2 align-items-center fs-6">
							<img
								style="height: 60px"
								src="assets/images/previsao/{{ dadoSelo.evento }}.svg"
								[alt]="dadoSelo.evento" />
							<div class="d-flex flex-column">
								<div class="font-size-40 p-0 fw-275">
									{{ dadoSelo.minima }}º
								</div>
								<div>Mínima</div>
							</div>
							<div class="d-flex flex-column">
								<div class="font-size-40 p-0 fw-275">
									{{ dadoSelo.maxima }}º
								</div>
								<div>Máxima</div>
							</div>
						</div>
						<div class="text-white w-100 font-size-14">
							{{ dadoSelo.descricao }}
						</div>
					</div>
					<div
						class="d-inline-flex justify-content-around text-white w-100 fs-4 fw-normal">
						<div
							*ngFor="let seguinte of dadoSelo.seguintes"
							class="d-flex flex-column align-content-center">
							<img
								style="height: 24px"
								src="assets/images/previsao/{{ seguinte.evento }}.svg"
								[alt]="dadoSelo.evento" />
							<div>{{ seguinte.diaMes }}/{{ seguinte.mes }}</div>
						</div>
					</div>

					<div class="d-flex flex-column align-items-center text-center w-100">
						<div class="text-white w-100 fs-6">Fonte: SEIRA - AESA</div>
						<div class="text-white w-100 fs-7">
							Selo gerado usando dados de previsão (WRF)
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="d-flex justify-content-center w-100">
			<button
				class="bg-transparent border-0 p-0"
				type="button"
				data-bs-target="#card"
				data-bs-slide="prev">
				<i
					[ngClass]="dadosSelos.length < 2 ? 'disabled' : ''"
					class="ph-fill ph-caret-left carrossel-acoes fs-1 py-2 text-white fw-900 font-size-24"
					aria-hidden="true"></i>
				<span class="visually-hidden">Anterior</span>
			</button>
			<button
				class="bg-transparent border-0 p-0"
				type="button"
				data-bs-target="#card"
				data-bs-slide="next">
				<i
					[ngClass]="dadosSelos.length < 2 ? 'disabled' : ''"
					class="ph-bold ph-caret-right carrossel-acoes fs-1 py-2 text-white fw-900 font-size-24"
					aria-hidden="true"></i>

				<span class="visually-hidden">Próximo</span>
			</button>
		</div>
	</ng-container>

	<div class="text-white text-center" *ngIf="dadosSelos.length === 0 && gerou">
		Nenhuma previsão encontrada para os Municípios selecionados
	</div>
	<div class="text-white text-center" *ngIf="dadosSelos.length === 0 && !gerou">
		Selecione o(s) município(s) e clique no botão "Gerar selo"
	</div>
	<seira-loading [show]="loading"></seira-loading>
</div>
