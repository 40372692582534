<div class="mt-3">
	<ng-container>
		<seira-highchart
			classChart="highcharts-light h-auto"
			styleChart="width: 100%; height: 600px; display: block"
			(chartInstance)="setGrafico($event)"
			[options]="opcoesGrafico" />
	</ng-container>
</div>

