<div class="mb-2" style="position: relative">
	<label class="form-label fw-semibold" [for]="id"
		>{{ label }}<span class="text-danger" *ngIf="isRequired">*</span></label
	>
	<angular-editor
		[config]="editorConfig"
		[id]="id"
		class="form-control"
		[ngClass]="showsTextRequired || seiraError ? 'is-invalid' : ''"
		[placeholder]="placeholder"
		[formControlName]="controlName">
	</angular-editor>
	<div class="invalid-feedback mt-0" *ngIf="showsTextRequired || seiraError">
		{{ textRequired || seiraError }}
	</div>
</div>
