import { Component, Input } from '@angular/core';
import { ADTColumns } from 'angular-datatables/src/models/settings';
import { DadosRelatorioVariaveis } from '@home/submodulos/dados-meteorologicos/interfaces/response';
import { format } from 'date-fns';
import { numberToBrNumber } from '@utils';

@Component({
	selector: 'seira-tabela-relatorio-variaveis-meteorologicas',
	templateUrl: './tabela-relatorio-variaveis-meteorologicas.component.html',
	styleUrls: ['./tabela-relatorio-variaveis-meteorologicas.component.scss'],
})
export class TabelaRelatorioVariaveisMeteorologicasComponent {
	@Input() dadosTabelaVariaveis?: DadosRelatorioVariaveis[];

	colunas: ADTColumns[] = [
		{
			data: 'dataColeta',
			className: 'text-start',
			title: 'Data',
			render: (data: string, type: string) => {
				if (type === 'display') {
					return format(new Date(data), 'dd/MM/yyyy HH:mm');
				}
				return new Date(data).getTime();
			},
		},
		{
			data: null,
			className: 'text-start',
			render: function (data) {
				return data.precipitacao ? numberToBrNumber(data.precipitacao) : '-';
			},
			title: 'Precipitação',
		},
		{
			data: null,
			className: 'text-start',
			render: function (data) {
				return data.temperatura ? numberToBrNumber(data.temperatura) : '-';
			},
			title: 'Temperatura',
		},
		{
			data: null,
			className: 'text-start',
			render: function (data) {
				return data.umidade ? numberToBrNumber(data.umidade) : '-';
			},
			title: 'Umidade',
		},
		{
			data: null,
			className: 'text-start',
			render: function (data) {
				return data.pressao ? numberToBrNumber(data.pressao) : '-';
			},
			title: 'Pressão',
		},
		{
			data: null,
			className: 'text-start',
			render: function (data) {
				return data.ventoDirecao ? numberToBrNumber(data.ventoDirecao) : '-';
			},
			title: 'Direção do vento 1m',
		},
		{
			data: null,
			className: 'text-start',
			render: function (data) {
				return data.ventoVelocidade
					? numberToBrNumber(data.ventoVelocidade)
					: '-';
			},
			title: 'Velocidade do vento 1m',
		},
		{
			data: null,
			className: 'text-start',
			render: function (data) {
				return data.radiacao ? numberToBrNumber(data.radiacao) : '-';
			},
			title: 'Radiação',
		},
		{
			data: null,
			className: 'text-start',
			render: function (data) {
				return data.vento2m ? numberToBrNumber(data.vento2m) : '-';
			},
			title: 'Velocidade do vento 2m',
		},
		{
			data: null,
			className: 'text-start',
			render: function (data) {
				return data.ventoDirecao2 ? numberToBrNumber(data.ventoDirecao2) : '-';
			},
			title: 'Direção do vento 2m',
		},
		{
			data: null,
			className: 'text-start',
			render: function (data) {
				return data.temperaturaSolo
					? numberToBrNumber(data.temperaturaSolo)
					: '-';
			},
			title: 'Temperatura do solo',
		},
		{
			data: null,
			className: 'text-start',
			render: function (data) {
				return data.umidadeSolo ? numberToBrNumber(data.umidadeSolo) : '-';
			},
			title: 'Umidade do solo',
		},
		{
			data: null,
			className: 'text-start',
			render: function (data) {
				return data.evapotranspiracaoPotencial
					? numberToBrNumber(data.evapotranspiracaoPotencial)
					: '-';
			},
			title: 'Evapotranspiração',
		},
		{
			data: null,
			className: 'text-start',
			render: function (data) {
				return data.correnteRecarga
					? numberToBrNumber(data.correnteRecarga)
					: '-';
			},
			title: 'Corrente recarga',
		},
		{
			data: null,
			className: 'text-start',
			render: function (data) {
				return data.sensorPortaAberta
					? numberToBrNumber(data.sensorPortaAberta)
					: '-';
			},
			title: 'Sensor porta aberta',
		},
		{
			data: null,
			className: 'text-start',
			render: function (data) {
				return data.umidadeInterna
					? numberToBrNumber(data.umidadeInterna)
					: '-';
			},
			title: 'Umidade interna',
		},
		{
			data: null,
			className: 'text-start',
			render: function (data) {
				return data.temperaturaInterna
					? numberToBrNumber(data.temperaturaInterna)
					: '-';
			},
			title: 'Temperatura interna',
		},
		{
			data: null,
			className: 'text-end',
			render: function (data) {
				return data.tensaoBateria ? numberToBrNumber(data.tensaoBateria) : '-';
			},
			title: 'Tensão da bateria',
		},
		{
			data: null,
			className: 'text-end',
			render: function (data) {
				return data.conteudoAguaSolo60cm
					? numberToBrNumber(data.conteudoAguaSolo60cm)
					: '-';
			},
			title: 'Conteúdo água solo 60 cm',
		},
		{
			data: null,
			className: 'text-end',
			render: function (data) {
				return data.conteudoAguaSolo50cm
					? numberToBrNumber(data.conteudoAguaSolo50cm)
					: '-';
			},
			title: 'Conteúdo água solo 50 cm',
		},
		{
			data: null,
			className: 'text-end',
			render: function (data) {
				return data.conteudoAguaSolo40cm
					? numberToBrNumber(data.conteudoAguaSolo40cm)
					: '-';
			},
			title: 'Conteúdo água solo 40 cm',
		},
		{
			data: null,
			className: 'text-end',
			render: function (data) {
				return data.conteudoAguaSolo30cm
					? numberToBrNumber(data.conteudoAguaSolo30cm)
					: '-';
			},
			title: 'Conteúdo água solo 30 cm',
		},
		{
			data: null,
			className: 'text-end',
			render: function (data) {
				return data.conteudoAguaSolo20cm
					? numberToBrNumber(data.conteudoAguaSolo20cm)
					: '-';
			},
			title: 'Conteúdo água solo 20 cm',
		},
		{
			data: null,
			className: 'text-end',
			render: function (data) {
				return data.conteudoAguaSolo10cm
					? numberToBrNumber(data.conteudoAguaSolo10cm)
					: '-';
			},
			title: 'Conteúdo água solo 10 cm',
		},

		{
			data: null,
			className: 'text-end',
			render: function (data) {
				return data.temperaturaDoSolo10cm
					? numberToBrNumber(data.temperaturaDoSolo10cm)
					: '-';
			},
			title: 'Temperatura solo 10 cm',
		},

		{
			data: null,
			className: 'text-end',
			render: function (data) {
				return data.temperaturaDoSolo20cm
					? numberToBrNumber(data.temperaturaDoSolo20cm)
					: '-';
			},
			title: 'Temperatura solo 20 cm',
		},
		{
			data: null,
			className: 'text-end',
			render: function (data) {
				return data.temperaturaDoSolo30cm
					? numberToBrNumber(data.temperaturaDoSolo30cm)
					: '-';
			},
			title: 'Temperatura solo 30 cm',
		},
		{
			data: null,
			className: 'text-end',
			render: function (data) {
				return data.temperaturaDoSolo40cm
					? numberToBrNumber(data.temperaturaDoSolo40cm)
					: '-';
			},
			title: 'Temperatura solo 40 cm',
		},
		{
			data: null,
			className: 'text-end',
			render: function (data) {
				return data.temperaturaDoSolo50cm
					? numberToBrNumber(data.temperaturaDoSolo50cm)
					: '-';
			},
			title: 'Temperatura solo 50 cm',
		},
		{
			data: null,
			className: 'text-end',
			render: function (data) {
				return data.temperaturaDoSolo60cm
					? numberToBrNumber(data.temperaturaDoSolo60cm)
					: '-';
			},
			title: 'Temperatura solo 60 cm',
		},
		{
			data: null,
			className: 'text-end',
			render: function (data) {
				return data.ventoDirecao1DesvioPadrao
					? numberToBrNumber(data.ventoDirecao1DesvioPadrao)
					: '-';
			},
			title: 'Vento direção 1 desvio Padrão ',
		},
		{
			data: null,
			className: 'text-end',
			render: function (data) {
				return data.ventoDirecao2DesvioPadrao
					? numberToBrNumber(data.ventoDirecao2DesvioPadrao)
					: '-';
			},
			title: 'Vento direção 2 desvio Padrão',
		},
		{
			data: null,
			className: 'text-end',
			render: function (data) {
				return data.ventoVelocidade1Maxima
					? numberToBrNumber(data.ventoVelocidade1Maxima)
					: '-';
			},
			title: 'Vento velocidade 1 máxima',
		},
		{
			data: null,
			className: 'text-end',
			render: function (data) {
				return data.ventoVelocidade2Maxima
					? numberToBrNumber(data.ventoVelocidade2Maxima)
					: '-';
			},
			title: 'Vento velocidade 2 máxima',
		},
	];
}
