import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'seira-a',
	templateUrl: './a.component.html',
	styleUrls: ['./a.component.scss'],
})
export class AComponent {
	@Input() disabled = false;
	@Input() customClass = '';
	@Output() clicked = new EventEmitter<Event>();
}
