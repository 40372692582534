import * as Leaflet from 'leaflet';
import { TileLayer } from 'leaflet';

export enum MAPAS_LEAFLET {
	OPEN_STREET_MAP = 'OpenStreetMap',
	WORLD_STREET_MAP = 'Esri.WorldStreetMap',
	NAT_GEO_WORLD_MAP = 'Esri.NatGeoWorldMap',
	DE_LORME = 'Esri.DeLorme',
	OPEN_TOPO_MAP = 'OpenTopoMap',
}

export const mapLayers: Record<MAPAS_LEAFLET, TileLayer> = {
	[MAPAS_LEAFLET.OPEN_STREET_MAP]: Leaflet.tileLayer(
		'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
		{
			zIndex: 1,
			id: MAPAS_LEAFLET.OPEN_STREET_MAP,
			maxZoom: 19,
			attribution:
				'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
		}
	),
	[MAPAS_LEAFLET.WORLD_STREET_MAP]: Leaflet.tileLayer(
		'https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}',
		{
			zIndex: 1,
			id: MAPAS_LEAFLET.WORLD_STREET_MAP,
			maxZoom: 19,
			attribution:
				'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Tiles © Esri — Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012, Tiles © Esri — Sources: GEBCO, NOAA, CHS, OSU, UNH, CSUMB, National Geographic, DeLorme, NAVTEQ, and Esri',
		}
	),
	[MAPAS_LEAFLET.NAT_GEO_WORLD_MAP]: Leaflet.tileLayer(
		'https://server.arcgisonline.com/ArcGIS/rest/services/NatGeo_World_Map/MapServer/tile/{z}/{y}/{x}',
		{
			zIndex: 1,
			id: MAPAS_LEAFLET.NAT_GEO_WORLD_MAP,
			maxZoom: 19,
			attribution:
				'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Tiles © Esri — National Geographic, Esri, DeLorme, NAVTEQ, UNEP-WCMC, USGS, NASA, ESA, METI, NRCAN, GEBCO, NOAA, iPC, Tiles © Esri — Sources: GEBCO, NOAA, CHS, OSU, UNH, CSUMB, National Geographic, DeLorme, NAVTEQ, and Esri',
		}
	),
	[MAPAS_LEAFLET.DE_LORME]: Leaflet.tileLayer(
		'https://server.arcgisonline.com/ArcGIS/rest/services/Specialty/DeLorme_World_Base_Map/MapServer/tile/{z}/{y}/{x}',
		{
			zIndex: 1,
			id: MAPAS_LEAFLET.DE_LORME,
			maxZoom: 19,
			attribution:
				'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Tiles © Esri — National Geographic, Esri, DeLorme, NAVTEQ, UNEP-WCMC, USGS, NASA, ESA, METI, NRCAN, GEBCO, NOAA, iPC, Tiles © Esri — Sources: GEBCO, NOAA, CHS, OSU, UNH, CSUMB, National Geographic, DeLorme, NAVTEQ, and Esri',
		}
	),
	[MAPAS_LEAFLET.OPEN_TOPO_MAP]: Leaflet.tileLayer(
		'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
		{
			zIndex: 1,
			id: MAPAS_LEAFLET.OPEN_TOPO_MAP,
			maxZoom: 19,
			attribution:
				'&copy; Kartendaten: © OpenStreetMap-Mitwirkende, SRTM &copy; Kartendarstellung: © OpenTopoMap (CC-BY-SA)',
		}
	),
};
