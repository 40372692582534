import { Component, Input } from '@angular/core';
import { ValorPorMesoregiao } from '@utils/interpolacao/interpolacao';
import {
	capitalizeFirstLetter,
	isNotNuloOuUndefined,
	numberToBrNumber,
} from '@utils';

@Component({
	selector: 'seira-popup-informacoes-chuva-estado',
	templateUrl: './popup-informacoes-chuva-estado.component.html',
	styleUrls: ['./popup-informacoes-chuva-estado.component.scss'],
})
export class PopupInformacoesChuvaEstadoComponent {
	@Input()
	markerData!: ValorPorMesoregiao;
	constructor() {}

	protected readonly isNotNuloOuUndefined = isNotNuloOuUndefined;
	protected readonly numberToBrNumber = numberToBrNumber;
	protected readonly capitalizeFirstLetter = capitalizeFirstLetter;
}
