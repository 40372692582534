import {
	DadosGraficoVariaveis,
	DadosSerieVariavelMeteorologica,
	SerieVariavelMeteorologica,
} from './serie-variavel-meteorologica';
import mensagens from './mensagens';
import { getOptions } from 'highcharts';

export interface DadosNebulosidade extends DadosGraficoVariaveis {
	nebulosidadeMedia: number[];
	nebulosidadeAlta?: number[];
	nebulosidadeBaixa?: number[];
}

export class SerieNebulosidade extends SerieVariavelMeteorologica {
	private corNebulosidadeBaixa = getOptions().colors?.at(3);
	private corNebulosidadeMedia = getOptions().colors?.at(0);
	private corNebulosidadeAlta = getOptions().colors?.at(2);

	private unidadeMedida = ` ${mensagens['variavel.nebulosidade.unidade']}`;

	obterDadosSerie(
		dados: DadosNebulosidade,
		indexEixo: number
	): DadosSerieVariavelMeteorologica {
		const series = [];

		if (dados.nebulosidadeBaixa) {
			series.push(this.obterSerieNebulosidadeBaixa(dados, indexEixo));
		}

		if (dados.nebulosidadeMedia) {
			series.push(this.obterSerieNebulosidadeMedia(dados, indexEixo));
		}

		if (dados.nebulosidadeAlta) {
			series.push(this.obterSerieNebulosidadeAlta(dados, indexEixo));
		}

		return {
			serie: series,
			eixo: {
				title: {
					text: mensagens['variavel.nebulosidade.titulo-meteograma'],
				},
				offset: 0,
				lineWidth: 2,
				opposite: false,
				labels: { align: 'left', x: 3 },
			},
		};
	}

	private obterSerieNebulosidadeBaixa(
		dados: DadosNebulosidade,
		indexEixo: number
	): Object {
		if (dados.nebulosidadeBaixa) {
			return {
				yAxis: indexEixo,
				name: mensagens['variavel.baixa'],
				type: 'line',
				color: this.corNebulosidadeBaixa,
				data: this.prepararSerie(dados.datas, dados.nebulosidadeBaixa),
				tooltip: {
					valueDecimals: 2,
					valueSuffix: this.unidadeMedida,
				},
			};
		} else return {};
	}

	private obterSerieNebulosidadeMedia(
		dados: DadosNebulosidade,
		indexEixo: number
	): Object {
		return {
			yAxis: indexEixo,
			name: mensagens['variavel.media'],
			type: 'line',
			color: this.corNebulosidadeMedia,
			data: this.prepararSerie(dados.datas, dados.nebulosidadeMedia),
			tooltip: {
				valueSuffix: this.unidadeMedida,
			},
		};
	}

	private obterSerieNebulosidadeAlta(
		dados: DadosNebulosidade,
		indexEixo: number
	): Object {
		if (dados.nebulosidadeAlta) {
			return {
				yAxis: indexEixo,
				name: mensagens['variavel.alta'],
				type: 'line',
				color: this.corNebulosidadeAlta,
				data: this.prepararSerie(dados.datas, dados.nebulosidadeAlta),
				tooltip: {
					valueSuffix: this.unidadeMedida,
				},
			};
		} else return {};
	}
}
