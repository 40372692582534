import { Component, Input } from '@angular/core';

@Component({
	selector: 'seira-popup-padrao-mapa-paraiba',
	templateUrl: './popup-padrao-mapa-paraiba.component.html',
	styleUrls: ['./popup-padrao-mapa-paraiba.component.scss'],
})
export class PopupPadraoMapaParaibaComponent {
	@Input() headerColor!: string | null;
	@Input() headerTextColor: string | undefined = 'text-tertiary';
}
