import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TextoInformativo } from '@home/submodulos/dados-meteorologicos/interfaces/texto-informativo';

@Component({
	selector: 'seira-public-page-about',
	templateUrl: './public-page-about.component.html',
	styleUrls: ['./public-page-about.component.scss'],
})
export class PublicPageAboutComponent {
	@ViewChild('modal') modalInformacoesGerais!: TemplateRef<any>;

	@Input() textosInformativos!: TextoInformativo;

	modalRef: BsModalRef | undefined;

	constructor(private modalService: BsModalService) {}

	mostrarInformacoesGerais() {
		this.modalRef = this.modalService.show(this.modalInformacoesGerais, {
			class: 'modal-dialog-centered',
		});
	}
}
