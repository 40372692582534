import { Component, HostBinding, OnInit } from '@angular/core';
import { UserService } from '@core/services/user/user.service';
import { Router } from '@angular/router';

@Component({
	selector: 'seira-login-page',
	templateUrl: './login-page.component.html',
	styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
	@HostBinding('class') clazz = 'expandir-flex';

	constructor(
		private router: Router,
		private userService: UserService
	) {}

	ngOnInit() {
		if (this.userService.isLogged()) {
			this.router.navigate(['meteorologia', 'medicoes-pluviometricas']).then();
		}
	}
}
