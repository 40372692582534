import { ptBrLang } from '@utils/index';
import * as Highcharts from 'highcharts/highstock';

export const highChartsOptions: Highcharts.Options = {
	global: {
		useUTC: false,
		timezone: 'America/Fortaleza',
	},
	lang: ptBrLang,
	time: {
		useUTC: false,
		timezone: 'America/Fortaleza',
	},
	chart: {
		style: {
			fontFamily: 'Kanit, sans-serif',
		},
	},
};
