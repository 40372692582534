import { Component, Input } from '@angular/core';

@Component({
	selector: 'seira-multi-color-bar',
	templateUrl: './multi-color-bar.component.html',
	styleUrls: ['./multi-color-bar.component.scss'],
})
export class MultiColorBarComponent {
	@Input() colors!: string[];
	@Input() label = '';
	@Input() widthColors: string = '50px';
	@Input() labels: string[] = [];
	percentage() {
		return 100 / this.colors.length + '%';
	}
	setRadius(i: number) {
		let start = '0';
		let end = '0';
		if (i === 0) {
			start = '100px';
		}
		if (i === this.colors.length - 1) {
			end = '100px';
		}
		return `${start} ${end} ${end} ${start}`;
	}
}
