export enum StatusEstacao {
	ATIVA = 'Ativa',
	EXTINTA = 'Extinta',
	INATIVA = 'Inativa',
	MANUTENCAO = 'Manutenção',
	OUTROS = 'Outros',
}

export enum StatusEstacaoIcon {
	ATIVA = 'ph-check-circle text-success',
	EXTINTA = 'ph-x-circle text-danger',
	INATIVA = 'ph-x-circle text-warning',
	MANUTENCAO = 'ph-wrench text-warning',
	OUTROS = 'ph-dots-three-circle text-warning',
}
