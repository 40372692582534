import { Component, inject } from '@angular/core';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { FormGroup, Validators } from '@angular/forms';
import {
	Microrregiao,
	PostosRelatorios,
} from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import { Estacao } from '@home/submodulos/dados-meteorologicos/interfaces/filtros-opcoes';
import { Select } from '@layout/interfaces/select';
import { Subscription } from 'rxjs';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';
import { ToastrService } from 'ngx-toastr';
import { format, formatISO } from 'date-fns';
import * as pdfseira from '@utils/pdf-seira';
import { isNotNuloOuUndefined, numberToBrNumber } from '@utils';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { DocumentExporter } from '@utils/document-exporter';

@Component({
	selector: 'seira-pluviometro-tabela-pluviometrico-do-estado',
	templateUrl: './pluviometro-tabela-pluviometrico-do-estado.component.html',
	styleUrls: ['./pluviometro-tabela-pluviometrico-do-estado.component.scss'],
})
export class PluviometroTabelaPluviometricoDoEstadoComponent {
	inputs = inject(INPUTS_RELATORIOS);
	form!: FormGroup;
	postos: PostosRelatorios[] = [];
	estacoes: Estacao[] = [];
	microrregioes!: Select<string>[];
	carregandoRelatorio = false;
	dadosTabelaRelatorio: Microrregiao[] = [];
	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => this.exportarPDF(),
		},
		{
			label: '.csv',
			size: 'small',
			icon: 'ph-file-csv',
			onClick: () => this.exportarCSV(),
		},
		{
			label: '.txt',
			size: 'small',
			icon: 'ph-file-text',
			onClick: () => this.exportarTXT(),
		},
	];

	private subscription = new Subscription();
	constructor(
		private relatoriosService: RelatoriosService,
		private toastr: ToastrService
	) {}

	ngOnInit() {
		this.form = this.inputs.form;
		setTimeout(() => {
			this.setValidators();
		});
	}

	getFormItemValue(field: string) {
		return this.form.get(field)!.value;
	}

	formataData(data: Date) {
		const formatoData = new Date(data);
		return format(formatoData, 'MM/yyyy');
	}

	gerarRelatorio() {
		const periodo = this.form.get('periodo')?.value;
		if (this.form.invalid || !periodo || !this.postos.length) {
			return;
		}
		const postosIds = this.postos.map(posto => posto.id);
		this.inputs.setLoading(true);
		this.carregandoRelatorio = true;

		this.relatoriosService
			.buscarRelatorioPluviometricosDoEstado(
				formatISO(new Date(periodo)),
				formatISO(new Date(periodo)),
				postosIds
			)
			.subscribe({
				next: pluviometricos => {
					this.dadosTabelaRelatorio = pluviometricos.dadosMicrorregiao;
					this.carregandoRelatorio = false;
					this.inputs.setLoading(false);
				},
				error: () => {
					this.carregandoRelatorio = false;
					this.toastr.error('Ocorreu um erro ao gerar o relatório');
					this.inputs.setLoading(false);
				},
			});
	}

	setValidators() {
		this.form.get(FormularioRelatorio.TIPO)?.setValidators(Validators.required);
		this.form
			.get(FormularioRelatorio.PERIODO)
			?.setValidators(Validators.required);
	}
	ngOnDestroy() {
		this.form.get(FormularioRelatorio.TIPO)?.clearValidators();
		this.form.get(FormularioRelatorio.PERIODO)?.clearValidators();
		this.subscription.unsubscribe();
	}

	formatNumber(value: number): string {
		return isNotNuloOuUndefined(value)
			? String(numberToBrNumber(value.toFixed(2)))
			: '-';
	}

	generateTableData(dadoRelatorio: Microrregiao) {
		const tableData: (string | number)[][] = [];

		tableData.push([
			'Municipio',
			'Posto',
			'Latitude',
			'Longitude',
			'Norm. Mensal (mm)',
			'Obs. Mensal (mm)',
			'Desv. Mensal (mm)',
			'Desv. Mensal (%)',
			'Norm. Anual (mm)',
			'Obs. Anual (mm)',
			'Desv. Anual (mm)',
			'Desv. Anual (%)',
		]);

		dadoRelatorio.dadosPorMunicipio.forEach(dado => {
			tableData.push([
				dado.detalhesPosto.municipio,
				dado.detalhesPosto.posto,
				dado.detalhesPosto.latitude,
				dado.detalhesPosto.longitude,
				this.formatNumber(dado.mediaMes),
				this.formatNumber(dado.valorObservadoMes),
				this.formatNumber(dado.desvioMes),
				this.formatNumber(dado.desvioPercentualMes),
				this.formatNumber(dado.mediaAno),
				this.formatNumber(dado.valorObservadoAno),
				this.formatNumber(dado.desvioAno),
				this.formatNumber(dado.desvioPercentualAno),
			]);
		});

		return tableData;
	}

	getPeriodo() {
		return this.formataData(this.getFormItemValue('periodo')).toLowerCase();
	}

	async exportarPDF() {
		const documentDefinition: any = await pdfseira.documentDefinitions(
			'paisagem'
		);

		documentDefinition.pageOrientation = 'landscape';

		documentDefinition.content.push({
			text: `Relatório - Pluviométrico do estado - ${this.getPeriodo()}`,
			fontSize: 12,
			alignment: 'center',
			margin: [0, 10],
		});

		if (!this.dadosTabelaRelatorio.length) {
			documentDefinition.content.push({
				text: 'Nenhum dado encontrado na tabela',
				alignment: 'center',
				fontSize: 10,
				margin: [0, 10],
			});
		}

		this.dadosTabelaRelatorio.forEach(dadoRelatorio => {
			const tableData = this.generateTableData(dadoRelatorio);
			documentDefinition.content.push(
				{
					text: `Microrregião ${dadoRelatorio.microrregiao}`,
					fontSize: 12,
					margin: [0, 10],
				},
				{
					table: {
						body: tableData,
						layout: {
							noWrap: false,
							fontSize: 5,
						},
					},
				}
			);
		});
		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	async exportarCSV() {
		let tableData: (string | number)[][] = [];
		this.dadosTabelaRelatorio.forEach(dadoRelatorio => {
			const table = this.generateTableData(dadoRelatorio);
			tableData.push(...table);
		});
		DocumentExporter.gerarCSV(
			tableData,
			`relatorio-pluviometrico-do-estado-${this.getPeriodo()}`
		);
	}

	async exportarTXT() {
		let txtData = '';

		this.dadosTabelaRelatorio.forEach(microrregiao => {
			txtData += `Microrregião: ${microrregiao.microrregiao} \n\n`;

			microrregiao.dadosPorMunicipio.forEach(dado => {
				txtData +=
					`Município: ${dado.detalhesPosto.municipio} \n` +
					`Posto: ${dado.detalhesPosto.posto} \n` +
					`Latitude: ${dado.detalhesPosto.latitude} \n` +
					`Longitude: ${dado.detalhesPosto.longitude} \n` +
					`Norm. Mensal (mm): ${this.formatNumber(dado.mediaMes)} \n` +
					`Obs. Mensal (mm): ${this.formatNumber(dado.valorObservadoMes)} \n` +
					`Desv. Mensal (mm): ${this.formatNumber(dado.desvioMes)} \n` +
					`Desv. Mensal (%): ${this.formatNumber(
						dado.desvioPercentualMes
					)} \n` +
					`Norm. Anual (mm): ${this.formatNumber(dado.mediaAno)} \n` +
					`Obs. Anual (mm): ${this.formatNumber(dado.valorObservadoAno)} \n` +
					`Desv. Anual (mm): ${this.formatNumber(dado.desvioAno)} \n` +
					`Desv. Anual (%): ${this.formatNumber(
						dado.desvioPercentualAno
					)} \n\n`;
			});
		});

		DocumentExporter.gerarTXT(
			txtData,
			`relatorio-pluviometrico-do-estado-${this.getPeriodo()}`
		);
	}
}
