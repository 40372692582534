import { Injectable } from '@angular/core';
import {
	AgrupamentoResponse,
	DadosAgrupamento,
} from '@home/submodulos/dados-meteorologicos/interfaces';
import { Rotas } from '@core/enums/routes';
import { HttpClientProtectedService } from '@core/services/auth/http-client-protected.service';
import { Observable, forkJoin } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class GraficosObservadoDesvioClimatologiaService {
	constructor(private http: HttpClientProtectedService) {}

	buscarObservadoDesvioClimatologia(
		dados: DadosAgrupamento,
		agrupamento: string,
		diaInicio: string,
		diaFim: string,
		id: number
	): Observable<any[]> {
		return forkJoin([
			this.http.post<DadosAgrupamento, AgrupamentoResponse[]>(
				Rotas.RELATORIOS + '/precipitacao/agrupamento-precipitacao',
				dados
			),
			this.http.get<any>('desvio', {
				agrupamento,
				dataInicio: diaInicio,
				dataFim: diaFim,
				id,
				agrupar: true,
			}),
		]);
	}
}
