import {
	Component,
	ElementRef,
	forwardRef,
	Input,
	ViewChild,
} from '@angular/core';
import { HTMLInputTypeAttribute } from '@layout/interfaces/htmlTypeInput';
import {
	ControlValueAccessor,
	FormsModule,
	NG_VALUE_ACCESSOR,
	ReactiveFormsModule,
} from '@angular/forms';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { CommonModule } from '@angular/common';

const INPUT_FIELD_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => InputMaskComponent),
	multi: true,
};

@Component({
	selector: 'seira-input-mask',
	templateUrl: './input-mask.component.html',
	styleUrls: ['./input-mask.component.scss'],
	standalone: true,
	imports: [NgxMaskDirective, FormsModule, ReactiveFormsModule, CommonModule],
	providers: [provideNgxMask(), INPUT_FIELD_VALUE_ACCESSOR],
})
export class InputMaskComponent implements ControlValueAccessor {
	@ViewChild('inputElement') inputElement!: ElementRef;
	@Input() id!: string;
	@Input() label!: string;
	@Input() required = false;
	@Input() control!: any;
	@Input() placeholder!: string;
	@Input() showsTextRequired = false;
	@Input() textRequired = '';
	@Input() type: HTMLInputTypeAttribute = 'text';
	@Input() mask!: string;
	private _innerValue: any;

	get value() {
		return this._innerValue;
	}
	set value(v: string) {
		if (this._innerValue !== v) {
			this._innerValue = v;
			this.onChangeCb(v);
		}
	}

	onChangeCb: (_: any) => void = () => {};
	onTouchedCb: (_: any) => void = () => {};

	writeValue(value: any): void {
		this.value = value;
	}

	registerOnChange(fn: any): void {
		this.onChangeCb = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouchedCb = fn;
	}

	checkMax() {
		if (this.type === 'datetime-local') {
			return '9999-12-31T23:59';
		}
		if (this.type === 'date') {
			return '9999-12-31';
		}
		return null;
	}

	objectHelp = Object;
}
