<div
	class="accordion card border-start border-start-width-3 border-start-danger rounded-start-0">
	<div class="card-header text-danger text-with d-flex flex-wrap">
		<h6 class="mb-0">
			<span class="badge bg-danger bg-opacity-20 text-danger rounded-pill">{{
				Object.keys(errosPorLinha).length
			}}</span>
			Linha(s) com erro(s)
		</h6>
		<div class="d-inline-flex ms-auto">
			<a
				class="text-body accordion-button p-0 pe-1 in"
				data-bs-toggle="collapse"
				data-bs-target="#content-card-erros"
				aria-expanded="true">
			</a>
		</div>
	</div>

	<div class="collapse show" id="content-card-erros">
		<div class="card-body">
			<div class="accordion" id="accordion_collapsed">
				<div
					*ngFor="let erroPorLinha of errosPorLinha | keyvalue"
					class="accordion-item">
					<h2 class="accordion-header">
						<button
							class="accordion-button fw-semibold in pt-1 pb-1"
							type="button"
							data-bs-toggle="collapse"
							[attr.data-bs-target]="'#collapsed_error_item' + erroPorLinha.key"
							aria-expanded="true">
							<small class="lead">Linha #{{ erroPorLinha.key }} </small>
							<span
								class="badge bg-danger bg-opacity-20 text-danger rounded-pill ms-2"
								>{{ erroPorLinha.value.length }}</span
							>
						</button>
					</h2>
					<div
						id="collapsed_error_item{{ erroPorLinha.key }}"
						class="accordion-collapse collapse show"
						data-bs-parent="#accordion_collapsed"
						style="">
						<div class="accordion-body">
							<ol class="list-group list-group-numbered">
								<li
									*ngFor="let erro of erroPorLinha.value"
									class="list-group-item d-flex justify-content-between align-items-start">
									<div class="ms-2 me-auto">
										<div class="fw-bold">
											<span class="badge bg-light text-body">{{
												erro.campo
											}}</span>
										</div>
										<small>{{ erro.mensagem }}</small>
									</div>
								</li>
							</ol>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
