<table
	datatable
	#tabelaPv
	[dtOptions]="dtOptions"
	[dtTrigger]="dtTrigger"
	[class]="class"
	style="width: 100%">
	<thead>
		<tr>
			<th
				*ngFor="let column of columns; let i = index"
				[class]="styling.thClass"
				[style]="borderRadius(i)">
				{{ column.title }}
			</th>
		</tr>
	</thead>
</table>
