<div class="d-inline-flex">
	<div class="dropdown">
		<seira-button
			buttonStyle="custom"
			icon="ph-list"
			classButton="btn btn-link p-0 text-body"
			data-bs-toggle="dropdown">
		</seira-button>
		<div class="dropdown-menu dropdown-menu-end">
			<seira-button
				*ngFor="let option of options"
				buttonStyle="custom"
				[icon]="option.icon"
				(clicked)="option.click()"
				[routerLink]="option.routerLink"
				[classButton]="'dropdown-item ' + option.className">
				{{ option.title }}
			</seira-button>
		</div>
	</div>
</div>
