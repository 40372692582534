<label class="text-tertiary fw-semibold mt-3 text-uppercase">
	Gráfico de Variáveis Meteorológicas -
	{{ getVariavelName ? getVariavelName.name : '' }} {{ getVariavelUnidade }} -
	{{ getDataInicio }} - {{ getDataFinal }}</label
>
<div style="height: 500px" *ngIf="loading">
	<seira-loading [show]="loading" customStyle="top:80% !important" />
</div>
<div *ngIf="dados && possuiDadosGrafico && !loading">
	<seira-card-info aditionalClasses="my-3">
		Placeholder informativo
	</seira-card-info>

	<div id="grafico" class="my-4"></div>
</div>
<div *ngIf="(!dados || !possuiDadosGrafico) && !loading">
	<seira-sem-dados />
</div>
