import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import {
	FormularioRelatorio,
	INPUTS_RELATORIOS,
	InstanciaRelatorio,
} from '@home/submodulos/dados-meteorologicos/interfaces/tipos-relatorios';
import { GroupButton } from '@componentes/public-button-group/public-button-group.component';
import { FormGroup, Validators } from '@angular/forms';
import { DadosNdc } from '@home/submodulos/dados-meteorologicos/interfaces/variavel-meteorologica-chuva';
import { LabelsHistoricoVariaveisMeteorologicas } from '@home/submodulos/dados-meteorologicos/enum/Variaveis-metereologica';
import { LegendaOptions } from '@home/submodulos/dados-meteorologicos/componentes/legenda-relatorio-historico-variaveis/legenda-relatorio-historico-variaveis.component';
import { RelatoriosService } from '@home/submodulos/dados-meteorologicos/services/relatorios.service';
import { ToastrService } from 'ngx-toastr';
import * as pdfseira from '@utils/pdf-seira';
import html2canvas from 'html2canvas';
import {
	criarCanvasInterpolacao,
	InterpolacaoCanvasBounds,
	ValorPorNdc,
} from '@utils/interpolacao/interpolacao';
import { PostosRelatorios } from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';
import { Estacao } from '@home/submodulos/dados-meteorologicos/interfaces/filtros-opcoes';
import { Select } from '@layout/interfaces/select';
import { Position } from 'pdfmake/interfaces';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { FeatureCollection, Polygon } from 'geojson';
import moment, { Moment } from 'moment';
import { sharedData } from '@home/submodulos/dados-meteorologicos/componentes/relatorios/mapa-ndc/shared-data';
import { format } from 'date-fns';
import ptBrLocale from 'date-fns/locale/pt-BR';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { numberToBrNumber } from '@utils';

@Component({
	selector: 'seira-mapa-ndc-chuva',
	templateUrl: './mapa-ndc.component.html',
	styleUrls: ['./mapa-ndc.component.scss'],
})
export class MapaNdcComponent implements OnInit, OnDestroy, InstanciaRelatorio {
	@Input() imagem!: DadosNdc;
	chuvas: DadosNdc[] = [];
	interpolacao?: InterpolacaoCanvasBounds;
	carregando = false;
	inputs = inject(INPUTS_RELATORIOS);
	form!: FormGroup;
	postos: PostosRelatorios[] = [];
	estacoes: Estacao[] = [];
	microrregioes: Select[] = [];
	loading = false;
	regiao?: Position[][];

	botoesDeExportacao: GroupButton[] = [
		{
			label: '.pdf',
			size: 'small',
			icon: 'ph-file-pdf',
			onClick: () => {
				this.exportPdf().then();
			},
		},
	];
	legenda: LegendaOptions = {
		label: LabelsHistoricoVariaveisMeteorologicas.PRECIPITACAO,
		items: [],
	};

	constructor(
		private httpClient: HttpClient,
		private relatorioService: RelatoriosService,
		private toastr: ToastrService
	) {}

	ngOnInit() {
		this.form = this.inputs.form;
		setTimeout(() => {
			this.setValidators();
		}, 0);
		this.initialValues();
	}

	initialValues() {
		const pbGeoJsonObservable = this.httpClient.get<FeatureCollection>(
			'assets/geoJson/pb-geo.json'
		);
		pbGeoJsonObservable.subscribe(value => {
			this.regiao = (<Polygon>value.features[0].geometry)
				.coordinates as Position[][];
		});
	}

	gerarRelatorio() {
		const dataInicio: Moment | null = this.form.get(
			FormularioRelatorio.DATA_INICIO
		)?.value;
		const dataFim: Moment | null = this.form.get(FormularioRelatorio.DATA_FIM)
			?.value;
		const periodo = this.form.get(FormularioRelatorio.TIPO_PERIODO)?.value;

		if (dataInicio && dataFim && periodo.toUpperCase() == 'MENSAL') {
			this.inputs.setLoading(true);
			this.relatorioService
				.buscarHistoricoChuvas(
					periodo.toUpperCase(),
					dataInicio.toISOString(),
					dataFim.toISOString()
				)
				.subscribe({
					next: resp => {
						this.chuvas = resp;
						this.interpolarValores();
						this.inputs.setLoading(false);
					},
					error: (erro: HttpErrorResponse) => {
						this.toastr.error('Nenhum resultado encontrado', erro.message);
					},
				});
		} else if (periodo.toUpperCase() == 'ANUAL') {
			this.inputs.setLoading(true);
			this.relatorioService
				.buscarHistoricoChuvas(
					periodo.toUpperCase(),
					this.verificarDataSelecionada(dataInicio)
				)
				.subscribe({
					next: resp => {
						this.chuvas = resp;
						this.interpolarValores();
						this.inputs.setLoading(false);
					},
					error: (erro: HttpErrorResponse) => {
						this.toastr.error('Nenhum resultado encontrado', erro.message);
					},
				});
		} else if (dataInicio && dataFim && periodo.toUpperCase() == 'DIARIO') {
			this.relatorioService
				.buscarHistoricoChuvas(
					periodo.toUpperCase(),
					dataInicio.toISOString(),
					dataFim.toISOString()
				)
				.subscribe({
					next: resp => {
						this.chuvas = resp;
						this.interpolarValores();
						this.inputs.setLoading(false);
					},
					error: (erro: HttpErrorResponse) => {
						this.toastr.error('Nenhum resultado encontrado', erro.message);
					},
				});
		}
	}

	verificarDataSelecionada(dataInicio: moment.Moment | null): string | null {
		if (sharedData.isSelect) {
			const anoSelecionado = sharedData.anoSelecionado;
			const dataInicioAno = moment(`${anoSelecionado}-01-01`)
				.startOf('day')
				.toDate();

			return dataInicioAno.toISOString();
		}

		if (dataInicio) {
			return dataInicio.toISOString();
		} else {
			console.error('dataInicio is null');
			return null;
		}
	}

	interpolarValores() {
		this.carregando = true;
		if (this.chuvas.length > 0) {
			this.criarImagemInterpolacao().then(interpolacao => {
				this.interpolacao = interpolacao as InterpolacaoCanvasBounds;
				this.carregando = false;
			});
		} else {
			this.interpolacao = undefined;
			this.carregando = false;
		}
	}

	criarImagemInterpolacao() {
		const valor: ValorPorNdc[] = this.chuvas.map((value: any) => {
			return {
				nomePosto: value.nome_posto,
				lat: value.latitude,
				lng: value.longitude,
				precipitacao: value.soma_precipitacao.toFixed(1),
				value: value.numero_dias_com_chuva,
			};
		});
		return new Promise((resolve, reject) => {
			const interpolacao = criarCanvasInterpolacao(
				valor,
				'precipitacao_dias_chuva',
				this.regiao as number[][][],
				0.01,
				true
			);
			if (interpolacao === undefined || interpolacao === null) {
				reject();
			}
			resolve(interpolacao);
		});
	}

	setValidators() {
		this.form
			.get(FormularioRelatorio.VARIAVEL_MAPA)
			?.setValidators(Validators.required);
		this.form
			.get(FormularioRelatorio.TIPO_PERIODO)
			?.setValidators(Validators.required);
		this.form
			.get(FormularioRelatorio.DATA_INICIO)
			?.setValidators(Validators.required);
		this.form
			.get(FormularioRelatorio.DATA_FIM)
			?.setValidators(Validators.required);
	}

	async exportPdf() {
		const elementos = ['ndc'];
		const imagens = [];

		for (const elemento of elementos) {
			const elementoHtml = document.getElementById(elemento);
			if (elementoHtml) {
				const img = await html2canvas(elementoHtml).then(canvas =>
					canvas.toDataURL('image/png')
				);
				imagens.push({ imagem: img, titulo: 'Mapa de quantidade de chuva' });
			}
		}

		const documentDefinition: any = await pdfseira.documentDefinitions(
			'paisagem'
		);

		documentDefinition.content.push([
			{
				text: `Relatório de número de dias com chuva - ${this.getPeriodo()}`,
				alignment: 'center',
				margin: [0, 15, 5, 15],
			},
			{
				text: `Relatório exportado em: ${this.getPeriodoAtualExportacao()}`,
				alignment: 'center',
				margin: [0, 10, 5, 10],
				fontSize: 12,
			},
		]);

		for (const imagem of imagens) {
			if (imagem.imagem && imagem.titulo) {
				documentDefinition.content.push(
					{
						text: imagem.titulo,
						alignment: 'center',
						margin: [0, 15, 0, 5],
						fontSize: 12,
					},
					{
						image: imagem.imagem,
						width: 515,
						heigth: 250,
						alignment: 'center',
					}
				);
			}
		}

		documentDefinition.content.push({
			text: `Tabela de precipitação`,
			alignment: 'center',
			margin: [0, 15, 0, 5],
			fontSize: 12,
		});

		documentDefinition.content.push({
			table: {
				widths: ['30%', '35%', '35%'],
				body: this.tableData(),
				layout: {
					noWrap: false,
					fontSize: 5,
				},
			},
		});

		const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
		return pdfDocGenerator.open();
	}

	getPeriodo() {
		const periodo = this.form.get(FormularioRelatorio.TIPO_PERIODO)?.value;
		const dataInicio = this.form.get(FormularioRelatorio.DATA_INICIO)?.value;
		const dataFim = this.form.get(FormularioRelatorio.DATA_FIM)?.value;

		if (periodo == 'MENSAL') {
			return `${dataInicio.format('MM/yyyy')} a ${dataFim.format('MM/yyyy')}`;
		}

		if (periodo == 'DIARIO') {
			return `${dataInicio.format('DD/MM/YYYY')} a ${dataFim.format(
				'DD/MM/YYYY'
			)}`;
		}

		return `${dataInicio.format('yyyy')}`;
	}

	getPeriodoAtualExportacao() {
		return format(new Date(), "dd 'de' MMMM 'de' yyyy HH'h'mm", {
			locale: ptBrLocale,
		});
	}

	tableData() {
		const dadosTabela: Array<any[]> = [];

		const cabecalho = [
			{ text: 'Posto', fillColor: '#DCDCDC' },
			{ text: 'Precipitação', fillColor: '#DCDCDC' },
			{ text: 'Número de Dias com Chuva', fillColor: '#DCDCDC' },
		];
		dadosTabela.push(cabecalho);

		const valores = this.chuvas.map((value: any) => [
			value.nome_posto,
			numberToBrNumber(value.soma_precipitacao.toFixed(1)),
			value.numero_dias_com_chuva,
		]);
		dadosTabela.push(...valores);

		return dadosTabela;
	}

	ngOnDestroy() {
		this.form.get(FormularioRelatorio.VARIAVEL_MAPA)?.clearValidators();
		this.form.get(FormularioRelatorio.TIPO_PERIODO)?.clearValidators();
		this.form.get(FormularioRelatorio.DATA_INICIO)?.clearValidators();
		this.form.get(FormularioRelatorio.DATA_FIM)?.clearValidators();
	}
}
