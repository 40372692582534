const RANGES_QUANTIS_ANUAL: Record<string, RangeData> = {
	'25023': {
		nome: 'Litoral Sul',
		ranges: [932.07, 1131.02, 1438.09, 1679.21, 2600],
	},
	'25022': {
		nome: 'João Pessoa',
		ranges: [932.07, 1131.02, 1438.09, 1679.21, 2600],
	},
	'25021': {
		nome: 'Sapé',
		ranges: [932.07, 1131.02, 1438.09, 1679.21, 2600],
	},
	'25020': {
		nome: 'Litoral Norte',
		ranges: [932.07, 1131.02, 1438.09, 1679.21, 2600],
	},
	'25019': {
		nome: 'Umbuzeiro',
		ranges: [932.07, 1131.02, 1438.09, 1679.21, 2600],
	},
	'25018': {
		nome: 'Itabaiana',
		ranges: [1185.77, 1581.7, 2015.2, 2263.51, 2600],
	},
	'25017': {
		nome: 'Campina Grande',
		ranges: [826.66, 1101.9, 1478.4, 1779.23, 2600],
	},
	'25016': {
		nome: 'Guarabira',
		ranges: [713.47, 889.72, 1216.17, 1642.12, 2600],
	},
	'25015': {
		nome: 'Brejo Paraibano',
		ranges: [713.47, 889.72, 1216.17, 1642.12, 2600],
	},
	'25014': {
		nome: 'Esperança',
		ranges: [316.05, 435.7, 628.95, 849.05, 2600],
	},
	'25013': {
		nome: 'Curimataú Oriental',
		ranges: [389.21, 528.12, 740.72, 1005.71, 2600],
	},
	'25012': {
		nome: 'Curimataú Ocidental',
		ranges: [323.95, 463.45, 678.5, 865.4, 2600],
	},
	'25011': {
		nome: 'Cariri Oriental',
		ranges: [379.25, 574.4, 801.75, 1020.2, 2600],
	},
	'25010': {
		nome: 'Cariri Ocidental',
		ranges: [317.81, 509.2, 784.5, 1011.13, 2600],
	},
	'25009': {
		nome: 'Seridó Oriental',
		ranges: [287.38, 397.75, 611.9, 829.78, 2600],
	},
	'25008': {
		nome: 'Seridó Ocidental ',
		ranges: [311.58, 444.48, 681.44, 898.84, 2600],
	},
	'25007': {
		nome: 'Serra do Teixeira',
		ranges: [492.41, 760.72, 1014.21, 1289.03, 2600],
	},
	'25006': {
		nome: 'Itaporanga',
		ranges: [490.34, 654.74, 891.12, 1096.79, 2600],
	},
	'25005': {
		nome: 'Piancó',
		ranges: [595.26, 759.31, 1061.37, 1271.48, 2600],
	},
	'25004': {
		nome: 'Patos',
		ranges: [375.67, 527.32, 802.2, 1003.92, 2600],
	},
	'25003': {
		nome: 'Sousa',
		ranges: [442.15, 602.31, 885.96, 1115.77, 2600],
	},
	'25002': {
		nome: 'Cajazeiras',
		ranges: [426.12, 562.55, 867.77, 1068.8, 2600],
	},
	'25001': {
		nome: 'Catolé do Rocha',
		ranges: [365.06, 551.7, 885.0, 1046.53, 2600],
	},
};
export interface RangeData {
	nome: string;
	ranges: number[];
}
export default RANGES_QUANTIS_ANUAL;
