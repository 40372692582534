import { Component, Input } from '@angular/core';
import { ValorPorPosto } from '@utils/interpolacao/interpolacao';
import {
	capitalizeFirstLetter,
	isNotNuloOuUndefined,
	numberToBrNumber,
} from '@utils';

@Component({
	selector: 'seira-popup-informacoes-iapm',
	templateUrl: './popup-informacoes-iapm.component.html',
	styleUrls: ['./popup-informacoes-iapm.component.scss'],
})
export class PopupInformacoesIapmComponent {
	@Input()
	markerData!: ValorPorPosto;
	constructor() {}

	protected readonly isNotNuloOuUndefined = isNotNuloOuUndefined;
	protected readonly numberToBrNumber = numberToBrNumber;
	protected readonly capitalizeFirstLetter = capitalizeFirstLetter;
}
