<div
	class="bg-white card-indisponivel p-2 text-tertiary font-size-12 d-inline-flex flex-column gap-1">
	<div class="d-inline-flex gap-2 align-items-center">
		<div>
			<i class="ph-warning-fill font-size-24"></i>
		</div>
		<div>
			Os recursos desta página não estão disponíveis para telas de dispositivos
			móveis.
			<br />
			Recomendamos o acesso via computador.
		</div>
	</div>
	<div class="d-inline-flex gap-2 align-items-center">
		<div>
			<i class="ph-warning-fill font-size-24" [style.visibility]="'hidden'"></i>
		</div>
		<seira-public-a redirectLink="/" customClass="mx-0 fw-normal"
			>Ir para a página inicial</seira-public-a
		>
	</div>
</div>
