<div>
	<div class="modal-header">
		<ng-content select="[header]"></ng-content>
		<seira-button
			class="ms-auto"
			type="button"
			classButton="btn-close"
			buttonStyle="custom"
			(clicked)="handleClose.emit($event)"></seira-button>
	</div>
	<div class="modal-body">
		<div [ngClass]="{ container: !customBodySize }">
			<ng-content select="[body]"></ng-content>
		</div>
	</div>
	<div class="modal-footer">
		<ng-content select="[footer]"></ng-content>
	</div>
</div>
