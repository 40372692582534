<label *ngIf="label" class="form-label text-tertiary" [for]="id"
	>{{ label }}<span class="text-danger" *ngIf="required">*</span></label
>
<select
	class="border-0 w-100 form-select bg-secondary text-primary form-select-lg"
	[formControlName]="controlName">
	<option
		[class]="optionsClass"
		*ngIf="placeholder"
		selected
		disabled
		hidden="hidden">
		{{ placeholder }}
	</option>
	<option [class]="optionsClass" *ngIf="!options.length" disabled>
		{{ notFoundText }}
	</option>
	<option
		[class]="optionsClass"
		*ngFor="let option of options"
		[value]="handleOption(option, optionValue)">
		{{ handleOption(option, optionLabel) }}
	</option>
</select>
