<div class="d-flex align-items-center">
	<seira-page-title class="w-100 align-items-center">
		<div class="d-flex justify-content-between align-items-center">
			<div style="color: #728187; font-weight: 400; font-size: 24px !important">
				Dados meteorológicos
				<i class="dot bg-primary dot-space"></i>
				Rede de monitoramento
			</div>
			<div class="d-flex justify-content-end">
				<seira-public-page-about
					class="size-about"
					[textosInformativos]="informacoesGerais"></seira-public-page-about>
				<seira-public-a
					*seiraIfViewportSize="'mobile'"
					href
					(click)="abrirModalOpcoesDaPagina($event)"
					><i class="ph-list"></i> Opções desta página</seira-public-a
				>
			</div>
		</div>
	</seira-page-title>
</div>

<seira-estacoes-monitoramento-mapa
	class="col col-xl-12 col-lg-12 col-12 d-flex flex-column"
	[homePage]="true"></seira-estacoes-monitoramento-mapa>
<ng-template #legendaMapaMobile>
	<seira-legenda-estacoes></seira-legenda-estacoes>
</ng-template>

<div
	class="d-flex w-100 justify-content-start"
	*seiraIfViewportSize="'desktop'">
	<div class="col-9">
		<seira-info-gerais
			id="informacoes-gerais"
			[textosInformativos]="informacoesGerais"></seira-info-gerais>
	</div>
</div>
