import { Component, Input } from '@angular/core';
import { ADTColumns } from 'angular-datatables/src/models/settings';
import {
	DadosRelatorio,
	Relatorios,
} from '@home/submodulos/dados-meteorologicos/interfaces/tabela-relatorio';

@Component({
	selector: 'seira-tabela-relatorios',
	templateUrl: './tabela-relatorios.component.html',
	styleUrls: ['./tabela-relatorios.component.scss'],
})
export class TabelaRelatoriosComponent {
	@Input() relatorios: Relatorios<DadosRelatorio>[] = [];
	colunas: ADTColumns[] = [
		{
			data: 'mesorregiao',
			title: 'Mesorregião',
			type: 'string',
			orderable: false,
		},
		{
			data: 'microrregiao',
			title: 'Microrregião',
			type: 'string',
			orderable: false,
		},
		{
			data: 'posto',
			title: 'Posto',
			type: 'string',
			orderable: false,
		},
		{
			data: 'latitude',
			title: 'Latitude',
			type: 'number',
			orderable: false,
		},
		{ data: 'longitude', title: 'Longitude', type: 'number', orderable: false },
		{ data: 'jan', title: 'Jan', type: 'number', orderable: false },
		{ data: 'fev', title: 'Fev', type: 'number', orderable: false },
		{ data: 'mar', title: 'Mar', type: 'number', orderable: false },
		{ data: 'abr', title: 'Abr', type: 'number', orderable: false },
		{ data: 'mai', title: 'Mai', type: 'number', orderable: false },
		{ data: 'jun', title: 'Jun', type: 'number', orderable: false },
		{ data: 'jul', title: 'Jul', type: 'number', orderable: false },
		{ data: 'ago', title: 'Ago', type: 'number', orderable: false },
		{ data: 'set', title: 'Set', type: 'number', orderable: false },
		{ data: 'out', title: 'Out', type: 'number', orderable: false },
		{ data: 'nov', title: 'Nov', type: 'number', orderable: false },
		{ data: 'dez', title: 'Dez', type: 'number', orderable: false },
	];
}
