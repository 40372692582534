import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map, Observable, ReplaySubject, retry, timer } from 'rxjs';
import { environment } from '@environment';

export interface InfoApi {
	versao: string;
}

const API_URL = environment.API_URL;

@Injectable({
	providedIn: 'root',
})
export class InfoService {
	private dados?: ReplaySubject<InfoApi>;

	constructor(private http: HttpClient) {}

	get info(): Observable<InfoApi> {
		if (this.dados !== undefined && this.dados !== null) {
			return this.dados.asObservable();
		}

		this.dados = new ReplaySubject<InfoApi>(1);

		this.http
			.get<InfoApi>(`${API_URL}info/`)
			.pipe(
				retry({
					delay: (e: HttpErrorResponse) => {
						if (e.status === 0) {
							this.dados?.next({
								versao: 'offline',
							});

							return timer(10000);
						}

						throw e;
					},
				})
			)
			.subscribe({
				next: (response: InfoApi) => {
					this.dados?.next(response);
				},
				error: (_e: HttpErrorResponse) => {
					this.dados?.next({
						versao: '?',
					});
				},
			});

		return this.dados.asObservable();
	}

	get versao(): Observable<string> {
		return this.info.pipe(map(({ versao }) => versao));
	}
}
