import { Component, Input } from '@angular/core';
import { isNotNuloOuUndefined, numberToBrNumber } from '@utils';
import { ValorDesvioInterpolacao } from '@utils/interpolacao/interpolacao';
import { Unidade } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/pages/variaveis/interfaces/variaveis';

@Component({
	selector: 'seira-popup-mapa-desvio',
	templateUrl: './popup-mapa-desvio.component.html',
	styleUrls: ['./popup-mapa-desvio.component.scss'],
})
export class PopupMapaDesvioComponent {
	@Input()
	markerData!: ValorDesvioInterpolacao;

	formatarNumero(numero: number): string {
		if (isNotNuloOuUndefined(numero)) {
			return (
				numberToBrNumber(numero.toFixed(2)) +
				(this.markerData?.desvio === 'desvioMilimetro' ? 'mm' : '%')
			);
		} else {
			return ' ';
		}
	}

	protected readonly isNotNuloOuUndefined = isNotNuloOuUndefined;
	protected readonly numberToBrNumber = numberToBrNumber;
	protected readonly Unidade = Unidade;
}
