import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import {
	InputType,
	regexInputTypeToRemove,
} from '@utils/directives/interfaces/input-type';

@Directive({
	selector: '[seiraInputType]',
})
export class InputTypeDirective {
	@Input() inputType: InputType | undefined;

	constructor(private _el: ElementRef) {}

	@HostListener('input', ['$event']) onInputChange(event: any) {
		if (!this.inputType) {
			return;
		}
		const initalValue = this._el.nativeElement.value;

		this._el.nativeElement.value = initalValue
			.match(regexInputTypeToRemove[this.inputType])
			.join('');
		if (initalValue !== this._el.nativeElement.value) {
			event.stopPropagation();
		}
	}
}
