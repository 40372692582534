import { Component, Input, OnInit } from '@angular/core';
import {
	ControlContainer,
	FormControl,
	FormGroupDirective,
} from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';

interface Mapeamento {
	[key: string]: string;
}

@Component({
	selector: 'seira-public-checkbox',
	templateUrl: './public-checkbox.component.html',
	styleUrls: ['./public-checkbox.component.scss'],
	viewProviders: [
		{ provide: ControlContainer, useExisting: FormGroupDirective },
	],
})
export class PublicCheckboxComponent implements OnInit {
	@Input() label!: string;
	@Input() labelPlacement: 'start' | 'end' = 'end';
	@Input() id: string = `public-radio-box-${uuidv4()}`;
	@Input() controlName!: string;
	@Input() qtd!: number;
	@Input() cor!: string;
	@Input() control!: FormControl;

	constructor(private controlContainer: ControlContainer) {}

	get iconURLByLabel(): string {
		const mapeamento: Mapeamento = {
			'Vertendo > 100 %': 'azul_escuro',
			'Favorável 70 a 100 %': 'azul_claro',
			'Em normalidade 50 a 70 %': 'verde',
			'Em observação 20 a 50 %': 'amarelo',
			'Atenção 10 a 20 %': 'laranja',
			'Situação crítica < 10 %': 'vermelho',
		};
		const nomeIcone = mapeamento[this.label.trim()] || 'cinza';
		return `/assets/images/reservatorios/res_${nomeIcone}.png`;
	}

	ngOnInit(): void {
		if (!this.control) {
			this.control = this.controlContainer.control?.get(
				this.controlName
			) as FormControl;
		}
	}
}
