<div class="mb-2" style="position: relative">
	<label class="form-label fw-semibold" [for]="id"
		>{{ label }}<span class="text-danger" *ngIf="isRequired">*</span></label
	>
	<div class="input-container">
		<input
			[type]="type"
			[formControlName]="controlName"
			[placeholder]="placeholder"
			[accept]="accept"
			class="form-control"
			[id]="id"
			[ngClass]="showsTextRequired || seiraError ? 'is-invalid' : ''"
			(change)="onFileSelected($event)" />
		<div *ngIf="file" class="button-download">
			<button
				*ngIf="control.enabled"
				class="border-0 btn btn-outline-light px-2 rounded-0"
				type="button"
				(click)="limparCampo()"
				data-bs-toggle="tooltip"
				title="Remover arquivo">
				<i class="ph-x font-size-14"></i>
			</button>
			<button
				class="btn btn-light"
				type="button"
				(click)="download()"
				data-bs-toggle="tooltip"
				title="Baixar arquivo">
				<i class="ph-file-arrow-down"></i>
			</button>
		</div>
	</div>
	<span
		class="invalid-feedback mt-0 d-block"
		*ngIf="showsTextRequired || seiraError">
		{{ textRequired || seiraError }}
	</span>
</div>
