import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '@environment';
import { Observable, tap } from 'rxjs';
import { UserService } from '../user/user.service';
import { PermissoesEnum } from '@administracao/submodulos/grupo/enums/permissoesEnum';

const API_URL = environment.API_URL;
const KEY = 'authTokenSeira';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	constructor(
		private http: HttpClient,
		private userService: UserService
	) {}

	hasToken() {
		return !!this.getToken();
	}

	setToken(token: string) {
		window.localStorage.setItem(KEY, token);
	}

	getToken() {
		return window.localStorage.getItem(KEY);
	}

	removeToken() {
		window.localStorage.removeItem(KEY);
	}

	authenticate(login: string, senha: string) {
		return this.http
			.post(API_URL + 'login', { login, senha }, { observe: 'response' })
			.pipe(
				tap((res: HttpResponse<any>) => {
					if (res.body !== null) {
						const authTokenSeira: string = res.body.token;
						this.userService.setToken(authTokenSeira);
					}
				})
			);
	}

	changePassword(
		login: string | undefined,
		senhaAtual: string,
		novaSenha: string
	): Observable<void> {
		return this.http.post<void>(API_URL + 'trocar-senha', {
			login: login,
			senha: senhaAtual,
			novaSenha: novaSenha,
		});
	}

	resetPassword(token: string, novaSenha: string): Observable<void> {
		return this.http.post<void>(API_URL + 'redefinir-senha', {
			token,
			novaSenha,
		});
	}

	recoverPassword(login: string, email: string): Observable<void> {
		return this.http.post<void>(API_URL + 'recuperar-senha', {
			login,
			email,
		});
	}

	hasPermission(permissao: PermissoesEnum) {
		return this.http.get<{
			possuiPermissao: boolean;
			novoToken: string;
			permissoes: string[];
		} | null>(API_URL + 'possui-permissao', {
			params: {
				permissao,
			},
		});
	}
}
