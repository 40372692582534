<form
	[formGroup]="recuperarSenhaForm"
	class="alterar-senha-form"
	(submit)="recuperarSenha()">
	<div class="card mb-0">
		<div class="card-body">
			<div class="text-center mb-3">
				<div
					class="d-inline-flex align-items-center justify-content-center mb-4 mt-2">
					<img src="/assets/images/logo_1_1.svg" class="h-64px" alt="logo" />
				</div>
				<h5 class="mb-0">Recuperar senha</h5>
				<span class="d-block text-muted">Preencha os campos abaixo</span>
			</div>

			<seira-input
				[showsTextRequired]="conditionShowTextRequired('login')"
				textRequired="Login é obrigatório."
				[required]="true"
				type="text"
				controlName="login"
				label="Login"
				placeholder="Informe o login"
				id="login_form"></seira-input>

			<seira-input
				[showsTextRequired]="conditionShowTextRequired('email')"
				textRequired="Email é obrigatório."
				[required]="true"
				type="text"
				controlName="email"
				label="Email"
				placeholder="Informe o email cadastrado"
				id="email_form"></seira-input>

			<seira-button
				buttonStyle="alterar"
				class="w-100"
				[loading]="carregando"
				type="submit"
				[disabledButton]="recuperarSenhaForm.invalid || carregando">
				Recuperar senha
			</seira-button>
		</div>
	</div>
</form>
