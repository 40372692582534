import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl } from '@angular/forms';

@Component({
	selector: 'seira-custom-radio-group',
	templateUrl: './custom-radio-group.component.html',
	styleUrls: ['./custom-radio-group.component.scss'],
})
export class CustomRadioGroupComponent implements OnInit {
	@Input() options!: any[];
	@Input() controlName!: string;
	@Input() control = new FormControl();
	constructor(private controlContainer: ControlContainer) {}
	isMobile: boolean = window.innerWidth < 560;

	ngOnInit(): void {
		this.control = this.controlContainer.control?.get(
			this.controlName
		) as FormControl;
	}
}
