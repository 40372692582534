<!-- Page content -->
<div class="page-content">
	<!-- Main content -->
	<div class="content-wrapper">
		<!-- Inner content -->
		<div class="content-inner">
			<!-- Content area -->
			<div class="content d-flex justify-content-center align-items-center">
				<seira-form-redefinir-senha></seira-form-redefinir-senha>
			</div>
			<seira-footer></seira-footer>
			<!-- /content area -->
		</div>
		<!-- /inner content -->
	</div>
	<!-- /main content -->
</div>
<!-- /page content -->
