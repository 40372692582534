<div class="col py-2 d-flex align-items-end justify-content-start">
	<div
		style="
			font-size: 2.25rem;
			color: #728187;
			font-style: normal;
			font-weight: 275;
			line-height: normal;
		">
		<div class="d-flex align-items-center">
			<ng-content></ng-content>
		</div>
	</div>
	<div
		*ngIf="sobreAPagina"
		class="py-2"
		style="margin-left: 10px; height: 100%; display: flex; align-items: center">
		<div style="height: 82%">
			<div class="d-flex align-items-center" style="height: 100%">
				<seira-public-a
					[fragment]="'informacoes-gerais'"
					class="text-primary"
					customClass="fw-light mx-2">
					<i class="ph-question-fill ms-1"></i>
					<ng-container *seiraIfViewportSize="'desktop'">
						Sobre esta página</ng-container
					>
				</seira-public-a>
			</div>
		</div>
	</div>
</div>
