<seira-loading [show]="loading" />
<div *ngIf="gerou && !loading && resultado">
	<seira-card-info aditionalClasses="mt-3">
		Placeholder informativo
	</seira-card-info>
	<seira-grafico-quantis
		(chartInstance)="onChartCreated($event)"
		[useMaximumLimit]="useMaximumLimit"
		[isAnual]="isAnual"
		[isPeriodosPluviometricos]="isPeriodosPluviometricos"
		[dado]="resultado"></seira-grafico-quantis>
</div>
<ng-container *ngIf="!gerou && !loading">
	<seira-sem-dados />
</ng-container>
