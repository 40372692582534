import { Component, HostBinding, OnInit } from '@angular/core';
import { TabMenuItem } from '@shared/interfaces/menu';
import { NavigationEnd, Router } from '@angular/router';
import { UserService } from '@core/services/user/user.service';
import { filter } from 'rxjs';
import { menuItens } from '@home/pages/home-page/menu-itens';

@Component({
	selector: 'seira-home-page',
	templateUrl: './home-page.component.html',
	styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
	@HostBinding('class') clazz = 'expandir-flex';
	activePathPage = 'Home';
	isLogado = false;
	menu = menuItens;
	subMenu: TabMenuItem[] = [];

	constructor(
		private router: Router,
		private userService: UserService
	) {
		router.events
			.pipe(filter(e => e instanceof NavigationEnd))
			.subscribe(() => this.defineSubmenu());
	}

	ngOnInit() {
		this.isLogado = this.userService.isLogged();
	}

	goToLogin() {
		window.open('/auth/login', '_blank');
	}

	private defineSubmenu() {
		const paths = this.router.url.split('/');
		const selectedMenuPath = paths[1];
		const selectedMenu = this.menu.find(item => item.path === selectedMenuPath);

		this.subMenu = selectedMenu?.submenu ?? [];
	}
}
