<div>
	<div class="d-flex flex-row gap-2 justify-content-center">
		<div
			class="py-3 mb-5 px-3 bg-white rounded shadow-lg d-flex flex-column"
			id="legenda"
			style="height: 100px"
			*seiraIfViewportSize="'desktop'">
			<div class="card-body d-flex flex-row align-items-center gap-3">
				<label class="text-tertiary fw-semibold">Legenda</label>
				<div class="d-flex flex-row align-items-center gap-3 mt-1">
					<div *ngFor="let item of legenda" class="text-end">
						<seira-icon-with-label>
							<i
								icon
								[style.color]="item.color"
								class="ph ph-circle-fill me-1"></i>
							<label label class="text-tertiary">{{ item.label }}</label>
						</seira-icon-with-label>
						<span
							><small>{{ item.index }} município(s)</small></span
						>
					</div>
				</div>
			</div>
		</div>
		<seira-public-button-group
			*seiraIfViewportSize="'desktop'"
			class="ms-md-2 mt-md-0 mt-2"
			id="buttons"
			[buttons]="botoesDeExportacao"></seira-public-button-group>
		<div
			id="legenda_mobile"
			*seiraIfViewportSize="'mobile'"
			class="min-vw-100 d-flex flex-row">
			<div class="col-md-4 col-sm-4" style="word-wrap: break-word">
				<label class="text-tertiary fw-semibold mb-2 d-block">LEGENDA</label>
				<seira-public-a href (click)="openModal()" class="d-block"
					>Toque para exibir a legenda</seira-public-a
				>
			</div>
			<div class="col-md-3 col-sm-3">
				<p class="text-tertiary fw-semibold mb-2 row">EXPORTAR</p>
				<div class="row">
					<button
						style="max-width: 60px"
						type="button"
						(click)="exportarPDF()"
						class="w-auto btn btn-primary d-flex align-items-center flex-row py-1 px-3 vstack gap-1">
						<img
							style="height: 15px"
							src="./assets/images/files/pdf.svg"
							alt="pdf" />
						.pdf
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
