import { Component, Input, TemplateRef } from '@angular/core';
import { LegendaDeCores } from '@home/submodulos/dados-meteorologicos/interfaces/legenda-de-cores';

@Component({
	selector: 'seira-map-color-legend',
	templateUrl: './map-color-legend.component.html',
	styleUrls: ['./map-color-legend.component.scss'],
})
export class MapColorLegendComponent {
	@Input() title!: string;
	@Input() legendaDeCores!: LegendaDeCores;
	@Input() actionsTemplate!: TemplateRef<any>;
	@Input() maxWidth!: string;
}
