<div class="legenda-horizontal">
	<div
		*ngFor="let key of getKeys()"
		class="legenda-item"
		[style]="borderRadius(key)"
		[style.backgroundColor]="legendaInfo[key]"></div>
</div>
<div class="legenda-horizontal">
	<div *ngFor="let key of getLegendas()" class="legenda-item">
		{{ key.valor }} mm
	</div>
</div>
