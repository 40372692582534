import { Injectable } from '@angular/core';
import { HttpClientProtectedService } from '@core/services/auth/http-client-protected.service';
import { Rotas } from '@core/enums/routes';
import { Observable } from 'rxjs';
import { EstacaoUltimasMedicoes } from '@home/submodulos/dados-meteorologicos/submodulos/monitoramento/pages/variaveis/interfaces/estacao-ultimas-medicoes';
import ZonedDateTime from '@utils/date/zoned-datetime';

@Injectable({
	providedIn: 'root',
})
export class VariaveisService {
	constructor(private http: HttpClientProtectedService) {}

	medicoesPersonalizada(
		body: {
			diaInicio: ZonedDateTime;
			diaFim: ZonedDateTime;
		},
		variavel: string
	): Observable<EstacaoUltimasMedicoes[]> {
		return this.http.post(
			Rotas.ACOMPANHAMENTO_COLETA + `/medicoes-personalizada/${variavel}`,
			body
		);
	}
	ultimasMedicoes(
		janelaTempo: number,
		variavel: string
	): Observable<EstacaoUltimasMedicoes[]> {
		return this.http.get(
			`${Rotas.ACOMPANHAMENTO_COLETA}/ultimas-medicoes/${variavel}?janelaTempo=${janelaTempo}`
		);
	}
}
