import * as Highcharts from 'highcharts/highstock';
import { QuantisCategoriasType } from '../../types';
import { QuantisCategoriasMapColors } from '../../interfaces';

const configResponsividade: Highcharts.Options['responsive'] = {
	rules: [
		{
			condition: {
				maxWidth: 500,
			},
			chartOptions: {
				subtitle: {
					y: 0,
				},
				legend: {
					enabled: true,
					align: 'center',
					verticalAlign: 'bottom',
					layout: 'horizontal',
				},
				yAxis: {
					labels: {
						align: 'left',
						x: 0,
						y: -5,
					},
					title: {
						text: null,
					},
				},
				credits: {
					enabled: false,
				},
			},
		},
		{
			condition: {
				minWidth: 0,
			},
			chartOptions: {
				rangeSelector: {
					y: -26,
				},
			},
		},
		{
			condition: {
				minWidth: 300,
			},
			chartOptions: {
				rangeSelector: {
					y: -14,
				},
			},
		},
		{
			condition: {
				minWidth: 560,
			},
			chartOptions: {
				rangeSelector: {
					y: 20,
				},
				subtitle: {
					y: 14,
				},
			},
		},
	],
};

export const optionsChartReservatorio = (): Highcharts.Options => ({
	tooltip: {
		valueSuffix: ' mm',
		valueDecimals: 1,
	},
	chart: {
		type: 'column',
	},
	responsive: configResponsividade,
	navigator: {
		enabled: false,
	},
	scrollbar: {
		enabled: false,
	},
	title: {},

	noData: {
		useHTML: true,
	},
	exporting: {
		enabled: true,
	},
	credits: {
		enabled: false,
	},
	rangeSelector: {
		enabled: false,
	},
	yAxis: {
		max: 500,
		opposite: false,
		labels: {
			align: 'left',
			x: 0,
			y: -5,
		},
		title: {
			text: 'Precipitação (mm)',
		},
		stackLabels: {
			enabled: false,
			style: {
				fontWeight: 'bold',
				color: 'gray',
			},
		},
	},
	legend: {
		enabled: true,
		align: 'center',
		verticalAlign: 'bottom',
		layout: 'horizontal',
	},

	plotOptions: {
		column: {
			grouping: false,
			shadow: false,
		},
	},
	xAxis: {
		title: {
			text: 'Meses',
		},
		type: 'category',
	},
	series: [],
});

export type QuantisHighcartsSerieOptions = {
	name: QuantisCategoriasType;
};

export const relatorioQuantis: Array<
	Partial<Highcharts.SeriesOptionsType> & QuantisHighcartsSerieOptions
> = [
	{
		name: 'Muito Chuvoso',
		type: 'column',
		color: QuantisCategoriasMapColors.get('Muito Chuvoso'),
		data: [],
		pointPadding: 0.2,
		pointPlacement: 0.0,
	},
	{
		name: 'Chuvoso',
		type: 'column',
		color: QuantisCategoriasMapColors.get('Chuvoso'),
		data: [],
		pointPadding: 0.2,
		pointPlacement: 0.0,
	},
	{
		name: 'Normal',
		type: 'column',
		color: QuantisCategoriasMapColors.get('Normal'),
		data: [],
		pointPadding: 0.2,
		pointPlacement: 0.0,
	},
	{
		name: 'Seco',
		type: 'column',
		color: QuantisCategoriasMapColors.get('Seco'),
		data: [],
		pointPadding: 0.2,
		pointPlacement: 0.0,
	},
	{
		name: 'Muito Seco',
		type: 'column',
		color: QuantisCategoriasMapColors.get('Muito Seco'),
		data: [],
		pointPadding: 0.2,
		pointPlacement: 0.0,
	},
	{
		name: 'Observado',
		type: 'column',
		color: QuantisCategoriasMapColors.get('Observado'),
		data: [],
		pointPadding: 0.4,
		pointPlacement: 0.0,
	},
];
