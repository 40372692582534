import { Component, OnDestroy, OnInit } from '@angular/core';
import { debounceTime, fromEvent, Subscription } from 'rxjs';

const EVENT_DEBOUNCE_TIME_IN_MS = 500;
const ACTIVATION_SCROLL_VALUE = 200;

@Component({
	selector: 'seira-scroll-to-top-button',
	templateUrl: './scroll-to-top-button.component.html',
	styleUrls: ['./scroll-to-top-button.component.scss'],
})
export class ScrollToTopButtonComponent implements OnInit, OnDestroy {
	isVisible = false;
	topElement?: HTMLElement;
	scrollEventSubscription?: Subscription;

	ngOnInit() {
		this.topElement = document.getElementsByTagName('div')[1];

		if (!this.topElement) return;

		this.scrollEventSubscription = fromEvent(this.topElement, 'scroll')
			.pipe(debounceTime(EVENT_DEBOUNCE_TIME_IN_MS))
			.subscribe(() => this.onScroll());
	}

	onScroll() {
		if (!this.topElement) return;

		const scrollTop = this.topElement['scrollTop'] ?? 0;

		this.isVisible = scrollTop > ACTIVATION_SCROLL_VALUE;
	}

	scrollToTop() {
		if (!this.topElement) return;

		this.topElement['scrollTop'] = 0;
	}

	ngOnDestroy() {
		if (this.scrollEventSubscription) {
			this.scrollEventSubscription.unsubscribe();
		}
	}
}
