<nav class="navbar bg-primary">
	<div *ngIf="mode === 'home'" class="d-flex align-items-center">
		<a href="/">
			<img
				src="../assets/images/logo_5.svg"
				alt="logo seira"
				style="width: 40px" />
		</a>
		<a
			class="text-white ms-3"
			href
			data-bs-toggle="offcanvas"
			data-bs-target="#offcanvasNavbar"
			aria-controls="offcanvasNavbar"
			aria-expanded="false">
			<i class="ph-list"></i> Menu geral
		</a>
	</div>
	<div
		*ngIf="mode === 'landing-page'"
		class="d-flex align-items-center justify-content-between px-1 py-2 w-100">
		<a
			class="text-white"
			href
			data-bs-toggle="offcanvas"
			data-bs-target="#offcanvasNavbar"
			aria-controls="offcanvasNavbar"
			aria-expanded="false">
			<i class="ph-list mt-2" style="font-size: 25px; font-weight: 900"></i>
		</a>
		<a class="d-flex justify-content-center logo-seira" href="/">
			<img
				src="../assets/images/logo_1.svg"
				alt="logo seira"
				style="width: 160px" />
		</a>
		<button
			id="area_restrita"
			type="button"
			(click)="acessoAreaRestrita()"
			class="btn btn-light border-0 text-white mt-2">
			<i class="ph ph-user-fill"></i>
			<span class="d-none d-md-inline-block mx-md-2">Área restrita</span>
		</button>
	</div>
	<div
		class="offcanvas offcanvas-start text-bg-primary border-0"
		tabindex="-1"
		id="offcanvasNavbar"
		aria-labelledby="offcanvasNavbarLabel">
		<div class="offcanvas-header">
			<div class="d-flex align-items-center">
				<img src="../assets/images/logo_5.svg" alt="" style="width: 40px" />
				<a href class="text-white ms-4" data-bs-dismiss="offcanvas">
					<i class="ph-x"></i>
					Fechar o menu
				</a>
			</div>
		</div>

		<div class="menu">
			<div class="accordion" id="menu">
				<div class="accordion-item" *ngFor="let item of menu">
					<button
						class="accordion-button collapsed text-white"
						type="button"
						data-bs-toggle="collapse"
						[attr.data-bs-target]="'#' + item.path"
						[attr.aria-controls]="item.path"
						aria-expanded="false">
						{{ item.label }}
					</button>

					<div
						*ngFor="let subitem of item.submenu"
						id="{{ item.path }}"
						class="accordion-collapse collapse">
						<div class="accordion-body">
							<div class="d-flex flex-column align-items-start gap-1">
								<div
									class="d-flex gap-1 align-items-center"
									style="min-width: 100%">
									<a
										*ngIf="!subitem.submenu"
										class="dropdown-item text-white"
										href="{{ subitem.path }}"
										><i class="dot icon bg-white"></i> {{ subitem.label }}</a
									>

									<div
										*ngIf="subitem.submenu"
										class="accordion-item sub-accordion">
										<button
											class="accordion-button collapsed text-white pb-0"
											type="button"
											data-bs-toggle="collapse"
											[attr.data-bs-target]="
												'#' + subitem.path.replace('/', '')
											"
											[attr.aria-controls]="subitem.path.replace('/', '')"
											aria-expanded="false">
											<i class="dot icon bg-white"></i>{{ subitem.label }}
										</button>
										<div
											*ngFor="let item_subitem of subitem.submenu"
											id="{{ subitem.path.replace('/', '') }}"
											class="accordion-collapse collapse">
											<div class="accordion-body pt-2 pb-0">
												<div class="d-flex flex-column align-items-start gap-1">
													<div class="d-flex gap-1 align-items-center">
														<a
															class="dropdown-item text-white"
															href="{{ item_subitem.path }}"
															><i class="dot icon bg-white"></i>
															{{ item_subitem.label }}</a
														>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<hr class="accordion-item-separtor" />
				</div>
			</div>
		</div>
	</div>
</nav>
